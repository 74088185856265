import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import CenturyLabForm from './CenturyLabForm';
import { API_URL } from 'links/API_LINKS';
import { useQueryClient } from 'react-query';
import axios from 'axios';

const EditCenturyTask = ({
  currentBatch,
  snackbarActions,
  handleClose,
  currentProject,
}) => {
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <Box>
      <Box>
        <Formik
          enableReinitialize={false}
          initialValues={{
            projectId: currentProject._id,
            batchId: currentProject.batchId,
            title: currentProject.title || '',
            type: '21st Century Skills',
            level: currentProject.level || 'Basic',
            introduction: currentProject.introduction || '',
            template: currentProject.template || {
              link: '',
              description: '',
            },
            tasks: currentProject.tasks || [
              {
                skillId: [],
                learningOutcomes: '',
                backgroundDescription: '',
                taskInstructions: '',
                resources: '',
                // level: 'Basic',
                week: '',
                publishAt: new Date(),
                dueDateTime: new Date(),
              },
            ],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            tasks: Yup.array().of(
              Yup.object().shape({
                learningOutcomes: Yup.string().required(
                  'Learning Outcomes is required'
                ),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
                week: Yup.number().required(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            axios({
              method: 'PUT',
              url: API_URL + '/vga-project',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Project added successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries(
                  `fetchCurrentBatchProject${currentBatch._id}`
                );
                queryClient.invalidateQueries(
                  `fetchCurrentBatch${currentBatch._id}`
                );
                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to add Project, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <CenturyLabForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                projectTaskToEdit={currentProject}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Update Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              ></Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditCenturyTask;

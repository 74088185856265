import React from 'react';
import { Grid, Container } from '@mui/material';

// components
import Page from '../components/Page';
import TeamAdmins from '../components/_dashboard/team';

const Team = () => {
  return (
    <Page title='Team | Belong'>
      <Container maxWidth='xl'>
        <Grid item xs={12} sm={12} md={12}>
          <TeamAdmins />
        </Grid>
      </Container>
    </Page>
  );
};

export default Team;

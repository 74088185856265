import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import FacultyBody from './FacultyBody';
import EditFaculty from './EditFaculty';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function FacultyHeader({ data, setValue }) {
  const [facultyToEdit, setFacultyToEdit] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((faculty) => {
    return {
      id: faculty._id,
      profile: faculty.profile,
      name: faculty.name,
      designation: faculty.designation,
      faculty: faculty,
    };
  });

  const TABLE_HEAD = [
    { id: 'profile', label: '', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'designation', label: 'Designation', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {facultyToEdit ? (
        <EditFaculty
          facultyToEdit={facultyToEdit}
          setFacultyToEdit={setFacultyToEdit}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'
          >
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <FacultyBody
                    key={row._id}
                    row={row}
                    setFacultyToEdit={setFacultyToEdit}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
}

export default FacultyHeader;

import React from 'react';
import { Typography, Box, Stack, CardMedia, Grid } from '@mui/material';

const FirstPage = ({ logoUrl }) => {
  return (
    <Box sx={{ bgcolor: '#f9f9f9' }}>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <Box mr={5} mt={1}>
          <CardMedia
            sx={{ height: 100 }}
            component='img'
            image={logoUrl}
            alt='image'
          />
        </Box>
      </Stack>
      <Grid container spacing={3} my={1}>
        <Grid item xs={4}>
          <Typography variant='h6' m={4} mt={20}>
            Employability Masterclass Assessment Report
          </Typography>
          <Box mx={6} mb={2}>
            <CardMedia
              component='img'
              image='https://res.cloudinary.com/belong/image/upload/v1629792864/uploaded_resources/logo_exlnou.png'
              alt='image'
            />
          </Box>
          <Typography variant='body-2' m={4}>
            Powered by Belong
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Stack direction='row' justifyContent='flex-end' alignItems='center'>
            <Box mr={5}>
              <CardMedia
                sx={{ maxWidth: 500 }}
                component='img'
                image='http://res.cloudinary.com/belong/image/upload/v1652344779/uploaded_resources/3255469_lczcui.jpg'
                alt='image'
              />
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstPage;

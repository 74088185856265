import React from 'react';
import { Box, Grid, Typography, Avatar, Stack } from '@mui/material';
import Chart from 'react-apexcharts';
import _ from 'lodash';

const userExperienceLabel = [
  'Communication',
  'Creativity',
  'Analytical Skills',
  'Self Awareness',
];

const TopPerformer = ({ users, questions }) => {
  let userExperienceData = [8, 6, 10, 10];
  if (questions.length) {
    const communicationQuestion = questions.filter((a) => {
      return a.category === 'COMMUNICATION';
    })[0].questions.length;
    const creativityQuestion = questions.filter((a) => {
      return a.category === 'CREATIVITY';
    })[0].questions.length;
    const analyticalQuestion = questions.filter((a) => {
      return a.category === 'ANALYTICAL_SKILLS';
    })[0].questions.length;
    const selfQuestion = questions.filter((a) => {
      return a.category === 'SELF_AWARENESS';
    })[0].questions.length;
    userExperienceData = [
      communicationQuestion,
      creativityQuestion,
      analyticalQuestion,
      selfQuestion,
    ];
  }

  const piSeries = userExperienceData;
  const piOptions = {
    chart: {
      width: 380,
      type: 'pie',
    },
    labels: userExperienceLabel,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
  };

  const top5User = users.filter((user, index) => {
    return (
      index < 5 &&
      user.masterclassAssessment &&
      user.masterclassAssessment.percentageScore
    );
  });
  const assessmentGiven = users.filter((user) => {
    return (
      user.masterclassAssessment && user.masterclassAssessment.percentageScore
    );
  });
  const avgANALYTICAL_SKILLS =
    assessmentGiven.reduce(
      (count, user) =>
        count + user.masterclassAssessment.percentageScore.ANALYTICAL_SKILLS,
      0
    ) / assessmentGiven.length;
  const avgCOMMUNICATION =
    assessmentGiven.reduce(
      (count, user) =>
        count + user.masterclassAssessment.percentageScore.COMMUNICATION,
      0
    ) / assessmentGiven.length;
  const avgCREATIVITY =
    assessmentGiven.reduce(
      (count, user) =>
        count + user.masterclassAssessment.percentageScore.CREATIVITY,
      0
    ) / assessmentGiven.length;
  const avgSELF_AWARENESS =
    assessmentGiven.reduce(
      (count, user) =>
        count + user.masterclassAssessment.percentageScore.SELF_AWARENESS,
      0
    ) / assessmentGiven.length;

  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: [
        'ANALYTICAL SKILLS',
        'COMMUNICATION',
        'CREATIVITY',
        'SELF AWARENESS',
      ],
    },
  };

  const series = [
    {
      name: 'series-1',
      data: [
        parseInt(avgANALYTICAL_SKILLS),
        parseInt(avgCOMMUNICATION),
        parseInt(avgCREATIVITY),
        parseInt(avgSELF_AWARENESS),
      ],
    },
  ];

  let categoryScore = [
    {
      name: 'Analytics',
      score: avgANALYTICAL_SKILLS,
      text: 'Need to improve analytical skills and be open to work with data to take informed decisions & efficiently solve complex problems',
    },
    {
      name: 'Communications',
      score: avgCOMMUNICATION,
      text: 'Need to develop an understanding of best workplace practices and improve communication style',
    },
    {
      name: 'Creativity',
      score: avgCREATIVITY,
      text: 'Need to develop a more creative mindset and be open to exploring new thoughts and ideas to build unique innovative solutions',
    },
    {
      name: 'Self Awareness',
      score: avgSELF_AWARENESS,
      text: 'Need to develop an ability to reflect and improve understanding of their strengths and weaknesses to work towards growth',
    },
  ];

  const sortCategoryScore = _.orderBy(categoryScore, ['score'], ['desc']);

  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <Box my={2}>
        <Grid container spacing={3} mb={3}>
          <Grid item xs={3}>
            <Typography mb={2} align='center' variant='h5'>
              TOP PERFORMERS
            </Typography>
            {top5User.map((user) => {
              return (
                <Box
                  key={user._id}
                  mb={5}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Avatar alt='1' src='' sx={{ width: 56, height: 56 }} />
                  </Box>
                  <Box ml={2}>
                    <Typography variant='h6'>{user.name} </Typography>
                    <Typography variant='body1'>
                      {user.totalPercentage} %
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Grid>
          <Grid item xs={9}>
            <Box>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={4}>
                  <Typography variant='h5'> SKILLS TESTED</Typography>
                  <Typography align='justify' variant='body1'>
                    The learners are being evaluated through Belong's
                    Employability Assessment on the basis of 4 critical
                    workplace readiness skills - Business Communication,
                    Observations & Analytics, Creativity & Reasoning and Self
                    Awareness.The weightage of each skill can be seen in the
                    figure to the right.
                  </Typography>
                </Grid>
                <Grid item xs={7} mx={1}>
                  <Chart
                    options={piOptions}
                    series={piSeries}
                    type='pie'
                    // heading='User Experience'
                    //label={userExperienceLabel}
                    //data={userExperienceData}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Typography variant='h6' mb={1}>
                AVERAGE COHORT PERFORMANCE ACROSS EVERY SKILL
              </Typography>
              <Grid container spacing={3} mb={3}>
                <Grid item xs={4}>
                  <Typography align='justify' variant='body1'>
                    On average, the cohort’s strongest skill is{' '}
                    {sortCategoryScore[0].name}. This is followed by{' '}
                    {sortCategoryScore[1].name} and {sortCategoryScore[2].name}.
                    The cohort however needs to improve its overall score in{' '}
                    {sortCategoryScore[3].name}. The low score indicates that
                    the students {sortCategoryScore[3].text}.
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Chart
                    options={options}
                    series={series}
                    type='bar'
                    width={450}
                    height={300}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default TopPerformer;

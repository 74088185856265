import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import { useQuery, useQueryClient } from 'react-query';
import ShowFilesPopup from '../../halper/showFilesPopup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Modal,
  MenuItem,
  Typography,
  Stack,
  Button,
} from '@mui/material';

import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { API_URL, API_URL_V1 } from '../../../../links/API_LINKS';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchCountry = async () => {
  const res = await axios.get(API_URL_V1 + '/countrytimezones/countries');
  return res;
};

const fetchIndustry = async () => {
  const res = await axios.get(API_URL + '/industries', {
    params: { limit: 0 },
  });
  return res;
};

const EditCompany = ({ companyToEdit, setCompanyToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const [sections, setSections] = useState(1);
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const countries = useQuery('fetchCountry', fetchCountry);
  const industries = useQuery('fetchIndustry', fetchIndustry);
  const queryClient = useQueryClient();
  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }

  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setCompanyToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          companyId: companyToEdit._id,
          name: companyToEdit.name,
          website: companyToEdit.website,
          country: companyToEdit.country,
          industryId: companyToEdit.industryId,
          slogan: companyToEdit.slogan,
          companyImageUrl: companyToEdit.companyImageUrl,
          logoUrl: companyToEdit.logoUrl,
          // contact: companyToEdit.contact,
          companyPhone: companyToEdit.companyPhone,
          employeeName: companyToEdit.employeeName,
          companyEmail: companyToEdit.companyEmail,
          companyStage: 2,
          sectionTitle: companyToEdit.sectionTitle,
          sectionContent: companyToEdit.sectionContent || [
            {
              sectionNumber: 0,
              contentToPublish: '',
              contentType: 'Text',
            },
          ],
          socialMediaHandles: companyToEdit.socialMediaHandles || {
            linkedIn: '',
            twitter: '',
            instagram: '',
            youtube: '',
            facebook: '',
          },
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a name'),
          website: Yup.string().url().required(),
          companyImageUrl: Yup.string().url().required(),
          logoUrl: Yup.string().url().required(),
          slogan: Yup.string().required(),
          industryId: Yup.string().required(),
          sectionContent: Yup.array().of(
            Yup.object().shape({
              contentToPublish: Yup.string().required(
                'Company Brief content is required'
              ),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/company',
            data: values,
          })
            .then(function (response) {
              console.log(response);
              queryClient.invalidateQueries('fetchAllCompanies');
              setServerResponse(response.data.msg);
              setSubmitting(false);
              setCompanyToEdit(null);
            })
            .catch(function (response) {
              console.log(response);
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='name'
                  type='text'
                  label='Company Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='website'
                  type='text'
                  label='Company Website'
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='country'
                  label='Country'
                >
                  {countries.status === 'success' &&
                    Object.entries(countries.data.data).map(
                      ([countryCode, countryData]) => {
                        return (
                          <MenuItem key={countryCode} value={countryData.name}>
                            {countryData.name}
                          </MenuItem>
                        );
                      }
                    )}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='industryId'
                  label='Industry'
                >
                  {industries.status === 'success' &&
                    industries.data.data.data.map((indistry) => {
                      return (
                        <MenuItem key={indistry._id} value={indistry._id}>
                          {indistry.title}
                        </MenuItem>
                      );
                    })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='companyImageUrl'
                  type='text'
                  label='Company ImageUrl'
                />
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                    setSelectedField('companyImageUrl');
                    setRefresh(!refresh);
                  }}
                >
                  Select Image
                </Button>
                <div>
                  <img src={values.companyImageUrl} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='logoUrl'
                  type='text'
                  label='Company Logo'
                />
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                    setSelectedField('logoUrl');
                    setRefresh(!refresh);
                  }}
                >
                  Select Image
                </Button>
                <div>
                  <img src={values.logoUrl} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <TextInput
                  fullWidth
                  size='small'
                  name='slogan'
                  type='text'
                  label='Company slogan'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='employeeName'
                  type='text'
                  label='Contact Person'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='companyPhone'
                  type='text'
                  label='Contact Person phone'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <TextInput
                  fullWidth
                  disabled
                  size='small'
                  name='companyEmail'
                  type='text'
                  label='Contact Person Email'
                /> */}
                {values.companyEmail && (
                  <Typography>Email: {values.companyEmail} </Typography>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
              sx={{
                backgroundColor: 'primary.light',
                padding: '20px',
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: '2px',
                marginY: '10px',
              }}
            >
              <Box
                sx={{
                  backgroundColor: 'primary.light',
                  padding: '20px',
                  border: '1px solid',
                  borderColor: 'primary.main',
                  borderRadius: '2px',
                  marginY: '10px',
                }}
              >
                <Grid container spacing={2} mb={1}>
                  <Grid item xs={12}>
                    <Typography textAlign='center' variant='subtitle2'>
                      Social Media
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      fullWidth
                      size='small'
                      name='socialMediaHandles.twitter'
                      type='text'
                      label='twitter Url'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      fullWidth
                      size='small'
                      name='socialMediaHandles.facebook'
                      type='text'
                      label='facebook Url'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      fullWidth
                      size='small'
                      name='socialMediaHandles.instagram'
                      type='text'
                      label='instagram Url'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      fullWidth
                      size='small'
                      name='socialMediaHandles.linkedIn'
                      type='text'
                      label='linkedIn Url'
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextInput
                      fullWidth
                      size='small'
                      name='socialMediaHandles.youtube'
                      type='text'
                      label='youtube Url'
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Typography>Company Brief</Typography>
            <Button
              variant='text'
              color='primary'
              onClick={() => {
                setSections(sections + 1);
              }}
              type='button'
            >
              Add section +
            </Button>
            {sections > 1 && (
              <Button
                variant='text'
                color='primary'
                onClick={() => {
                  setSections(sections - 1);
                }}
                type='button'
              >
                Remove section -
              </Button>
            )}

            <Box
              sx={{
                backgroundColor: 'primary.light',
                padding: '20px',
                border: '1px solid',
                borderColor: 'primary.main',
                borderRadius: '2px',
                marginY: '10px',
              }}
            >
              {[...Array(sections)].map((e, i) => (
                <div key={i}>
                  <Stack direction='row' justifyContent='flex-start'>
                    <Grid container>
                      <Grid item xs={4} md={2}>
                        <Typography variant='subtitle1' m={2} gutterBottom>
                          Section {i + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={8} md={10}>
                        <TextInput
                          name={`sectionTitle[${i}]`}
                          type='text'
                          placeholder='Title'
                          fullWidth
                        ></TextInput>
                      </Grid>
                    </Grid>
                  </Stack>
                  <FieldArray
                    name='sectionContent'
                    render={(arrayHelpers) => (
                      <>
                        <div className='space-y-4 mt-6'>
                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                sectionNumber: i,
                                contentToPublish: '',
                                contentType: 'Text',
                              })
                            }
                          >
                            +ADD
                          </Button>

                          {values.sectionContent.map((content, index) => {
                            return (
                              content.sectionNumber === i && (
                                <Grid
                                  container
                                  rowSpacing={3}
                                  columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                  mb={3}
                                >
                                  {' '}
                                  <Grid item xs={12} md={6}>
                                    <Grid container>
                                      <Grid item xs={4}>
                                        <Typography
                                          variant='subtitle1'
                                          gutterBottom
                                        >
                                          Content to publish
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={8}>
                                        <TextInput
                                          primary
                                          multiline
                                          fullWidth
                                          label='Content'
                                          name={`sectionContent[${index}].contentToPublish`}
                                          className='w-1/3 p-2'
                                          placeholder='Content'
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid item xs={12} md={4}>
                                    <SelectInput
                                      name={`sectionContent.${index}.contentType`}
                                    >
                                      <MenuItem value='Text'>Text</MenuItem>
                                      <MenuItem value='List'>List</MenuItem>
                                      <MenuItem value='Tab'>Tab</MenuItem>
                                      <MenuItem value='Link'>Link</MenuItem>
                                      <MenuItem value='Image'>Image</MenuItem>
                                      <MenuItem value='File'>File</MenuItem>
                                    </SelectInput>
                                  </Grid>
                                  <Grid item xs={2} sm={2}>
                                    {index > 0 && (
                                      <Button
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        -Remove
                                      </Button>
                                    )}
                                  </Grid>
                                </Grid>
                              )
                            );
                          })}
                        </div>
                      </>
                    )}
                  />
                </div>
              ))}
            </Box>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EditCompany;

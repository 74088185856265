import React, { useState } from 'react';
import { TableRow, TableCell, Box } from '@mui/material';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import ViewSubEvent from './ViewSubEvent';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SubEventBody = ({
  event,
  index,
  allEvent,
  setAllEvent,
  subEvent,
  subIndex,
  snackbarActions,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function deleteSubEvent() {
    if (
      window.confirm(
        'Are you sure you want to permanently delete ' +
          subEvent.title +
          'Event?'
      )
    ) {
      const tempAllEvent = [...allEvent];
      let tempEvent = { ...event };
      const tempSubEvents = [...tempEvent.subEvents];
      tempSubEvents.splice(subIndex, 1);
      tempEvent = { ...event, subEvents: tempSubEvents };
      tempAllEvent[index] = tempEvent;
      setAllEvent(tempAllEvent);
    }
  }

  return (
    <>
      <TableRow key={subIndex}>
        <TableCell>{subEvent.title}</TableCell>
        <TableCell>
          {subEvent.eventDate &&
            format(new Date(subEvent.eventDate), 'do LLLL yyyy h:mm a iiii')}
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={handleOpen}>
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell>
          {subEvent.checked && <CheckCircleIcon color='success' />}
        </TableCell>
        <TableCell>
          <IconButton
            size='small'
            color='error'
            onClick={() => deleteSubEvent()}
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ViewSubEvent
            event={event}
            index={index}
            allEvent={allEvent}
            setAllEvent={setAllEvent}
            handleClose={handleClose}
            subEvent={subEvent}
            subIndex={subIndex}
            snackbarActions={snackbarActions}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SubEventBody;

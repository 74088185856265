import React from 'react';
import { TableRow, TableCell, Button } from '@mui/material';
import exportFromJSON from 'export-from-json';

const allCategory = [
  'upskilling',
  'masterclass',
  'companyTasks',
  'coach',
  'companyMentor',
  'futureReadiness',
  'platform',
  'recommendationRating',
];

const NpsBody = ({ batch }) => {
  const users = batch.users || [];
  const totalUserCount = users.length;
  const userFeedBacks = users
    .filter((item) => {
      return item.vgaFeedback?.data;
    })
    .map((el) => {
      return el.vgaFeedback?.data.filter((asd) => {
        return asd.questionType === 'radio';
      });
    });
  const activeUserCount = users.filter(
    (item) => item.status === 'Active'
  ).length;

  const totalFeedbackCount = userFeedBacks.length;

  const userAvgScore = userFeedBacks.map((el) => {
    const categoryScores = {};

    allCategory.forEach((category) => {
      categoryScores[category] = { sum: 0, count: 0 };
    });

    el.forEach((item) => {
      if (categoryScores[item.category]) {
        categoryScores[item.category].sum += item.value;
        categoryScores[item.category].count += 1;
      }
    });

    const averageScores = {};

    for (const category in categoryScores) {
      const { sum, count } = categoryScores[category];
      averageScores[category] = count ? sum / count : 0;
    }

    return averageScores;
  });

  const finalNps = allCategory.map((category) => {
    let promoters = 0;
    let detractors = 0;
    userAvgScore.forEach((item) => {
      if (item[category] <= 2) {
        detractors++;
      }
      if (item[category] >= 4) {
        promoters++;
      }
    });

    const npsScore = Math.round(
      ((promoters - detractors) * 100) / totalFeedbackCount
    );

    return { category, npsScore };
  });

  const npsObj = finalNps.reduce((acc, { category, npsScore }) => {
    acc[category] = npsScore;
    return acc;
  }, {});

  const feedbacks = users.map((user) => {
    const userObj = {
      Batch: batch.name,
      Name: user.name,
      Email: user.email,
      UserStatus: user.status,
    };
    const vgaFeedback = user.vgaFeedback;

    if (vgaFeedback?.data?.length) {
      //   const radioFeedbacks = vgaFeedback.data.filter((el) => {
      //     return el.questionType === 'radio';
      //   });

      vgaFeedback.data.forEach((el) => {
        userObj[`${el.category}- ${el.question}`] = el.value;
      });
      //   const avgScore =
      //     radioFeedbacks.reduce((acc, curr) => acc + curr.value, 0) /
      //     radioFeedbacks.length;
      //   userObj['Average Score'] = avgScore.toFixed(2);
    }
    return userObj;
  });

  const exportData = () => {
    const fileName = `${batch.name}-feedback`;
    const exportType = 'xls';
    exportFromJSON({ data: feedbacks, fileName, exportType });
  };

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {batch.name}
        </TableCell>
        <TableCell component='th' scope='row'>
          {totalUserCount}
        </TableCell>
        <TableCell component='th' scope='row'>
          {activeUserCount}
        </TableCell>
        <TableCell component='th' scope='row'>
          {totalFeedbackCount}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.upskilling}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.masterclass}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.companyTasks}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.coach}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.companyMentor}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.futureReadiness}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.platform}
        </TableCell>
        <TableCell component='th' scope='row'>
          {npsObj.recommendationRating}
        </TableCell>
        <TableCell component='th' scope='row'>
          <Button onClick={exportData}>DownLoad</Button>
        </TableCell>
      </TableRow>
    </>
  );
};

export default NpsBody;

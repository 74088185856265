import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from 'links/API_LINKS';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from 'components/layouts/Loading';

const FetchCoachFeedbacks = async (batchId, setIsLoading) => {
  setIsLoading(true);
  const ApiEndpoint = API_URL + '/batch/coach-feedback';
  return axios
    .get(ApiEndpoint, {
      params: { batchId: batchId },
    })
    .then((res) => {
      return res.data.data[0];
    })
    .catch((err) => {
      console.log(err);
    });
};

const Index = ({ currentBatch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [coachFeedbacks, setCoachFeedbacks] = useState([]);

  useEffect(() => {
    const batchId = currentBatch._id;
    FetchCoachFeedbacks(batchId, setIsLoading)
      .then((data) => {
        if (data) {
          setCoachFeedbacks(data.coachFeedbacks);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentBatch._id]);

  return (
    <Box>
      {isLoading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {coachFeedbacks.length === 0 && (
            <Typography>Feedback not found.</Typography>
          )}
          {coachFeedbacks.map((feedback, idx) => (
            <Box
              key={idx}
              px={2}
              py={2}
              border={1}
              borderBottom={idx === coachFeedbacks.length - 1 ? 1 : 0}
              borderColor={'rgb(100, 100, 100)'}>
              <Stack display='flex' flexDirection='row' gap={1}>
                <Typography>Coach Name:</Typography>
                <Typography fontWeight={600}>
                  {feedback.coachId.name}
                </Typography>
              </Stack>
              <Typography pt={2}>Questions</Typography>
              <Stack display='flex' flexDirection='column' gap={1}>
                {feedback.feedback.map((fb, fb_idx) => (
                  <Stack key={fb_idx}>
                    <Typography fontWeight={600}>
                      {fb_idx + 1}. {fb.question}
                    </Typography>
                    {fb.questionType === 'text' && (
                      <Typography>{fb.value}</Typography>
                    )}
                    {fb.questionType === 'radio' && (
                      <Typography>{fb.value}</Typography>
                    )}
                  </Stack>
                ))}
              </Stack>
            </Box>
          ))}
        </React.Fragment>
      )}
    </Box>
  );
};

export default Index;

import React, { useState, useCallback } from 'react';
import { Button, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';
import { useQueryClient } from 'react-query';

const ApplicationStatus = ({ user }) => {
  const [status, setStatus] = useState(user.status);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const statusUpate = useCallback(
    (currentStatus) => {
      setLoading(true);
      axios({
        method: 'POST',
        url: API_URL + '/application/update-status',
        data: {
          status: currentStatus,
          applicationId: user._id,
        },
      })
        .then(function (response) {
          setStatus(currentStatus);
          setLoading(false);
          queryClient.invalidateQueries('fetchApplications');
        })
        .catch(function (response) {
          setLoading(false);
        });
    },
    [queryClient, user._id]
  );

  //   useEffect(() => {
  //     if (status === 'Applied') {
  //       statusUpate('Viewed');
  //     }
  //   }, [status, statusUpate]);

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        spacing={1}
      >
        <Button
          disabled={loading}
          onClick={() => {
            statusUpate('Applied');
          }}
          variant={status === 'Applied' ? 'contained' : 'outlined'}
        >
          Applied
        </Button>
        <Button
          disabled={loading}
          onClick={() => {
            statusUpate('Viewed');
          }}
          variant={status === 'Viewed' ? 'contained' : 'outlined'}
        >
          Viewed
        </Button>
        <Button
          disabled={loading}
          color={status === 'Shortlisted' ? 'secondary' : 'primary'}
          onClick={() => {
            statusUpate('Shortlisted');
          }}
          variant={status === 'Shortlisted' ? 'contained' : 'outlined'}
        >
          Shortlisted
        </Button>
        <Button
          disabled={loading}
          color={status === 'Rejected' ? 'error' : 'primary'}
          onClick={() => {
            statusUpate('Rejected');
          }}
          variant={status === 'Rejected' ? 'contained' : 'outlined'}
        >
          Rejected
        </Button>
        <Button
          color={status === 'Hired' ? 'success' : 'primary'}
          disabled={loading}
          onClick={() => {
            statusUpate('Hired');
          }}
          variant={status === 'Hired' ? 'contained' : 'outlined'}
        >
          Hired
        </Button>
      </Stack>
    </div>
  );
};

export default ApplicationStatus;

import { Stack, Typography } from '@mui/material';
import React from 'react';

const ScoreView = ({ data, email }) => {
  return (
    <div>
      <Typography textAlign='center'>{email} </Typography>
      <Typography textAlign='center'>
        Belong Score : {data.belongScore.total}{' '}
      </Typography>
      <Stack
        mt={2}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography>
          {' '}
          Cognitive Acumen : {data.belongScore.cognitiveAcumen} / 40{' '}
        </Typography>
        <Typography>
          Skill Competency : {data.belongScore.skillCompetency} / 45
        </Typography>
        <Typography>
          Profile Strength : {data.belongScore.profileStrength} / 15
        </Typography>
      </Stack>
      <Typography mt={2} mb={2} textAlign='center'>
        Profile Strength Details{' '}
      </Typography>

      {data.profileDetails.map((el) => {
        return (
          <Stack
            key={el.key}
            mt={2}
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <Typography> {el.key} :</Typography>
            <Typography ml={2} variant='subtitle2'>
              {el.score}/{el.max}{' '}
            </Typography>
            <Typography></Typography>
          </Stack>
        );
      })}
    </div>
  );
};

export default ScoreView;

import React from 'react';
import { Box, Button } from '@mui/material';

import TicketDetails from './TicketDetails';

const Index = ({ setTicketDetails, ticketDetails, batchId }) => {
  // const [updateTicket, setUpdateTicket] = useState(false);

  return (
    <Box sx={{ p: 2 }}>
      <Button
        onClick={() => setTicketDetails(null)}
        variant='outlined'
        sx={{ mr: 2 }}
      >
        Back
      </Button>
      <TicketDetails
        ticketDetails={ticketDetails}
        setTicketDetails={setTicketDetails}
        batchId={batchId}
      />
    </Box>
  );
};

export default Index;

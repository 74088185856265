import React, { useState } from 'react';
import { Table, Button, Box } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import EventBody from './EventBody';
import { API_URL } from '../../../../../../../links/API_LINKS';
import axios from 'axios';
import Modal from '@mui/material/Modal';
import AddNewEvent from './AddNewEvent';
import { LoadingButton } from '@mui/lab';
import Snackbar from '../../../../../../misc/Snackbar';
import { useQueryClient } from 'react-query';
import _ from 'lodash';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ShowEvent = ({ data, makeCurrentEvent, currentBatch }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [allEvent, setAllEvent] = useState(data);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const unwind = (array, field) => {
    if (_.isArray(array)) {
      return _.flatten(
        array.map((input) => {
          if (_.isArray(input[field])) {
            if (!input[field].length) return { ...input, journies: {} };
            return input[field].map((i) => {
              const output = { ...input };
              output[field] = i;

              return output;
            });
          }
          return input;
        })
      );
    }
    return array;
  };

  async function submitAllEvent() {
    const isAllchecked = allEvent.every((item) => {
      return item.checked;
    });
    if (isAllchecked) {
      const allunwind = unwind(allEvent, 'subEvents');
      if (
        window.confirm(
          'Are you sure you want to add ' +
            allunwind.length +
            ' New Events \n' +
            'Events already added ' +
            currentBatch.events.length
        )
      ) {
        setLoading(true);
        axios({
          method: 'POST',
          url: API_URL + '/event/add-multiple',
          data: allEvent,
        })
          .then(function (response) {
            console.log(response);
            setSnackbarText('Event added successfully');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            setLoading(false);
            queryClient.invalidateQueries(
              `fetchCurrentBatchEvents${currentBatch._id}`
            );
            queryClient.invalidateQueries(
              `fetchCurrentBatch${currentBatch._id}`
            );
            setAllEvent([]);
          })
          .catch(function (response) {
            setSnackbarText('Failed to add Events, Please try after sometime');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            setLoading(false);
          });
      }
    } else {
      setSnackbarText('Please confirm all events first');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  }

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          p: 1,
          m: 1,
        }}
      >
        <Button variant='outlined' onClick={() => handleOpen()}>
          Add New Event
        </Button>
      </Box>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell align='left'> Event Type</TableCell>
              <TableCell align='left'> Week / Day </TableCell>
              <TableCell align='left'>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allEvent.map((event, index) => {
              return (
                <EventBody
                  key={index}
                  event={event}
                  index={index}
                  allEvent={allEvent}
                  setAllEvent={setAllEvent}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          p: 1,
          m: 1,
        }}
      >
        <LoadingButton
          loading={loading}
          variant='outlined'
          onClick={() => submitAllEvent()}
        >
          Add All Events
        </LoadingButton>
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddNewEvent
            makeCurrentEvent={makeCurrentEvent}
            allEvent={allEvent}
            setAllEvent={setAllEvent}
            handleClose={handleClose}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </div>
  );
};
export default ShowEvent;

import React, { useState } from 'react';
import { TableRow, TableCell, Chip, Modal, Box, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import Prompt from '../../../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '../../../../../misc/Snackbar';
import EditCenturyTask from './EditCenturyTask';
import EditCompanyProject from './EditCompanyProject';
import AudienceDetails from './AudienceDetails';
import { getAllCompany } from '../helper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllProjectbody = ({ project, currentBatch }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAudience, setOpenAudience] = useState(false);
  const handleOpenAudience = () => setOpenAudience(true);
  const handleCloseAudience = () => setOpenAudience(false);

  const deleteTemplate = () => {
    setSnackbarText('Deleting Project...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/vga-project', {
        data: { projectId: project._id, batchId: currentBatch._id },
      })
      .then((res) => {
        setSnackbarText('Project has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries(
          `fetchCurrentBatchProject${currentBatch._id}`
        );
        queryClient.invalidateQueries(`fetchCurrentBatch${currentBatch._id}`);
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const batchUser = currentBatch.users;
  const allCompany = getAllCompany(batchUser);

  const currentProject = currentBatch.projects.filter((el) => {
    return el.projectId === project._id;
  })[0];

  let companyName = '';
  if (currentProject && currentProject.companyId.length) {
    const eventCompany = allCompany.filter((company) => {
      return company._id === currentProject.companyId[0];
    });
    if (eventCompany[0]) {
      companyName = eventCompany[0].name;
    }
  }
  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {project.title} - {companyName}
        </TableCell>
        <TableCell align='left'>
          {' '}
          {project.type === '21st Century Skills' ? (
            <Chip color='primary' size='small' label={project.type} />
          ) : (
            <Chip color='secondary' size='small' label={project.type} />
          )}
        </TableCell>
        <TableCell align='left'>
          {project.tasks && project.tasks.length}
        </TableCell>
        <TableCell align='left'>
          <Button onClick={handleOpenAudience}>Audience</Button>
        </TableCell>
        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={() => handleOpen()}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Project?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteTemplate();
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {project.type === '21st Century Skills' ? (
            <EditCenturyTask
              currentBatch={currentBatch}
              currentProject={project}
              handleClose={handleClose}
              snackbarActions={{
                setSnackbarText,
                setSnackbarSeverity,
                setSnackbarOpen,
              }}
            />
          ) : (
            <EditCompanyProject
              currentBatch={currentBatch}
              currentProject={project}
              handleClose={handleClose}
              snackbarActions={{
                setSnackbarText,
                setSnackbarSeverity,
                setSnackbarOpen,
              }}
            />
          )}
        </Box>
      </Modal>

      <Modal
        open={openAudience}
        onClose={handleCloseAudience}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AudienceDetails
            handleClose={handleCloseAudience}
            currentBatch={currentBatch}
            projectId={project._id}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default AllProjectbody;

import React, { useState } from 'react';
import { TableRow, TableCell, Chip, Modal, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditProjectTask from './EditProjectTask';
import EditCenturyTask from './EditCenturyTask';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ProjectBody = ({
  project,
  allProject,
  checkForError,
  setAllProject,
  index,
  skills,
}) => {
  function deleteProject() {
    const tempAllProject = [...allProject];
    tempAllProject.splice(index, 1);
    setAllProject(tempAllProject);
    checkForError(tempAllProject);
  }
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getSkillName = (skillId) => {
    const skill = skills?.find((skill) => skill?._id === skillId);
    return skill ? skill.title : 'Unknown Skill';
  };
  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {project.title}
        </TableCell>
        <TableCell align='left'>
          {' '}
          {project.type === '21st Century Skills' ? (
            <Chip color='primary' size='small' label={project.type} />
          ) : (
            <Chip color='secondary' size='small' label={project.type} />
          )}
        </TableCell>
        <TableCell align='left'>{project.level}</TableCell>
        <TableCell align='left'>
          {project.tasks.map((task, taskIndex) => (
            <div key={taskIndex} style={{ display: 'flex', flexWrap: 'wrap' }}>
              {task.skillId?.map((skillId, skillIndex) => (
                <span key={skillIndex} style={{ marginRight: '10px' }}>
                  {getSkillName(skillId)}
                </span>
              ))}
            </div>
          ))}
        </TableCell>
        <TableCell align='left'>
          {project.tasks && project.tasks.length}
        </TableCell>
        <TableCell align='left'>
          {project.companyId?.name}-{project.domainId?.title}
        </TableCell>
        <TableCell align='left'>
          {project.checked && <CheckCircleIcon color='success' />}{' '}
        </TableCell>
        {/* <TableCell align='left'>
    <IconButton
      sx={{ cursor: 'pointer' }}
      size='small'
      onClick={() => setProjectToEdit(project)}
    >
      <EditIcon color='primary' />
    </IconButton>
  </TableCell> */}
        <TableCell align='right'>
          <IconButton
            onClick={() => handleOpen()}
            size='small'
            color='primary'
            aria-label='Edit'
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => deleteProject()}
            size='small'
            color='error'
            aria-label='delete'
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          {project.type === '21st Century Skills' ? (
            <EditCenturyTask
              projectToEdit={project}
              index={index}
              setAllProject={setAllProject}
              allProject={allProject}
              handleClose={handleClose}
            />
          ) : (
            <EditProjectTask
              projectToEdit={project}
              index={index}
              setAllProject={setAllProject}
              allProject={allProject}
              handleClose={handleClose}
            />
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProjectBody;

import axios from 'axios';
import { Typography, Button, Box, Stack, TextField } from '@mui/material';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import { API_URL } from 'links/API_LINKS';

const Index = () => {
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [templateId, setTemplateId] = useState('');
  const [items, setItems] = useState([]);
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setItems(data);
    });
  };
  const sendMail = () => {
    setSubmitting(true);
    axios({
      method: 'POST',
      url: API_URL + '/user/send-mail',
      data: { users: items, templateId: templateId.trim() },
    })
      .then(function (response) {
        setServerResponse('Emails send successfully');
        setSubmitting(false);
      })
      .catch(function (response) {
        setServerResponse('Error! Could not process your request.');
        setSubmitting(false);
      });
  };

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        spacing={2}
      >
        <Box>
          <Typography>Enter a vaild Sendgrid template Id</Typography>
          <TextField
            size='small'
            fullWidth
            placeholder='TemplateId'
            value={templateId}
            onChange={(e) => setTemplateId(e.target.value)}
          />
        </Box>

        <Box>
          <Typography p={1}>Please upload XSLX file</Typography>
          <input
            type='file'
            onChange={(e) => {
              const file = e.target.files[0];
              readExcel(file);
            }}
          />
        </Box>
      </Stack>
      <Stack
        // direction='row'
        mt={4}
        justifyContent='Center'
        alignItems='center'
        spacing={2}
      >
        {items.length > 0 && templateId && (
          <Box pt={1}>
            <Typography variant='h6' m={2}>
              {items.length} Emails found{' '}
            </Typography>
            <Button
              onClick={() => sendMail()}
              variant='outlined'
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Processing...' : 'Send Mail'}
            </Button>
          </Box>
        )}
        <Typography variant='h6'>{serverResponse}</Typography>
      </Stack>
    </div>
  );
};

export default Index;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Typography } from '@mui/material';
import { format } from 'date-fns';

function UserBody({ row }) {
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.name}
        </TableCell>
        <TableCell align='left'>{row.email}</TableCell>
        <TableCell align='left' component='th' scope='row'>
          {row.createdAt && format(new Date(row.createdAt), 'do LLLL yyyy')}{' '}
        </TableCell>

        <TableCell align='left'>
          {row.aptitudeTest ? <>{row.aptitudeTest} %</> : '-'}{' '}
        </TableCell>

        <TableCell align='left'>
          {row.domainTests.map((domain) => {
            return (
              <Typography>
                {domain.domain.title} : {domain.percentage} %
              </Typography>
            );
          })}
        </TableCell>
      </TableRow>
    </>
  );
}

export default UserBody;

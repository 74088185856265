import { Box } from '@mui/material';
import React from 'react';
import AllTicketHeader from './AllTicketHeader';

const Index = ({ currentBatch }) => {
  return (
    <Box>
      <AllTicketHeader currentBatch={currentBatch} />
    </Box>
  );
};

export default Index;

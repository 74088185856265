import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { FieldArray } from 'formik';

import {
  Box,
  Modal,
  Grid,
  MenuItem,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import ShowFilesPopup from '../../halper/showFilesPopup';
import { API_URL_V1 } from '../../../../links/API_LINKS';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchCountry = async () => {
  const res = await axios.get(API_URL_V1 + '/countrytimezones/countries');
  return res;
};

const MentorForm = ({ values, setFieldValue }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const [sections, setSections] = useState(1);

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }
  const countries = useQuery('fetchCountry', fetchCountry);
  return (
    <div>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='name'
            type='text'
            label='Mentor Name'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='website'
            type='text'
            label='Website'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='designation'
            type='text'
            label='Designation'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='linkedIn'
            type='text'
            label='linkedIn'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='imageUrl'
            type='text'
            label='image URL'
          />
          <Button
            type='button'
            onClick={() => {
              setOpen(true);
              setSelectedField('imageUrl');
              setRefresh(!refresh);
            }}
          >
            Select Image
          </Button>
          <div>
            <img src={values.imageUrl} alt='' width='150' />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='mentorSign'
            type='text'
            label='Signature Image URL'
          />
          <Button
            type='button'
            onClick={() => {
              setOpen(true);
              setSelectedField('mentorSign');
              setRefresh(!refresh);
            }}
          >
            Select Image
          </Button>
          <div>
            <img src={values.mentorSign} alt='' width='150' />
          </div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <SelectInput size='small' fullWidth name='country' label='Country'>
            {countries.status === 'success' &&
              Object.entries(countries.data.data).map(
                ([countryCode, countryData]) => {
                  return (
                    <MenuItem key={countryCode} value={countryData.name}>
                      {countryData.name}
                    </MenuItem>
                  );
                }
              )}
          </SelectInput>
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', gap: '2%', alignItems: 'center' }}>
        <Typography variant='h6'>Mentor Brief</Typography>
        <Button
          variant='text'
          color='primary'
          onClick={() => {
            setSections(sections + 1);
          }}
          type='button'
        >
          Add section +
        </Button>
        {sections > 1 && (
          <Button
            variant='text'
            color='error'
            onClick={() => {
              setSections(sections - 1);
            }}
            type='button'
          >
            Remove section -
          </Button>
        )}
      </Box>

      <Box
        sx={{
          backgroundColor: 'primary.light',
          padding: '20px',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '2px',
          marginY: '10px',
        }}
      >
        {[...Array(sections)].map((e, i) => (
          <div key={i}>
            <Stack direction='row' justifyContent='flex-start'>
              <Grid container>
                <Grid item xs={4} md={2}>
                  <Typography variant='subtitle1' m={2} gutterBottom>
                    Section {i + 1}
                  </Typography>
                </Grid>
                <Grid item xs={8} md={10}>
                  <TextInput
                    name={`sectionTitle[${i}]`}
                    type='text'
                    placeholder='Title'
                    fullWidth
                  ></TextInput>
                </Grid>
              </Grid>
            </Stack>
            <FieldArray
              name='sectionContent'
              render={(arrayHelpers) => (
                <>
                  <div className='space-y-4 mt-6'>
                    <Button
                      type='button'
                      onClick={() =>
                        arrayHelpers.push({
                          sectionNumber: i,
                          contentToPublish: '',
                          contentType: 'Text',
                        })
                      }
                      sx={{ marginLeft: '1.5%' }}
                    >
                      + ADD
                    </Button>

                    {values.sectionContent.map((content, index) => {
                      return (
                        content.sectionNumber === i && (
                          <Grid
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                            mb={3}
                          >
                            {' '}
                            <Grid item xs={12} md={6}>
                              <Grid container sx={{ alignItems: 'center' }}>
                                <Grid item xs={4}>
                                  <Typography variant='subtitle1' gutterBottom>
                                    Content to publish
                                  </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                  <TextInput
                                    primary
                                    multiline
                                    fullWidth
                                    label='Content'
                                    name={`sectionContent[${index}].contentToPublish`}
                                    className='w-1/3 p-2'
                                    placeholder='Content'
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                              <SelectInput
                                name={`sectionContent.${index}.contentType`}
                              >
                                <MenuItem value='Text'>Text</MenuItem>
                                <MenuItem value='List'>List</MenuItem>
                                <MenuItem value='Tab'>Tab</MenuItem>
                                <MenuItem value='Link'>Link</MenuItem>
                                <MenuItem value='Image'>Image</MenuItem>
                                <MenuItem value='File'>File</MenuItem>
                              </SelectInput>
                            </Grid>
                            <Grid item xs={2} sm={2}>
                              {index > 0 && (
                                <Button
                                  type='button'
                                  color='error'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              )}
                            </Grid>
                          </Grid>
                        )
                      );
                    })}
                  </div>
                </>
              )}
            />
          </div>
        ))}
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default MentorForm;

import React, { useState } from 'react';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Card, Box, Typography } from '@mui/material';
import AllTicketBody from './AllTicketBody';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from 'react-query';
import Ticket from './ticket';
import TicketDownload from './TicketDownload';

const AllTicketHeader = ({ currentBatch }) => {
  const fetchAllTickets = async () => {
    const res = await axios.get(API_URL + '/ticket', {
      params: {
        limit: 0,
        sort: 'status',
        order: 'desc',
        batchId: currentBatch._id,
      },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchAllTickets${currentBatch._id}`,
    fetchAllTickets
  );
  const [ticketDetails, setTicketDetails] = useState(null);
  return (
    <>
      {ticketDetails ? (
        <Ticket
          batchId={currentBatch._id}
          ticketDetails={ticketDetails}
          setTicketDetails={setTicketDetails}
        />
      ) : (
        <>
          {status === 'loading' && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='50vh'
            >
              <CircularProgress />
            </Box>
          )}
          {status === 'error' && <div>Error fetching data</div>}

          {status === 'success' &&
            (data?.data?.data?.length === 0 ? (
              <Box
                display='flex'
                justifyContent='end'
                alignItems='center'
                minHeight='30vh'
                minWidth='15vw'
              >
                <Typography variant='h4'>No Tickets raised</Typography>
              </Box>
            ) : (
              <>
                <TicketDownload allTickets={data?.data?.data || []} />
                <TableContainer component={Card}>
                  <Table
                    size='small'
                    sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
                    aria-label='table'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align='left'>Title</TableCell>
                        <TableCell align='center'>Type</TableCell>
                        <TableCell align='center'>Status</TableCell>
                        <TableCell align='center'>Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.data.data.map((ticket) => {
                        return (
                          <AllTicketBody
                            key={ticket._id}
                            ticket={ticket}
                            setTicketDetails={setTicketDetails}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ))}
        </>
      )}
    </>
  );
};

export default AllTicketHeader;

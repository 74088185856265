import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../halper/TableSort';
import InvoiceBody from './InvoiceBody';
import EditInvoice from './EditInvoice';

function InvoiceHeader({ data, setValue, invoiceType }) {
  const [editInvoice, setEditInvoice] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((invoice) => {
    return {
      invoice: invoice,
      coachName: invoice.coachId.name,
      batchName: invoice.batchId.name,
      totalAmount: invoice.totalAmount,
      addedOn: invoice.createdAt,
      slug: invoice.slug,
      currency: invoice.currency,
    };
  });

  const TABLE_HEAD = [
    { id: 'coachName', label: 'Coach Name', align: 'left' },
    { id: 'batchName', label: 'Batch Name', align: 'left' },
    { id: 'totalAmount', label: 'Total Amount', align: 'left' },
    { id: 'addedOn', label: 'Added On', align: 'left' },
  ];

  // Push this to the end of TABLE_HEAD
  const TABLE_END = { id: 'edit', label: 'Edit', align: 'left' };

  const approvedOnRow = {
    id: 'approvedOn',
    label: 'Approved On',
    align: 'left',
  };
  const paidOnRow = { id: 'paidOn', label: 'Paid On', align: 'left' };
  switch (invoiceType) {
    case 'approved':
      TABLE_HEAD.push(approvedOnRow);
      TABLE_HEAD.push(TABLE_END);
      break;

    case 'paid':
      TABLE_HEAD.push(approvedOnRow);
      TABLE_HEAD.push(paidOnRow);
      TABLE_HEAD.push(TABLE_END);
      break;

    default:
      TABLE_HEAD.push(TABLE_END);
      break;
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {editInvoice ? (
        <EditInvoice
          setValue={setValue}
          editInvoice={editInvoice}
          setEditInvoice={setEditInvoice}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'>
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <InvoiceBody
                    key={row.invoice._id}
                    row={row}
                    setEditInvoice={setEditInvoice}
                    invoiceType={invoiceType}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
}

export default InvoiceHeader;

import React from 'react';
import { Table, Stack, Button } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import AllBody from './AllBody';
import _ from 'lodash';
import CsvDownload from 'react-json-to-csv';
import { format } from 'date-fns';
import { unwind } from '../helper';

const AllHeader = ({ allEventData, allAssignmentData, allProjectData }) => {
  let allData = [];

  allEventData.forEach((item) => {
    if (item.subEvents && item.subEvents.length) {
      let subData = [];
      item.subEvents.forEach((subItem) => {
        subData.push({
          week: subItem.week,
          title: subItem.title,
          type: subItem.type,
          date:
            subItem.dateTime &&
            format(new Date(subItem.dateTime), 'do LLLL yyyy h:mm a iiii'),
          link: subItem.link,
          eventOrAssignment: 'Event',
        });
      });
      allData.push({
        title: item.title,
        type: item.subEvents[0].type,
        week: item.subEvents[0].week,
        date:
          item.dateTime &&
          format(new Date(item.dateTime), 'do LLLL yyyy h:mm a iiii'),
        subData: subData,
        eventOrAssignment: 'Event',
      });
    } else {
      allData.push({
        week: item.week,
        title: item.title,
        type: item.type,
        date:
          item.dateTime &&
          format(new Date(item.dateTime), 'do LLLL yyyy h:mm a iiii'),
        link: item.link,
        eventOrAssignment: 'Event',
      });
    }
  });

  allAssignmentData.forEach((item) => {
    if (item.subAssignments && item.subAssignments.length) {
      let subData = [];
      item.subAssignments.forEach((subItem) => {
        subData.push({
          week: subItem.week,
          title: subItem.title,
          type: subItem.type,
          date:
            subItem.dueDateTime &&
            format(new Date(subItem.dueDateTime), 'do LLLL yyyy h:mm a iiii'),
          link: subItem.assignmentFileUrl,
          eventOrAssignment: 'Assignment',
        });
      });
      allData.push({
        title: item.title,
        type: item.subAssignments[0].type,
        week: item.subAssignments[0].week,
        date:
          item.dueDateTime &&
          format(new Date(item.dueDateTime), 'do LLLL yyyy h:mm a iiii'),
        subData: subData,
        eventOrAssignment: 'Assignment',
      });
    } else {
      allData.push({
        week: item.week,
        title: item.title,
        type: item.type,
        date:
          item.dueDateTime &&
          format(new Date(item.dueDateTime), 'do LLLL yyyy h:mm a iiii'),
        link: item.assignmentFileUrl,
        eventOrAssignment: 'Assignment',
      });
    }
  });

  allProjectData.forEach((item) => {
    if (item.type === '21st Century Skills') {
      allData.push({
        week: item.tasks[0]?.week,
        title: item.title,
        type: item.type,
        date:
          item.tasks[0]?.dueDateTime &&
          format(
            new Date(item.tasks[0]?.dueDateTime),
            'do LLLL yyyy h:mm a iiii'
          ),
        // link: item.assignmentFileUrl,
        eventOrAssignment: 'Assignment',
      });
    }
  });
  const companyProject = allProjectData.filter((item) => {
    return item.type === 'Company Tasks';
  });
  const allunwind = unwind(companyProject, 'tasks').map((item) => {
    return {
      title: item.title,
      type: item.type,
      week: item.tasks.week,
      date: item.tasks.dueDateTime,
      taskTitle: item.tasks.title,
    };
  });

  const groupedTasks = allunwind.reduce((groups, task) => {
    const { week } = task;
    if (groups[week]) {
      groups[week].push(task);
    } else {
      groups[week] = [task];
    }
    return groups;
  }, {});

  const groupedTasksArray = Object.entries(groupedTasks).map(
    ([week, tasks]) => ({
      title: `Company Task week- ${week}`,
      type: tasks[0].type,
      week: Number(week),
      eventOrAssignment: 'Assignment',
      subData: tasks.map(({ week, type, date, taskTitle, title }) => ({
        date: date ? format(new Date(date), 'do LLLL yyyy h:mm a iiii') : '',
        title: title + ' - ' + taskTitle,
        week,
        type,
      })),
    })
  );

  const finalData = allData.concat(groupedTasksArray);
  const sortAllData = _.orderBy(finalData, ['week'], ['asc']);

  let csvData = sortAllData
    .map((object) => {
      if (object.subData) {
        return object.subData;
      } else {
        return [{ ...object }];
      }
    })
    .flat();

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}
      >
        <CsvDownload data={csvData}>
          <Button variant='outlined'>Event And Assignment</Button>
        </CsvDownload>
      </Stack>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Name</TableCell>
              <TableCell align='left'> Type</TableCell>
              <TableCell align='left'> Week</TableCell>
              <TableCell align='left'>Date</TableCell>
              <TableCell align='left'>Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortAllData.map((event) => {
              return <AllBody key={event._id} event={event} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllHeader;

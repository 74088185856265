import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import ScoreBar from './ScoreBar';

const ScoreInfo = ({
  belongScore,
  areas,
  selectedDomain,
  setSelectedDomain,
}) => {
  console.log(selectedDomain, areas);
  return (
    <Grid container spacing={4}>
      <Grid item sm={12} md={3} align='center'>
        <Typography variant='h6' sx={{ color: 'primary.main' }}>
          Belong Score :{' '}
          <Typography variant='h6' sx={{ color: 'black', display: 'inline' }}>
            {belongScore}
          </Typography>
        </Typography>
        <Typography variant='body2'>
          Employability Proof powered by Belong
        </Typography>
      </Grid>
      <Grid item sm={12} md={9} align='center'>
        <ScoreBar />
        <Typography variant='p'>
          Choose the domain to view domain specific Belong Score Analysis :
        </Typography>
        <Box m={2}>
          {areas.map((area, index) => {
            return (
              <Button
                key={area._id}
                onClick={() => {
                  setSelectedDomain(index);
                }}
                variant='outlined'
                size='large'
                sx={{
                  mx: 2,
                  backgroundColor:
                    area._id === areas[selectedDomain]._id
                      ? 'primary.light'
                      : 'white',
                  color: 'black',
                }}
              >
                {area.title}
              </Button>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ScoreInfo;

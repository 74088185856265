import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import EventSummary from './EventSummary';
import UploadAttendance from './UploadAttendance';
import AttendanceDetails from './AttendanceDetails';
import AddManualAttendance from './AddManualAttendance';

const checkForUser = (criteriaId, usersIdForThatCriteria) => {
  if (!criteriaId.length) {
    return true;
  }
  if (criteriaId.length && criteriaId.includes(usersIdForThatCriteria._id)) {
    return true;
  }
  return false;
};

const Index = ({ event, snackbarActions, handleClose, currentBatch }) => {
  const [add, setAdd] = useState(false);
  const batchUser = currentBatch.users;
  const eventId = event._id;
  const eventWeek = event.week;
  const currentEvent = currentBatch.events.filter((event) => {
    return event.eventId === eventId;
  })[0];
  let eligibleUsers = [];

  if (currentEvent) {
    batchUser.forEach((user) => {
      const userCompany = user.company;
      const userMentor = user.mentor;
      const userCoach = user.coach;
      const userArea = user.area;
      const userCompany2 = user.company2;
      const userMentor2 = user.mentor2;
      const userCoach2 = user.coach2;

      if (user.status === 'Active') {
        if (
          currentBatch.timeSheet &&
          currentBatch.timeSheet.startsAt <= eventWeek
        ) {
          checkForUser(currentEvent.companyId, userCompany2 || userCompany) &&
            checkForUser(currentEvent.mentorId, userMentor2 || userMentor) &&
            checkForUser(currentEvent.coachId, userCoach2 || userCoach) &&
            checkForUser(currentEvent.areas, userArea) &&
            eligibleUsers.push(user);
        } else {
          checkForUser(currentEvent.companyId, userCompany) &&
            checkForUser(currentEvent.mentorId, userMentor) &&
            checkForUser(currentEvent.coachId, userCoach) &&
            checkForUser(currentEvent.areas, userArea) &&
            eligibleUsers.push(user);
        }
      }
    });
  }

  return (
    <div>
      <Typography align='center' mb={4}>
        {' '}
        {event.title}{' '}
      </Typography>
      {currentEvent ? (
        <>
          <EventSummary
            batchName={currentBatch.name}
            eventName={event.title}
            eventDateTime={event.dateTime}
            totalUsers={batchUser.length}
            totalEligibleUsers={eligibleUsers.length}
          />
          {event.attendance.length > 0 && (
            <AttendanceDetails
              attendance={event.attendance}
              snackbarActions={snackbarActions}
              handleClose={handleClose}
              eventId={eventId}
            />
          )}
          {event.attendance.length === 0 && (
            <Button onClick={() => setAdd(true)}>Add Manual Attendance</Button>
          )}
          {add && (
            <AddManualAttendance
              eligibleUsers={eligibleUsers}
              snackbarActions={snackbarActions}
              handleClose={handleClose}
              eventId={eventId}
              setAdd={setAdd}
            />
          )}
          <UploadAttendance
            eligibleUsers={eligibleUsers}
            eventId={eventId}
            snackbarActions={snackbarActions}
            handleClose={handleClose}
          />
        </>
      ) : (
        <Typography>Audience Not Added for this Event </Typography>
      )}
    </div>
  );
};

export default Index;

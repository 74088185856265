import { Box, Typography, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import React, { useRef } from 'react';
import { TextInput } from '../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const AddIndustry = ({ industry, snackbarActions, handleClose }) => {
  const formikRef = useRef();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <Typography align='center' mb={4} variant='h6'>
        Edit Industry
      </Typography>
      <div>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            industryId: industry._id,
            title: industry.title,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            axios({
              method: 'PUT',
              url: API_URL + '/industries',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Industry added successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchIndustry');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to update Industry, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          <Form>
            <TextInput
              size='small'
              fullWidth
              name='title'
              type='text'
              label='Title'
            />

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton type='submit' variant='contained'>
                  Update Industry
                </LoadingButton>
              </Stack>
            </Box>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default AddIndustry;

import axios from 'axios';
import {
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
} from './teamTypes';

import { API_URL } from '../../links/API_LINKS';

export const fetchTeamDetails = () => {
  return (dispatch) => {
    dispatch(fetchTeamRequest());
    axios
      .get(API_URL + '/admin/team')
      .then((response) => {
        const team = response.data;
        dispatch(fetchTeamSuccess(team));
      })
      .catch((error) => {
        dispatch(fetchTeamFailure(error.message));
      });
  };
};

export const fetchTeamRequest = () => {
  return {
    type: FETCH_TEAM_REQUEST,
  };
};

export const fetchTeamSuccess = (team) => {
  return {
    type: FETCH_TEAM_SUCCESS,
    payload: team,
  };
};

export const fetchTeamFailure = (error) => {
  return {
    type: FETCH_TEAM_FAILURE,
    payload: error,
  };
};

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination, Box, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import QuestionBody from './QuestionBody';
import EditQuestion from './EditQuestion';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchQuestion = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword } = params;
  const matchObj = {
    limit,
    page,
    sort,
    order,
    keyword: searchRequestKeyword,
    type: 'APTITUDE',
  };

  const res = await axios.get(API_URL + '/question', {
    params: matchObj,
  });

  return res;
};

function QuestionHeader() {
  const [questionToEdit, setQuestionToEdit] = useState(null);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'category',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchQuestion`, paginationData],
    () =>
      fetchQuestion({
        ...paginationData,
      }),
    { keepPreviousData: true }
  );

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'question', label: 'Question', align: 'left' },
    { id: 'category', label: 'Category', align: 'left' },
    { id: 'type', label: 'Type', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {questionToEdit ? (
            <EditQuestion
              questionToEdit={questionToEdit}
              setQuestionToEdit={setQuestionToEdit}
            />
          ) : (
            <>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Paper
                  elevation={5}
                  sx={{ minWidth: 250, maxWidth: 345, margin: 1 }}></Paper>
              </Stack>

              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                  aria-label='table'
                  size='small'>
                  <TableListHead
                    order={paginationData.order}
                    orderBy={paginationData.sort}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {data.data.data.map((row) => {
                      return (
                        <QuestionBody
                          key={row.id}
                          row={row}
                          setQuestionToEdit={setQuestionToEdit}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[
                    ...new Set([paginationData.limit, 5, 10, 50]),
                  ]}
                  component='div'
                  count={data.data.totalCount}
                  rowsPerPage={paginationData.limit}
                  page={paginationData.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{
                    disabled:
                      isFetching || data.data.page === data.data.totalPages - 1,
                  }}
                />
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
}

export default QuestionHeader;

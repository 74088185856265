import Loading from 'components/layouts/Loading';
import { supabase } from 'links/supabaseConfig';
import React, { useState, useEffect } from 'react';
import UserDetails from './UserDetails';
import { Typography } from '@mui/material';

const Index = () => {
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('jupiter_event')
        .select();

      if (fetchedData) {
        setAllUsers(fetchedData);
        setLoading(false);
      }
    };

    setDataFn();
  }, []);

  return (
    <div>
      <Typography m={2}> Jupiter Event Users </Typography>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <UserDetails allUsers={allUsers} />
        </div>
      )}
    </div>
  );
};

export default Index;

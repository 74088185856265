import React, { useState } from 'react';
import { Table, Box, Select, MenuItem } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import Templatebody from './Templatebody';
import EditTaskTemplate from './EditTaskTemplate';
import EditCenturyTemplates from './EditCenturyTemplates';
import CircularProgress from '@mui/material/CircularProgress';

const fetchProjectTemplate = async () => {
  const res = await axios.get(API_URL + '/project-template');
  return res;
};

const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0 },
  });
  return res;
};

const TemplateHeader = () => {
  const [projectToEdit, setProjectToEdit] = useState(null);
  // new
  const [selectedFilter, setSelectedFilter] = useState(''); // State for selected filter

  // filter for company
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  const { status, data } = useQuery(
    'fetchProjectTemplate',
    fetchProjectTemplate
  );

  const allSkills = useQuery('fetchSkills', fetchSkills);

  const filteredData = data?.data?.data.filter((template) => {
    if (!selectedFilter) return true; // If no filter selected, return all data
    return template.type === selectedFilter; // Filter based on selected filter value
  });

  if (status === 'success') {
    let allCompany = [];
    data?.data?.data.forEach((el) => {
      if (el.companyId?._id) {
        if (
          !allCompany.some((company) => {
            return el.companyId._id === company._id;
          })
        ) {
          allCompany.push(el.companyId);
        }
      }
    });
    if (!companies.length) {
      setCompanies(allCompany);
    }
  }

  return (
    <>
      {projectToEdit ? (
        <>
          {projectToEdit.type === '21st Century Skills' ? (
            <EditCenturyTemplates
              projectToEdit={projectToEdit}
              setProjectToEdit={setProjectToEdit}
            />
          ) : (
            <EditTaskTemplate
              projectToEdit={projectToEdit}
              setProjectToEdit={setProjectToEdit}
            />
          )}
        </>
      ) : (
        <>
          {status === 'loading' && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='50vh'
            >
              <CircularProgress />
            </Box>
          )}
          {status === 'error' && <div>Error fetching data</div>}
          {status === 'success' && (
            <>
              <Box display='flex' gap={4}>
                {/* Dropdown filter for Type */}
                <Box sx={{ marginBottom: 2 }}>
                  <Select
                    value={selectedFilter}
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value=''>All Types</MenuItem>
                    <MenuItem value='21st Century Skills'>
                      21st Century Skills
                    </MenuItem>
                    <MenuItem value='Company Tasks'>Company Tasks</MenuItem>
                  </Select>
                </Box>
                {/* Dropdown filter for Companies */}
                <Box sx={{ marginBottom: 2 }}>
                  <Select
                    value={selectedCompany}
                    onChange={(e) => setSelectedCompany(e.target.value)}
                    displayEmpty
                    fullWidth
                  >
                    <MenuItem value=''>All Companies</MenuItem>
                    {companies.map((company) => (
                      <MenuItem key={company} value={company._id}>
                        {company.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
              <TableContainer component={Card}>
                <Table
                  size='small'
                  sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
                  aria-label='table'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Title</TableCell>
                      <TableCell align='left'> Type</TableCell>
                      <TableCell align='left'>Skill</TableCell>
                      <TableCell align='left'>Difficulty Level</TableCell>
                      <TableCell align='left'> Total Task </TableCell>
                      <TableCell align='left'>Company</TableCell>
                      <TableCell align='left'>Domain</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {status === 'success' && (
                      <>
                        {/* if company filter is applied */}

                        {/* if type filter is applied */}
                        {filteredData.length > 0 && (
                          <>
                            {filteredData
                              .filter((el) => {
                                if (!selectedCompany) {
                                  return true;
                                }
                                return el.companyId?._id === selectedCompany;
                              })
                              .map((template) => (
                                <Templatebody
                                  skills={allSkills?.data?.data?.data || []}
                                  template={template}
                                  key={template._id}
                                  setProjectToEdit={setProjectToEdit}
                                />
                              ))}
                          </>
                        )}
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
};

export default TemplateHeader;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { Box, Button, Stack, TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../halper/TableSort';
import NewProfile from 'components/_dashboard/layouts/NewProfile';

import CsvDownload from 'react-json-to-csv';
import UserBody from './UserBody';

const UserHeader = ({ allUser }) => {
  const rows = allUser;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('firstName');
  const [showProfile, setShowProfile] = useState(null);

  const TABLE_HEAD = [
    { id: 'firstName', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'percentage', label: 'Quiz Score', align: 'left' },
  ];
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  const csvData = allUser.map((user) => {
    return {
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      Percentage: user.percentage,
      userId: user._id,
    };
  });

  return (
    <div>
      <Box display='flex' mb={4}>
        AIC Reporting
      </Box>
      <Stack
        mb={2}
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <CsvDownload data={csvData}>
          <Button variant='outlined'>Users Data</Button>
        </CsvDownload>
      </Stack>
      {showProfile ? (
        <NewProfile setShowProfile={setShowProfile} userId={showProfile} />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'
          >
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <UserBody
                    row={row}
                    key={index}
                    index={index}
                    setShowProfile={setShowProfile}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10, 50, 100]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </div>
  );
};

export default UserHeader;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

import {
  Box,
  Modal,
  Grid,
  MenuItem,
  //   Typography,
  //   Stack,
  Button,
} from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import ShowFilesPopup from '../../halper/showFilesPopup';
import { API_URL } from 'links/API_LINKS';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchAreas = async (keys) => {
  const res = await axios.get(API_URL + '/area', { params: { limit: 0 } });
  return res;
};
const fetchSkills = async (keys) => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0 },
  });
  return res;
};

const QuestionForm = ({ values, setFieldValue, email }) => {
  //   const formikRef = useRef();
  //   const [serverResponse, setServerResponse] = useState();
  const areas = useQuery('fetchAreas', fetchAreas);
  const skills = useQuery('fetchSkills', fetchSkills);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  //   const [sections, setSections] = useState(1);

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }
  return (
    <Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
        mb={3}
      >
        <Grid item xs={12} sm={4}>
          <SelectInput
            size='small'
            name='type'
            type='text'
            label='Question type'
          >
            <MenuItem value='GENERAL'>GENERAL</MenuItem>
            <MenuItem value='DOMAIN'>DOMAIN</MenuItem>
            <MenuItem value='MASTERCLASS'>Employability Assessment</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item>
            {values.type === 'DOMAIN' && areas.status === 'success' && (
              <Grid item sm={10} style={{ display: 'flex', gap: '20px' }}>
                <Grid item sm={6}>
                  <SelectInput name='domain' label='Area' size='small'>
                    {/* <MenuItem value=''>Select Domain</MenuItem> */}
                    {areas.status === 'success' &&
                      areas.data.data.data.map((area) => {
                        return (
                          <MenuItem key={area._id} value={area._id}>
                            {area.title}
                          </MenuItem>
                        );
                      })}
                  </SelectInput>
                </Grid>
                <Grid item sm={6}>
                  <SelectInput name='skill' label='Skill' size='small'>
                    {/* <MenuItem value=''>Select Skill</MenuItem> */}
                    {skills.status === 'success' &&
                      skills.data.data.data.map((skill) => {
                        return (
                          <MenuItem key={skill._id} value={skill._id}>
                            {skill.title}
                          </MenuItem>
                        );
                      })}
                  </SelectInput>
                </Grid>
              </Grid>
            )}

            {values.type === 'MASTERCLASS' && (
              <Grid item sm={10} style={{ display: 'flex', gap: '20px' }}>
                <Grid item sm={8}>
                  <SelectInput
                    name='category'
                    label='Question category'
                    size='small'
                  >
                    <MenuItem value=''>Select category </MenuItem>
                    <MenuItem value='COMMUNICATION'>COMMUNICATION</MenuItem>
                    <MenuItem value='CREATIVITY'>CREATIVITY</MenuItem>
                    <MenuItem value='ANALYTICAL_SKILLS'>
                      ANALYTICAL SKILLS
                    </MenuItem>
                    <MenuItem value='SELF_AWARENESS'>SELF AWARENESS</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item sm={4}>
                  <TextInput
                    size='small'
                    label='Time in seconds'
                    name='time'
                    type='number'
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            rows={2}
            label='Question'
            name='question'
            type='text'
            multiline
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          {values.type === 'MASTERCLASS' && (
            <Grid item>
              {[...Array(1)].map((e, i) => (
                <Grid item key={i}>
                  <FieldArray
                    name='image'
                    render={(arrayHelpers) => (
                      <>
                        <Grid
                          item
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          Add question image
                          <Button
                            type='button'
                            onClick={() => arrayHelpers.push()}
                          >
                            <FaPlusCircle />
                          </Button>
                          {values.image.map((content, index) => {
                            return (
                              <Grid item key={index}>
                                <Grid
                                  item
                                  sm={12}
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                  }}
                                >
                                  <TextInput
                                    name={`image[${index}]`}
                                    type='text'
                                    placeholder='Image URL'
                                  />
                                  <Grid item>
                                    <Button
                                      type='button'
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedField(`image[${index}]`);
                                        setRefresh(!refresh);
                                      }}
                                    >
                                      Select File
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <img
                                      src={values.image[index]}
                                      alt=''
                                      width='150'
                                    />
                                    <Grid item>
                                      <Button
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        <FaTrashAlt />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
        <Grid item xs={4}>
          <SelectInput
            name='maxSelect'
            label='Maximum options a user can select'
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12}>
          {[...Array(1)].map((e, i) => (
            <Grid item key={i}>
              <FieldArray
                name='options'
                render={(arrayHelpers) => (
                  <>
                    <Grid
                      item
                      style={{
                        display: 'flex-wrap',
                        alignItems: 'center',
                      }}
                    >
                      Add
                      <Button
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            option: '',
                            value: 0,
                          })
                        }
                      >
                        <FaPlusCircle />
                      </Button>
                      {values.options.map((content, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            style={{
                              display: 'flex',
                              marginTop: '12px',
                              gap: '10px',
                            }}
                            sm={8}
                            lg={5}
                          >
                            <TextInput
                              size='small'
                              rows={1}
                              name={`options[${index}].option`}
                              placeholder={`option ${index + 1}`}
                              multiline
                              fullWidth
                            />
                            <SelectInput
                              size='small'
                              name={`options[${index}].value`}
                              label='Value'
                            >
                              <MenuItem value={0}>0</MenuItem>
                              <MenuItem value={1}>1</MenuItem>
                              <MenuItem value={0.5}>0.5</MenuItem>
                              <MenuItem value={-0.5}>-0.5</MenuItem>
                              <MenuItem value={-1}>-1</MenuItem>
                            </SelectInput>
                            <Button
                              type='button'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <FaTrashAlt />
                            </Button>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </>
                )}
              />
            </Grid>
          ))}
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='question'
            type='text'
            label='image URL'
          />
          <Button
            type='button'
            onClick={() => {
              setOpen(true);
              setSelectedField('coachImageUrl');
              setRefresh(!refresh);
            }}
          >
            Select Image
          </Button>
          <Grid>
            <img src={values.coachImageUrl} alt='' width='150' />
          </Grid>
        </Grid> */}
      </Grid>

      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default QuestionForm;

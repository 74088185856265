import moment from 'moment';
import _ from 'lodash';

export function getAllCompany(batchUser) {
  let allCompany = [];
  batchUser.forEach((user) => {
    if (
      !allCompany.some((company) => {
        return user.company._id === company._id;
      })
    ) {
      allCompany.push(user.company);
    }
    if (user.company2) {
      if (
        !allCompany.some((company) => {
          return user.company2._id === company._id;
        })
      ) {
        allCompany.push(user.company2);
      }
    }
  });
  return allCompany;
}

export function getAllCoach(batchUser) {
  let allCoach = [];
  batchUser.forEach((user) => {
    if (
      !allCoach.some((coach) => {
        return user.coach._id === coach._id;
      })
    ) {
      allCoach.push(user.coach);
    }
    if (user.coach2) {
      if (
        !allCoach.some((coach) => {
          return user.coach2._id === coach._id;
        })
      ) {
        allCoach.push(user.coach2);
      }
    }
  });
  return allCoach;
}

export function getAllArea(batchUser) {
  let allArea = [];
  batchUser.forEach((user) => {
    if (
      !allArea.some((area) => {
        return user.area._id === area._id;
      })
    ) {
      allArea.push(user.area);
    }
  });
  return allArea;
}

export function getUniqueAreas({ companyId, batchUser }) {
  const companyUser = batchUser.filter((user) => {
    return user.company._id === companyId;
  });
  let allArea = [];
  companyUser.forEach((user) => {
    if (
      !allArea.some((area) => {
        return user.area._id === area._id;
      })
    ) {
      allArea.push(user.area);
    }
  });
  return allArea;
}

export function updateDateTime({ project, batchStartDate }) {
  const dt1 = new Date(batchStartDate);
  const battchStartDay = dt1.getDay();
  const publishAtTime = moment(dt1).set({
    hour: 0,
    minute: 5,
  });
  const dueDateTimestart = moment(dt1).set({
    hour: 23,
    minute: 59,
  });

  const timeUpdatedTasks = project.tasks.map((task) => {
    let publishAt;

    if (task.day + battchStartDay > 6) {
      publishAt = moment(publishAtTime, 'DD-MM-YYYY').add(
        (task.week - 1) * 7 + task.day + 1,
        'days'
      )._d;
    } else {
      publishAt = moment(publishAtTime, 'DD-MM-YYYY').add(
        (task.week - 1) * 7 + task.day - 1,
        'days'
      )._d;
    }
    let dueDateTime;
    const dueDateDays = task.day + task.duration;
    if (battchStartDay + dueDateDays > 11) {
      dueDateTime = moment(dueDateTimestart, 'DD-MM-YYYY').add(
        (task.week - 1) * 7 + dueDateDays + 3,
        'days'
      )._d;
    } else if (battchStartDay + dueDateDays > 6) {
      dueDateTime = moment(dueDateTimestart, 'DD-MM-YYYY').add(
        (task.week - 1) * 7 + dueDateDays + 1,
        'days'
      )._d;
    } else {
      dueDateTime = moment(dueDateTimestart, 'DD-MM-YYYY').add(
        (task.week - 1) * 7 + dueDateDays - 1,
        'days'
      )._d;
    }

    return { ...task, publishAt, dueDateTime };
  });

  return { ...project, tasks: timeUpdatedTasks };
}

export function updateCompanyDomainName({ tempIds, allCompany, allArea }) {
  const updatedname = tempIds.map((emptyIds) => {
    const companyId = allCompany.filter((company) => {
      return emptyIds.companyId === company._id;
    })[0];
    const domainId = allArea.filter((area) => {
      return area._id === emptyIds.domainId;
    })[0];
    return { companyId, domainId };
  });
  return updatedname;
}

export const unwind = (array, field) => {
  if (_.isArray(array)) {
    return _.flatten(
      array.map((input) => {
        if (_.isArray(input[field])) {
          if (!input[field].length) return { ...input, journies: {} };
          return input[field].map((i) => {
            const output = { ...input };
            output[field] = i;

            return output;
          });
        }
        return input;
      })
    );
  }
  return array;
};

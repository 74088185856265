import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';
import { useQuery } from 'react-query';

function CapstoneBody({ row, setCapstoneToEdit }) {
  const capstoneId = row._id;
  const fetchQuestion = async () => {
    const res = await axios.get(API_URL + '/question', {
      params: { limit: 0, capstoneId },
    });
    return res;
  };

  const { status, data } = useQuery(
    `fetchQuestion${capstoneId}`,
    fetchQuestion
  );
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left'>{row.title} </TableCell>
        <TableCell align='left'> {row.domainId.title} </TableCell>
        {/* <TableCell align='left'> {row.industryId.title} </TableCell> */}
        <TableCell align='left'>{row.companyId.name} </TableCell>
        <TableCell align='left'>{row.companyId.country} </TableCell>
        <TableCell align='left'>
          {row.skillId ? row.skillId.length : 0}{' '}
        </TableCell>
        <TableCell align='left'>{row.level} </TableCell>
        <TableCell align='left'>
          {status === 'success' && data.data.data.length}{' '}
        </TableCell>
        <TableCell align='center'>
          <Button
            onClick={() => {
              setCapstoneToEdit(row);
            }}
          >
            <EditIcon sx={{ color: 'primary.main' }} />{' '}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default CapstoneBody;

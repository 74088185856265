import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import Chart from 'react-apexcharts';

const Performance = ({ users }) => {
  const top20User = users.filter((user, index) => {
    return (
      index < 20 &&
      user.masterclassAssessment &&
      user.masterclassAssessment.percentageScore
    );
  });

  const userName = top20User.map((user) => {
    return user.name;
  });
  const userScore = top20User.map((user) => {
    return user.totalPercentage;
  });
  const options = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: userName,
    },
    plotOptions: {
      bar: {
        borderRadius: 8,
        columnWidth: '65%',
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return val + '%';
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#304738'],
      },
    },
  };

  const series = [
    {
      name: 'series-1',
      data: userScore,
    },
  ];

  return (
    <Box sx={{ bgcolor: '#f9f9f9' }}>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>Overall Performance</Typography>
          <Chart
            options={options}
            series={series}
            type='bar'
            width={1000}
            height={400}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default Performance;

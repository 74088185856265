import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  TableRow,
  TableCell,
  Typography,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import { format } from 'date-fns';

import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchOrder = async (params) => {
  const { limit, page, sort, order } = params;
  const res = await axios.get(API_URL + '/order', {
    params: { limit, page, sort, order, orderType: 'BootcampMay23' },
  });

  return res;
};

function Index() {
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'createdAt',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchOrder`, paginationData],
    () => fetchOrder({ ...paginationData }),
    { keepPreviousData: true }
  );

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'phone', label: 'Phone', align: 'left' },
    { id: 'createdAt', label: 'Registration Time', align: 'left' },
    { id: 'paymentDate', label: 'Payment Date', align: 'left' },
    { id: 'amount', label: 'Amount', align: 'left' },
    // { id: 'otherDetails.grade', label: 'Grade', align: 'left' },
    // { id: 'otherDetails.school', label: 'School', align: 'left' },
    // { id: 'otherDetails.parentPhone', label: 'Parent Phone', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          <Typography m={2}>Bootcamp23 Reporting </Typography>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
              size='small'
            >
              <TableListHead
                order={paginationData.order}
                orderBy={paginationData.sort}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data.data.data.map((row) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell align='left'>{row.name} </TableCell>
                    <TableCell align='left'> {row.email} </TableCell>
                    <TableCell align='left'> {row.phone} </TableCell>
                    <TableCell align='left'>
                      {' '}
                      {row.createdAt &&
                        format(new Date(row.createdAt), 'do LLLL yyyy')}{' '}
                    </TableCell>
                    <TableCell align='left'>
                      {' '}
                      {row.paymentDate &&
                        format(new Date(row.paymentDate), 'do LLLL yyyy')}{' '}
                    </TableCell>
                    <TableCell align='left'>
                      {row.amount ? row.amount / 100 : ''}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[
                ...new Set([paginationData.limit, 5, 10, 50]),
              ]}
              component='div'
              count={data.data.totalCount}
              rowsPerPage={paginationData.limit}
              page={paginationData.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{
                disabled:
                  isFetching || data.data.page === data.data.totalPages - 1,
              }}
            />
          </TableContainer>
        </>
      )}
    </>
  );
}

export default Index;

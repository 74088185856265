import React, { useState } from 'react';
import { Table, Button, Box, Stack, Modal } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import Snackbar from '../../../../misc/Snackbar';
import EventTemplate from './EventTemplate';
import EventTemplateBody from './EventTemplateBody';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EventTemplateHeader = ({ data }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}
      >
        <Button variant='outlined' onClick={() => handleOpen()}>
          Add New Event Template
        </Button>
      </Stack>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align='left'> Type</TableCell>
              <TableCell align='left'> Journies </TableCell>
              <TableCell align='left'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((template) => {
              return (
                <EventTemplateBody template={template} key={template._id} />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EventTemplate
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </div>
  );
};

export default EventTemplateHeader;

import React from 'react';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import AssignmentBody from './AssignmentBody';

const AssignmentHeader = ({ data, setReFetch }) => {
  return (
    <TableContainer component={Card}>
      <Table
        size='small'
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'
      >
        <TableHead>
          <TableRow>
            <TableCell>Title</TableCell>
            <TableCell align='left'> Deadline</TableCell>
            <TableCell align='left'> Task Number</TableCell>
            <TableCell align='left'></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((template) => {
            return (
              <AssignmentBody
                row={template}
                key={template._id}
                setReFetch={setReFetch}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssignmentHeader;

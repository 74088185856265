import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Stack, Button } from '@mui/material';
import AllHeader from './AllHeader';
import CsvDownload from 'react-json-to-csv';

const Index = ({ currentBatch }) => {
  const fetchCurrentBatchAssignment = async () => {
    const res = await axios.get(API_URL + '/assignment/get-all', {
      params: { batchId: currentBatch._id },
    });
    return res;
  };
  const allAssignment = useQuery(
    `fetchCurrentBatchAssignment${currentBatch._id}`,
    fetchCurrentBatchAssignment
  );
  const fetchCurrentBatchEvents = async () => {
    const res = await axios.get(API_URL + '/event/get-all', {
      params: { batchId: currentBatch._id },
    });
    return res;
  };
  const allEvents = useQuery(
    `fetchCurrentBatchEvents${currentBatch._id}`,
    fetchCurrentBatchEvents
  );

  const fetchCurrentBatchProject = async () => {
    const res = await axios.get(API_URL + '/vga-project', {
      params: { batchId: currentBatch._id },
    });
    return res;
  };

  const allProjects = useQuery(
    `fetchCurrentBatchProject${currentBatch._id}`,
    fetchCurrentBatchProject
  );

  const isOldVga =
    new Date('2023-04-30T00:30:00.000Z') > new Date(currentBatch.startDate);

  const users = currentBatch.users || [];
  const feedbacks = users.map((user) => {
    const userObj = {
      Batch: currentBatch.name,
      Name: user.name,
      Email: user.email,
    };
    const userFeedback = user.feedback;
    const vgaFeedback = user.vgaFeedback;

    if (userFeedback && isOldVga) {
      Object.keys(userFeedback).forEach((key) => {
        if (
          typeof userFeedback[key] === 'object' &&
          userFeedback[key] !== null &&
          !Array.isArray(userFeedback[key])
        ) {
          const rating =
            Object.values(userFeedback[key]).reduce((a, b) => a + b, 0) /
            Object.values(userFeedback[key]).length;
          userObj[key] = rating.toFixed(2);
        }
      });
      userObj['Recommendation'] = userFeedback['Score and Comment'];
      userObj['Show Testimonial'] =
        userFeedback[
          'Do you share your consent to feature your experience as a testimonial on our platform?'
        ];
      userObj['Short Testimonial'] =
        userFeedback[
          'Could you leave a short testimonial summarising your experience and learning from the journey?'
        ];
      if (userFeedback.image) {
        userObj['image'] =
          userFeedback.image && userFeedback.image.length
            ? 'https://api.belong.education/api/upload/' +
              userFeedback.image[userFeedback.image.length - 1]?.content
            : '';
      }
    }
    if (vgaFeedback?.data?.length && !isOldVga) {
      const radioFeedbacks = vgaFeedback.data.filter((el) => {
        return el.questionType === 'radio';
      });

      radioFeedbacks.forEach((el) => {
        userObj[`${el.category}- ${el.question}`] = el.value;
      });
      const avgScore =
        radioFeedbacks.reduce((acc, curr) => acc + curr.value, 0) /
        radioFeedbacks.length;
      userObj['Average Score'] = avgScore.toFixed(2);
    }
    return userObj;
  });

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}
      >
        {currentBatch.status === 'Completed' && (
          <CsvDownload data={feedbacks}>
            <Button variant='outlined'>Users Feedback</Button>
          </CsvDownload>
        )}
      </Stack>
      {(allAssignment.status === 'loading' ||
        allEvents.status === 'loading' ||
        allProjects.status === 'loading') && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {(allAssignment.status === 'error' ||
        allEvents.status === 'error' ||
        allProjects.status === 'error') && <div>Error fetching data</div>}
      {allAssignment.status === 'success' &&
        allEvents.status === 'success' &&
        allProjects.status === 'success' && (
          <AllHeader
            allEventData={allEvents.data.data.data}
            allAssignmentData={allAssignment.data.data.data}
            allProjectData={allProjects.data?.data?.data}
            currentBatch={currentBatch}
          />
        )}
    </div>
  );
};

export default Index;

import React, { useEffect, useCallback } from 'react';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { Box } from '@mui/material';
import axios from 'axios';
import { API_URL, UPLOAD_OR_DOWNLOAD_URL } from 'links/API_LINKS';

const TextEditor = ({ setFieldValue, valueToUpdate, initialtext }) => {
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: [
        ['bold', 'italic'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ size: ['small', false, 'large', 'huge'] }],
        [{ color: [] }],
        // ['clean'],
        ['image'],
      ],
    },
    formats: [
      'bold',
      'list',
      'link',
      'color',
      //   'clean',
      'italic',
      'size',
      'underline',
      'image',
    ],
  });
  useEffect(() => {
    if (quill) {
      quill.root.innerHTML = initialtext;
      quill.on('text-change', () => {
        setFieldValue(valueToUpdate, quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, setFieldValue, initialtext, valueToUpdate]);

  if (Quill && !quill) {
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  const insertToEditor = useCallback(
    (imageUrl) => {
      const finalUrl = `${UPLOAD_OR_DOWNLOAD_URL}/${imageUrl}`;
      if (quill) {
        const range = quill.getSelection();
        if (range) {
          quill.insertEmbed(range.index, 'image', finalUrl);
        }
      }
    },
    [quill]
  );

  const uploadFile = useCallback(
    async (files) => {
      const fileExtension = files.name
        .substring(files.name.lastIndexOf('.') + 1)
        .toLowerCase();
      const formData = new FormData();
      formData.append('file', files);
      formData.append('field', 'PROFILE_IMAGE');
      formData.append('fileExtension', fileExtension);
      const result = await axios.post(API_URL + '/aws-upload/single', formData);
      if (result.status === 200) {
        insertToEditor(result.data.publicUrl);
      }
    },
    [insertToEditor]
  );

  const selectLocalImage = useCallback(() => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = () => {
      if (input.files && quill) {
        const file = input.files[0];
        uploadFile(file);
      }
    };
  }, [quill, uploadFile]);

  useEffect(() => {
    if (quill) {
      quill.getModule('toolbar').addHandler('image', selectLocalImage);
    }
  }, [quill, selectLocalImage]);

  return (
    <Box sx={{ height: 150 }} mb={8}>
      <div ref={quillRef} />
    </Box>
  );
};

export default TextEditor;

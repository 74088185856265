export const oldLabel = [
  'April-2020',
  'May-2020',
  'June-2020',
  'July-2020',
  'August-2020',
  'September-2020',
  'October-2020',
  'November-2020',
  'December-2020',
  'January-2021',
  'February-2021',
  'March-2021',
  'April-2021',
];

export const OldEnrolment = [
  218, 234, 186, 25, 92, 41, 75, 40, 214, 139, 190, 268, 398,
];

export const OldEvent = [16, 111, 179, 127, 30, 26, 21, 16, 34, 29, 38, 24, 26];

export const OldUser = [
  218, 234, 186, 25, 92, 41, 75, 40, 214, 139, 190, 268, 398,
];

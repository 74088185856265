import React, { useState, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import QuestionBody from './QuestionBody';
import EditQuestion from './EditQuestion';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

const fetchQuestion = async (params) => {
  const {
    limit,
    page,
    sort,
    order,
    searchRequestKeyword,
    questionType,
    domainId,
  } = params;
  const matchObj = { limit, page, sort, order, keyword: searchRequestKeyword };
  if (questionType) matchObj.type = questionType;
  if (domainId) matchObj.domain = domainId;
  const res = await axios.get(API_URL + '/question', {
    params: matchObj,
  });

  return res;
};

const fetchAreas = async (keys) => {
  const res = await axios.get(API_URL + '/area', { params: { limit: 0 } });
  return res;
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function QuestionHeader() {
  const [questionToEdit, setQuestionToEdit] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [questionType, setQuestionType] = useState('');
  const [domainId, setDomainId] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'type',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [
      `fetchQuestion`,
      paginationData,
      searchRequestKeyword,
      questionType,
      domainId,
    ],
    () =>
      fetchQuestion({
        ...paginationData,
        searchRequestKeyword,
        questionType,
        domainId,
      }),
    { keepPreviousData: true }
  );

  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'question', label: 'Question', align: 'left' },
    { id: 'type', label: 'Type', align: 'left' },
    { id: 'domain', label: 'Domain', align: 'left' },
    { id: 'skill', label: 'Skill', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  const areas = useQuery('fetchAreas', fetchAreas);

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {questionToEdit ? (
            <EditQuestion
              questionToEdit={questionToEdit}
              setQuestionToEdit={setQuestionToEdit}
            />
          ) : (
            <>
              <Stack direction='row' spacing={2}>
                <Paper
                  elevation={5}
                  sx={{ minWidth: 250, maxWidth: 345, margin: 1 }}>
                  <TextField
                    fullWidth
                    label='Search Question'
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                      debouncedHandler(e.target.value);
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton
                            onClick={() => {
                              setSearchKeyword('');
                              debouncedHandler('', 0);
                            }}
                            edge='end'>
                            {searchKeyword ? <CloseIcon /> : <SearchIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Paper>
                <FormControl sx={{ marginInline: 4 }} fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>Type</InputLabel>
                  <Select
                    size='large'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={questionType}
                    label='Type'
                    onChange={(e) => setQuestionType(e.target.value)}>
                    <MenuItem value=''>ALL</MenuItem>
                    <MenuItem value='DOMAIN'>DOMAIN</MenuItem>
                    <MenuItem value='MASTERCLASS'>MASTERCLASS</MenuItem>
                    <MenuItem value='B2C DOMAIN'>B2C DOMAIN </MenuItem>
                    <MenuItem value='GENERAL'>GENERAL </MenuItem>
                  </Select>
                </FormControl>

                <FormControl sx={{ marginInline: 4 }} fullWidth size='small'>
                  <InputLabel id='demo-simple-select-label'>Domain</InputLabel>
                  <Select
                    size='large'
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={domainId}
                    label='Type'
                    onChange={(e) => setDomainId(e.target.value)}>
                    <MenuItem value=''>ALL</MenuItem>
                    {areas.status === 'success' &&
                      areas?.data?.data?.data?.map((el) => {
                        return (
                          <MenuItem value={el._id} key={el._id}>
                            {' '}
                            {el.title}{' '}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Stack>

              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                  aria-label='table'
                  size='small'>
                  <TableListHead
                    order={paginationData.order}
                    orderBy={paginationData.sort}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {data.data.data.map((row) => {
                      return (
                        <QuestionBody
                          key={row.id}
                          row={row}
                          setQuestionToEdit={setQuestionToEdit}
                        />
                      );
                    })}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[
                    ...new Set([paginationData.limit, 5, 10, 50]),
                  ]}
                  component='div'
                  count={data.data.totalCount}
                  rowsPerPage={paginationData.limit}
                  page={paginationData.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{
                    disabled:
                      isFetching || data.data.page === data.data.totalPages - 1,
                  }}
                />
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
}

export default QuestionHeader;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Typography } from '@mui/material';

const AllAdminBody = ({ admin }) => {
  return (
    <>
      <TableRow
        key={admin._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left'>
          {admin.firstName} {admin.lastName}
        </TableCell>
        <TableCell align='left'> {admin.email} </TableCell>
        <TableCell align='left' sx={{ display: 'flex' }}>
          {admin.roles.map((role, index) => {
            return (
              <Typography key={role}>{(index ? ', ' : '') + role}</Typography>
            );
          })}
        </TableCell>
        <TableCell align='left'>
          {admin.partnerId && <Typography>{admin.partnerId.name}</Typography>}
        </TableCell>
        <TableCell align='left'>
          {admin.companyId && <Typography>{admin.companyId.name}</Typography>}
        </TableCell>
      </TableRow>
    </>
  );
};

export default AllAdminBody;

import { Box, CircularProgress } from '@mui/material';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Card from '@mui/material/Card';
import Feedback from './Feedback';

const UserDetails = ({ allUsers }) => {
  const [userData, setUserData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        allUsers.map(async (item) => {
          const user = await axios(API_URL + '/user', {
            params: { userId: item.user_id },
          });
          return { ...item, userDetails: user?.data?.data };
        })
      );
      setUserData(data);
      setLoading(false);
    };

    fetchData();
  }, [allUsers]);

  return (
    <div>
      {loading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {userData.length && (
        <>
          <Feedback userData={userData} />
          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'>
              <TableHead>
                <TableRow>
                  <TableCell>Sr No.</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align='left'>Email</TableCell>
                  <TableCell align='left'>Contact No</TableCell>
                  <TableCell align='left'>Education</TableCell>
                  <TableCell align='left'>Work Experience</TableCell>
                  <TableCell align='left'>milestone-1 </TableCell>
                  <TableCell align='left'>milestone-2</TableCell>
                  <TableCell align='left'>milestone-3</TableCell>

                  <TableCell align='left'>Git Link</TableCell>
                  <TableCell align='left'>WbbPage Link</TableCell>
                  {/* <TableCell align='left'>Tasks</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {userData.map((user, index) => {
                  const milestone_1 =
                    user?.milestone_1?.html?.length +
                    user?.milestone_1?.css?.length;
                  const milestone_2 =
                    user?.milestone_2?.js?.length +
                    user?.milestone_2?.bootstrap?.length;
                  const milestone_3 = user?.milestone_3?.git?.length;
                  const workExperience =
                    user?.userDetails?.workExperience || [];

                  let timeDiff = 0;
                  workExperience.forEach((exp) => {
                    const date1 = new Date(exp.startDate);
                    const date2 = exp.endDate
                      ? new Date(exp.endDate)
                      : new Date(Date.now());
                    const differenceInTime = date2.getTime() - date1.getTime();
                    const differenceInMonth =
                      differenceInTime / (1000 * 3600 * 24 * 30);
                    timeDiff += differenceInMonth;
                  });
                  return (
                    <TableRow
                      key={user._id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}>
                      <TableCell component='th' scope='row'>
                        {index + 1}
                      </TableCell>
                      <TableCell component='th' scope='row'>
                        {user?.userDetails?.firstName}{' '}
                        {user?.userDetails?.lastName}
                      </TableCell>
                      <TableCell
                        align='left'
                        // sx={{ maxWidth: '250px', wordBreak: 'break-word' }}
                      >
                        {user?.userDetails?.email}
                      </TableCell>
                      <TableCell align='left'>
                        {user?.userDetails?.contactNumber}
                      </TableCell>
                      <TableCell align='left'>
                        {' '}
                        {user?.userDetails?.education[0]?.oganizationName}{' '}
                      </TableCell>
                      <TableCell align='left'>
                        {' '}
                        {timeDiff.toFixed(1)}{' '}
                      </TableCell>
                      <TableCell align='left'>{milestone_1}/24</TableCell>
                      <TableCell align='left'>{milestone_2}/37</TableCell>
                      <TableCell align='left'>{milestone_3}/5</TableCell>
                      <TableCell align='left'>
                        {' '}
                        {user.githubUrl && (
                          <a
                            href={user.githubUrl}
                            target='_blank'
                            rel='noreferrer'>
                            Git
                          </a>
                        )}{' '}
                      </TableCell>
                      <TableCell align='left'>
                        {' '}
                        {user.webpageUrl && (
                          <a
                            href={user.webpageUrl}
                            target='_blank'
                            rel='noreferrer'>
                            Web
                          </a>
                        )}{' '}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default UserDetails;

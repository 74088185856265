import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import CoachForm from './CoachForm';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditCoach = ({ coachToEdit, setCoachToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const {
    _id,
    name,
    email,
    country,
    website,
    designation,
    linkedIn,
    coachImageUrl,
    areas,
    sectionTitle,
    sectionContent,
    coachSign,
  } = {
    ...coachToEdit,
  };
  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setCoachToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          coachId: _id,
          name: name,
          website: website,
          email: email,
          designation: designation,
          linkedIn: linkedIn,
          country: country,
          coachImageUrl: coachImageUrl,
          areas: areas,
          sectionTitle: sectionTitle,
          sectionContent: sectionContent,
          coachSign: coachSign || '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(3, 'Must be 3 characters or more')
            .max(30, 'Must be 30 characters or less')
            .required('Please enter a name'),
          website: Yup.string().url(),
          country: Yup.string().required(),
          email: Yup.string().email().required(),
          designation: Yup.string().required('Please enter the designation'),
          coachImageUrl: Yup.string().url().required(),
          sectionContent: Yup.array().of(
            Yup.object().shape({
              contentToPublish: Yup.string().required(
                'Mentor Brief content is required'
              ),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/coach',
            data: values,
          })
            .then(function (response) {
              console.log(response);
              queryClient.invalidateQueries('fetchCoach');
              setServerResponse(response.data.msg);
              setSubmitting(false);
              setCoachToEdit(null);
            })
            .catch(function (response) {
              console.log(response);
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <CoachForm
              values={values}
              email={email}
              setFieldValue={setFieldValue}
            />

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditCoach;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Avatar, Checkbox } from '@mui/material';
import { UPLOAD_OR_DOWNLOAD_URL } from 'links/API_LINKS';
// import EditIcon from '@mui/icons-material/Edit';

const AllUserBody = ({ row, index, selectedUser, setSelectedUser }) => {
  const imageUrl = row.imageUrl
    ? UPLOAD_OR_DOWNLOAD_URL + '/' + row.imageUrl
    : '';

  const handleClick = () => {
    const isItemSelected = selectedUser.find(
      (user) => user._id === row.user._id
    );
    if (isItemSelected) {
      const newSelected = selectedUser.filter(
        (user) => user._id !== row.user._id
      );
      setSelectedUser(newSelected);
    } else {
      const newSelected = selectedUser.concat([row.user]);
      setSelectedUser(newSelected);
    }
  };

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell padding='checkbox'>
          <Checkbox disabled={row.userTeam} onChange={handleClick} />
        </TableCell>
        <TableCell align='left'>{index + 1} </TableCell>
        <TableCell align='left'>
          <Avatar
            alt='faculty Image'
            src={imageUrl}
            sx={{ width: 24, height: 24 }}
          />{' '}
        </TableCell>{' '}
        <TableCell align='left'>{row.name} </TableCell>
        <TableCell align='left'> {row.email} </TableCell>
        <TableCell align='left'>{row.profile_Percentage} </TableCell>
        <TableCell align='left'> {row.quizPercentage} </TableCell>
        <TableCell align='left'> {row.totalScore} </TableCell>
        <TableCell align='left'> {row.level} </TableCell>
        <TableCell align='left'> {row.workExperience} </TableCell>
        <TableCell align='left'> {row.stream} </TableCell>
        <TableCell align='left'> {row.userTeam} </TableCell>
      </TableRow>
    </>
  );
};

export default AllUserBody;

import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { TextInput, SelectInput } from '../../layouts/FormElement';

import { Grid, Typography, MenuItem } from '@mui/material';
import { API_URL } from 'links/API_LINKS';
import TextEditor from '../../pod/capstones/TextEditor';
import { FieldArray } from 'formik';
// import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';

const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};

const CenturyLabForm = ({
  values,
  setFieldValue,
  projectTaskToEdit,
  errors,
}) => {
  const skills = useQuery('fetchSkills', fetchSkills);
  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 3 }}
        mb={3}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='title'
            type='text'
            label='Title*'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' name='level' label='Difficulty Level*'>
            <MenuItem value='Basic'>Basic</MenuItem>
            <MenuItem value='Intermediate'>Intermediate</MenuItem>
            <MenuItem value='Advanced'>Advanced</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <SelectInput
            size='small'
            fullWidth
            name='journeyType'
            label='Journey Type'
            multiple
          >
            <MenuItem value='School/DT Journey'>School/DT Journey</MenuItem>
            <MenuItem value='Tech'>Tech</MenuItem>
            <MenuItem value='Non Tech'>Non Tech</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Introduction
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.introduction || ''}
            valueToUpdate='introduction'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.problemStatement}
          </Typography>
        </Grid>
        {/* <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Business objectives
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.businessObjectives || ''}
            valueToUpdate='businessObjectives'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.businessObjectives}
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Template Description
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.template?.description || ''}
            valueToUpdate='template.description'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors?.template?.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='template.link'
            type='text'
            label='Template Link'
          />
        </Grid>
      </Grid>
      <Grid mt={6} item>
        <FieldArray
          name='tasks'
          render={(arrayHelpers) => (
            <>
              {values.tasks.map((title, index) => {
                return (
                  <Grid
                    sx={{ border: 1 }}
                    key={index}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 1, md: 3 }}
                    mb={3}
                    pb={5}
                  >
                    <Grid item xs={12} sm={6} md={4}>
                      {skills.status === 'success' && (
                        <SelectInput
                          size='small'
                          name={`tasks[${index}].skillId`}
                          label='Skills'
                          multiple
                          fullWidth
                        >
                          {skills.data.data.data.map((skill) => {
                            return (
                              <MenuItem key={skill._id} value={skill._id}>
                                {skill.title}
                              </MenuItem>
                            );
                          })}
                        </SelectInput>
                      )}
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={4}>
                      <SelectInput
                        size='small'
                        name={`tasks[${index}].level`}
                        label='Level (not compulsory)'
                      >
                        <MenuItem value='Basic'>Basic</MenuItem>
                        <MenuItem value='Intermediate'>Intermediate</MenuItem>
                        <MenuItem value='Advanced'>Advanced</MenuItem>
                      </SelectInput>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='number'
                        name={`tasks[${index}].week`}
                        label='Week'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <SelectInput
                        size='small'
                        name={`tasks[${index}].day`}
                        label='Day'
                      >
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>5</MenuItem>
                        <MenuItem value={5}>5</MenuItem>
                      </SelectInput>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='number'
                        name={`tasks[${index}].duration`}
                        label='Duration in Days'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Learning Outcomes
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]?.learningOutcomes ||
                          ''
                        }
                        valueToUpdate={`tasks[${index}].learningOutcomes`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.learningOutcomes}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Description
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]
                            ?.backgroundDescription || ''
                        }
                        valueToUpdate={`tasks[${index}].backgroundDescription`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks &&
                          errors.tasks[index]?.backgroundDescription}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Task Instructions
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]?.taskInstructions ||
                          ''
                        }
                        valueToUpdate={`tasks[${index}].taskInstructions`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.taskInstructions}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Resources
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]?.resources || ''
                        }
                        valueToUpdate={`tasks[${index}].resources`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.resources}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              {/* <Box mb={1}>
                Add more task
                <Button
                  type='button'
                  onClick={() =>
                    arrayHelpers.push({
                      title: '',
                      skillId: [],
                      backgroundDescription: '',
                      taskInstructions: '',
                      resources: '',
                      time: '',
                    })
                  }
                >
                  <FaPlusCircle />
                </Button>
              </Box> */}
            </>
          )}
        />
      </Grid>
    </div>
  );
};

export default CenturyLabForm;

import React, { useState } from 'react';
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
  Button,
  Modal,
  Stack,
} from '@mui/material';
import ProjectBody from './ProjectBody';
import AddCompanyProject from './AddCompanyProject';
import AddCenturyTask from './AddCenturyTask';
import { API_URL } from '../../../../../../../links/API_LINKS';
import axios from 'axios';
import Snackbar from '../../../../../../misc/Snackbar';
import { useQuery, useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0 },
  });
  return res;
};

const ShowProjects = ({
  allProject,
  checkForError,
  setAllProject,
  currentBatch,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openOther, setOpenOther] = useState(false);
  const handleOpenOther = () => setOpenOther(true);
  const handleCloseOther = () => setOpenOther(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  async function submitAllProject() {
    const isAllchecked = allProject.every((item) => {
      return item.checked;
    });
    if (isAllchecked) {
      setLoading(true);
      const projectData = allProject.map((project) => {
        const tempProject = { ...project };
        delete tempProject.checked;

        if (tempProject.type === 'Company Tasks') {
          const companyId = tempProject.companyId?._id;
          const domainId = tempProject.domainId?._id;

          return { ...tempProject, companyId, domainId };
        } else {
          delete tempProject.companyId;
          delete tempProject.domainId;
          return { ...tempProject };
        }
      });
      axios({
        method: 'POST',
        url: API_URL + '/vga-project/add-multiple',
        data: projectData,
      })
        .then(function (response) {
          console.log(response);
          setSnackbarText('Project added successfully');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
          setLoading(false);
          queryClient.invalidateQueries(
            `fetchCurrentBatchProject${currentBatch._id}`
          );
          queryClient.invalidateQueries(`fetchCurrentBatch${currentBatch._id}`);
          setAllProject([]);
        })
        .catch(function (response) {
          setSnackbarText('Failed to add Project, Please try after sometime');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
          setLoading(false);
        });
    } else {
      setSnackbarText('Please confirm all Project first');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  }

  const allSkills = useQuery('fetchSkills', fetchSkills);

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          p: 1,
          m: 1,
          gap: 2,
        }}>
        <Button onClick={() => handleOpen()} variant='outlined'>
          Add Company Project
        </Button>
        <Button onClick={() => handleOpenOther()} variant='outlined'>
          Add 21st Century Project
        </Button>
      </Box>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align='left'> Project Type</TableCell>
              <TableCell align='left'> Level</TableCell>
              <TableCell align='left'> Skills</TableCell>
              <TableCell align='left'> Tasks </TableCell>
              <TableCell align='left'>Company-Domain</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allProject.map((project, index) => {
              return (
                <ProjectBody
                  key={index}
                  project={project}
                  index={index}
                  allProject={allProject}
                  checkForError={checkForError}
                  setAllProject={setAllProject}
                  skills={allSkills?.data?.data?.data || []}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        mt={2}>
        <LoadingButton
          loading={loading}
          variant='outlined'
          onClick={() => submitAllProject()}>
          Add All Projects
        </LoadingButton>
      </Stack>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <AddCompanyProject
            allProject={allProject}
            checkForError={checkForError}
            setAllProject={setAllProject}
            currentBatch={currentBatch}
            handleClose={handleClose}
          />
        </Box>
      </Modal>
      <Modal
        open={openOther}
        onClose={handleCloseOther}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <AddCenturyTask
            allProject={allProject}
            checkForError={checkForError}
            setAllProject={setAllProject}
            currentBatch={currentBatch}
            handleClose={handleCloseOther}
          />
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}></Snackbar>
    </Box>
  );
};

export default ShowProjects;

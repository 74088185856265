import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import {
  TableRow,
  Chip,
  Modal,
  Box,
  Button,
  Badge,
  Stack,
} from '@mui/material';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import EditIcon from '@mui/icons-material/Edit';
import SchoolIcon from '@mui/icons-material/School';
import { format } from 'date-fns';
import Snackbar from '../../../../misc/Snackbar';
import EditBatch from './EditBatch';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '95%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BatchBody({ row, setCurrentBatch, setAdduserBatch }) {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchBatchTicket = async () => {
    const res = await axios.get(API_URL + '/ticket/count-ticket', {
      params: { batchId: row._id },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchBatchTicket${row._id}`,
    fetchBatchTicket
  );

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left' sx={{ cursor: 'pointer' }}>
          {row.name}
        </TableCell>
        <TableCell align='left'>
          {format(new Date(row.startDate), 'do LLLL yyyy')}{' '}
        </TableCell>
        <TableCell align='left'>
          {row.status === 'Active' ? (
            <Chip color='primary' size='small' label='Active' />
          ) : (
            <Chip size='small' label={row.status} />
          )}
        </TableCell>
        <TableCell align='left'>{row.user}</TableCell>

        <TableCell
          align='left'
          onClick={() => setCurrentBatch(row.batchData)}
          sx={{ cursor: 'pointer' }}
        >
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
          >
            <SchoolIcon color='primary' />
            {status === 'success' && data?.data?.data?.openTicket > 0 && (
              <Badge
                badgeContent={data?.data?.data?.openTicket}
                color='warning'
              >
                <CircleNotificationsIcon color='secondary' />
              </Badge>
            )}
          </Stack>
        </TableCell>
        <TableCell
          align='left'
          onClick={() => handleOpen()}
          sx={{ cursor: 'pointer' }}
        >
          <EditIcon color='primary' />
        </TableCell>
        <TableCell
          align='left'
          onClick={() => setAdduserBatch(row.batchData)}
          sx={{ cursor: 'pointer' }}
        >
          <Button> Add User</Button>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditBatch
            handleCloseBatch={handleClose}
            batchData={row.batchData}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
}

export default BatchBody;

import React, { useRef } from 'react';
import {
  TextInput,
  DateTimePickerField,
  SelectInput,
} from '../../../../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography, Button } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';
import { getAllCompany, getAllCoach, getAllArea } from '../helper';

const AddSubEvent = ({ event, snackbarActions, handleClose, currentBatch }) => {
  const queryClient = useQueryClient();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  const batchUser = currentBatch.users;
  let allCoach = getAllCoach(batchUser);
  let allCompany = getAllCompany(batchUser);
  let allArea = getAllArea(batchUser);

  return (
    <div>
      <Typography align='center' mb={2}>
        Add Event Under {event.title}
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          masterEventId: event._id,
          batch: currentBatch._id,
          title: '',
          type: 'Coach Connect',
          week: '',
          description: event.description,
          imageUrl: event.imageUrl,
          dateTime: event.dateTime,
          link: '',
          onlineOrOffline: 'Online',
          status: 'publish',
          resources: [],
          companyId: null,
          areaId: null,
          coachId: null,
        }}
        validationSchema={Yup.object({
          link: Yup.string().url().required('link is required'),
          week: Yup.number().required('Week is required'),
          title: Yup.string().required('Title is required'),
          imageUrl: Yup.string().url().required('image is required'),
          resources: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
              link: Yup.string().url().required('Link is required'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios({
            method: 'POST',
            url: API_URL + '/event/add-event-in-masterevent',
            data: values,
          })
            .then(function (response) {
              setSnackbarText('Event Added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${event.batch}`
              );
              queryClient.invalidateQueries(`fetchCurrentBatch${event.batch}`);
              handleClose();
            })
            .catch(function (response) {
              setSnackbarText('Failed to add Events');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='title'
                  type='text'
                  label='Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='type'
                  label='Event Type'
                >
                  <MenuItem value='Coach Connect'>Coach Connect</MenuItem>
                  <MenuItem value='Mentor Connect'>Mentor Connect</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  name='week'
                  type='number'
                  label='Week'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  multiline
                  rows={3}
                  name='description'
                  type='text'
                  label='Description'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='imageUrl'
                  type='text'
                  label='Image'
                />
                <div>
                  <img src={values.imageUrl} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePickerField
                  name='dateTime'
                  value={values.dateTime}
                  label='Event Date'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='link'
                  type='text'
                  label='Link'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Status'
                >
                  <MenuItem value='publish'>Publish</MenuItem>
                  <MenuItem value='draft'>Draft</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='recordingUrl'
                  type='text'
                  label='Recording Url'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='coachId'
                  label='Coach'
                >
                  <MenuItem value={null}>All</MenuItem>
                  {allCoach.map((coach) => {
                    return (
                      <MenuItem value={coach._id} key={coach._id}>
                        {coach.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='companyId'
                  label='Company'
                >
                  <MenuItem value={null}>All</MenuItem>
                  {allCompany.map((company) => {
                    return (
                      <MenuItem value={company._id} key={company._id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput size='small' fullWidth name='areaId' label='Area'>
                  <MenuItem value={null}>All</MenuItem>
                  {allArea.map((area) => {
                    return (
                      <MenuItem value={area._id} key={area._id}>
                        {area.title}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name='resources'
                  render={(arrayHelpers) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography variant='h6'>Resources</Typography>

                        <Button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              link: '',
                            })
                          }
                        >
                          +ADD
                        </Button>
                      </div>
                      {values.resources.map((content, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                              mb={3}
                            >
                              {' '}
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].link`}
                                  type='text'
                                  label='Link'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].title`}
                                  type='text'
                                  label='Title'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Event
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddSubEvent;

import React, { useState } from 'react';
import moment from 'moment';
import ShowEvent from './ShowEvent';
import { API_URL } from '../../../../../../../links/API_LINKS';
import axios from 'axios';
import GetDetailForm from './GetDetailForm';
import BasicInfo from './BasicInfo';
import { Typography } from '@mui/material';
import { getAllCompany, getAllCoach } from '../../helper';

const Index = ({ currentBatch }) => {
  const batchId = currentBatch._id;
  const partnerId = currentBatch.partner;
  const batchUser = currentBatch.users;
  let allCoach = getAllCoach(batchUser);
  let allCompany = getAllCompany(batchUser);

  const dt1 = new Date(currentBatch.startDate);
  const battchStartDay = dt1.getDay();
  const [allEvents, setAllEvent] = useState([]);

  const getUniqueAreas = (companyId) => {
    const companyUser = batchUser.filter((user) => {
      return user.company._id === companyId;
    });
    let allArea = [];
    companyUser.forEach((user) => {
      if (
        !allArea.some((area) => {
          return user.area._id === area._id;
        })
      ) {
        allArea.push(user.area);
      }
    });
    return allArea;
  };

  const updateDateTime = (event, masterClassTime, coachTime, mentorTime) => {
    let timeUpdate = dt1;
    if (event.type === 'Masterclass') {
      timeUpdate = moment(dt1).set({
        hour: masterClassTime.getHours(),
        minute: masterClassTime.getMinutes(),
      });
    }
    if (event.type === 'Coach Connect') {
      timeUpdate = moment(dt1).set({
        hour: coachTime.getHours(),
        minute: coachTime.getMinutes(),
      });
    }
    if (event.type === 'Mentor Connect') {
      timeUpdate = moment(dt1).set({
        hour: mentorTime.getHours(),
        minute: mentorTime.getMinutes(),
      });
    }
    let mydate;
    if (battchStartDay + event.journies.day > 6) {
      mydate = moment(timeUpdate, 'DD-MM-YYYY').add(
        (event.journies.week - 1) * 7 + event.journies.day + 1,
        'days'
      )._d;
    } else {
      mydate = moment(timeUpdate, 'DD-MM-YYYY').add(
        (event.journies.week - 1) * 7 + event.journies.day - 1,
        'days'
      )._d;
    }
    return mydate;
  };

  const makeCurrentEvent = (event, eventDate) => {
    let tempEvent = {};
    const eventBasicDetail = {
      eventDate: eventDate,
      link: '',
      onlineOrOffline: 'Online',
      status: 'publish',
      publishAt: '',
      batch: batchId,
    };
    if (event.type === 'Masterclass') {
      const currentEventMade = {
        ...event,
        ...eventBasicDetail,
      };
      tempEvent = { ...currentEventMade };
    }
    if (event.type === 'Coach Connect') {
      let subEvents = [];
      allCoach.forEach((coach) => {
        subEvents.push({
          ...event,
          ...eventBasicDetail,
          title: event.title + ' | ' + coach.name,
          coachId: coach._id,
        });
      });
      const currentEventMade = {
        ...event,
        ...eventBasicDetail,
        subEvents: subEvents,
        partner: partnerId,
      };
      tempEvent = { ...currentEventMade };
    }

    if (event.type === 'Mentor Connect') {
      let subEvents = [];
      for (let i = 0; i < allCompany.length; i++) {
        const uniqueAreas = getUniqueAreas(allCompany[i]._id);
        if (uniqueAreas.length === 1) {
          subEvents.push({
            ...event,
            ...eventBasicDetail,
            title: event.title + ' | ' + allCompany[i].name,
            companyId: allCompany[i]._id,
          });
        } else {
          for (let j = 0; j < uniqueAreas.length; j++) {
            subEvents.push({
              ...event,
              ...eventBasicDetail,
              title:
                event.title +
                ' | ' +
                allCompany[i].name +
                ' | ' +
                uniqueAreas[j].title,
              companyId: allCompany[i]._id,
              areaId: uniqueAreas[j]._id,
            });
          }
        }
      }
      const currentEventMade = {
        ...event,
        ...eventBasicDetail,
        subEvents: subEvents,
        partner: partnerId,
      };
      tempEvent = { ...currentEventMade };
    }
    return tempEvent;
  };

  const makeJourny = (data, masterClassTime, coachTime, mentorTime) => {
    let tempEvents = [];
    data.forEach((event) => {
      const eventDate = updateDateTime(
        event,
        masterClassTime,
        coachTime,
        mentorTime
      );
      const currentEventMade = {
        ...makeCurrentEvent(event, eventDate),
      };
      tempEvents.push(currentEventMade);
    });

    setAllEvent(tempEvents);
  };

  async function getTemplate(
    journeyType,
    journeyDuration,
    masterClassTime,
    coachTime,
    mentorTime,
    setSubmitting
  ) {
    const res = await axios.get(API_URL + '/event-template', {
      params: { journeyType, journeyDuration },
    });
    if (res.status === 200) {
      makeJourny(res.data.data, masterClassTime, coachTime, mentorTime);
      setSubmitting(false);
    }
  }

  return (
    <div>
      <BasicInfo
        allCoach={allCoach}
        allCompany={allCompany}
        batchUser={batchUser}
        eventAdded={currentBatch.events}
      />
      <GetDetailForm getTemplate={getTemplate} />
      {allEvents.length > 0 ? (
        <ShowEvent
          data={allEvents}
          makeCurrentEvent={makeCurrentEvent}
          currentBatch={currentBatch}
        />
      ) : (
        <Typography textAlign='center' variant='subtitle2'>
          No Event found
        </Typography>
      )}
    </div>
  );
};

export default Index;

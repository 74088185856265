import { Typography, Stack, Button } from '@mui/material';
import React, { useState } from 'react';
import EditAudience from './EditAudience';
import { getAllCompany, getAllCoach, getAllArea } from '../helper';

const checkForUser = (criteriaId, usersIdForThatCriteria) => {
  if (!criteriaId.length) {
    return true;
  }
  if (criteriaId.length && criteriaId.includes(usersIdForThatCriteria._id)) {
    return true;
  }
  return false;
};

const AudienceDetails = ({
  currentBatch,
  projectId,
  snackbarActions,
  handleClose,
}) => {
  const [edit, setEdit] = useState(false);
  const batchUser = currentBatch.users;
  const currentProject = currentBatch.projects.filter((project) => {
    return project.projectId === projectId;
  })[0];
  let eligibleUsers = [];

  if (currentProject) {
    currentBatch.users.forEach((user) => {
      const userCompany = user.company;
      const userMentor = user.mentor;
      const userCoach = user.coach;
      const userArea = user.area;

      checkForUser(currentProject.companyId, userCompany) &&
        checkForUser(currentProject.mentorId, userMentor) &&
        checkForUser(currentProject.coachId, userCoach) &&
        checkForUser(currentProject.areas, userArea) &&
        eligibleUsers.push(user);
    });
  }

  let allCoach = getAllCoach(batchUser);
  let allCompany = getAllCompany(batchUser);
  let allArea = getAllArea(batchUser);

  let areaName = 'All Areas';
  if (currentProject && currentProject.areas.length) {
    const eventArea = allArea.filter((area) => {
      return area._id === currentProject.areas[0];
    });
    if (eventArea[0]) {
      areaName = eventArea[0].title;
    }
  }
  let coachName = 'All Coach';
  if (currentProject && currentProject.coachId.length) {
    const eventCoach = allCoach.filter((coach) => {
      return coach._id === currentProject.coachId[0];
    });
    if (eventCoach[0]) {
      coachName = eventCoach[0].name;
    }
  }
  let companyName = 'All Company';
  if (currentProject && currentProject.companyId.length) {
    const eventCompany = allCompany.filter((company) => {
      return company._id === currentProject.companyId[0];
    });
    if (eventCompany[0]) {
      companyName = eventCompany[0].name;
    }
  }

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography variant='subtitle2'> Audience Details</Typography>
        <Button onClick={() => setEdit(!edit)}>
          {edit ? 'Cancel' : 'Add/Edit Audience'}
        </Button>
      </Stack>

      {edit ? (
        <EditAudience
          allCoach={allCoach}
          allCompany={allCompany}
          allArea={allArea}
          projectId={projectId}
          snackbarActions={snackbarActions}
          handleClose={handleClose}
          batchId={currentBatch._id}
        />
      ) : currentProject ? (
        <>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Typography>Areas : {areaName} </Typography>
            <Typography>Coach : {coachName} </Typography>
            <Typography>Company : {companyName} </Typography>
          </Stack>
          <Typography>
            {eligibleUsers.length} Audience eligible for this Task
          </Typography>
        </>
      ) : (
        <Typography>No Audience added for this Task </Typography>
      )}
    </div>
  );
};

export default AudienceDetails;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination, Box } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import MentorBody from './MentorBody';
import EditMentor from './EditMentor';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchMentor = async (params) => {
  const { limit, page, sort, order } = params;
  const res = await axios.get(API_URL + '/mentor', {
    params: { limit, page, sort, order },
  });

  return res;
};

function MentorHeader() {
  const [mentorToEdit, setMentorToEdit] = useState(null);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'name',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchMentor`, paginationData],
    () => fetchMentor({ ...paginationData }),
    { keepPreviousData: true }
  );

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'imageUrl', label: '', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'designation', label: 'Designation', align: 'left' },
    { id: 'country', label: 'Country', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {mentorToEdit ? (
            <EditMentor
              mentorToEdit={mentorToEdit}
              setMentorToEdit={setMentorToEdit}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.data.data.map((row) => (
                    <MentorBody
                      key={row._id}
                      row={row}
                      setMentorToEdit={setMentorToEdit}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data.data.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching || data.data.page === data.data.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default MentorHeader;

import React from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import FacultyHeader from './FacultyHeader';

const AllFaculty = ({ setValue }) => {
  const fetchFaculty = async () => {
    const res = await axios.get(API_URL + '/faculty', {
      params: { limit: 0 },
    });
    console.log('faculty data', res);

    return res;
  };
  const { status, data } = useQuery(`fetchFaculty`, fetchFaculty);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          <FacultyHeader data={data.data.data} setValue={setValue} />
        </Box>
      )}
    </div>
  );
};

export default AllFaculty;

import React, { useRef } from 'react';
import { Typography, Box, Button } from '@mui/material';
import Performance from './Performance';
import TopFivePerformers from './TopFivePerformers';
import TopPerformer from './TopPerformer';
import CategoryPerformers from './CategoryPerformers';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import { PDFExport } from '@progress/kendo-react-pdf';

const MakeReport = ({ masterclass, questions, logoUrl }) => {
  const pdfExportComponent = useRef(null);
  const contentArea = useRef(null);

  const handleExportWithMethod = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };
  return (
    <div>
      <Button onClick={handleExportWithMethod}>Download</Button>
      <Typography
        variant='h3'
        sx={{ textAlign: 'center', padding: '10px', margin: '10px' }}
      >
        Employability Masterclass Assessment Report
      </Typography>
      <PDFExport
        paperSize='auto'
        margin={{ top: 20, left: 30, right: 30, bottom: 40 }}
        ref={pdfExportComponent}
      >
        <Box px={1} ref={contentArea}>
          <FirstPage logoUrl={logoUrl} />
          <SecondPage />
          <Performance users={masterclass.users} />
          <TopPerformer users={masterclass.users} questions={questions} />
          <TopFivePerformers users={masterclass.users} />
          <CategoryPerformers users={masterclass.users} />
        </Box>
      </PDFExport>
    </div>
  );
};

export default MakeReport;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import MasterclassBody from './MasterclassBody';

import TableListHead from '../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function MasterclassHeader({ data, setShowReport, setMasterClassToEdit }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startDate');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((ass) => {
    return {
      _id: ass._id,
      masterclass: ass,
      title: ass.title,
      endDate: ass.endDate,
      startDate: ass.startDate,
      status: ass.status,
      partner: ass.partnerId.name,
      url: ass.code,
    };
  });

  const TABLE_HEAD = [
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'startDate', label: 'Start Date', align: 'left' },
    { id: 'endDate', label: 'End Date', align: 'center' },
    { id: 'partner', label: 'Partner', align: 'left' },
    { id: 'url', label: 'URL', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'action', label: 'Action', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <MasterclassBody
                  row={row}
                  key={row._id}
                  setShowReport={setShowReport}
                  setMasterClassToEdit={setMasterClassToEdit}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default MasterclassHeader;

import React from 'react';
import ApplicationStatus from './ApplicationStatus';
import NewProfile from '../../layouts/NewProfile';
import { Box } from '@mui/system';

const ApplicantProfile = ({ user, setShowProfile }) => {
  return (
    <div>
      <Box mb={4}>
        <ApplicationStatus user={user} />
      </Box>

      {/* <Profile setShowProfile={setShowProfile} userId={user.userId} /> */}
      <NewProfile setShowProfile={setShowProfile} userId={user.userId} />
    </div>
  );
};

export default ApplicantProfile;

import React, { useRef } from 'react';
import { SelectInput } from '../../../../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const EditAudience = ({
  allCoach,
  allCompany,
  allArea,
  projectId,
  snackbarActions,
  handleClose,
  batchId,
}) => {
  const queryClient = useQueryClient();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          projectId: projectId,
          batchId: batchId,
          companyId: null,
          areaId: null,
          coachId: null,
        }}
        validationSchema={Yup.object({})}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios({
            method: 'POST',
            url: API_URL + '/vga-project/update-audience',
            data: values,
          })
            .then(function (response) {
              setSnackbarText('Audience edited successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${batchId}`
              );
              handleClose();
            })
            .catch(function (response) {
              setSnackbarText('Failed to Edit Audience');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='coachId'
                  label='Coach'
                >
                  <MenuItem value={null}>All</MenuItem>
                  {allCoach.map((coach) => {
                    return (
                      <MenuItem value={coach._id} key={coach._id}>
                        {coach.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='companyId'
                  label='Company'
                >
                  <MenuItem value={null}>All</MenuItem>
                  {allCompany.map((company) => {
                    return (
                      <MenuItem value={company._id} key={company._id}>
                        {company.name}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput size='small' fullWidth name='areaId' label='Area'>
                  <MenuItem value={null}>All</MenuItem>
                  {allArea.map((area) => {
                    return (
                      <MenuItem value={area._id} key={area._id}>
                        {area.title}
                      </MenuItem>
                    );
                  })}
                </SelectInput>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='small'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update Audience
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditAudience;

import React from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';
import { format } from 'date-fns';
import { Button, Stack } from '@mui/material';

const TicketDownload = ({ allTickets }) => {
  const headers = [
    {
      key: 'user',
      name: 'User',
    },
    {
      key: 'email',
      name: 'Email',
    },
    {
      key: 'time',
      name: 'Raised Time',
    },
    {
      key: 'department',
      name: 'Department',
    },
    {
      key: 'status',
      name: 'Status',
    },
    {
      key: 'subject',
      name: 'Subject',
    },
    {
      key: 'description',
      name: 'Description',
    },
  ];

  const data = allTickets.map((ticket) => {
    return {
      user: ticket.user?.name || ticket.user?.email,
      email: ticket.user?.email,
      time:
        ticket.createdAt && format(new Date(ticket.createdAt), 'do LLLL yyyy'),
      department: ticket.department,
      status: ticket.status,
      subject: ticket.subject,
      description: ticket.description,
    };
  });

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <ExportJsonCsv headers={headers} items={data}>
          <Button variant='outlined'>Ticket Data</Button>
        </ExportJsonCsv>
      </Stack>
    </div>
  );
};

export default TicketDownload;

import React from 'react';
import Chart from 'react-apexcharts';

const ShowWeeklyChart = ({ data }) => {
  const series = data.values;
  const options = {
    chart: {
      type: 'bar',
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: data.labels,
    },
    yaxis: {
      title: {
        text: 'Sign Up',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return val + ' User';
        },
      },
    },
  };

  return (
    <div>
      <Chart options={options} series={series} type='bar' height={350} />
    </div>
  );
};

export default ShowWeeklyChart;

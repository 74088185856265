import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import CenturyLabForm from './CenturyLabForm';

const EditCenturyTask = ({
  projectToEdit,
  allProject,
  index,
  setAllProject,
  handleClose,
}) => {
  return (
    <Box>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            projectTemplateId: projectToEdit._id,
            title: projectToEdit.title,
            level:
              projectToEdit.level || projectToEdit.tasks[0]?.level || 'Basic',
            type: projectToEdit.type,
            introduction: projectToEdit.introduction || '',
            template: projectToEdit.template || {
              link: '',
              description: '',
            },
            journeyType: projectToEdit.journeyType || [],
            tasks: projectToEdit.tasks || [],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            tasks: Yup.array().of(
              Yup.object().shape({
                learningOutcomes: Yup.string().required(
                  'Learning Outcomes is required'
                ),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
                day: Yup.number().required(),
                week: Yup.number().required(),
                duration: Yup.number().required(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            values.checked = true;
            const tempAllProject = [...allProject];
            const tempCurrentProject = { ...projectToEdit, ...values };
            tempAllProject[index] = tempCurrentProject;
            setAllProject(tempAllProject);
            handleClose();
          }}>
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <CenturyLabForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                projectTaskToEdit={projectToEdit}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                  disabled={isSubmitting}>
                  Update Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}></Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditCenturyTask;

import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';
import { Button, Grid } from '@mui/material';
import ShowWeeklyChart from './layouts/ShowWeeklyChart';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const WeeklyChart = () => {
  const [chartData, setChartData] = useState({});
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    (async () => {
      const result = await axios(
        API_URL + '/dashboard/graph-data/weekly-users',
        {
          params: {
            windowEndDate: endDate,
          },
        }
      );
      setChartData(result.data.data);
    })();
  }, [endDate]);

  return (
    <div>
      {chartData.weeklyChartData && (
        <Grid container spacing={3} mb={3}>
          <Grid item xs={1} mt={18}>
            <Button
              onClick={() => {
                setEndDate(chartData.prev);
              }}
            >
              <ArrowBackIosNewIcon />
            </Button>
          </Grid>
          <Grid item xs={10}>
            <ShowWeeklyChart data={chartData.weeklyChartData} />
          </Grid>
          <Grid item xs={1} mt={18}>
            {chartData.next && (
              <Button
                onClick={() => {
                  setEndDate(chartData.next);
                }}
              >
                <ArrowForwardIosIcon />
              </Button>
            )}
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default WeeklyChart;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { API_URL_V1 } from '../../../../links/API_LINKS';

import ApplicantProfile from './ApplicantProfile';
import ApplicantHeader from './ApplicantHeader';

const Index = ({ customData }) => {
  const [showProfile, setShowProfile] = useState(null);

  const fetchApplications = async () => {
    const res = await axios.get(API_URL_V1 + '/application');
    return res;
  };

  const { status, data } = useQuery('fetchApplications', fetchApplications);

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          {showProfile ? (
            <ApplicantProfile
              user={showProfile}
              setShowProfile={setShowProfile}
            />
          ) : (
            <ApplicantHeader
              data={customData || data.data}
              setShowProfile={setShowProfile}
            />
          )}
        </Box>
      )}
    </>
  );
};

export default Index;

import React, { useState } from 'react';
import { TableRow, TableCell, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from 'components/misc/Snackbar';
import EditAssignment from './EditAssignment';
import { format } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AssignmentBody = ({ row, setReFetch }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {row.title}
        </TableCell>
        <TableCell align='left'>
          {row.deadline &&
            format(new Date(row.deadline), 'do LLLL yyyy h:mm a iiii')}
        </TableCell>
        <TableCell align='left'> {row.task_no} </TableCell>
        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={handleOpen}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'></TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditAssignment
            editData={row}
            handleClose={handleClose}
            setReFetch={setReFetch}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default AssignmentBody;

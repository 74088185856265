import React from 'react';
import { Button, Box } from '@mui/material';
import MakeReport from './MakeReport';
import { API_URL_V1 } from '../../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

const Index = ({ masterclass, setShowReport }) => {
  const fetchleaderBoard = async () => {
    const res = await axios.get(
      API_URL_V1 + '/assessment/assessment-leaderboard',
      {
        params: {
          partnerId: masterclass.partnerId._id,
          assessmentId: masterclass._id,
        },
      }
    );
    return res;
  };
  const { status, data } = useQuery(
    `fetchleaderBoard${masterclass._id}`,
    fetchleaderBoard
  );
  return (
    <div>
      <Button onClick={() => setShowReport(null)}>Back</Button>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <MakeReport
          questions={masterclass.questions}
          masterclass={data.data[0]}
          logoUrl={masterclass.partnerId.logoUrl}
        />
      )}
    </div>
  );
};

export default Index;

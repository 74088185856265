import React from 'react';
import { Container } from '@mui/material';
import Page from '../../../Page';

import Applications from './Applications';

const Index = () => {
  return (
    <Page title='Admin'>
      <Container maxWidth='xl'>
        <Applications />
      </Container>
    </Page>
  );
};

export default Index;

import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  MenuItem,
  Typography,
  Stack,
  TextField,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Radio,
  FormLabel,
  Button,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import { API_URL_V1 } from '../../../../links/API_LINKS';

const MasterClassForm = ({ action, data, setMasterClassToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const queryClient = useQueryClient();
  const actionText = action === 'add' ? 'Add' : 'Edit';

  const fetchPartners = async (keys) => {
    const res = await axios.get(API_URL_V1 + '/partner', {});
    return res;
  };

  const allPartners = useQuery('fetchPartners', fetchPartners);

  return (
    <div>
      <Box my={2} sx={{ display: action === 'add' && 'none' }}>
        <Button
          onClick={() => {
            setMasterClassToEdit(null);
          }}
        >
          <ArrowBackIcon></ArrowBackIcon> Back
        </Button>
      </Box>

      <Box sx={{}} className='bg-gray-100 rounded-2xl p-4 inline-block'>
        <Typography variant='h6'>{actionText} masterclass</Typography>
        <br />
      </Box>
      <div>
        <Formik
          enableReinitialize={true}
          initialValues={{
            assessmentId: data?._id || '',
            title: data?.title || '',
            partnerId: data?.partnerId._id || '610a7ca8d3c51a1458d005ce',
            startDate: data?.startDate || new Date(),
            endDate: data?.endDate || new Date(),
            status: data?.status || 'inactive',
            assessmentType: data?.assessmentType || 'masterclass',
            showFinalScreen: data?.showFinalScreen || true,
            offer: {
              offerId: data?.offerId || '',
              validTill: data?.validTill || '',
            },
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .min(2, 'Must be 3 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required('Please enter a title'),
            startDate: Yup.string().required('Please enter Start Time'),
            endDate: Yup.string().required('Please enter End Time'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');

            axios({
              method: action === 'add' ? 'POST' : 'PUT',
              url: API_URL_V1 + '/assessment',
              data: values,
            })
              .then(function (response) {
                setServerResponse(response.data.message);
                setSubmitting(false);
                response.status === 200 &&
                  queryClient.invalidateQueries('fetchAllAssessment') &&
                  resetForm({
                    values: {
                      title: '',
                      partnerId: '610a7ca8d3c51a1458d005ce',
                      startDate: new Date(),
                      endDate: new Date(),
                      status: 'inactive',
                      assessmentType: 'masterclass',
                      showFinalScreen: true,
                      offer: {
                        offerId: '',
                        validTill: '',
                      },
                    },
                  });
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                mb={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInput
                    size='small'
                    fullWidth
                    name='partnerId'
                    label='Partner'
                  >
                    {allPartners.status === 'success' &&
                      allPartners.data.data.map((partner) => {
                        return (
                          <MenuItem key={partner._id} value={partner._id}>
                            {partner.name}
                          </MenuItem>
                        );
                      })}
                  </SelectInput>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Start Date'
                      value={values.startDate}
                      onChange={(newValue) => {
                        setFieldValue('startDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size='small' fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='End Date'
                      value={values.endDate}
                      onChange={(newValue) => {
                        setFieldValue('endDate', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size='small' fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInput
                    size='small'
                    fullWidth
                    name='status'
                    label='Status'
                  >
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='inactive'>Inactive</MenuItem>
                    <MenuItem value='completed'>Completed</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl>
                    <FormLabel id='demo-radio-buttons-group-label'>
                      Show Final Screen
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby='demo-radio-buttons-group-label'
                      defaultValue={true}
                      name='radio-buttons-group'
                      onChange={(e) => {
                        setFieldValue(
                          'showFinalScreen',
                          e.target.value === 'true'
                        );
                      }}
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label='Yes'
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label='No'
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='offer.offerId'
                    type='text'
                    label='Offer Code'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Offer Validity'
                      value={values.offer.validTill}
                      onChange={(newValue) => {
                        setFieldValue('offer.validTill', newValue);
                      }}
                      renderInput={(params) => (
                        <TextField size='small' fullWidth {...params} />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  {actionText} Masterclass
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MasterClassForm;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';

// import useLinkCopy from '../../../../utils/hooks';

function MasterclassBody({ row, setShowReport, setMasterClassToEdit }) {
  // const tableCellColor =

  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.title}
        </TableCell>
        <TableCell align='left' component='th' scope='row'>
          {row.startDate && format(new Date(row.startDate), 'do LLLL yyyy')}{' '}
        </TableCell>
        <TableCell align='left'>
          {row.endDate && format(new Date(row.endDate), 'do LLLL yyyy')}
        </TableCell>

        <TableCell align='left'>{row.partner}</TableCell>
        <TableCell align='left'>
          {'https://masterclass.belong.education/' + row.url}
        </TableCell>

        <TableCell align='left'>{row.status}</TableCell>
        <TableCell
          sx={{
            display: 'flex',
          }}
        >
          <Button
            onClick={() => {
              setShowReport(row.masterclass);
            }}
          >
            <PreviewIcon />
          </Button>
          <Button
            onClick={() => {
              setMasterClassToEdit(row.masterclass);
            }}
          >
            <EditIcon />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default MasterclassBody;

import { useQuery } from 'react-query';
import axios from 'axios';
import { Typography, Stack, Button, Box } from '@mui/material';
import React, { useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import * as XLSX from 'xlsx';
import { API_URL } from 'links/API_LINKS';
const AddQuestionFile = () => {
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [items, setItems] = useState([]);
  //   const queryClient = useQueryClient();
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      const finalData = data.map((question) => {
        const correctOption = question.rightOption;
        let options = [
          { option: question.option1, value: 0 },
          { option: question.option2, value: 0 },
          { option: question.option3, value: 0 },
          { option: question.option4, value: 0 },
        ];
        options[correctOption - 1]['value'] = 1;
        const questionObject = {
          question: question.question,
          domain: question.domain,
          skill: question.skill,
          options: options,
          maxSelect: 1,
          type: 'B2C DOMAIN',
          image: [],
        };
        if (question.image && question.image.length) {
          questionObject.image = question.image.split(',');
        }
        return questionObject;
      });
      setItems(finalData);
      setSubmitting(true);
      axios({
        method: 'POST',
        url: API_URL + '/question/multiple',
        data: { questions: finalData },
      })
        .then(function (response) {
          setServerResponse('Questions uploaded successfully');
          setSubmitting(false);
        })
        .catch(function (response) {
          setServerResponse('Error! Could not process your request.');
          setSubmitting(false);
        });
    });
  };

  const fetchAreas = async (keys) => {
    const res = await axios.get(API_URL + '/area', { params: { limit: 0 } });
    return res;
  };
  const fetchSkills = async (keys) => {
    const res = await axios.get(API_URL + '/skills', { params: { limit: 0 } });
    return res;
  };

  const areas = useQuery('fetchAreas', fetchAreas);
  const skills = useQuery('fetchSkills', fetchSkills);

  let mydata = [];
  if (areas.status === 'success' && skills.status === 'success') {
    let datalength = mydata.length;
    for (var i = 0; i < areas.data.data.data.length; i++) {
      if (i < datalength) {
        mydata[i]['area'] = areas.data.data.data[i].title;
        mydata[i]['area_id'] = areas.data.data.data[i]._id;
      } else {
        mydata.push({
          area: areas.data.data.data[i].title,
          area_id: areas.data.data.data[i]._id,
        });
      }
    }
    datalength = mydata.length;
    for (i = 0; i < skills.data.data.data.length; i++) {
      if (i < datalength) {
        mydata[i]['skill'] = skills.data.data.data[i].title;
        mydata[i]['skill_id'] = skills.data.data.data[i]._id;
      } else {
        mydata.push({
          skill: skills.data.data.data[i].title,
          skill_id: skills.data.data.data[i]._id,
        });
      }
    }
  }
  return (
    <div>
      <Box sx={{ border: 1 }} p={1}>
        <Typography mb={2} variant='subtitle2' align='center'>
          Add Question
        </Typography>
        <Stack
          direction='column'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <div>
            <CsvDownload data={mydata}>
              <Button variant='outlined'>Skill/Area data</Button>
            </CsvDownload>{' '}
            <Button variant='outlined'>
              <a
                href='https://res.cloudinary.com/belong/raw/upload/v1658750051/uploaded_resources/Sample-Add-Question_o1ugns.xlsx'
                download
              >
                Sample File for add question
              </a>
            </Button>
          </div>

          <div>
            <Typography p={1}>Please upload XSLX file</Typography>
            <input
              type='file'
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }}
            />
            {items.length > 0 && (
              <Box pt={1}>
                <b>{items.length}</b> Question found
                <Button variant='outlined' disabled={isSubmitting}>
                  {isSubmitting ? 'Processing...' : 'Submit'}
                </Button>
              </Box>
            )}
            <Typography>{serverResponse}</Typography>
          </div>
        </Stack>
      </Box>
    </div>
  );
};

export default AddQuestionFile;

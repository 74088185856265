import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Table,
  TableBody,
  Grid,
  Chip,
} from '@mui/material';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import ViewEvent from './ViewEvent';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import SubEventBody from './SubEventBody';
import Snackbar from '../../../../../../misc/Snackbar';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EventBody = ({ event, index, allEvent, setAllEvent }) => {
  const [open, setOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function deleteEvent() {
    if (
      window.confirm(
        'Are you sure you want to permanently delete ' + event.title + 'Event?'
      )
    ) {
      const tempAllEvent = [...allEvent];
      tempAllEvent.splice(index, 1);
      setAllEvent(tempAllEvent);
    }
  }

  return (
    <>
      <TableRow>
        <TableCell
          //sx={{ backgroundColor: bgColor }}
          component='th'
          scope='row'
        >
          {event.subEvents && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              {collapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          {event.title}
        </TableCell>
        <TableCell align='left'>
          {event.type === 'Masterclass' ? (
            <Chip color='primary' size='small' label={event.type} />
          ) : event.type === 'Mentor Connect' ? (
            <Chip color='secondary' size='small' label={event.type} />
          ) : (
            <Chip color='warning' size='small' label={event.type} />
          )}
        </TableCell>
        <TableCell align='left'>
          {event.journies.week} / {event.journies.day}{' '}
        </TableCell>
        <TableCell align='left'>
          {format(new Date(event.eventDate), 'do LLLL yyyy h:mm a iiii')}
        </TableCell>
        <TableCell align='left'>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <IconButton
                sx={{ cursor: 'pointer' }}
                size='small'
                onClick={handleOpen}
              >
                <EditIcon color='primary' />
              </IconButton>
            </Grid>
            <Grid item xs={4}>
              {event.checked && <CheckCircleIcon color='success' />}
            </Grid>
            <Grid item xs={4}>
              <IconButton
                sx={{ cursor: 'pointer' }}
                size='small'
                color='error'
                onClick={() => deleteEvent()}
              >
                <DeleteIcon />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label=''>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {event.subEvents &&
                    event.subEvents.map((subEvent, subIndex) => {
                      return (
                        <SubEventBody
                          key={subIndex}
                          event={event}
                          index={index}
                          allEvent={allEvent}
                          setAllEvent={setAllEvent}
                          subEvent={subEvent}
                          subIndex={subIndex}
                          snackbarActions={{
                            setSnackbarText,
                            setSnackbarSeverity,
                            setSnackbarOpen,
                          }}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ViewEvent
            event={event}
            index={index}
            allEvent={allEvent}
            setAllEvent={setAllEvent}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default EventBody;

import React from 'react';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { Grid, MenuItem, Button, Box } from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';

const QuestionField = ({
  values,
  setOpen,
  setSelectedField,
  setRefresh,
  refresh,
  skillsWithQuestions,
}) => {
  const allSkillArrays = values.tasks.map((task) => task.skillId);
  const allSkills = allSkillArrays.flat(1);

  return (
    <div>
      <Grid item xs={12} sm={6} md={4}>
        <SelectInput
          size='small'
          name='skillId'
          label='Select Primary Skills*'
          multiple
          fullWidth>
          {skillsWithQuestions.status === 'success' &&
            skillsWithQuestions.data.data.data
              .filter((checkSkill) => allSkills.includes(checkSkill._id))
              .map((skill) => {
                return (
                  <MenuItem key={skill._id} value={skill._id}>
                    {skill.title}
                  </MenuItem>
                );
              })}
        </SelectInput>
      </Grid>
      <Grid mt={6} item>
        <FieldArray
          name='questions'
          render={(arrayHelpers) => (
            <>
              {values.questions.map((_, index) => {
                return (
                  <Grid
                    sx={{ border: 1 }}
                    key={index}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 1, md: 3 }}
                    mb={3}
                    pb={5}>
                    <Grid item xs={6}>
                      Question - {index + 1}
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      direction='row'
                      justifyContent='flex-end'>
                      {!_._id && (
                        <Button
                          type='button'
                          color='error'
                          onClick={() => arrayHelpers.remove(index)}>
                          Delete Question - {index + 1} <FaTrashAlt />
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={11}>
                      <TextInput
                        size='small'
                        type='text'
                        name={`questions[${index}].question`}
                        label='question'
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid item>
                        <FieldArray
                          name={`questions[${index}].image`}
                          render={(arrayHelpers) => (
                            <>
                              <Grid
                                item
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '5px',
                                }}>
                                Add question image
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.push()}>
                                  <FaPlusCircle />
                                </Button>
                                {values.questions[index].image.map(
                                  (content, imgIndex) => {
                                    return (
                                      <Grid item key={imgIndex}>
                                        <Grid
                                          item
                                          sm={12}
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}>
                                          <TextInput
                                            name={`questions[${index}].image[${imgIndex}]`}
                                            type='text'
                                            placeholder='Image URL'
                                          />
                                          <Grid item>
                                            <Button
                                              type='button'
                                              onClick={() => {
                                                setOpen(true);
                                                setSelectedField(
                                                  `questions[${index}].image[${imgIndex}]`
                                                );
                                                setRefresh(!refresh);
                                              }}>
                                              Select File
                                            </Button>
                                          </Grid>
                                          <Grid item>
                                            <img
                                              src={
                                                values.questions[index].image[
                                                  imgIndex
                                                ]
                                              }
                                              alt=''
                                              width='150'
                                            />
                                            <Grid item>
                                              <Button
                                                type='button'
                                                onClick={() =>
                                                  arrayHelpers.remove(imgIndex)
                                                }>
                                                <FaTrashAlt />
                                              </Button>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </>
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={7}>
                      <SelectInput
                        name={`questions[${index}].maxSelect`}
                        label='Maximum options a user can select'
                        sx={{ width: '250px' }}>
                        <MenuItem value={1}>1</MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                        <MenuItem value={4}>4</MenuItem>
                      </SelectInput>
                    </Grid>

                    <Grid item xs={12}>
                      {[...Array(1)].map((e, i) => (
                        <Grid item key={i}>
                          <FieldArray
                            name={`questions[${index}].options`}
                            render={(arrayHelpers) => (
                              <>
                                <Grid
                                  item
                                  style={{
                                    display: 'flex-wrap',
                                    alignItems: 'center',
                                  }}>
                                  Add
                                  <Button
                                    type='button'
                                    onClick={() =>
                                      arrayHelpers.push({
                                        option: '',
                                        value: 0,
                                      })
                                    }>
                                    <FaPlusCircle />
                                  </Button>
                                  {values.questions[index].options.map(
                                    (content, OptionIndex) => {
                                      return (
                                        <Grid
                                          item
                                          key={OptionIndex}
                                          style={{
                                            display: 'flex',
                                            marginTop: '12px',
                                            gap: '10px',
                                          }}
                                          sm={10}>
                                          <TextInput
                                            size='small'
                                            rows={1}
                                            name={`questions[${index}].options[${OptionIndex}].option`}
                                            placeholder={`option ${
                                              OptionIndex + 1
                                            }`}
                                            multiline
                                            fullWidth
                                          />
                                          <SelectInput
                                            size='small'
                                            name={`questions[${index}].options[${OptionIndex}].value`}
                                            label='Value'>
                                            <MenuItem value={0}>0</MenuItem>
                                            <MenuItem value={1}>1</MenuItem>
                                            <MenuItem value={0.5}>0.5</MenuItem>
                                            <MenuItem value={-0.5}>
                                              -0.5
                                            </MenuItem>
                                            <MenuItem value={-1}>-1</MenuItem>
                                          </SelectInput>
                                          <Button
                                            type='button'
                                            onClick={() =>
                                              arrayHelpers.remove(OptionIndex)
                                            }>
                                            <FaTrashAlt />
                                          </Button>
                                        </Grid>
                                      );
                                    }
                                  )}
                                </Grid>
                              </>
                            )}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                );
              })}
              <Box mb={1}>
                Add more questions
                <Button
                  type='button'
                  onClick={() =>
                    arrayHelpers.push({
                      question: '',
                      type: 'CAPSTONE',
                      options: [
                        {
                          option: '',
                          value: 0,
                        },
                      ],
                      maxSelect: 1,
                      image: [],
                    })
                  }>
                  <FaPlusCircle />
                </Button>
              </Box>
            </>
          )}
        />
      </Grid>
    </div>
  );
};

export default QuestionField;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  Stack,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

import TableListHead from '../../layouts/TableListHead';
import CapstoneBody from './CapstoneBody';
import EditCapstone from './EditCapstone';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchCapstone = async (params) => {
  const { limit, page, sort, order, domainId } = params;
  const matchObj = {
    limit,
    page,
    sort,
    order,
  };
  if (domainId) matchObj.domainId = domainId;
  const res = await axios.get(API_URL + '/capstone', {
    params: matchObj,
  });

  return res;
};

const fetchAreas = async () => {
  const res = await axios.get(API_URL + '/area', {
    params: { limit: 0 },
  });
  return res;
};

function CapstoneHeader() {
  const [capstoneToEdit, setCapstoneToEdit] = useState(null);
  const allAreas = useQuery('fetchAreas', fetchAreas);
  const [domainId, setDominId] = useState(null);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'title',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchCapstone`, paginationData, domainId],
    () => fetchCapstone({ ...paginationData, domainId }),
    { keepPreviousData: true }
  );

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'domainId', label: 'Domain', align: 'left' },
    { id: 'companyId', label: 'Company', align: 'left' },
    { id: 'country', label: 'Country', align: 'left' },
    { id: 'skillId', label: 'Primary Skills', align: 'left' },
    { id: 'level', label: 'Level', align: 'left' },
    { id: 'question', label: 'Question', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {capstoneToEdit ? (
            <EditCapstone
              capstoneToEdit={capstoneToEdit}
              setCapstoneToEdit={setCapstoneToEdit}
            />
          ) : (
            <>
              <Paper elevation={3} sx={{ margin: 1, padding: 2 }}>
                <Stack
                  direction='row'
                  justifyContent='space-between'
                  alignItems='center'
                  spacing={4}
                >
                  <FormControl>
                    <InputLabel id='demo-simple-select-label'>
                      Select Domain
                    </InputLabel>
                    <Select
                      sx={{ maxWidth: 350, minWidth: 300 }}
                      labelId='demo-simple-select-label'
                      id='demo-simple-select'
                      value={domainId}
                      label='Select Domain'
                      onChange={(e) => setDominId(e.target.value)}
                    >
                      <MenuItem value={null}>All Domain</MenuItem>
                      {allAreas.status === 'success' &&
                        allAreas?.data?.data?.data?.map((domain) => {
                          return (
                            <MenuItem key={domain._id} value={domain._id}>
                              {domain.title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Stack>
              </Paper>
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                  aria-label='table'
                  size='small'
                >
                  <TableListHead
                    order={paginationData.order}
                    orderBy={paginationData.sort}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {data.data.data.map((row) => (
                      <CapstoneBody
                        key={row._id}
                        row={row}
                        setCapstoneToEdit={setCapstoneToEdit}
                      />
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[
                    ...new Set([paginationData.limit, 5, 10, 50]),
                  ]}
                  component='div'
                  count={data.data.totalCount}
                  rowsPerPage={paginationData.limit}
                  page={paginationData.page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{
                    disabled:
                      isFetching || data.data.page === data.data.totalPages - 1,
                  }}
                />
              </TableContainer>
            </>
          )}
        </>
      )}
    </>
  );
}

export default CapstoneHeader;

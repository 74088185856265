import React from 'react';
import { Table, Box } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { API_URL } from '../../../../../links/API_LINKS';
import { useQuery } from 'react-query';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import CompanyBody from './CompanyBody';

const Index = () => {
  const fetchUnderCompanies = async () => {
    const res = await axios.get(API_URL + '/company', {
      params: { limit: 0, companyStage: 1 },
    });
    return res;
  };
  const { status, data } = useQuery(`fetchUnderCompanies`, fetchUnderCompanies);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <TableContainer component={Card}>
          <Table
            size='small'
            sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
            aria-label='table'
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'>Name</TableCell>
                <TableCell align='left'> Country</TableCell>
                <TableCell align='left'> Added on </TableCell>
                <TableCell align='left'>Company Email </TableCell>
                <TableCell align='left'>Employee Name </TableCell>
                <TableCell align='left'></TableCell>
                <TableCell align='left'></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.data.map((company) => {
                return <CompanyBody key={company._id} company={company} />;
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Index;

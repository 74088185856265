import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button, Modal, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import AddCompanyAdmin from './AddCompanyAdmin';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';
import { useQuery } from 'react-query';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function CompanyBody({ row, setCompanyToEdit }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const fetchCompanyAdmin = async () => {
    const res = await axios.get(API_URL + '/company/get-admin', {
      params: { companyId: row._id },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchCompanyAdmin${row._id}`,
    fetchCompanyAdmin
  );
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.name}
        </TableCell>
        <TableCell align='left'>{row.country} </TableCell>
        <TableCell align='center'>
          <a target='_blank' rel='noreferrer' href={row.website}>
            <LinkIcon />
          </a>
        </TableCell>

        <TableCell align='left'>
          {row.companyStage === 1
            ? 'Under Review'
            : row.companyStage === 2
            ? 'Approved'
            : 'Rejected'}
          {/* companyStage 1 => 'Under Review'
           companyStage 2 => 'Approved'
          companyStage 3 => 'Rejected' */}
          <Button
            onClick={() => {
              setCompanyToEdit(row);
            }}
          >
            <EditIcon />
          </Button>
        </TableCell>
        <TableCell>
          {status === 'success' && data.data.data.length ? (
            data.data.data[0].firstName || data.data.data[0].name
          ) : (
            <Button
              onClick={() => {
                setOpen(true);
              }}
            >
              Add Admin
            </Button>
          )}
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddCompanyAdmin handleClose={handleClose} company={row} />
        </Box>
      </Modal>
    </>
  );
}

export default CompanyBody;

import React, { useState } from 'react';
import { Typography, Stack, Button, Box } from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { API_URL } from 'links/API_LINKS';

const AddCertificateScore = ({ batchId }) => {
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [items, setItems] = useState([]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promise.then((data) => {
      setItems(data);
    });
  };

  function datasubmit(arrayData) {
    const values = {
      batchId: batchId,
      report: arrayData,
    };
    setSubmitting(true);
    axios({
      method: 'PUT',
      url: API_URL + '/batch/user-report',
      data: values,
    })
      .then(function (response) {
        setServerResponse('Score Added');
        setSubmitting(false);
      })
      .catch(function (response) {
        setServerResponse('Error! Could not process your request.');
        setSubmitting(false);
      });
  }

  return (
    <Box sx={{ border: 1 }} p={1}>
      <Typography mb={2} variant='subtitle2' textAlign='center'>
        Add final Score
      </Typography>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <Button variant='outlined'>
          <a
            href='https://res.cloudinary.com/belong/raw/upload/v1656066271/uploaded_resources/final_score_weaebh.xlsx'
            download
          >
            Sample File for score
          </a>
        </Button>
      </Stack>
      <Typography p={1}>Please upload XSLX file</Typography>
      <input
        type='file'
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      {items.length > 0 && (
        <Box pt={1}>
          <b>{items.length}</b> Users data found
          <Button
            variant='outlined'
            disabled={isSubmitting}
            onClick={() => datasubmit(items)}
          >
            {isSubmitting ? 'Processing...' : 'Submit'}
          </Button>
        </Box>
      )}
      <Typography mt={1} textAlign='center'>
        {serverResponse}
      </Typography>
    </Box>
  );
};

export default AddCertificateScore;

import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import LinkIcon from '@mui/icons-material/Link';
import { TableRow, Button, Paper, Modal } from '@mui/material';
import { Box, Link } from '@mui/material';
import { format } from 'date-fns';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '1px solid primary.main',
  boxShadow: 24,
  p: 4,
};

const PartnerBody = ({ row }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const filteredData = row.assessment.filter((ass) => {
    return ass.averagePercentage;
  });
  const avg =
    filteredData.reduce((count, ass) => count + ass.averagePercentage, 0) /
    filteredData.length;

  return (
    <TableRow hover key={row._id} sx={{ cursor: 'pointer' }}>
      <TableCell align='left'>
        <img alt={row.name} src={row.logoUrl} component='img' height='30' />
      </TableCell>
      <TableCell align='left'>{row.name}</TableCell>
      <TableCell align='center'>{row.country}</TableCell>
      <TableCell align='center'>{row.userCount}</TableCell>
      <TableCell align='center'>
        <Button onClick={handleOpen}>{row.assessment.length}</Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow key={row._id}>
                    <TableCell>Assessment Name</TableCell>
                    <TableCell align='left'>Assessment Date</TableCell>
                    <TableCell align='left'>Assessment Average Score</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.assessment.map((ass) => {
                    return (
                      <TableRow key={ass._id}>
                        <TableCell component='th' scope='row'>
                          {ass.title}
                        </TableCell>
                        <TableCell align='left'>
                          {' '}
                          {ass.startDate &&
                            format(new Date(ass.startDate), 'do LLLL yyyy')}
                        </TableCell>
                        <TableCell align='left'>
                          {ass.averagePercentage}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Modal>
      </TableCell>
      <TableCell align='center'>
        {filteredData.length ? `${avg} %` : '-'}
      </TableCell>
      <TableCell align='center'>
        <Link
          href={row.website}
          target='_blank'
          rel='noreferrer'
          underline='hover'
        >
          <LinkIcon />
        </Link>
      </TableCell>
    </TableRow>
  );
};

export default PartnerBody;

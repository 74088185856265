import React from 'react';
import { Box } from '@mui/material';
import AllBatch from './AllBatch';

const Index = ({ setCurrentBatch }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <AllBatch setCurrentBatch={setCurrentBatch} />
    </Box>
  );
};

export default Index;

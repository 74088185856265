import React, { useRef } from 'react';
import {
  TextInput,
  DateTimePickerField,
} from '../../../../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const MasterEdit = ({ event, snackbarActions, handleClose, currentBatch }) => {
  const queryClient = useQueryClient();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          masterEventId: event._id,
          title: event.title,
          description: event.description,
          imageUrl: event.imageUrl,
          dateTime: event.dateTime,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          imageUrl: Yup.string().url().required('image is required'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios({
            method: 'PUT',
            url: API_URL + '/masterevent',
            data: values,
          })
            .then(function (response) {
              setSnackbarText('Event edited successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${currentBatch._id}`
              );
              handleClose();
            })
            .catch(function (response) {
              setSnackbarText('Failed to Edit Events');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='title'
                  type='text'
                  label='Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  multiline
                  rows={3}
                  name='description'
                  type='text'
                  label='Description'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='imageUrl'
                  type='text'
                  label='Image'
                />
                <div>
                  <img src={values.imageUrl} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePickerField
                  name='dateTime'
                  value={values.dateTime}
                  label='Event Date'
                  onChange={setFieldValue}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update Event
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MasterEdit;

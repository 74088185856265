import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { supabase } from 'links/supabaseConfig';
import AllUserHeader from './AllUserHeader';
import AllTeams from './AllTeams';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchAllUser = async () => {
    const res = await axios.get(API_URL + '/user', {
      params: { partnerId: '61b0488c86f85a00239a28f3' },
    });
    return res;
  };
  const { status, data } = useQuery(`fetchAllUser`, fetchAllUser);

  const [quizData, setQuizData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teamData, setteamData] = useState([]);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase
        .from('quiz')
        .select('user_id , percentage')
        .match({ type: 'PRETEST' });

      setQuizData(fetchedData || []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select()
        .match({ bootcamp_id: 1 });
      setteamData(fetchedData || []);
      // setIsLoading(false);
    };

    fetchData();
  }, [refetch]);

  return (
    <>
      {(status === 'loading' || isLoading) && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && quizData.length > 0 && (
        <Box sx={{ width: '100%' }}>
          <Box display='flex' mb={4}>
            Offline Bootcamp
          </Box>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='opportunity'
                variant='scrollable'
                scrollButtons='auto'
              >
                <Tab label='All Users' {...a11yProps(0)} />
                <Tab label='Teams' {...a11yProps(1)} />
                <Tab label='Leaderboard' {...a11yProps(2)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <AllUserHeader
                quizData={quizData}
                data={data?.data?.data || []}
                teamData={teamData}
                setRefetch={setRefetch}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <AllTeams
                data={data?.data?.data || []}
                teamData={teamData}
                setRefetch={setRefetch}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              Leaderboard
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Index;

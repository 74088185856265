import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
// import CapstoneDetails from '../capstoneDetails';

function CapstoneTableBody({ row, setCurrentCapstone }) {
  const myAllCapstones = row.capstones.filter((cap) => {
    return cap.capstone;
  });
  let enrolled = myAllCapstones.length;
  let completed = 0;
  myAllCapstones.forEach((capstone) => {
    if (
      capstone.capstone?.tasks.length === capstone?.capstoneSubmissions?.length
    ) {
      completed = completed + 1;
    }
  });
  let remaining = enrolled - completed;
  return (
    <>
      <TableRow
        key={row._id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          cursor: 'pointer',
        }}
        onClick={() => {
          setCurrentCapstone(row);
        }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell align='left'>{row.email} </TableCell>
        <TableCell align='center'>{enrolled}</TableCell>
        <TableCell align='center'>{completed}</TableCell>
        <TableCell align='center'>{remaining}</TableCell>
      </TableRow>
    </>
  );
}

export default CapstoneTableBody;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';

import AssignmentForm from './AssignmentForm';
import { supabase } from 'links/supabaseConfig';

const EditAssignment = ({ setReFetch, handleClose, editData }) => {
  return (
    <Box>
      <Typography variant='h6'>Edit Assignment</Typography>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: editData?.title || '',
            deadline: editData?.deadline || new Date(),
            skills: editData?.skills || '',
            task_no: editData?.task_no || '',
            guideline: editData?.guideline || '',
            resources: editData?.resources || '',
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            deadline: Yup.string().required('deadline is required'),
            task_no: Yup.string().required('task_no is required'),
            skills: Yup.string().required('skills is required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            await supabase
              .from('assignments')
              .update(values)
              .match({ id: editData.id })
              .then(() => {
                setSubmitting(false);
                setReFetch((pre) => !pre);
                handleClose();
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <AssignmentForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                assignmentToEdit={editData}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Update Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              ></Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditAssignment;

import React from 'react';
import { Card } from 'react-bootstrap';

const ImageCard = (props) => {
  async function copyToClipboard(text) {
    await navigator.clipboard.writeText(text);
    alert('Link Copied');
  }

  const { file, trueSize, width } = props;

  return (
    file && (
      <Card title={file.url.split('/').slice(-1)[0]}>
        <Card.Img
          variant='top'
          src={file.url}
          onClick={() => {
            copyToClipboard(file.url);
            props.setImage(file.url);
          }}
          style={trueSize ? { width } : { width, height: width }}
        />
      </Card>
    )
  );
};

export default ImageCard;

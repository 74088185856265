import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button, Avatar } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function MentorBody({ row, setMentorToEdit }) {
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        {' '}
        <TableCell align='left'>
          <Avatar
            alt='Mentor Image'
            src={row.imageUrl}
            sx={{ width: 24, height: 24 }}
          />{' '}
        </TableCell>
        <TableCell align='left'>{row.name} </TableCell>
        <TableCell align='left'> {row.designation} </TableCell>
        <TableCell align='left'> {row.country} </TableCell>
        <TableCell align='center'>
          <Button
            onClick={() => {
              setMentorToEdit(row);
            }}
          >
            {' '}
            <EditIcon sx={{ color: 'primary.main' }} />{' '}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default MentorBody;

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';

import AssignmentForm from './AssignmentForm';
import { supabase } from 'links/supabaseConfig';

const AddAssignment = ({ setReFetch, handleClose }) => {
  const [serverResponse, setServerResponse] = useState();
  // const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
  //   snackbarActions;
  return (
    <Box>
      <Typography variant='h6'>Add a new Assignment</Typography>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: '',
            deadline: new Date(),
            skills: '',
            task_no: '',
            guideline: '',
            resources: '',
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            deadline: Yup.string().required('deadline is required'),
            task_no: Yup.string().required('task_no is required'),
            skills: Yup.string().required('skills is required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            console.log(values);
            await supabase
              .from('assignments')
              .insert(values)
              .then(() => {
                // setSnackbarText('Assignment added successfully');
                // setSnackbarSeverity('success');
                // setSnackbarOpen(true);
                setSubmitting(false);
                setReFetch((pre) => !pre);
                handleClose();
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <AssignmentForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Add Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddAssignment;

import React from 'react';
import { Button, Box, Typography } from '@mui/material';
import Events from './allEvents';
// import Assignment from './allAssignments';
import Automation from './automation';
import Tickets from './allTickets';
import CoachFeedback from './allCoachFeedback';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import Overview from './overview';
import Projects from './allProjects';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Index = ({ currentBatch, setCurrentBatch }) => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchCurrentBatch = async () => {
    const res = await axios.get(API_URL + '/batch', {
      params: { batchId: currentBatch._id },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchCurrentBatch${currentBatch._id}`,
    fetchCurrentBatch
  );
  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box sx={{ width: '100%' }}>
          <Box display='flex' mb={4}>
            <Button variant='outlined' onClick={() => setCurrentBatch(null)}>
              Back
            </Button>
            <Box sx={{ width: '100%' }}>
              <Typography align='center' variant='h3'>
                {currentBatch.name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label='opportunity'
                variant='scrollable'
                scrollButtons='auto'>
                <Tab label='Overview' {...a11yProps(0)} />
                <Tab label='Events' {...a11yProps(1)} />
                {/* <Tab label='Asignment' {...a11yProps(2)} /> */}
                <Tab label='Project' {...a11yProps(2)} />
                <Tab label='Create Journey' {...a11yProps(3)} />
                <Tab label='Tickets' {...a11yProps(4)} />
                <Tab label='Coach Feedback' {...a11yProps(5)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Overview currentBatch={data.data.data[0]} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Events currentBatch={data.data.data[0]} />
            </TabPanel>
            {/* <TabPanel value={value} index={2}>
              <Assignment currentBatch={data.data.data[0]} />
            </TabPanel> */}
            <TabPanel value={value} index={2}>
              <Projects currentBatch={data.data.data[0]} />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <Automation currentBatch={data.data.data[0]} />
            </TabPanel>
            <TabPanel value={value} index={4}>
              <Tickets currentBatch={data.data.data[0]} />
            </TabPanel>
            <TabPanel value={value} index={5}>
              <CoachFeedback currentBatch={data.data.data[0]} />
            </TabPanel>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Index;

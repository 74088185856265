import { React } from 'react';
import { Typography, Grid, Box, Stack } from '@mui/material';
import Chart from 'react-apexcharts';
import _ from 'lodash';

const TopFivePerformers = ({ users }) => {
  const assessmentGiven = users.filter((user) => {
    return (
      user.masterclassAssessment && user.masterclassAssessment.percentageScore
    );
  });
  const myUserData = assessmentGiven.map((user) => {
    return {
      name: user.name,
      CREATIVITY: user.masterclassAssessment.percentageScore.CREATIVITY,
      COMMUNICATION: user.masterclassAssessment.percentageScore.COMMUNICATION,
      ANALYTICAL_SKILLS:
        user.masterclassAssessment.percentageScore.ANALYTICAL_SKILLS,
      SELF_AWARENESS: user.masterclassAssessment.percentageScore.SELF_AWARENESS,
    };
  });

  const sortANALYTICAL_SKILLS = _.orderBy(
    myUserData,
    ['ANALYTICAL_SKILLS'],
    ['desc']
  );
  const sortCOMMUNICATION = _.orderBy(myUserData, ['COMMUNICATION'], ['desc']);
  const sortCREATIVITY = _.orderBy(myUserData, ['CREATIVITY'], ['desc']);
  const sortSELF_AWARENESS = _.orderBy(
    myUserData,
    ['SELF_AWARENESS'],
    ['desc']
  );
  const ANALYTICAL_SKILLSTop5 = sortANALYTICAL_SKILLS.filter(
    (ass, index) => index < 5
  );
  const ANALYTICAL_SKILLSoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: ANALYTICAL_SKILLSTop5.map((user) => user.name),
    },
  };
  const ANALYTICAL_SKILLSscores = [
    {
      name: 'series-1',
      data: ANALYTICAL_SKILLSTop5.map((user) => user.ANALYTICAL_SKILLS),
    },
  ];

  const COMMUNICATIONTop5 = sortCOMMUNICATION.filter((ass, index) => index < 5);
  const COMMUNICATIONoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: COMMUNICATIONTop5.map((user) => user.name),
    },
  };
  const COMMUNICATIONSscores = [
    {
      name: 'series-1',
      data: COMMUNICATIONTop5.map((user) => user.COMMUNICATION),
    },
  ];

  const CREATIVITYTop5 = sortCREATIVITY.filter((ass, index) => index < 5);
  const CREATIVITYoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: CREATIVITYTop5.map((user) => user.name),
    },
  };
  const CREATIVITYSscores = [
    {
      name: 'series-1',
      data: CREATIVITYTop5.map((user) => user.CREATIVITY),
    },
  ];

  const SELF_AWARENESSTop5 = sortSELF_AWARENESS.filter(
    (ass, index) => index < 5
  );

  const SELF_AWARENESSoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: SELF_AWARENESSTop5.map((user) => user.name),
    },
  };
  const SELF_AWARENESSscores = [
    {
      name: 'series-1',
      data: SELF_AWARENESSTop5.map((user) => user.SELF_AWARENESS),
    },
  ];

  return (
    <Box sx={{ bgcolor: '#f9f9f9' }}>
      <Stack direction='row' justifyContent='center' alignItems='center'>
        <Box>
          <Typography m={6} variant='h4' gutterBottom>
            Top 5 Performers in each category
          </Typography>
          <Stack direction='row' justifyContent='space-evenly'>
            <Box m={4}>
              <Typography variant='h6'>Self Awareness</Typography>
              <Chart
                options={SELF_AWARENESSoptions}
                series={SELF_AWARENESSscores}
                type='bar'
                width={500}
                height={400}
              />
            </Box>
            <Box m={4}>
              <Typography variant='h6'>Creativity</Typography>
              <Chart
                options={CREATIVITYoptions}
                series={CREATIVITYSscores}
                type='bar'
                width={500}
                height={400}
              />
            </Box>
          </Stack>
          <Stack direction='row' justifyContent='space-evenly'>
            <Box m={4}>
              <Typography variant='h6'>Communication</Typography>
              <Chart
                options={COMMUNICATIONoptions}
                series={COMMUNICATIONSscores}
                type='bar'
                width={500}
                height={400}
              />
            </Box>
            <Box m={4}>
              <Typography variant='h6'>Analytical Skills</Typography>
              <Chart
                options={ANALYTICAL_SKILLSoptions}
                series={ANALYTICAL_SKILLSscores}
                type='bar'
                width={500}
                height={400}
              />
            </Box>
          </Stack>
          <Grid container spacing={3} mb={3}>
            <Grid item xs={3}>
              <Typography variant='h6' mx={2}>
                Business Communication
              </Typography>
              <Typography align='justify' variant='body1' mx={2}>
                The skill that influences the way in which a person conveys
                information in a clear, impactful and effective manner with
                colleagues and stakeholders. It is the process of sharing
                information between people within and outside a company.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6' mx={2}>
                Observation & Analytics
              </Typography>
              <Typography align='justify' variant='body1' mx={2}>
                The ability to investigate a problem and find the ideal solution
                in a timely, efficient manner when detecting patterns,
                brainstorming, observing, interpreting data, integrating new
                information, theorizing, and making decisions based on the
                multiple factors and options available.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6' mx={2}>
                Reasoning & Creativity
              </Typography>
              <Typography align='justify' variant='body1' mx={2}>
                The ability to think about a task or a problem in a new or
                different way, or the ability to use the imagination to generate
                new ideas. It enables a person to innovate & imagine with a
                unique perspective and solve complex problems.
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography variant='h6' mx={2}>
                Self Awareness
              </Typography>
              <Typography align='justify' variant='body1' mx={2}>
                The vital skill that impacts a person's personality,
                mindfulness, perspective,communication style, emotional
                intelligence and decision making. It enables the individual to
                shape their professional and personal development by providing a
                uniquely personal reference frame to discuss and develop their
                potential.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default TopFivePerformers;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Button, TableRow } from '@mui/material';
import PreviewIcon from '@mui/icons-material/Preview';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';

function RolesBody({ row, setEditRole }) {
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell component='th' scope='row' padding='none'>
          {row.roleTitle}
        </TableCell>
        <TableCell align='left'>{row.roleType} </TableCell>
        <TableCell align='left'> {row.company} </TableCell>
        <TableCell align='left'> {row.domain} </TableCell>
        <TableCell align='left'>
          {format(new Date(row.addedOn), 'do LLLL yyyy')}
        </TableCell>
        <TableCell align='left'>
          {format(new Date(row.cutoffDate), 'do LLLL yyyy')}
        </TableCell>
        <TableCell align='left'>{row.status} </TableCell>
        <TableCell align='center'>
          <a
            target='_blank'
            rel='noreferrer'
            href={`https://dashboard.belong.education/jobs/${row.slug}`}
          >
            <PreviewIcon color='primary' />
          </a>
          <Button
            onClick={() => {
              setEditRole(row.role);
            }}
          >
            <EditIcon />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default RolesBody;

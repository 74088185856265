import { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import {
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Button,
  Chip,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Prompt from '../../misc/Prompt';
import Snackbar from '../../misc/Snackbar';
import { connect } from 'react-redux';

import { API_URL } from '../../../links/API_LINKS';

const AllAdmins = ({ setShowAllAdmin, setEditToAdmin, team }) => {
  const fetchAdmin = async () => {
    const res = await axios.get(API_URL + '/dashboard/team/team-members');
    return res;
  };

  const teamData = team.team.data;

  const queryClient = useQueryClient();

  const { status, data } = useQuery('fetchAdmin', fetchAdmin);

  const deleteUser = (id) => {
    setSnackbarText('Deleting user...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/admin/partner', {
        data: { adminId: id },
      })
      .then((res) => {
        setSnackbarText('User has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchCsm');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <>
          <Box
            sx={{
              p: 1,
              m: 1,
            }}
          >
            <Button
              onClick={() => setShowAllAdmin('ADD')}
              variant='outlined'
              startIcon={<AddCircleIcon />}
            >
              Add new team member
            </Button>
          </Box>

          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='left'>Email</TableCell>
                  <TableCell align='left'>Role</TableCell>
                  <TableCell align='right' width='3%'></TableCell>
                  <TableCell align='right' width='3%'></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.data.map((admin) => {
                  return (
                    <TableRow
                      key={admin._id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell component='th' scope='row'>
                        {admin.firstName}
                      </TableCell>
                      <TableCell align='left'>{admin.email}</TableCell>

                      <TableCell align='left'>
                        {admin.roles.map((role) => {
                          return (
                            <Chip
                              sx={{ m: 0.5 }}
                              key={role}
                              size='small'
                              color={
                                role === 'Admin'
                                  ? 'warning'
                                  : role === 'Team'
                                  ? 'info'
                                  : 'default'
                              }
                              label={role}
                              onDelete={() => {}}
                            />
                          );
                        })}
                      </TableCell>

                      <TableCell align='right'>
                        <IconButton
                          color='secondary'
                          onClick={() => {
                            setEditToAdmin(admin);
                            // setShowAllAdmin('EDIT');
                          }}
                          aria-label='Edit'
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>

                      <TableCell align='right'>
                        {teamData.email !== admin.email && (
                          <Prompt
                            triggerComponent={
                              <IconButton color='error' aria-label='delete'>
                                <DeleteIcon />
                              </IconButton>
                            }
                            title={'Are you sure to delete the user?'}
                            description={'The action cannot be undone'}
                            cancelText={'cancel'}
                            confirmText={'DELETE'}
                            handlConfirm={() => {
                              deleteUser(admin._id);
                            }}
                            handleCancel={() => {}}
                          ></Prompt>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={5000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    team: state.team,
  };
};

export default connect(mapStateToProps)(AllAdmins);

import React, { useRef, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import { LoadingButton } from '@mui/lab';
import CapstoneForm from './CapstoneForm';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditCapstone = ({ capstoneToEdit, setCapstoneToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const [activeStep, setActiveStep] = useState(0);
  const formikRef = useRef();
  const queryClient = useQueryClient();
  const capstoneId = capstoneToEdit._id;

  const fetchQuestion = async () => {
    const res = await axios.get(API_URL + '/question', {
      params: { limit: 0, capstoneId },
    });
    return res;
  };

  const { status, data } = useQuery(
    `fetchQuestion${capstoneId}`,
    fetchQuestion
  );

  const {
    _id,
    title,
    domainId,
    companyId,
    level,
    problemStatement,
    learningObjectives,
    businessObjectives,
    introductoryVideo,
    taskDependency,
    prerequisites,
    tools,
    mentorDetail,
    dataset,
    videoImage,
    capstoneImage,
  } = {
    ...capstoneToEdit,
  };
  const tasks = capstoneToEdit.tasks
    ? capstoneToEdit.tasks.map((task) => {
        const myskillId = task.skillId.map((skill) => skill._id);
        return { ...task, skillId: myskillId };
      })
    : [
        {
          title: '',
          skillId: [],
          backgroundDescription: '',
          taskInstructions: '',
          resources: '',
          time: '',
          idealSolution: '',
        },
      ];
  const skillId = capstoneToEdit.skillId
    ? capstoneToEdit.skillId.map((skill) => skill._id)
    : [];
  async function addQuestions(questions) {
    return Promise.all(
      questions.map(async (question) => {
        if (question._id) {
          const questionData = {
            questionId: question._id,
            question: question.question,
            type: question.type,
            options: question.options,
            maxSelect: question.maxSelect,
            image: question.image,
            capstoneId,
          };
          return await axios({
            method: 'PUT',
            url: API_URL + '/question',
            data: questionData,
          });
        } else {
          const questionData = { ...question, capstoneId };
          return await axios({
            method: 'POST',
            url: API_URL + '/question',
            data: questionData,
          });
        }
      })
    );
  }

  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setCapstoneToEdit(null)}>Back</Button>
      </Typography>
      {status === 'success' && (
        <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          initialValues={{
            capstoneId: _id,
            title: title,
            domainId: domainId._id,
            companyId: companyId._id,
            level: level,
            skillId: skillId,
            problemStatement: problemStatement,
            learningObjectives: learningObjectives,
            businessObjectives: businessObjectives,
            introductoryVideo: introductoryVideo || '',
            taskDependency: taskDependency || false,
            prerequisites: prerequisites || '',
            tools: tools || '',
            tasks: tasks,
            mentorDetail: mentorDetail || {
              name: '',
              linkedin: '',
              designation: '',
              image: '',
            },
            dataset: dataset || {
              link: '',
              description: '',
            },
            videoImage: videoImage || '',
            capstoneImage: capstoneImage || '',
            questions: data?.data?.data || [],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            domainId: Yup.string().required('Domain is required'),
            companyId: Yup.string().required('Company is required'),
            level: Yup.string().required(),
            skillId: Yup.array().min(1, 'Minimum 1 skill required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            learningObjectives: Yup.string().required(
              'learning Objectives is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            introductoryVideo: Yup.string().url(),
            taskDependency: Yup.boolean().required('Task Dependency required'),
            prerequisites: Yup.string(),
            tools: Yup.string(),
            tasks: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required('Title is required'),
                backgroundDescription: Yup.string().required(
                  'Description is required'
                ),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                resources: Yup.string(),
                time: Yup.number(),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
              })
            ),
            questions: Yup.array().of(
              Yup.object().shape({
                question: Yup.string().required('question is required'),
              })
            ),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            const questions = values.questions;
            const capstoneData = { ...values, isActive: questions.length >= 5 };
            delete capstoneData.questions;
            axios({
              method: 'PUT',
              url: API_URL + '/capstone',
              data: capstoneData,
            })
              .then(async function (response) {
                queryClient.invalidateQueries('fetchCapstone');
                setServerResponse('Capstone Updated, Question Adding..');
                await addQuestions(questions);
                queryClient.invalidateQueries(`fetchQuestion${capstoneId}`);
                setServerResponse(response.data.msg);
                resetForm();
                setSubmitting(false);
                setCapstoneToEdit(null);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <CapstoneForm
                values={values}
                setFieldValue={setFieldValue}
                capstoneToEdit={capstoneToEdit}
                errors={errors}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                {activeStep === 2 && (
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    {isSubmitting ? 'Updateing' : 'Update'}
                  </LoadingButton>
                )}
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};

export default EditCapstone;

import React, { useState, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import ShowFilesPopup from '../../../halper/showFilesPopup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Modal,
  Grid,
  MenuItem,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import {
  TextInput,
  SelectInput,
  DatePickerField,
  CheckBoxField,
} from '../../../layouts/FormElement';

import { API_URL } from '../../../../../links/API_LINKS';

const fetchCountry = async () => {
  const res = await axios.get(API_URL + '/countrytimezones/countries');
  return res;
};

const fetchPartner = async () => {
  const res = await axios.get(API_URL + '/partner', { params: { limit: 0 } });
  return res;
};

const fetchArea = async () => {
  const res = await axios.get(API_URL + '/area', { params: { limit: 0 } });
  return res;
};

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddBatch = ({ handleCloseBatch, snackbarActions }) => {
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [serverResponse, setServerResponse] = useState();
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const queryClient = useQueryClient();

  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }
  const countries = useQuery('fetchCountry', fetchCountry);
  const allPartner = useQuery('fetchPartner', fetchPartner);
  const allArea = useQuery('fetchArea', fetchArea);
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  return (
    <div>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          name: '',
          type: 'Partner',
          startDate: new Date(),
          endDate: new Date(),
          status: 'Active',
          note: '',
          logoUrl: '',
          country: 'IN', //default set to India
          programTitle: '',
          guidebook: '',
          tutorial: '',
          partner: '610a7ca8d3c51a1458d005ce',
          week: '',
          website: '',
          enableQueryManagement: false,
          taskEvaluationBy: [],
          journeyType: 'School/DT Journey',
          domainId: '',
          vgaTab: {
            companyConnect: false,
            projectTask: false,
            coachSupport: false,
            masterclass: false,
            centuryLab: false,
            domainQuiz: false,
          },
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be 2 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a name'),
          status: Yup.string().required(),
          programTitle: Yup.string().required(),
          guidebook: Yup.string().url().required(),
          tutorial: Yup.string().url().required(),
          week: Yup.number('Week must be number').required(),
          website: Yup.string().url().required(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'POST',
            url: API_URL + '/batch',
            data: values,
          })
            .then(function (response) {
              setSnackbarText('Batch added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries('fetchAllBatch');
              handleCloseBatch();
            })
            .catch(function (response) {
              setSnackbarText('Failed to add Batch, Please try after sometime');
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
              setServerResponse('Error! Could not process your request.');
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='name'
                  type='text'
                  label='Batch Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput size='small' fullWidth name='type' label='Type'>
                  <MenuItem value='Partner'>Partner</MenuItem>
                  <MenuItem value='Belong'>Belong</MenuItem>
                  <MenuItem value='University'>University</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  name='startDate'
                  value={values.startDate}
                  label='Start Date'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  name='endDate'
                  value={values.endDate}
                  label='End Date'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='journeyType'
                  label='Journey Type'
                >
                  <MenuItem value='School/DT Journey'>
                    School/DT Journey
                  </MenuItem>
                  <MenuItem value='Tech'>Tech</MenuItem>
                  <MenuItem value='Non Tech'>Non Tech</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Status'
                >
                  <MenuItem value='Active'>Active</MenuItem>
                  <MenuItem value='Upcoming'>Upcoming</MenuItem>
                  <MenuItem value='Completed'>Completed</MenuItem>
                  <MenuItem value='On Hold'>On Hold</MenuItem>
                  <MenuItem value='Inactive'>Inactive</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  multiline
                  name='note'
                  type='text'
                  label='Note'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='logoUrl'
                  type='text'
                  label='Logo'
                />
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                    setSelectedField('logoUrl');
                    setRefresh(!refresh);
                  }}
                >
                  Select Image
                </Button>
                <div>
                  <img src={values.logoUrl} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                {countries.status === 'success' && (
                  <SelectInput
                    size='small'
                    fullWidth
                    name='country'
                    label='Country'
                  >
                    {Object.entries(countries.data.data.data).map(
                      ([countryCode, countryData]) => {
                        return (
                          <MenuItem key={countryCode} value={countryCode}>
                            {countryData.name}
                          </MenuItem>
                        );
                      }
                    )}
                  </SelectInput>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='guidebook'
                  type='text'
                  label='Guidebook Url'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='tutorial'
                  type='text'
                  label='Tutorial Video Url'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='programTitle'
                  type='text'
                  label='Program Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {allPartner.status === 'success' && (
                  <SelectInput
                    size='small'
                    fullWidth
                    name='partner'
                    label='Partner'
                  >
                    {allPartner.data.data.data.map((partner) => {
                      return (
                        <MenuItem key={partner._id} value={partner._id}>
                          {partner.name}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='week'
                  type='number'
                  label='Duration (Weeks)'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='website'
                  type='text'
                  label='Partner Website'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CheckBoxField
                  name='enableQueryManagement'
                  label='Enable Query Management'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  multiple
                  size='small'
                  fullWidth
                  name='taskEvaluationBy'
                  label='Task Evaluation By Coach:'
                >
                  <MenuItem value='21st Century Skills'>21st Century</MenuItem>
                  <MenuItem value='Company Tasks'>Company Tasks</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                {allArea.status === 'success' && (
                  <SelectInput
                    size='small'
                    fullWidth
                    name='domainId'
                    label='Area For The Domain-Skill Quiz'
                  >
                    <MenuItem value=''>Select Domain if required</MenuItem>
                    {allArea.data.data.data.map((domain) => {
                      return (
                        <MenuItem key={domain._id} value={domain._id}>
                          {domain.title}
                        </MenuItem>
                      );
                    })}
                  </SelectInput>
                )}
              </Grid>
            </Grid>
            <Box>
              <Typography mt={2}>Tab to show on User VGA</Typography>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField
                    name='vgaTab.companyConnect'
                    label='Company Connect'
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField
                    name='vgaTab.projectTask'
                    label='Project Task'
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField
                    name='vgaTab.coachSupport'
                    label='Coach Support'
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField
                    name='vgaTab.masterclass'
                    label='Masterclass'
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField name='vgaTab.centuryLab' label='Century Lab' />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CheckBoxField name='vgaTab.domainQuiz' label='DomainQuiz' />
                  <Typography variant='body2'>
                    check the box to activate the quiz for the users
                  </Typography>{' '}
                </Grid>
              </Grid>
            </Box>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Batch
              </LoadingButton>
            </Stack>
            <Typography mt={2} textAlign='center'>
              {serverResponse}
            </Typography>
          </Form>
        )}
      </Formik>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ShowFilesPopup
            setOpen={setOpen}
            resource_type={'image'}
            setImage={setImage}
            setSelectedField={setSelectedField}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddBatch;

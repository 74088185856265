import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import ProgramForm from './ProgramForm';
import { Box, Typography, Stack, Button } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditProgram = ({ setProgramToEdit, row }) => {
  const queryClient = useQueryClient();

  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();

  console.log(row);
  return (
    <Box sx={{ my: 4, px: 4 }}>
      <Typography my={4}>
        <Button onClick={() => setProgramToEdit(null)}>Back</Button>
      </Typography>{' '}
      <Box sx={{ py: 2 }}>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            programId: row._id,
            title: row.title,
            company: row.company._id,
            // country: row.country,
            price: row.price,
            area: row.area ? row.area._id : '',
            skills: row.skills || [],
            overviewNote: row.overviewNote,
            slug: row.slug,
            externallink: row.externallink,
            points: row.overviewPoints ? [...row.overviewPoints] : '',
            publishStatus: row.publishStatus,
            featured: row.featured,
            showOnHomePage: row.showOnHomePage,
            sectionTitle: row.sectionTitle,
            sectionContent: row.sectionContent,
            category: row.category ? row.category._id : '',
            highlightPoints: row.highlightPoints,
            underReview: row.underReview,
            jobDescriptionDoc: row.jobDescriptionDoc,
            // contact: row.contact,
            roleType: row.roleType,
            stipend: row.stipend,
            applyBy: row.applyBy,
            jobLocation: row.jobLocation,
            linkedinLink: row.linkedinLink,
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .min(3, 'Must be 3 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required(),
            slug: Yup.string()
              .required()
              .matches(
                '^[A-Za-z0-9-]*$',
                'URL can have alphanumberic letters or hyphen'
              ),
            price: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number(),
              })
            ),
            area: Yup.string().required(),
            company: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
            setServerResponse('');

            axios({
              method: 'PUT',
              url: API_URL + '/programs',
              data: values,
              config: { headers: { 'Content-Type': 'application/json' } },
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchProgram');
                setServerResponse(response.data.msg);
                setSubmitting(false);
                setProgramToEdit();
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <ProgramForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditProgram;

import { Box, Stepper, Step, StepButton } from '@mui/material';
import React, { useState } from 'react';
import CreateNewSuperCapstone from './CreateNewSuperCapstone';
import AddEditTask from './AddEditTask';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';

const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};

const CapstoneSteps = ({ setValue, capstoneEditData, setCapStoneEditData }) => {
  const steps = ['Overview', 'Task 1', 'Task 2', 'Task 3', 'Task 4'];
  const [activeStep, setActiveStep] = useState(0);
  const [superCapstoneId, setSuperCapstoneId] = useState(
    capstoneEditData?._id || null
  );
  const skills = useQuery('fetchSkills', fetchSkills);

  const capstoneData = {
    title: capstoneEditData?.title || '',
    domainId: capstoneEditData?.domainId?._id || '',
    companyId: capstoneEditData?.companyId?._id || '',
    level: capstoneEditData?.level || 'Basic',
    tools: capstoneEditData?.tools || '',
    prerequisites: capstoneEditData?.prerequisites || '',
    // introductoryVideo: capstoneEditData?.introductoryVideo || '',
    // videoImage: capstoneEditData?.videoImage || '',
    // capstoneImage: capstoneEditData?.capstoneImage || '',
    businessObjectives: capstoneEditData?.businessObjectives || '',
    learningObjectives: capstoneEditData?.learningObjectives || '',
    problemStatement: capstoneEditData?.problemStatement || '',
    // mentorDetail: capstoneEditData?.mentorDetail || {
    //   name: '',
    //   linkedin: '',
    //   designation: '',
    //   image: '',
    // },
    dataset: capstoneEditData?.dataset || {
      link: '',
      description: '',
    },
  };

  const capstoneTasks = capstoneEditData?.tasks || [];

  const taskInitialValues = {
    title: '',
    skillId: [],
    backgroundDescription: '',
    taskInstructions: '',
    resources: '',
    time: '',
    userInputType: 'TEXT EDITOR',
    idealSolution: '',
    submissionFormat: '',
    hints: '',
    questions: [],
  };

  const task1PreData =
    capstoneTasks.find((el) => {
      return el.taskNo === 1;
    }) || taskInitialValues;

  const task1Data = {
    ...task1PreData,
    taskNo: 1,
    superCapstoneId: task1PreData._id,
    skillId: task1PreData.skillId.map((el) => el._id),
  };

  // Deleting the extra field _id. Else mongo throws extra field error.
  delete task1Data._id;

  const task2PreData =
    capstoneTasks.find((el) => {
      return el.taskNo === 2;
    }) || taskInitialValues;

  const task2Data = {
    ...task2PreData,
    taskNo: 2,
    superCapstoneId: task2PreData._id,
    skillId: task2PreData.skillId.map((el) => el._id),
  };

  delete task2Data._id;

  const task3PreData =
    capstoneTasks.find((el) => {
      return el.taskNo === 3;
    }) || taskInitialValues;

  const task3Data = {
    ...task3PreData,
    taskNo: 3,
    superCapstoneId: task3PreData._id,
    skillId: task3PreData.skillId.map((el) => el._id),
  };

  delete task3Data._id;

  const task4PreData =
    capstoneTasks.find((el) => {
      return el.taskNo === 4;
    }) || taskInitialValues;

  const task4Data = {
    ...task4PreData,
    taskNo: 4,
    superCapstoneId: task4PreData._id,
    skillId: task4PreData.skillId.map((el) => el._id),
  };

  delete task4Data._id;

  const handleStep = (step) => () => {
    // remove ! before superCapstoneId, before pushing into production
    if (superCapstoneId) {
      setActiveStep(step);
    } else {
      setActiveStep(0);
    }
  };

  return (
    <div>
      {/* header containing overview-task1-task2... */}
      <Box sx={{ width: '50%' }} mb={2}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <CreateNewSuperCapstone
          superCapstoneId={superCapstoneId}
          capstoneData={capstoneData}
          setCapStoneEditData={setCapStoneEditData}
          setActiveStep={setActiveStep}
          setValue={setValue}
          setSuperCapstoneId={setSuperCapstoneId}
        />
      )}
      {activeStep === 1 && (
        <AddEditTask
          capstoneData={capstoneData}
          setCapstoneData={capstoneData}
          superCapstoneId={superCapstoneId}
          taskNo={1}
          skills={skills}
          taskData={task1Data}
          setCapStoneEditData={setCapStoneEditData}
          setActiveStep={setActiveStep}
          setValue={setValue}
        />
      )}
      {activeStep === 2 && (
        <AddEditTask
          capstoneData={capstoneData}
          setCapstoneData={capstoneData}
          superCapstoneId={superCapstoneId}
          taskNo={2}
          skills={skills}
          taskData={task2Data}
          setCapStoneEditData={setCapStoneEditData}
          setActiveStep={setActiveStep}
          setValue={setValue}
        />
      )}
      {activeStep === 3 && (
        <AddEditTask
          capstoneData={capstoneData}
          setCapstoneData={capstoneData}
          superCapstoneId={superCapstoneId}
          taskNo={3}
          skills={skills}
          taskData={task3Data}
          setCapStoneEditData={setCapStoneEditData}
          setActiveStep={setActiveStep}
          setValue={setValue}
        />
      )}
      {activeStep === 4 && (
        <AddEditTask
          capstoneData={capstoneData}
          setCapstoneData={capstoneData}
          superCapstoneId={superCapstoneId}
          taskNo={4}
          skills={skills}
          taskData={task4Data}
          setCapStoneEditData={setCapStoneEditData}
          setActiveStep={setActiveStep}
          setValue={setValue}
        />
      )}
    </div>
  );
};

export default CapstoneSteps;

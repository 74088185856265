import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow } from '@mui/material';
import { Card, Box } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchQuestionOverview = async () => {
  const res = await axios.get(API_URL + '/question/domain-question-count', {
    params: { type: 'DOMAIN' },
  });
  return res;
};

function Overview() {
  const { status, data } = useQuery(
    'fetchQuestionOverview',
    fetchQuestionOverview
  );

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <TableContainer component={Card} sx={{ marginTop: '30px' }}>
          <Table
            sx={{ minWidth: 550, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'
          >
            <TableHead>
              <TableRow>
                <TableCell align='left'></TableCell>
                <TableCell align='center'>Domain</TableCell>
                <TableCell align='center'>Question</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.data.data
                .filter((qu) => qu.count)
                .map((row, index) => {
                  return (
                    <TableRow hover key={index} sx={{ cursor: 'pointer' }}>
                      <TableCell align='left'>{index + 1}</TableCell>
                      <TableCell align='center'>{row.title}</TableCell>
                      <TableCell align='center'>{row.count}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}

export default Overview;

import React from 'react';

import CapstoneSummaryTable from './CapstoneSummaryTable';

const Index = () => {
  return (
    <div>
      <CapstoneSummaryTable />
    </div>
  );
};

export default Index;

import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import EventTemplateHeader from './EventTemplateHeader';

const Index = () => {
  const fetchEventTemplate = async () => {
    const res = await axios.get(API_URL + '/event-template');
    return res;
  };
  const { status, data } = useQuery('fetchEventTemplate', fetchEventTemplate);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          <EventTemplateHeader data={data.data.data} />{' '}
        </Box>
      )}
    </div>
  );
};

export default Index;

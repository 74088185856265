import axios from 'axios';
import {
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Modal,
  Grid,
} from '@mui/material';
import React, { useState } from 'react';
import { API_URL } from 'links/API_LINKS';
import format from 'date-fns/format';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const DomainTest = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [emailId, setEmailId] = useState('');
  const [isDeletingTest, setIsDeletingTest] = useState(false);
  const [fetchedData, setFetchedData] = useState(null);
  const [responsesData, setResponsesData] = useState([]);
  const [currDeleteId, setCurrDeleteId] = useState(-1);

  const getTest = () => {
    setSubmitting(true);
    axios({
      method: 'GET',
      url: API_URL + '/test/domain-test/email',
      params: {
        email: emailId.trim().toLowerCase(),
      },
    })
      .then(function (response) {
        setFetchedData(response.data.data);
        updateResponseData(response.data.data);
        setServerResponse('');
      })
      .catch(function (err) {
        console.log(err);
        setServerResponse('Error! Could not process your request.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Update the responsesData state for all objects
  const updateResponseData = (data) => {
    let updatedArr = [];
    for (const dataItem of data) {
      let total = dataItem.responses?.length;
      let totalAnswered = 0;
      let totalVisited = 0;
      for (const resp of dataItem.responses) {
        if (resp.status === 'answered') {
          totalAnswered += 1;
          totalVisited += 1;
        } else if (resp.status === 'visited') {
          totalVisited += 1;
        }
      }

      updatedArr.push({
        totalAnswered: totalAnswered,
        totalVisited: totalVisited,
        totalUnanswered: total - totalVisited,
      });
    }

    setResponsesData(updatedArr);
  };

  const handleTestDelete = () => {
    setIsDeletingTest(true);
    axios({
      method: 'DELETE',
      url: API_URL + '/test/domain-test',
      data: {
        testId: currDeleteId,
      },
    })
      .then((res) => {
        setServerResponse('Delete successful.');
        getTest();
      })
      .catch((err) => {
        console.log(err);
        setServerResponse('Error! Could not process your deletion request.');
      })
      .finally(() => {
        setIsDeletingTest(false);
        setCurrDeleteId(-1);
        handleClose();
      });
  };

  const getScoreString = (finalScoreArr) => {
    let totalMax = 0;
    let totalCorrect = 0;

    for (const score of finalScoreArr) {
      totalMax += score.max;
      totalCorrect += score.obtained;
    }

    return `${totalCorrect}/${totalMax}`;
  };

  return (
    <div>
      <Stack direction='row' spacing={4} alignItems='flex-end'>
        <Box>
          <Typography variant='caption'>
            Enter a vaild Email Id to fetch Test
          </Typography>
          <TextField
            size='small'
            fullWidth
            placeholder='Email Id'
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
        </Box>
        <Button
          onClick={() => getTest()}
          variant='outlined'
          disabled={emailId.length === 0 || isSubmitting}>
          {isSubmitting ? 'Processing...' : 'Get Test'}
        </Button>
      </Stack>
      <Stack
        // direction='row'
        mt={4}
        justifyContent='Center'
        spacing={2}>
        {emailId &&
          fetchedData != null &&
          (fetchedData.length === 0 ? (
            <Typography>Data unavailable.</Typography>
          ) : (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>
                    Name:{' '}
                    {`${fetchedData[0].userId?.firstName} ${fetchedData[0].userId?.lastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Email: {fetchedData[0].userId?.email}</Typography>
                </Grid>
                <Grid item xs={12}>
                  {fetchedData.map((dataItem, idx) => (
                    <Box key={idx} border={1} p={2}>
                      <Grid container>
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'Domain: '}
                            </Typography>
                            {dataItem.domain?.title}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'Skills: '}
                            </Typography>
                            {dataItem.skills
                              ?.map((skill) => skill.title)
                              .join(', ')}
                          </Box>
                        </Grid>
                        {dataItem.batchId && (
                          <Grid item xs={6}>
                            <Box display='flex' flexDirection='row' gap={2}>
                              <Typography fontWeight={600}>
                                {'Batch Name: '}
                              </Typography>
                              {dataItem.batchId.name}
                            </Box>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'Percentage: '}
                            </Typography>
                            {dataItem.percentage}
                            {'%'}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'Start Time: '}
                            </Typography>
                            {format(
                              new Date(dataItem.startTime),
                              'HH:mm:ss, do LLLL yyyy'
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'End Time: '}
                            </Typography>
                            {format(
                              new Date(dataItem.finishTime),
                              'HH:mm:ss, do LLLL yyyy'
                            )}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' flexDirection='row' gap={2}>
                            <Typography fontWeight={600}>
                              {'Time Taken: '}
                            </Typography>
                            {Math.ceil(dataItem.timeTaken / 1000) > 60
                              ? `${Math.ceil(
                                  dataItem.timeTaken / 1000 / 60
                                )} minute(s)`
                              : `${Math.ceil(
                                  dataItem.timeTaken / 1000
                                )} second(s)`}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' gap={1} flexDirection='row'>
                            <Typography fontWeight={600}>
                              {'Timeout: '}
                            </Typography>
                            {dataItem.timeOut ? 'Yes' : 'No'}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' gap={1} flexDirection='row'>
                            <Typography fontWeight={600}>
                              {'Score: '}
                            </Typography>
                            {getScoreString(dataItem.finalScore)}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' gap={1} flexDirection='row'>
                            <Typography fontWeight={600}>
                              {'Total Answered: '}
                            </Typography>
                            {responsesData[idx]?.totalAnswered}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' gap={1} flexDirection='row'>
                            <Typography fontWeight={600}>
                              {'Total Visited: '}
                            </Typography>
                            {responsesData[idx]?.totalVisited}
                          </Box>
                        </Grid>
                        <Grid item xs={6}>
                          <Box display='flex' gap={1} flexDirection='row'>
                            <Typography fontWeight={600}>
                              {'Total Not Visited: '}
                            </Typography>
                            {responsesData[idx]?.totalUnanswered}
                          </Box>
                        </Grid>
                      </Grid>
                      <Button
                        onClick={() => {
                          setCurrDeleteId(dataItem._id);
                          handleOpen();
                        }}
                        variant='outlined'
                        color='error'
                        size='medium'
                        sx={{ maxWidth: 200 }}
                        //   disabled={}
                      >
                        Delete Test
                      </Button>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </React.Fragment>
          ))}
        <Typography variant='h6'>{serverResponse}</Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Are you sure you want to delete this test ? You can't retrieve
              this information again. This is a permanent action !
            </Typography>
            <Stack direction='row' spacing={2} pt={2}>
              <Button onClick={handleClose} variant='contained' color='primary'>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='error'
                disabled={isDeletingTest}
                onClick={handleTestDelete}>
                {isDeletingTest ? 'Deleting...' : 'Delete'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Stack>
    </div>
  );
};

export default DomainTest;

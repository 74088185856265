// material
import { styled } from '@mui/material/styles';
import { Card, Container, Typography } from '@mui/material';
// layouts
// import AuthLayout from '../components/layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { LoginForm } from '../components/authentication/login';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title='Login | Belong'>
      {/* <AuthLayout>
        Don’t have an account? &nbsp;
        <Link
          underline='none'
          variant='subtitle2'
          component={RouterLink}
          to='/register'
        >
          Get started
        </Link>
      </AuthLayout> */}

      <MHidden width='mdDown'>
        <SectionStyle>
          <Typography variant='h3' sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome Back
          </Typography>
          <img
            src='https://res.cloudinary.com/belong/image/upload/v1628848245/uploaded_resources/Group_2_xbr94l.png'
            alt='Belong'
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth='sm'>
        <ContentStyle>
          <LoginForm />

          {/* <MHidden width='smUp'>
            <Typography variant='body2' align='center' sx={{ mt: 3 }}>
              Don’t have an account?&nbsp;
              <Link variant='subtitle2' component={RouterLink} to='register'>
                Get started
              </Link>
            </Typography>
          </MHidden> */}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}

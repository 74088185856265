import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import InvoiceHeader from './InvoiceHeader';
import exportFromJSON from 'export-from-json';

const PaidInvoice = ({ setValue }) => {
  const fetchPaidInvoice = async () => {
    const res = await axios.get(API_URL + '/invoice', {
      params: { status: 'Paid', limit: 0 },
    });
    return res;
  };
  const { status, data } = useQuery(`fetchPaidInvoice`, fetchPaidInvoice);

  const downloadFunc = () => {
    const dataToBeSaved = data.data.data.map((item) => {
      let paidDateStr = '';
      for (const activity of item.activity) {
        if (activity.status === 'Paid') {
          paidDateStr = new Date(activity.time).toDateString();
        }
      }
      let approvedDateStr = '';
      for (const activity of item.activity) {
        if (activity.status === 'Approved') {
          approvedDateStr = new Date(activity.time).toDateString();
        }
      }
      const rowObj = {
        'Coach Name': item.coachId.name,
        'Batch Name': item.batchId.name,
        'Total Amount': `${item.totalAmount} ${
          item.currency ? item.currency : ''
        }`,
        'Session Count': item.sessions,
        Currency: item.currency,
        'Added On': new Date(item.createdAt).toDateString(),
        'Approved On': approvedDateStr,
        'Paid On': paidDateStr,
        'Back Account Holder Name': item.bankDetails.accountHolderName,
        'Back Account Number': item.bankDetails.accountNumber,
        'UPI ID': item.bankDetails.UPI_Id,
        'Bank IFSC Code': item.bankDetails.IFSC_Code,
      };

      return rowObj;
    });

    const fileName = `paid-invoices`;
    const exportType = 'xls';
    exportFromJSON({ data: dataToBeSaved, fileName, exportType });
  };

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
            mb={2}>
            <Button onClick={downloadFunc}>Download</Button>
          </Stack>

          <InvoiceHeader
            data={data.data.data}
            setValue={setValue}
            invoiceType='paid'
          />
        </Box>
      )}
    </div>
  );
};

export default PaidInvoice;

// const [paidOn, setPaidOn] = useState('');

// const paidStatusCheckArray = data.data.data[0].activity;
// for (const row of paidStatusCheckArray) {
//   if (row.status === 'Paid') {
//     console.log('Time:', row.time);
//     setPaidOn(row.time);
//   }
// }

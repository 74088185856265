import { combineReducers } from 'redux';

import authReducer from './auth/authReducer';
import teamReducer from './team/teamReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  team: teamReducer,
});

export default rootReducer;

import React, { useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import DataCard from '../layouts/DataCard';
import { API_URL } from '../../../links/API_LINKS';
import PeopleIcon from '@mui/icons-material/People';
import BackupIcon from '@mui/icons-material/Backup';
import TaskIcon from '@mui/icons-material/Task';
import ScoreIcon from '@mui/icons-material/Score';
import FunctionsIcon from '@mui/icons-material/Functions';
import axios from 'axios';

const infoLighter = '#D0F2FF';
const successLighter = '#E9FCD4';
const warningLighter = '#FFF7CD';
const errorLighter = '#FFE7D9';
const infoDarker = '#04297A';
const successDarker = '#08660D';
const warningDarker = '#7A4F01';
const errorDarker = '#7A0C2E';

const Engagement = ({ data }) => {
  const [engagementData, seteEngagementData] = useState({});

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/dashboard/assessment-engagement');
      seteEngagementData(result.data.data);
    })();
  }, []);
  return (
    <Container maxWidth='xl' sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={15}
            title='Average live sessions/student'
            icon={PeopleIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={8586 + data.submissions}
            title='Total Submissions'
            icon={BackupIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={7}
            title='Average tasks/student'
            icon={TaskIcon}
            bgColor={errorLighter}
            textColor={errorDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={engagementData.assessmentsCounts}
            title='Base assessments'
            icon={TaskIcon}
            bgColor={successLighter}
            textColor={successDarker}
          />
        </Grid>
        {engagementData.medianAssessmentScore && (
          <Grid item xs={12} sm={6} md={3}>
            <DataCard
              total={`${engagementData.medianAssessmentScore} %`}
              title='Median score'
              icon={ScoreIcon}
              bgColor={infoLighter}
              textColor={infoDarker}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={`${engagementData.averageAssessmentScore} %`}
            title='Average of Masterclass and Employibility score'
            icon={FunctionsIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={engagementData.usersPerMasterclass}
            title='Users per Masterclass'
            icon={BackupIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Engagement;

import React from 'react';
import NpsBody from './NpsBody';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Card,
} from '@mui/material';

const NpsHeader = ({ data }) => {
  return (
    <div>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Batch Name</TableCell>
              <TableCell align='left'> Total User</TableCell>
              <TableCell align='left'> Active User</TableCell>
              <TableCell align='left'>Feedback User</TableCell>
              <TableCell align='left'> Up skilling</TableCell>
              <TableCell align='left'>Masterclass</TableCell>
              <TableCell align='left'>Company Tasks</TableCell>
              <TableCell align='left'> Coach </TableCell>
              <TableCell align='left'>Company Mentor</TableCell>
              <TableCell align='left'>Future Readiness</TableCell>
              <TableCell align='left'>Platform</TableCell>
              <TableCell align='left'>NPS</TableCell>
              <TableCell align='left'>Feedback</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((batch) => (
              <NpsBody batch={batch} key={batch._id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default NpsHeader;

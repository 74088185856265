import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Table,
  TableBody,
  Chip,
  Button,
} from '@mui/material';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import Snackbar from '../../../../../misc/Snackbar';
import Prompt from '../../../../../misc/Prompt';
import SubEventBody from './SubEventBody';
import EditEvent from './EditEvent';
import LinkIcon from '@mui/icons-material/Link';
import AddIcon from '@mui/icons-material/Add';
import AddSubEvent from './AddSubEvent';
import AddAttendance from './attendance';
import AudienceDetails from './AudienceDetails';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useQueryClient } from 'react-query';
import { API_URL } from '../../../../../../links/API_LINKS';
import MasterEdit from './MasterEdit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllEventBody = ({ event, currentBatch }) => {
  const [open, setOpen] = useState(false);
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAdd, setOpenAdd] = useState(false);
  const handleOpenAdd = () => setOpenAdd(true);
  const handleCloseAdd = () => setOpenAdd(false);

  const [openAttendance, setOpenAttendance] = useState(false);
  const handleOpenAttendance = () => setOpenAttendance(true);
  const handleCloseAttendance = () => setOpenAttendance(false);

  const [openAudience, setOpenAudience] = useState(false);
  const handleOpenAudience = () => setOpenAudience(true);
  const handleCloseAudience = () => setOpenAudience(false);

  const [openMaster, setOpenMaster] = useState(false);
  const handleOpenMaster = () => setOpenMaster(true);
  const handleCloseMaster = () => setOpenMaster(false);

  const queryClient = useQueryClient();
  const deleteEvent = (eventId) => {
    setSnackbarText('Deleting Event...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/event', {
        data: { eventId: eventId },
      })
      .then((res) => {
        setSnackbarOpen(true);
        setSnackbarText('Event has been deleted');
        setSnackbarSeverity('success');
        queryClient.invalidateQueries(
          `fetchCurrentBatchEvents${currentBatch._id}`
        );
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell
          //sx={{ backgroundColor: bgColor }}
          component='th'
          scope='row'
        >
          {event.subEvents && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              {collapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          {event.title}
        </TableCell>
        <TableCell align='left'>
          {event.type === 'Masterclass' ? (
            <Chip color='primary' size='small' label={event.type} />
          ) : event.subEvents &&
            event.subEvents[0] &&
            event.subEvents[0].type === 'Mentor Connect' ? (
            <Chip color='secondary' size='small' label='Mentor Connect' />
          ) : (
            <Chip
              color='warning'
              size='small'
              label={event.type || 'Coach Connect'}
            />
          )}
        </TableCell>

        <TableCell align='left'>
          {event.dateTime &&
            format(new Date(event.dateTime), 'do LLLL yyyy h:mm a iiii')}
        </TableCell>
        <TableCell align='left'>
          {event.link && (
            <a target='_blank' rel='noreferrer' href={event.link}>
              <LinkIcon />
            </a>
          )}
        </TableCell>
        <TableCell align='left'>
          {!event.subEvents ? (
            <IconButton
              sx={{ cursor: 'pointer' }}
              size='small'
              onClick={handleOpen}
            >
              <EditIcon color='primary' />
            </IconButton>
          ) : (
            <IconButton
              sx={{ cursor: 'pointer' }}
              size='small'
              onClick={handleOpenAdd}
            >
              <AddIcon color='primary' />
            </IconButton>
          )}
        </TableCell>
        {!event.subEvents ? (
          <>
            <TableCell align='left'>
              <Button onClick={handleOpenAudience}>Audience</Button>
            </TableCell>
            <TableCell align='left'>
              <Button onClick={handleOpenAttendance}>Attendance</Button>
            </TableCell>
            <TableCell align='left'>
              <Prompt
                triggerComponent={
                  <IconButton size='small' color='error' aria-label='delete'>
                    <DeleteIcon />
                  </IconButton>
                }
                title={'Are you sure to delete this Event?'}
                description={'The action cannot be undone'}
                cancelText={'cancel'}
                confirmText={'DELETE'}
                handlConfirm={() => {
                  deleteEvent(event._id);
                }}
                handleCancel={() => {}}
              ></Prompt>
            </TableCell>
            {event.pollingUser?.length > 0 && (
              <TableCell align='left'>
                Polling User: {event.pollingUser?.length}
              </TableCell>
            )}
          </>
        ) : (
          <TableCell align='left'>
            <IconButton
              sx={{ cursor: 'pointer' }}
              size='small'
              onClick={handleOpenMaster}
            >
              <EditIcon color='primary' />
            </IconButton>
          </TableCell>
        )}
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label=''>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {event.subEvents &&
                    event.subEvents.map((subEvent) => {
                      return (
                        <SubEventBody
                          key={subEvent._id}
                          subEvent={subEvent}
                          currentBatch={currentBatch}
                          snackbarActions={{
                            setSnackbarText,
                            setSnackbarSeverity,
                            setSnackbarOpen,
                          }}
                          deleteEvent={deleteEvent}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditEvent
            currentBatch={currentBatch}
            event={event}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={openAdd}
        onClose={handleCloseAdd}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddSubEvent
            currentBatch={currentBatch}
            event={event}
            handleClose={handleCloseAdd}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={openAttendance}
        onClose={handleCloseAttendance}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddAttendance
            currentBatch={currentBatch}
            event={event}
            handleClose={handleCloseAttendance}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Modal
        open={openAudience}
        onClose={handleCloseAudience}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AudienceDetails
            currentBatch={currentBatch}
            eventId={event._id}
            eventData={event}
            eventWeek={event.week}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
            handleClose={handleCloseAudience}
          />
        </Box>
      </Modal>

      <Modal
        open={openMaster}
        onClose={handleCloseMaster}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <MasterEdit
            currentBatch={currentBatch}
            event={event}
            handleClose={handleCloseMaster}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default AllEventBody;

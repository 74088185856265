import React from 'react';
import { Typography, Box, Grid, Stack } from '@mui/material';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import QuizIcon from '@mui/icons-material/Quiz';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import EqualizerIcon from '@mui/icons-material/Equalizer';

const SecondPage = () => {
  return (
    <Box>
      <Typography mt={4}>
        The Employability Assessment enables the learner to evaluate their
        employability quotient based on 4 essential skills of workplace
        readiness- Business Communication, Observation & Analytics, Reasoning &
        Creativity and Self Awareness.
      </Typography>
      <Grid container spacing={3} my={1}>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
          >
            <StarBorderPurple500Icon
              fontSize='large'
              sx={{ color: '#10ADB9' }}
            />
            <Typography>Showcase Employability Proof Certificate</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
          >
            <StarBorderPurple500Icon
              fontSize='large'
              sx={{ color: '#10ADB9' }}
            />
            <Typography>Earn Employability Badge for LinkedIn</Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={1}
          >
            <StarBorderPurple500Icon
              fontSize='large'
              sx={{ color: '#10ADB9' }}
            />
            <Typography>
              Access Instant Internship/ Job Opportunities
            </Typography>
          </Stack>
        </Grid>
      </Grid>

      <Typography mt={6} mb={2} align='center' variant='h6'>
        We have reached to the end of the employability assessment process
      </Typography>
      <Grid container spacing={4} mb={10}>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <EventNoteIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Schedule Global Employability Masterclass with the Belong Team
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <HowToRegIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Student Registration & Dashboard Access via customized signup link
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <QuizIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Attempt 20 - min Employability Assessment with 4 individual
              sections
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <EmojiEventsIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Obtain Learners' University Ranking through Belong's instant
              employability leaderboard
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <PersonSearchIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Understand Cohort Profiling like learners' strengths, weaknesses
              and Employability Quotient
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <EqualizerIcon fontSize='large' sx={{ color: '#10ADB9' }} />
            <Typography align='center' variant='subtitle2'>
              Access Workplace Readiness Report to determine learners' needs for
              placement training
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default SecondPage;

import React, { useRef, useState } from 'react';

import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography, Stack } from '@mui/material';
import { TextInput } from '../../layouts/FormElement';
import { API_URL } from '../../../../links/API_LINKS';

const AddPartnerAdmin = ({ partner, handleClose }) => {
  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  return (
    <div>
      <Typography align='center' mb={4}>
        Add Admin for {partner.name}{' '}
      </Typography>

      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          partnerId: partner._id,
          name: '',
          email: '',
          phone: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Please enter a name'),
          email: Yup.string()
            .email('Email must be a valid email')
            .required('Email is a required field'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'POST',
            url: API_URL + '/partner/add-admin',
            data: values,
          })
            .then(function (response) {
              setServerResponse(response.data.msg);
              setSubmitting(false);
              handleClose();
            })
            .catch(function (error) {
              setServerResponse(error?.response?.data?.message || 'Error');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='name'
                  type='text'
                  label='Admin Name'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='email'
                  type='text'
                  label='Admin Email'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  size='small'
                  name='phone'
                  type='text'
                  label='Admin phone'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                {/* <Typography>Default Password : BelongIF@1234!</Typography> */}
              </Grid>
            </Grid>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Admin
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddPartnerAdmin;

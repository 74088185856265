import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import UserHeader from './UserHeader';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const UserData = ({ quizData }) => {
  const [allUser, setAllUser] = useState([]);
  // const usersArray = quizData.map((el)=>el.user_id);

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        quizData.map(async (user) => {
          const userDetail = await axios(API_URL + '/user', {
            params: { userId: user.user_id },
          });
          const finalObj = userDetail?.data?.data;
          return { ...finalObj, percentage: user.percentage };
        })
      );
      setAllUser(data);
    };

    fetchData();
  }, [quizData]);

  return (
    <div>
      {allUser.length ? (
        <UserHeader allUser={allUser} />
      ) : (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
    </div>
  );
};

export default UserData;

import React from 'react';
import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import BatchHeader from './BatchHeader';

const AllBatch = ({ setCurrentBatch }) => {
  const fetchAllBatch = async () => {
    const res = await axios.get(API_URL + '/batch/info');
    return res;
  };
  const { status, data } = useQuery('fetchAllBatch', fetchAllBatch);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <BatchHeader data={data.data.data} setCurrentBatch={setCurrentBatch} />
      )}
    </div>
  );
};

export default AllBatch;

import React from 'react';
import { Typography, Box, Stack } from '@mui/material';
import Chart from 'react-apexcharts';
import _ from 'lodash';

const CategoryPerformers = ({ users }) => {
  const assessmentGiven = users.filter((user) => {
    return (
      user.masterclassAssessment && user.masterclassAssessment.percentageScore
    );
  });
  const myUserData = assessmentGiven.map((user) => {
    return {
      name: user.name,
      CREATIVITY: user.masterclassAssessment.percentageScore.CREATIVITY,
      COMMUNICATION: user.masterclassAssessment.percentageScore.COMMUNICATION,
      ANALYTICAL_SKILLS:
        user.masterclassAssessment.percentageScore.ANALYTICAL_SKILLS,
      SELF_AWARENESS: user.masterclassAssessment.percentageScore.SELF_AWARENESS,
    };
  });

  const sortANALYTICAL_SKILLS = _.orderBy(
    myUserData,
    ['ANALYTICAL_SKILLS'],
    ['desc']
  );
  const sortCOMMUNICATION = _.orderBy(myUserData, ['COMMUNICATION'], ['desc']);
  const sortCREATIVITY = _.orderBy(myUserData, ['CREATIVITY'], ['desc']);
  const sortSELF_AWARENESS = _.orderBy(
    myUserData,
    ['SELF_AWARENESS'],
    ['desc']
  );
  const ANALYTICAL_SKILLSTop20 = sortANALYTICAL_SKILLS.filter(
    (ass, index) => index < 20
  );
  const ANALYTICAL_SKILLSoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: ANALYTICAL_SKILLSTop20.map((user) => user.name),
    },
    colors: ['#FEB019'],
  };
  const ANALYTICAL_SKILLSscores = [
    {
      name: 'series-1',
      data: ANALYTICAL_SKILLSTop20.map((user) => user.ANALYTICAL_SKILLS),
    },
  ];

  const COMMUNICATIONTop20 = sortCOMMUNICATION.filter(
    (ass, index) => index < 20
  );
  const COMMUNICATIONoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: COMMUNICATIONTop20.map((user) => user.name),
    },
    colors: ['#008FFB'],
  };
  const COMMUNICATIONSscores = [
    {
      name: 'series-1',
      data: COMMUNICATIONTop20.map((user) => user.COMMUNICATION),
    },
  ];

  const CREATIVITYTop20 = sortCREATIVITY.filter((ass, index) => index < 20);
  const CREATIVITYoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: CREATIVITYTop20.map((user) => user.name),
    },
    colors: ['#00E396'],
  };
  const CREATIVITYSscores = [
    {
      name: 'series-1',
      data: CREATIVITYTop20.map((user) => user.CREATIVITY),
    },
  ];

  const SELF_AWARENESSTop20 = sortSELF_AWARENESS.filter(
    (ass, index) => index < 20
  );

  const SELF_AWARENESSoptions = {
    chart: {
      id: 'apexchart-example',
    },
    xaxis: {
      categories: SELF_AWARENESSTop20.map((user) => user.name),
    },
    colors: ['#E14560'],
  };
  const SELF_AWARENESSscores = [
    {
      name: 'series-1',
      data: SELF_AWARENESSTop20.map((user) => user.SELF_AWARENESS),
    },
  ];
  return (
    <Stack direction='row' justifyContent='center' alignItems='center'>
      <Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>Self Awareness</Typography>
          <Chart
            options={SELF_AWARENESSoptions}
            series={SELF_AWARENESSscores}
            type='bar'
            width={1000}
            height={350}
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>Creativity</Typography>
          <Chart
            options={CREATIVITYoptions}
            series={CREATIVITYSscores}
            type='bar'
            width={1000}
            height={350}
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>Communication</Typography>
          <Chart
            options={COMMUNICATIONoptions}
            series={COMMUNICATIONSscores}
            type='bar'
            width={1000}
            height={350}
          />
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <Typography variant='h4'>Analytical Skills</Typography>
          <Chart
            options={ANALYTICAL_SKILLSoptions}
            series={ANALYTICAL_SKILLSscores}
            type='bar'
            width={1000}
            height={350}
            colors={'#E91E63'}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default CategoryPerformers;

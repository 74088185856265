import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const SidebarConfig = [
  {
    title: 'Home',
    path: '/admin',
    icon: getIcon(pieChart2Fill),
  },

  {
    title: 'Stakeholders',
    path: '/stakeholders',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Partner',
        path: '/stakeholders/partner',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Companies',
        path: '/stakeholders/companies',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Mentors',
        path: '/stakeholders/mentors',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Coach',
        path: '/stakeholders/coach',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Faculty',
        path: '/stakeholders/faculty',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'All Admin',
        path: '/stakeholders/alladmin',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },
  {
    title: 'Operations',
    path: '/ops',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Roles',
        path: '/ops/roles',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Invoice',
        path: '/ops/invoice',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Applications',
        path: '/ops/applications',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Materclass',
        path: '/ops/masterclass',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'B 2 C Users',
        path: '/ops/b2cusers',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Emails',
        path: '/ops/email',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Quiz Reset',
        path: '/ops/quiz-reset',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },
  {
    title: 'VGA',
    path: '/vga',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Batch',
        path: '/vga/batch',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Event Templates',
        path: '/vga/templates',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Task Templates',
        path: '/vga/task-templates',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Batch NPS',
        path: '/vga/nps',
        icon: getIcon(pieChart2Fill),
      },
      // {
      //   title: 'Assignment',
      //   path: '/vga/assignment',
      //   icon: getIcon(pieChart2Fill),
      // },
      // {
      //   title: 'Query',
      //   path: '/vga/query',
      //   icon: getIcon(pieChart2Fill),
      // },
      // {
      //   title: 'Feedback',
      //   path: '/vga/feedback',
      //   icon: getIcon(pieChart2Fill),
      // },
    ],
  },
  {
    title: 'Product Offerings Data',
    path: '/pod',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Areas',
        path: '/pod/areas',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Categories',
        path: '/pod/categories',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Programs',
        path: '/pod/programs',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Industries',
        path: '/pod/industries',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Skills',
        path: '/pod/skills',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Capstones',
        path: '/pod/capstones',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Supercapstones',
        path: '/pod/supercapstones',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Questions',
        path: '/pod/questions',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'B2C Questions',
        path: '/pod/b2cquestions',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Employability Questions',
        path: '/pod/erquestions',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'New Aptitude Questions',
        path: '/pod/aptquestions',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },
  {
    title: 'User Reports',
    path: '/reports',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'All User',
        path: '/reports/users',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Capstone',
        path: '/reports/capstone',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Bootcamp',
        path: '/reports/bootcamp',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'FSD Payment',
        path: '/reports/gsap',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Bootcamp23',
        path: '/reports/bootcamp23',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Offline Bootcamp',
        path: '/reports/offline-bootcamp',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Aic Reporting',
        path: '/reports/aic-report',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Goa CB',
        path: '/reports/goacb',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'FSD Bootcamp',
        path: '/reports/fsd-bootcamp',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Jupiter Event',
        path: '/reports/jupiter',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },

  {
    title: 'AIC Reports',
    path: '/aic',
    icon: getIcon(pieChart2Fill),
    children: [
      {
        title: 'Team',
        path: '/aic/team',
        icon: getIcon(pieChart2Fill),
      },
      {
        title: 'Assignment',
        path: '/aic/assignment',
        icon: getIcon(pieChart2Fill),
      },
    ],
  },
];

export default SidebarConfig;

import React from "react";
import { Card } from "react-bootstrap";
import {
  GrDocument,
  GrDocumentPpt,
  GrDocumentPdf,
  GrDocumentWord,
  GrDocumentExcel,
  GrDocumentTxt,
} from "react-icons/gr";

const renderThumbnail = (format) => {
  if(format === 'pdf') return <GrDocumentPdf style={{ width: "80px", height: "80px" }} />
  else if(['pptx', 'ppt'].includes(format)) return <GrDocumentPpt style={{ width: "80px", height: "80px" }} />
  else if(['docx', 'doc'].includes(format)) return <GrDocumentWord style={{ width: "80px", height: "80px" }} />
  else if(['xlsx'].includes(format)) return <GrDocumentExcel style={{ width: "80px", height: "80px" }} />
  else if(['txt'].includes(format)) return <GrDocumentTxt style={{ width: "80px", height: "80px" }} />
  else return <GrDocument style={{ width: "80px", height: "80px" }} />
}

const FileCard = (props) => {
  async function copyToClipboard(text) {
    await navigator.clipboard.writeText(text);
    alert("Link Copied");
  }

  const { file } = props;
  const { format, url } = file;

  return (
    <Card
      onClick={() => {
        copyToClipboard(url);
        props.setImage(file.url);
      }}
      title={url.split("/").slice(-1)[0]}
    >
      { renderThumbnail(format)}

      
      <Card.Body>
        <p className="truncate w-40"> {url.split("/").slice(-1)[0]}</p>
      </Card.Body>
    </Card>
  );
};

export default FileCard;

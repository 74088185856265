import React, { useState } from 'react';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';

const FoldersList = (props) => {
  const { setPrev, setNext } = props;
  const { data } = props.data;
  const [selectedFolder, setSelectedFolder] = useState(null);
  const changeFolder = (path) => props.changeFolderParent(path);

  return (
    <>
      <ButtonGroup toggle>
        <ToggleButton
          type='checkbox'
          checked={selectedFolder === null}
          value='raw'
          onChange={(e) => {
            setSelectedFolder(null);
            changeFolder(null);
            setPrev([]);
            setNext(null);
          }}
        >
          <span className='m-1'>All</span>
        </ToggleButton>
      </ButtonGroup>
      {data.data.map((folder) => {
        return (
          <>
            <ButtonGroup toggle className='flex  text-sm'>
              <ToggleButton
                className='ml-1'
                type='checkbox'
                checked={selectedFolder === folder.name}
                value={folder.name}
                onChange={(e) => {
                  setSelectedFolder(e.currentTarget.value);
                  changeFolder('/' + e.currentTarget.value);
                  setPrev([]);
                  setNext(null);
                }}
              >
                <span className='m-1'>{folder.name}</span>
              </ToggleButton>
            </ButtonGroup>
          </>
        );
      })}
    </>
  );
};

export default FoldersList;

import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button, Modal, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddPartnerAdmin from './AddPartnerAdmin';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  height: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function PartnerBody({ row, setPartnerToEdit }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left'> {row.name} </TableCell>
        <TableCell align='left'>{row.country} </TableCell>

        <TableCell align='center'>
          <Button
            onClick={() => {
              setPartnerToEdit(row);
            }}
          >
            <EditIcon sx={{ color: 'primary.main' }} />{' '}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            onClick={() => {
              setOpen(true);
            }}
          >
            Add Admin
          </Button>
        </TableCell>
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddPartnerAdmin handleClose={handleClose} partner={row} />
        </Box>
      </Modal>
    </>
  );
}

export default PartnerBody;

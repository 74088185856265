import React, { useState } from 'react';
import { Grid } from '@mui/material';
import axios from 'axios';
import { useQuery } from 'react-query';

import Applicants from '../../applicants';
import Header from './Header';
import { API_URL_V1 } from '../../../../../links/API_LINKS';

const Index = () => {
  const [reportTime, setReportTime] = useState('TODAY');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const fetchApplications = async (keys) => {
    const res = await axios.get(API_URL_V1 + '/application', {
      params: {
        appliedOnStartDate: keys.queryKey[1],
        appliedOnEndDate: keys.queryKey[2],
      },
    });
    return res;
  };
  const { status, data } = useQuery(
    [`fetchApplicationsCustom`, startDate, endDate],
    fetchApplications
  );

  let reportSummary = {
    totalUsers: 0,
    totalOrganic: 0,
    totalApplications: 0,
    organicApplications: 0,
  };
  function makeSummaryData() {
    if (status === 'success') {
      let uniqueUsers = new Set();
      let organicUsers = new Set();
      const allApplications = data.data;
      allApplications.forEach((application) => {
        reportSummary.totalApplications += 1;
        uniqueUsers.add(application.userId._id);
        if (!application.userId.partnerId) {
          //Not a partner user
          reportSummary.organicApplications += 1;
          organicUsers.add(application.userId._id);
        }
      });
      reportSummary.totalUsers = uniqueUsers.size;
      reportSummary.totalOrganic = organicUsers.size;
    }
  }

  makeSummaryData();
  return (
    <Grid container spacing={3}>
      <Header
        reportTime={reportTime}
        setReportTime={setReportTime}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        reportSummary={reportSummary}
      />
      <Grid item xs={12} mt={4}>
        {status === 'loading' && 'Loading...'}
        {status === 'success' && <Applicants customData={data.data} />}
      </Grid>
    </Grid>
  );
};

export default Index;

import React, { useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Stack, Typography, Button, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { API_URL } from 'links/API_LINKS';
import { useQueryClient } from 'react-query';

const Reply = (props) => {
  const queryClient = useQueryClient();
  const ReplySchema = Yup.object().shape({
    comment: Yup.string().required('Comment is required'),
  });

  const formik = useFormik({
    initialValues: {
      ticketId: props.ticketId,
      comment: '',
      owner: 'belong',
      status: props.status,
    },
    validationSchema: ReplySchema,
    onSubmit: () => {
      axios({
        method: 'PUT',
        url: API_URL + '/ticket/comment',
        data: values,
      })
        .then(function (response) {
          setSubmitting(false);
          queryClient.invalidateQueries(`fetchAllTickets${props.batchId}`);
          props.setTicketDetails(null);
        })
        .catch(function (response) {
          setSubmitting(false);
        });
    },
  });
  const { isSubmitting, setSubmitting, values, handleSubmit, setFieldValue } =
    formik;
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      magicUrl: true,
      toolbar: [
        ['bold'],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }],
        // ['clean'],
      ],
    },
    // formats: ['bold', 'list', 'link', 'color', 'clean'],
    formats: ['bold', 'list', 'link', 'color'],
  });
  useEffect(() => {
    if (quill) {
      quill.on('text-change', () => {
        setFieldValue('comment', quill.root.innerHTML);
      });
    }
  }, [quill, quillRef, setFieldValue]);

  if (Quill && !quill) {
    const MagicUrl = require('quill-magic-url').default;
    Quill.register('modules/magicUrl', MagicUrl);
  }

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
          <Stack spacing={3} sx={{ my: 2 }}>
            <Box sx={{ height: 250 }} mb={3}>
              <div ref={quillRef} />
            </Box>
          </Stack>
          <Stack sx={{ p: 3 }} direction='row' alignItems='center' spacing={4}>
            <Typography>Do you want to close this ticket?</Typography>
            <Button
              variant={values.status === 'closed' ? 'contained' : 'outlined'}
              onClick={() => setFieldValue('status', 'closed')}
            >
              YES
            </Button>
            <Button
              variant={values.status === 'open' ? 'contained' : 'outlined'}
              onClick={() => setFieldValue('status', 'open')}
            >
              NO
            </Button>
          </Stack>

          <LoadingButton
            fullWidth
            size='large'
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            Reply
          </LoadingButton>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default Reply;

import React, { useState } from 'react';
// import axios from 'axios';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { useFormikContext } from 'formik';

import {
  Box,
  Modal,
  Grid,
  MenuItem,
  Button,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import ShowFilesPopup from '../../halper/showFilesPopup';

import TextEditor from 'components/_dashboard/pod/capstones/TextEditor';
import Multiselect from 'multiselect-react-dropdown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const skillsByCategory = {
  COMMUNICATION: [
    'Effective Communication',
    'Emotional Intelligence and Empathy',
    'Conflict Resolution and Negotiation Skills',
    'Self-Awareness and Growth Mindset',
    'Non-verbal and Visual Communication',
    'Adaptability in Communication',
  ],
  COLLABORATION: [
    'Teamwork',
    'Trustworthiness',
    'Initiative',
    'Leadership',
    'Organization Skills',
    'Networking',
    'Relationship Building',
  ],
  MINDSET: [
    'Reasoning',
    'Creativity',
    'Problem Solving',
    'Attention to Detail',
    'Customer Focus',
  ],
  WORKLIFE: [
    'Self-Motivation',
    'Time Management',
    'Adaptability',
    'Decision-making',
    'Ownership/ Accountability',
    'Prioritization',
  ],
};

const QuestionForm = ({
  values,
  setFieldValue,
  preSkills,
  questionPreValue,
  optionsPreValue,
}) => {
  //   const formikRef = useRef();
  //   const [serverResponse, setServerResponse] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  //   const [sections, setSections] = useState(1);
  const [selectedSkills, setSelectedSkills] = useState(preSkills || []);
  const { setFieldValue: formikSetFieldValue } = useFormikContext();

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }

  return (
    <Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
        mb={3}>
        <Grid item xs={12} sm={5}>
          <SelectInput
            size='small'
            name='employabilityCategory'
            type='text'
            label='Question Category'>
            <MenuItem value='COMMUNICATION'>
              Communication and Interpersonal Effectiveness
            </MenuItem>
            <MenuItem value='COLLABORATION'>
              Collaboration and Team Dynamics
            </MenuItem>
            <MenuItem value='MINDSET'>Solutions-Oriented Mindset </MenuItem>
            <MenuItem value='WORKLIFE'>Worklife Integration </MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Multiselect
            isObject={true}
            selectedValues={selectedSkills}
            displayValue='name'
            placeholder='Select Skills'
            options={skillsByCategory[values.employabilityCategory].map(
              (skill) => ({ name: skill, id: skill })
            )}
            onSelect={(selectedList) => {
              setSelectedSkills(selectedList);
              // Update options with new ratings based on selected skills
              const updatedOptions = values.options.map((option) => ({
                ...option,
                ratings: selectedList.map((skill) => ({
                  skill: skill.name,
                  score: 1,
                })),
              }));
              formikSetFieldValue('options', updatedOptions);
            }}
            onRemove={(selectedList) => {
              setSelectedSkills(selectedList);
              // Update options with new ratings based on selected skills
              const updatedOptions = values.options.map((option) => ({
                ...option,
                ratings: selectedList.map((skill) => ({
                  skill: skill.name,
                  score: 1,
                })),
              }));
              formikSetFieldValue('options', updatedOptions);
            }}
          />
        </Grid>
        {/* time in seconds */}
        <Grid item xs={4} sm={2}>
          <TextInput
            size='small'
            label='Time in seconds'
            name='time'
            type='number'
          />
        </Grid>

        <Grid item xs={9}>
          <Typography variant='h6'>Question</Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={questionPreValue || ''}
            valueToUpdate='question'
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item>
            {[...Array(1)].map((e, i) => (
              <Grid item key={i}>
                <FieldArray
                  name='image'
                  render={(arrayHelpers) => (
                    <>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}>
                        Add question image
                        <Button
                          type='button'
                          onClick={() => arrayHelpers.push()}>
                          <FaPlusCircle />
                        </Button>
                        {values.image.map((content, index) => {
                          return (
                            <Grid item key={index}>
                              <Grid
                                item
                                sm={12}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                <TextInput
                                  name={`image[${index}]`}
                                  type='text'
                                  placeholder='Image URL'
                                />
                                <Grid item>
                                  <Button
                                    type='button'
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedField(`image[${index}]`);
                                      setRefresh(!refresh);
                                    }}>
                                    Select File
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <img
                                    src={values.image[index]}
                                    alt=''
                                    width='150'
                                  />
                                  <Grid item>
                                    <Button
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }>
                                      <FaTrashAlt />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <SelectInput
            name='maxSelect'
            onChange={(e) => {
              if (e.target.value > 1) {
                const updatedOptions = Array.from(
                  { length: e.target.value },
                  (_, i) => i + 1
                ).map((opt) => ({
                  correctAnswers: opt,
                  ratings: selectedSkills.map((skill) => ({
                    skill: skill.name,
                    score: 1,
                  })),
                }));
                formikSetFieldValue('multiSelectRatings', updatedOptions);
              }
              formikSetFieldValue('maxSelect', e.target.value);
            }}
            label='Maximum options a user can select'>
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={2}>2</MenuItem>
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={4}>4</MenuItem>
          </SelectInput>
        </Grid>
      </Grid>
      {values.maxSelect > 1 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FieldArray
              name='options'
              render={(arrayHelpers) => (
                <>
                  {values.options.map((option, index) => (
                    <>
                      <Grid item xs={12}>
                        <Typography variant='h6'>
                          Option - {index + 1}{' '}
                        </Typography>
                        <TextEditor
                          setFieldValue={setFieldValue}
                          initialtext={
                            (optionsPreValue &&
                              optionsPreValue[index]?.option) ||
                            ''
                          }
                          valueToUpdate={`options[${index}].option`}
                        />
                      </Grid>
                      <SelectInput
                        size='small'
                        name={`options[${index}].value`}
                        label='Value'>
                        <MenuItem value={0}>0</MenuItem>
                        <MenuItem value={1}>1</MenuItem>
                      </SelectInput>
                      <IconButton
                        color='error'
                        onClick={() => arrayHelpers.remove(index)}>
                        <FaTrashAlt />
                      </IconButton>
                    </>
                  ))}
                  <Stack direction='row' spacing={2} alignItems='center' mt={2}>
                    <Button
                      variant='contained'
                      startIcon={<FaPlusCircle />}
                      onClick={() => {
                        arrayHelpers.push({
                          option: '',
                          value: 0,
                        });
                      }}>
                      Add Option
                    </Button>
                  </Stack>
                </>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FieldArray
              name='multiSelectRatings'
              render={(arrayHelpers) => (
                <>
                  {values.multiSelectRatings.map((option, index) => (
                    <Box
                      key={index}
                      sx={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        padding: '16px',
                        marginTop: '16px',
                        backgroundColor: '',
                      }}>
                      <Grid container spacing={2} alignItems='center'>
                        <Grid item xs={12}>
                          <Typography variant='h6'>
                            Correct Answer: {option.correctAnswers}
                          </Typography>
                        </Grid>

                        <Grid item xs={12}>
                          <Grid container spacing={3}>
                            {option.ratings?.map((rating, ratingIndex) => (
                              <Grid
                                item
                                xs={6}
                                sm={4}
                                md={2.2}
                                key={ratingIndex}>
                                <Box>
                                  <Typography
                                    sx={{ mb: 1 }}
                                    variant='subtitle2'>
                                    {rating.skill}
                                  </Typography>
                                  <SelectInput
                                    size='small'
                                    name={`multiSelectRatings[${index}].ratings[${ratingIndex}].score`}
                                    label='Rating'>
                                    {[1, 2, 3, 4, 5].map((value) => (
                                      <MenuItem key={value} value={value}>
                                        {value}
                                      </MenuItem>
                                    ))}
                                  </SelectInput>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </>
              )}
            />{' '}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={1} direction='column'>
          <FieldArray
            name='options'
            render={(arrayHelpers) => (
              <>
                {values.options.map((option, index) => (
                  <Box
                    key={index}
                    sx={{
                      border: '1px solid #ccc',
                      borderRadius: '8px',
                      padding: '16px',
                      marginTop: '16px',
                      backgroundColor: '',
                    }}>
                    <Grid container spacing={2} alignItems='center'>
                      <Grid item xs={12}>
                        <Typography variant='h6'>
                          Option - {index + 1}{' '}
                        </Typography>
                        <TextEditor
                          setFieldValue={setFieldValue}
                          initialtext={
                            (optionsPreValue &&
                              optionsPreValue[index]?.option) ||
                            ''
                          }
                          valueToUpdate={`options[${index}].option`}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Grid container spacing={3}>
                          {option.ratings?.map((rating, ratingIndex) => (
                            <Grid item xs={6} sm={4} md={2.2} key={ratingIndex}>
                              <Box>
                                <Typography sx={{ mb: 1 }} variant='subtitle2'>
                                  {rating.skill}
                                </Typography>
                                <SelectInput
                                  size='small'
                                  name={`options[${index}].ratings[${ratingIndex}].score`}
                                  label='Rating'>
                                  {[1, 2, 3, 4, 5].map((value) => (
                                    <MenuItem key={value} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </SelectInput>
                              </Box>
                            </Grid>
                          ))}
                        </Grid>
                      </Grid>

                      <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <IconButton
                          color='error'
                          onClick={() => arrayHelpers.remove(index)}>
                          <FaTrashAlt />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Box>
                ))}

                <Stack direction='row' spacing={2} alignItems='center' mt={2}>
                  <Button
                    variant='contained'
                    startIcon={<FaPlusCircle />}
                    onClick={() =>
                      arrayHelpers.push({
                        option: '',
                        ratings: selectedSkills.map((skill) => ({
                          skill: skill.name,
                          score: 1,
                        })),
                      })
                    }>
                    Add Option
                  </Button>
                </Stack>
              </>
            )}
          />
        </Grid>
      )}

      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default QuestionForm;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import QuestionForm from './QuestionForm';
import { useQueryClient } from 'react-query';

const CreateNewQuestion = ({ setValue }) => {
  const queryClient = useQueryClient();

  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  return (
    <div>
      <Box>
        <Typography variant='h6'>Add a new Question</Typography> <br />
      </Box>
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            type: 'APTITUDE',
            question: '',
            aptitudeCategory: 'COMMUNICATION',
            image: [],
            options: [],
            maxSelect: 1,
            time: '',
          }}
          validationSchema={Yup.object({
            question: Yup.string().required('Please enter a Question'),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const updatedData = { ...values }; //new added

            setServerResponse('');
            axios({
              method: 'POST',
              url: API_URL + '/question',
              data: updatedData,
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchQuestion');
                setServerResponse('Question added successfully');

                setSubmitting(false);
                resetForm();
                setValue(0);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}>
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <QuestionForm values={values} setFieldValue={setFieldValue} />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}>
                  Add Question
                </LoadingButton>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}>
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewQuestion;

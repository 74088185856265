import React, { useState } from 'react';
import CsvDownload from 'react-json-to-csv';
import { Stack, Button, Typography, Table, Card, Chip } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import EditAttendance from './EditAttendance';

const AttendanceDetails = ({
  attendance,
  snackbarActions,
  handleClose,
  eventId,
}) => {
  const [isEdit, setEdit] = useState(false);
  attendance = attendance.sort((a, b) => {
    return b.status - a.status;
  });
  const csvData = attendance.map((user, index) => {
    let temp = {};
    temp['S. No'] = index + 1;
    temp['Email'] = user.email;
    temp['Name'] = user.name;
    temp['Attendance'] = user.status ? 'Present' : 'Absent';
    temp['Duration Attended'] = (user.percent || 0) + '%';
    return temp;
  });
  return (
    <div>
      {isEdit ? (
        <EditAttendance
          setEdit={setEdit}
          attendance={attendance}
          snackbarActions={snackbarActions}
          handleClose={handleClose}
          eventId={eventId}
        />
      ) : (
        <>
          {' '}
          <Stack
            direction='row'
            justifyContent='end'
            alignItems='center'
            spacing={4}
            mb={2}
            mt={2}
          >
            <Button onClick={() => setEdit(true)}>Edit Attendance</Button>
          </Stack>
          <Stack
            direction='row'
            justifyContent='center'
            alignItems='center'
            spacing={4}
            mb={2}
            mt={2}
          >
            <Typography variant='h6'>
              {attendance.length} users attendance added
            </Typography>
            <CsvDownload data={csvData}>
              <Button variant='outlined'> Download Attendance</Button>
            </CsvDownload>
          </Stack>
          <TableContainer component={Card}>
            <Table
              size='small'
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align='left'> Email</TableCell>
                  <TableCell align='left'> Attendance </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {csvData.map((user, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{user.Name}</TableCell>
                      <TableCell align='left'> {user.Email}</TableCell>
                      <TableCell align='left'>
                        <Chip
                          color={
                            user.Attendance === 'Present' ? 'primary' : 'error'
                          }
                          size='small'
                          label={user.Attendance}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </div>
  );
};

export default AttendanceDetails;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
// import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../../links/API_LINKS';
import SuperCapstoneForm from './SuperCapstoneForm';

const CreateNewSuperCapstone = ({
  setCapStoneEditData,
  capstoneData,
  setActiveStep,
  setSuperCapstoneId,
  superCapstoneId,
}) => {
  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  return (
    <div className=''>
      <Box>
        <Typography variant='h6'>Add a new capstone</Typography> <br />
      </Box>
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={capstoneData}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            domainId: Yup.string().required('Domain is required'),
            companyId: Yup.string().required('Company is required'),
            level: Yup.string().required(),
            skillId: Yup.array().min(1, 'Minimum 1 skill required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            learningObjectives: Yup.string().required(
              'learning Objectives is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            // introductoryVideo: Yup.string().url(),
            prerequisites: Yup.string(),
            tools: Yup.string(),
            dataset: Yup.object().shape({
              link: Yup.string().url('Dataset link must be a valid URL'),
              description: Yup.string().when('link', {
                is: (link) => link && link.trim() !== '',
                then: Yup.string().required(
                  'Dataset description is required when dataset link is provided'
                ),
                otherwise: Yup.string(),
              }),
            }),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setServerResponse('');

            const sendMethod = superCapstoneId ? 'PUT' : 'POST';

            const dataToSend = superCapstoneId
              ? { ...values, superCapstoneId }
              : values;

            axios({
              method: sendMethod,
              url: API_URL + '/super-capstone/',
              data: dataToSend,
            })
              .then(async function (response) {
                setServerResponse('Capstone Added...');

                setSuperCapstoneId(response.data.supercapstone._id);
                setCapStoneEditData(response.data.supercapstone);

                // resetForm();
                setSubmitting(false);
                // setValue(0);
                setActiveStep(1);
              })
              .catch(function (err) {
                console.log(err);
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}>
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <SuperCapstoneForm
                capstoneData={capstoneData}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}>
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}>
                  Save{' '}
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewSuperCapstone;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  TableRow,
  TableCell,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import { format } from 'date-fns';

import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchOrder = async (params) => {
  const { limit, page, sort, order, paymentStatus } = params;
  const matchObj = {
    limit,
    page,
    sort,
    order,
    orderType: 'FSD',
    createdAt: '2024-10-01T14:47:35.057+00:00',
  };
  if (paymentStatus === 'Paid') matchObj.status = paymentStatus;
  const res = await axios.get(API_URL + '/order', {
    params: matchObj,
  });

  return res;
};

function Index() {
  const [paymentStatus, setPaymentStatus] = useState('All');
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'createdAt',
    order: 'desc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchOrder`, paginationData, paymentStatus],
    () => fetchOrder({ ...paginationData, paymentStatus }),
    { keepPreviousData: true }
  );

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Sr No', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'phone', label: 'Phone', align: 'left' },
    { id: 'createdAt', label: 'Registration Time', align: 'left' },
    { id: 'paymentDate', label: 'Payment Date', align: 'left' },
    { id: 'couponCode', label: 'couponCode', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          <Typography m={2}>FSD Payment </Typography>
          <FormControl>
            <InputLabel id='demo-simple-select-label'>
              Select Bootcamp
            </InputLabel>
            <Select
              sx={{ maxWidth: 350, minWidth: 300 }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={paymentStatus}
              label='Select Partner'
              onChange={(e) => setPaymentStatus(e.target.value)}>
              <MenuItem value={'All'}>All</MenuItem>
              <MenuItem value={'Paid'}>Paid</MenuItem>
            </Select>
          </FormControl>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
              size='small'>
              <TableListHead
                order={paginationData.order}
                orderBy={paginationData.sort}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {data.data.data.map((row, index) => (
                  <TableRow
                    key={row._id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell align='left'>{index + 1} </TableCell>
                    <TableCell align='left'>{row.name} </TableCell>
                    <TableCell align='left'> {row.email} </TableCell>
                    <TableCell align='left'> {row.phone} </TableCell>
                    <TableCell align='left'>
                      {' '}
                      {row.createdAt &&
                        format(new Date(row.createdAt), 'do LLLL yyyy')}{' '}
                    </TableCell>
                    <TableCell align='left'>
                      {' '}
                      {row.paymentDate &&
                        format(new Date(row.paymentDate), 'do LLLL yyyy')}{' '}
                    </TableCell>
                    <TableCell align='left'>{row.couponCode} </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[
                ...new Set([paginationData.limit, 5, 10, 50]),
              ]}
              component='div'
              count={data.data.totalCount}
              rowsPerPage={paginationData.limit}
              page={paginationData.page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{
                disabled:
                  isFetching || data.data.page === data.data.totalPages - 1,
              }}
            />
          </TableContainer>
        </>
      )}
    </>
  );
}

export default Index;

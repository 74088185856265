import React from 'react';
import { format } from 'date-fns';
import { Chip, useTheme } from '@mui/material';

import { TableRow, TableCell } from '@mui/material';

const AllTicketBody = ({ setTicketDetails, ticket }) => {
  const theme = useTheme();

  const startTime = new Date(ticket.createdAt);
  const nowTime = new Date();
  const timediff = Math.abs(startTime - nowTime) / 3600000;
  const onWarning = ticket.status === 'open' && timediff > 18 && timediff < 24;
  const lateWarning = ticket.status === 'open' && timediff > 24;

  return (
    <>
      <TableRow
        key={ticket.ticketNumber}
        onClick={() => setTicketDetails(ticket)}
        sx={{ cursor: 'pointer' }}
      >
        <TableCell>{ticket.subject}</TableCell>
        <TableCell align='center'>
          {ticket.department === 'Belong' && (
            <Chip
              variant='filled'
              label={ticket.department}
              sx={{
                background: theme.palette.gradients.primary,
              }}
            />
          )}
          {ticket.department === 'Coach' && (
            <Chip
              variant='filled'
              sx={{
                background: theme.palette.gradients.warning,
              }}
              label={ticket.department}
            />
          )}
          {ticket.department === 'Mentor' && (
            <Chip
              variant='filled'
              sx={{
                background: theme.palette.gradients.error,
                color: 'white',
              }}
              label={ticket.department}
            />
          )}
        </TableCell>
        <TableCell align='center'>
          <Chip
            variant='filled'
            label={ticket.status}
            color='default'
            sx={{
              backgroundColor: onWarning
                ? theme.palette.warning.light
                : lateWarning
                ? theme.palette.error.main
                : theme.palette.grey[400],
              color: lateWarning && 'white',
            }}
          />
        </TableCell>
        <TableCell align='center'>
          {format(new Date(ticket.createdAt), 'do LLLL yyyy')}
        </TableCell>
      </TableRow>
    </>
  );
};

export default AllTicketBody;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

function ProgramBody({ row, setProgramToEdit }) {
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell align='left'> {row.title} </TableCell>
        <TableCell align='left'>{row.area.title} </TableCell>

        <TableCell align='center'>
          <Button
            onClick={() => {
              setProgramToEdit(row);
            }}
          >
            <EditIcon sx={{ color: 'primary.main' }} />{' '}
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default ProgramBody;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow, TablePagination } from '@mui/material';
import { Card, Link } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';

function CompanyUser({ companyData }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Card} sx={{ marginTop: '30px' }}>
      <Table
        sx={{ minWidth: 550, shadow: 2, padding: '6px' }}
        aria-label='table'
        size='small'
      >
        <TableHead>
          <TableRow>
            <TableCell align='left'></TableCell>
            <TableCell align='left'>Company</TableCell>
            <TableCell align='center'>Country</TableCell>
            <TableCell align='center'>Students Engaged</TableCell>
            <TableCell align='center'> Website</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {companyData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return (
                <TableRow hover key={row._id} sx={{ cursor: 'pointer' }}>
                  <TableCell align='left'>
                    <img
                      alt={row.name}
                      src={row.logoUrl}
                      component='img'
                      height='30'
                    />
                  </TableCell>
                  <TableCell align='left'>{row.name}</TableCell>
                  <TableCell align='center'>{row.country}</TableCell>
                  <TableCell align='center'>{row.userCount}</TableCell>
                  <TableCell align='center'>
                    <Link
                      href={row.website}
                      target='_blank'
                      rel='noreferrer'
                      underline='hover'
                    >
                      <LinkIcon />
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component='div'
        count={companyData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default CompanyUser;

import React, { useRef, useState } from 'react';
import {
  TextInput,
  DateTimePickerField,
  SelectInput,
  CheckBoxField,
} from '../../../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography, Button, Modal } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import ShowFilesPopup from '../../../../halper/showFilesPopup';
import { getAllCompany, getAllCoach, getAllArea } from '../helper';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddOtherEvent = ({ currentBatch, snackbarActions, handleClose }) => {
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  const queryClient = useQueryClient();
  const batchUser = currentBatch.users;
  let allCoach = getAllCoach(batchUser);
  let allCompany = getAllCompany(batchUser);
  let allArea = getAllArea(batchUser);

  const [open, setOpen] = useState(false);
  const handleCloseImage = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);

  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }
  return (
    <div>
      <Typography align='center' mb={2}>
        Add new Event
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          batch: currentBatch._id,
          partner: currentBatch.partner,
          title: '',
          type: 'Coach Connect',
          description: '',
          image: '',
          link: '',
          journies: {
            day: 1,
            journeyDuration: '5 weeks',
            journeyType: 'School/DT Journey',
            week: 1,
          },
          eventDate: new Date(),
          onlineOrOffline: 'Online',
          status: 'publish',
          resources: [],
          subEvents: [
            {
              title: '',
              link: '',
              eventDate: new Date(),
              status: 'publish',
              companyId: null,
              areaId: null,
              coachId: null,
              pollingEvent: false,
            },
          ],
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('title is required'),
          description: Yup.string().required('Description is required'),
          image: Yup.string().url().required('image is required'),
          journies: Yup.object().shape({
            week: Yup.number().required('Week is required'),
          }),
          resources: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
              link: Yup.string().url().required('Link is required'),
            })
          ),
          subEvents: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('title is required'),
              link: Yup.string().url().required('link is required'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const subEventUpdate = values.subEvents.map((item) => {
            return {
              batch: values.batch,
              type: values.type,
              description: values.description,
              journies: values.journies,
              image: values.image,
              onlineOrOffline: values.onlineOrOffline,
              resources: values.resources,
              ...item,
            };
          });
          const myData = { ...values, subEvents: subEventUpdate };
          axios({
            method: 'POST',
            url: API_URL + '/event/add-multiple',
            data: [myData],
          })
            .then(function (response) {
              setSnackbarText('Event added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${currentBatch._id}`
              );
              queryClient.invalidateQueries(
                `fetchCurrentBatch${currentBatch._id}`
              );
              handleClose();
            })
            .catch(function (response) {
              setSnackbarText(
                'Failed to add Events, Please try after sometime'
              );
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='type'
                  label='Event Type'
                >
                  <MenuItem value='Coach Connect'>Coach Connect</MenuItem>
                  <MenuItem value='Mentor Connect'>Mentor Connect</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='title'
                  type='text'
                  label='Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  name='journies.week'
                  type='number'
                  label='Week'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  multiline
                  rows={3}
                  name='description'
                  type='text'
                  label='Description'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='image'
                  type='text'
                  label='Image'
                />
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                    setSelectedField('image');
                    setRefresh(!refresh);
                  }}
                >
                  Select Image
                </Button>
                <div>
                  <img src={values.image} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePickerField
                  name='eventDate'
                  value={values.eventDate}
                  label='Event Date'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name='resources'
                  render={(arrayHelpers) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography variant='h6'>Resources</Typography>

                        <Button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              link: '',
                            })
                          }
                        >
                          +ADD
                        </Button>
                      </div>
                      {values.resources.map((content, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                              mb={3}
                            >
                              {' '}
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].link`}
                                  type='text'
                                  label='Link'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].title`}
                                  type='text'
                                  label='Title'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box>
              <FieldArray
                name='subEvents'
                render={(arrayHelpers) => (
                  <>
                    <div>
                      <Button
                        type='button'
                        onClick={() =>
                          arrayHelpers.push({
                            title: '',
                            link: '',
                            eventDate: new Date(),
                            status: 'publish',
                            companyId: null,
                            areaId: null,
                            coachId: null,
                          })
                        }
                      >
                        +ADD
                      </Button>

                      {values.subEvents.map((content, index) => {
                        return (
                          <Box
                            key={index}
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid container spacing={1}>
                              {' '}
                              <Grid item xs={10}>
                                <Grid container spacing={1}>
                                  <Grid item xs={12} sm={6}>
                                    <TextInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].title`}
                                      type='text'
                                      label='Title'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <TextInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].link`}
                                      type='text'
                                      label='Link'
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <DateTimePickerField
                                      name={`subEvents[${index}].eventDate`}
                                      value={values.subEvents[index].eventDate}
                                      label='Event Date'
                                      onChange={setFieldValue}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={6}>
                                    <SelectInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].status`}
                                      label='Status'
                                    >
                                      <MenuItem value='publish'>
                                        Publish
                                      </MenuItem>
                                      <MenuItem value='draft'>Draft</MenuItem>
                                    </SelectInput>
                                  </Grid>
                                  <Grid item xs={12} sm={4}>
                                    <CheckBoxField
                                      name={`subEvents[${index}].pollingEvent`}
                                      label='Polling in Event'
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <SelectInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].coachId`}
                                      label='Coach'
                                    >
                                      <MenuItem value={null}>All</MenuItem>
                                      {allCoach.map((coach) => {
                                        return (
                                          <MenuItem
                                            value={coach._id}
                                            key={coach._id}
                                          >
                                            {coach.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </SelectInput>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <SelectInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].companyId`}
                                      label='Company'
                                    >
                                      <MenuItem value={null}>All</MenuItem>
                                      {allCompany.map((company) => {
                                        return (
                                          <MenuItem
                                            value={company._id}
                                            key={company._id}
                                          >
                                            {company.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </SelectInput>
                                  </Grid>
                                  <Grid item xs={12} sm={6}>
                                    <SelectInput
                                      size='small'
                                      fullWidth
                                      name={`subEvents[${index}].areaId`}
                                      label='Area'
                                    >
                                      <MenuItem value={null}>All</MenuItem>
                                      {allArea.map((area) => {
                                        return (
                                          <MenuItem
                                            value={area._id}
                                            key={area._id}
                                          >
                                            {area.title}
                                          </MenuItem>
                                        );
                                      })}
                                    </SelectInput>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                {index > 0 && (
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                )}
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </div>
                  </>
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Event
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Modal
        open={open}
        onClose={handleCloseImage}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ShowFilesPopup
            setOpen={setOpen}
            resource_type={'image'}
            setImage={setImage}
            setSelectedField={setSelectedField}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddOtherEvent;

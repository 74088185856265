import React, { useState } from 'react';

import AddTeam from './AddTeam';
import AllAdmins from './AllAdmins';
import EditTeam from './EditTeam';

const Index = () => {
  const [showAllAdmin, setShowAllAdmin] = useState('ALL');
  const [editToAdmin, setEditToAdmin] = useState({});
  return (
    <div>
      {showAllAdmin === 'ALL' && (
        <AllAdmins
          setShowAllAdmin={setShowAllAdmin}
          setEditToAdmin={setEditToAdmin}
        />
      )}
      {showAllAdmin === 'ADD' && (
        <AddTeam
          setShowAllAdmin={setShowAllAdmin}
          setEditToAdmin={setEditToAdmin}
        />
      )}
      {showAllAdmin === 'EDIT' && (
        <EditTeam
          setShowAllAdmin={setShowAllAdmin}
          setEditToAdmin={setEditToAdmin}
          adminData={editToAdmin}
        />
      )}
    </div>
  );
};

export default Index;

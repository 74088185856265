import { Box, Modal, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import Prompt from '../../../misc/Prompt';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { supabase } from 'links/supabaseConfig';
import Snackbar from '../../../misc/Snackbar';
import EditTeam from './EditTeam';
import EditIcon from '@mui/icons-material/Edit';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const TeamBody = ({ team, userData, setRefetch, allTeamData }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const deleteTemplate = async () => {
    const { error } = await supabase
      .from('team')
      .delete()
      .match({ id: team.id });

    if (error) {
      setSnackbarText('Something went wrong');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } else {
      setRefetch((pre) => !pre);
      setSnackbarText('Deleting  Team...');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align='left'> {team.team_name} </TableCell>
        <TableCell align='left'> {team.users?.length} </TableCell>
        {/* <TableCell align='left'> </TableCell> */}
        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={handleOpen}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Assignment template?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteTemplate();
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditTeam
            handleClose={handleClose}
            userData={userData}
            team={team}
            setRefetch={setRefetch}
            allTeamData={allTeamData}
          />
        </Box>
      </Modal>
    </>
  );
};

export default TeamBody;

import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { supabase } from 'links/supabaseConfig';
import React, { useState } from 'react';

const EditTeam = ({ team, userData, setRefetch, handleClose, allTeamData }) => {
  const [addMore, setAddMore] = useState(false);
  const [teamName, setTeamName] = useState(team.team_name);
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const teamUser = team.users.map((userId) => {
    const userFullData = userData.find(({ _id }) => _id === userId);
    return userFullData;
  });

  const [selectedUser, setSelectedUser] = useState(teamUser);
  const [otherSelectedUser, setOtherSelectedUser] = useState([]);

  const editTeamFn = async () => {
    setMessage('');
    setLoading(true);

    const { data, status } = await supabase
      .from('team')
      .select()
      .match({ team_name: teamName.trim() });

    if (status === 200 && data.length && teamName.trim() !== team.team_name) {
      setMessage('Team name already taken');
      setLoading(false);
    }
    if (
      status === 200 &&
      (!data.length || teamName.trim() === team.team_name)
    ) {
      const allUser = selectedUser.concat(otherSelectedUser);
      const users = allUser.map((user) => user._id);
      const teamData = {
        team_name: teamName.trim(),
        users,
      };
      const { error } = await supabase
        .from('team')
        .update(teamData)
        .match({ id: team.id });

      if (error) {
        setLoading(false);

        setMessage('Something went wrong! Please try again.');
      } else {
        setRefetch((pre) => !pre);
        setLoading(false);
        handleClose();
      }
    }
  };
  const otherUser = userData.filter((user) => {
    const userTeam = allTeamData.find(({ users }) => users.includes(user._id));
    return !userTeam;
  });

  const handleClick = (row) => {
    const isItemSelected = otherSelectedUser.find(
      (user) => user._id === row._id
    );
    if (isItemSelected) {
      const newSelected = otherSelectedUser.filter(
        (user) => user._id !== row._id
      );
      setOtherSelectedUser(newSelected);
    } else {
      const newSelected = otherSelectedUser.concat([row]);
      setOtherSelectedUser(newSelected);
    }
  };

  return (
    <Box>
      <TextField
        value={teamName}
        onChange={(e) => setTeamName(e.target.value)}
        id='outlined-basic'
        label='Team Name'
        variant='outlined'
      />
      <Box m={2}>
        Selected Users
        {selectedUser.map((user, index) => {
          return (
            <Stack
              key={index}
              mt={1}
              direction='row'
              justifyContent='space-between'
              spacing={2}
            >
              <Typography>
                {' '}
                {index + 1} : {user?.firstName} {user?.lastName} - {user?.email}{' '}
              </Typography>
              <Button
                onClick={() => {
                  const newusers = selectedUser.filter(
                    (el) => el._id !== user._id
                  );
                  setSelectedUser(newusers);
                }}
                color='error'
              >
                Remove
              </Button>
            </Stack>
          );
        })}
      </Box>
      <Button disabled={isLoading} onClick={() => setAddMore(true)}>
        Add Other User
      </Button>
      {addMore && (
        <Box m={2}>
          Selected Users
          {otherUser.map((user, index) => {
            return (
              <Stack
                key={index}
                mt={1}
                direction='row'
                justifyContent='space-between'
                spacing={2}
              >
                <Typography>
                  {' '}
                  {index + 1} : {user.firstName} {user.lastName} - {user.email}{' '}
                </Typography>
                <Checkbox
                  onChange={() => {
                    handleClick(user);
                  }}
                />
              </Stack>
            );
          })}
        </Box>
      )}
      <Stack mt={5} direction='row' justifyContent='center' spacing={2}>
        <Button disabled={isLoading} onClick={() => editTeamFn()}>
          Edit Team
        </Button>
      </Stack>
      <Stack mt={5} direction='row' justifyContent='center' spacing={2}>
        <Typography> {message} </Typography>
      </Stack>
    </Box>
  );
};

export default EditTeam;

import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import ProjectTaskForm from './ProjectTaskForm';
import { useQueryClient } from 'react-query';

const AddTaskTemplate = ({ setValue }) => {
  const queryClient = useQueryClient();
  const [serverResponse, setServerResponse] = useState();
  return (
    <Box>
      <Typography variant='h6'>Add a new Project Task</Typography>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            title: '',
            level: '',
            type: 'Company Tasks',
            domainId: '',
            companyId: '',
            problemStatement: '',
            businessObjectives: '',
            dataset: {
              link: '',
              description: '',
            },
            tasks: [
              {
                title: '',
                skillId: [],
                backgroundDescription: '',
                taskInstructions: '',
                resources: '',
                // level: 'Basic',
                day: '',
                week: '',
                duration: '',
              },
            ],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            level: Yup.string().required('Difficulty Level is required'),
            domainId: Yup.string().required('Domain is required'),
            companyId: Yup.string().required('Company is required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            tasks: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required('Title is required'),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                resources: Yup.string(),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
                day: Yup.number().required(),
                week: Yup.number().required(),
                duration: Yup.number().required(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            axios({
              method: 'POST',
              url: API_URL + '/project-template',
              data: values,
            })
              .then(function (response) {
                setServerResponse('Project Added');
                queryClient.invalidateQueries('fetchProjectTemplate');
                resetForm();
                setSubmitting(false);
                setValue(0);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              {console.log(errors)}
              <ProjectTaskForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Add Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddTaskTemplate;

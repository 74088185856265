import React, { useState } from 'react';
import { TableRow, TableCell, Box } from '@mui/material';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from '../../../misc/Snackbar';
import EditCategory from './EditCategory';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import Prompt from '../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CategoryBody = ({ category }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const queryClient = useQueryClient();

  const deleteCategory = (id) => {
    setSnackbarText('Deleting Category...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/categories', {
        data: { categoryId: id },
      })
      .then((res) => {
        setSnackbarText('Category has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchCategory');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {category.title}
        </TableCell>
        <TableCell>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={handleOpen}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Category?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteCategory(category._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditCategory
            category={category}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default CategoryBody;

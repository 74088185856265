import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Card from '@mui/material/Card';

const UserDetails = ({ allUsers }) => {
  return (
    <div>
      <TableContainer component={Card}>
        <Table
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Sr No.</TableCell>
              <TableCell>Name</TableCell>
              <TableCell align='left'>Email</TableCell>
              <TableCell align='left'>Contact No</TableCell>
              <TableCell align='left'>Education</TableCell>
              <TableCell align='left'>Work Experience</TableCell>
              <TableCell align='left'>Current city </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers.map((user, index) => {
              return (
                <TableRow
                  key={user._id}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  <TableCell component='th' scope='row'>
                    {index + 1}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {user?.name}
                  </TableCell>
                  <TableCell
                    align='left'
                    // sx={{ maxWidth: '250px', wordBreak: 'break-word' }}
                  >
                    {user.email}
                  </TableCell>
                  <TableCell align='left'>{user?.contact_number}</TableCell>
                  <TableCell align='left'> {user?.education} </TableCell>

                  <TableCell align='left'>{user.experience}</TableCell>
                  <TableCell align='left'>{user.current_city}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default UserDetails;

import React, { useState } from 'react';
import axios from 'axios';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { useQuery } from 'react-query';
import Multiselect from 'multiselect-react-dropdown';
import {
  Grid,
  Button,
  Box,
  Typography,
  Checkbox,
  MenuItem,
  FormGroup,
  FormControlLabel,
  TextField,
  TextareaAutosize,
} from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import { API_URL } from 'links/API_LINKS';

const fetchAreas = async () => {
  const res = await axios.get(API_URL + '/area', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};
const fetchCompanies = async () => {
  const res = await axios.get(API_URL + '/company', {
    params: { limit: 0, sort: 'name', order: 'asc' },
  });
  return res;
};
const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};

const ProgramForm = ({ values, setFieldValue, capstoneToEdit, errors }) => {
  const [sections, setSections] = useState(0);
  const areas = useQuery('fetchAreas', fetchAreas);
  const companies = useQuery('fetchCompanies', fetchCompanies);
  const skills = useQuery('fetchSkills', fetchSkills);

  let allSkills = [];

  if (skills.status === 'success') {
    allSkills = skills.data.data.data.map((skill) => {
      return { id: skill._id, name: skill.title };
    });
  }
  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 2 }}
        mb={3}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='title'
            type='text'
            label='Title'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            type='number'
            label='Price'
            name='price[0].amount'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' name='price[0].currency' label='Currency'>
            <MenuItem value='USD'>USD</MenuItem>
            <MenuItem value='INR'>INR</MenuItem>
            <MenuItem value='EUR'>EUR</MenuItem>
            <MenuItem value='ZAR'>ZAR</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            type='text'
            label='Country'
            name='jobLocation.country'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' fullWidth name='company' label='Company'>
            {companies.status === 'success' &&
              companies.data.data.data.map((company) => {
                return (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {areas.status === 'success' && (
            <SelectInput size='small' fullWidth name='area' label='Area'>
              {areas.status === 'success' &&
                areas.data.data.data.map((area) => {
                  return (
                    <MenuItem key={area._id} value={area._id}>
                      {area.title}
                    </MenuItem>
                  );
                })}
            </SelectInput>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography>Select Input Skills</Typography>
          {skills.status === 'success' && (
            <Multiselect
              options={allSkills}
              selectedValues={allSkills.filter((skill) => {
                return values.skills.includes(skill.id);
              })}
              onSelect={(selectedList, selectedItem) => {
                const myskill = values.skills.concat([selectedItem.id]);
                setFieldValue('skills', myskill);
              }}
              onRemove={(selectedList, removedItem) => {
                const myskill = values.skills.filter((skill) => {
                  return skill !== removedItem.id;
                });
                setFieldValue('skills', myskill);
              }}
              displayValue='name'
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextareaAutosize
            aria-label='Note'
            name='overviewNote'
            placeholder='Note'
            minRows={6}
            maxRows={6}
            style={{ width: 400, padding: 10 }}
          />
        </Grid>
      </Grid>
      <Box>
        <Typography variant='h6'>Overview Points</Typography>
        <Grid
          container
          rowSpacing={2}
          columnSpacing={{ xs: 2, sm: 1, md: 4 }}
          my={1}
        >
          <Grid item>
            <TextInput label='Point 1' name='points[0]' />
          </Grid>
          <Grid item>
            <TextInput label='Point 2' name='points[1]' />
          </Grid>
          <Grid item>
            <TextInput label='Point 2' name='points[2]' />
          </Grid>
          <Grid item>
            <TextInput label='Point 4' name='points[3]' />
          </Grid>
          <Grid item>
            <TextInput label='Point 5' name='points[4]' />
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 2 }}
        my={3}
      >
        <Grid item xs={12} sm={6} md={5}>
          <TextInput label='Slug (Identifier in url)' name='slug' />
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <TextInput
            label='External Link'
            name='externallink'
            placeholder='External Link'
          />
          <Typography>
            User will be redirected to this link and payment processing will not
            be done on our platform even when a price is added.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Typography>Category</Typography>
          <SelectInput name='category'>
            <MenuItem value='606b0f03b689e8771091c667'>
              Experience Projects
            </MenuItem>
            <MenuItem value='606b0ffeb689e8771091c66d'>Internships</MenuItem>
            <MenuItem value='606704203a3dbf3fc82cc74f'>Startup Labs</MenuItem>
            <MenuItem value='60f7b81e4e145c6894f8ff11'>Career Lounge</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <FormGroup sx={{ ml: 4 }}>
            <FormControlLabel
              control={<Checkbox size='small' />}
              label='Active'
              name='publishStatus'
            />
            <FormControlLabel
              control={<Checkbox size='small' />}
              label='Featured'
              name='featured'
            />
            <FormControlLabel
              control={<Checkbox size='small' />}
              label='Show On Home Page'
              name='showOnHomePage'
            />
          </FormGroup>
        </Grid>
      </Grid>

      <Box>
        <Typography variant='h6'>Highlight Points</Typography>
      </Box>
      {[...Array(1)].map((e, i) => (
        <Box key={i}>
          <FieldArray
            name='highlightPoints'
            render={(arrayHelpers) => (
              <>
                <Box sx={{ my: 2 }}>
                  <Button
                    type='button'
                    onClick={() =>
                      arrayHelpers.push({
                        title: '',
                        text: '',
                      })
                    }
                  >
                    <FaPlusCircle />
                  </Button>

                  {values.highlightPoints.map((content, index) => {
                    return (
                      <Grid container spacing={2} key={index} mb={2}>
                        <Grid item md={4}>
                          <TextField
                            name={`highlightPoints[${index}].title`}
                            placeholder='title'
                            maxRows={2}
                            multiline
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={4}>
                          <TextField
                            name={`highlightPoints[${index}].text`}
                            placeholder='text'
                            maxRows={4}
                            multiline
                            fullWidth
                          />
                        </Grid>
                        <Grid item md={2}>
                          <Button
                            type='button'
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <FaTrashAlt />
                          </Button>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Box>
              </>
            )}
          />
        </Box>
      ))}
      <Box>
        <Typography variant='h6'>Program Page Sections</Typography>
        <Button
          onClick={() => {
            setSections(sections + 1);
          }}
        >
          Add section +
        </Button>
        {sections > 0 && (
          <Button
            onClick={() => {
              setSections(sections - 1);
            }}
          >
            Remove section -
          </Button>
        )}
      </Box>
      <Box sx={{ my: 2 }}>
        {[...Array(sections)].map((e, i) => (
          <Box key={i} sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ marginRight: 2 }}>Section {i + 1}</Typography>
              <TextInput name={`sectionTitle[${i}]`} placeholder='Title' />
            </Box>
            <FieldArray
              name='sectionContent'
              render={(arrayHelpers) => (
                <>
                  <Box sx={{ my: 2 }}>
                    <Button
                      onClick={() =>
                        arrayHelpers.push({
                          sectionNumber: i,
                          contentToPublish: '',
                          contentType: 'Text',
                        })
                      }
                    >
                      <FaPlusCircle />
                    </Button>

                    {values.sectionContent.map((content, index) => {
                      return (
                        content.sectionNumber === i && (
                          <Grid container key={index} spacing={2} mb={2}>
                            <Grid item md={8}>
                              <TextField
                                name={`sectionContent[${index}].contentToPublish`}
                                placeholder='Content'
                                fullwidth
                                maxRows={4}
                                multiline
                                sx={{ width: 600 }}
                              />
                            </Grid>
                            <Grid item md={2}>
                              <SelectInput
                                name={`sectionContent.${index}.contentType`}
                              >
                                <MenuItem value='Text'>Text</MenuItem>
                                <MenuItem value='List'>List</MenuItem>
                                <MenuItem value='Tab'>Tab</MenuItem>
                              </SelectInput>
                            </Grid>
                            <Grid item md={2}>
                              <Button
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <FaTrashAlt />
                              </Button>
                            </Grid>
                          </Grid>
                        )
                      );
                    })}
                  </Box>
                </>
              )}
            />
          </Box>
        ))}
      </Box>
      {/* <Box>
        <Button disabled={isSubmitting}>
          {isSubmitting ? 'Processing...' : 'Submit'}
        </Button>
      </Box> */}
    </div>
  );
};

export default ProgramForm;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { Box, Button, Stack, TablePagination, Modal } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import AllUserBody from './AllUserBody';

import { applySortFilter, getComparator } from '../../halper/TableSort';
import { profilePercentage } from './helper';
import AddToTeam from './AddToTeam';
import CsvDownload from 'react-json-to-csv';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AllUserHeader = ({ data, quizData, teamData, setRefetch }) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [selectedUser, setSelectedUser] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const rows = data.map((user) => {
    const profile_Percentage = profilePercentage(user);
    const userQuiz = quizData.find(({ user_id }) => user_id === user._id);
    const userTeam = teamData.find(({ users }) => users.includes(user._id));

    let timeDiff = 0;
    const workExperience = user.workExperience || [];
    workExperience.forEach((exp) => {
      const date1 = new Date(exp.startDate);
      const date2 = exp.endDate ? new Date(exp.endDate) : new Date(Date.now());
      const differenceInTime = date2.getTime() - date1.getTime();
      const differenceInMonth = differenceInTime / (1000 * 3600 * 24 * 30);
      timeDiff += differenceInMonth;
    });
    const quizPercentage = userQuiz?.percentage
      ? Math.round(userQuiz.percentage)
      : 0;
    const totalScore = (profile_Percentage + quizPercentage) / 2;
    const level =
      totalScore >= 70 ? 'Level-1' : totalScore >= 30 ? 'Level-2' : 'Level-3';
    return {
      id: user._id,
      imageUrl: user.imageUrl,
      email: user.email,
      name: user.firstName + ' ' + user.lastName,
      user: user,
      profile_Percentage,
      workExperience: Math.round(timeDiff),
      quizPercentage,
      userTeam: userTeam?.team_name || '',
      totalScore,
      level,
      stream: user.stream,
    };
  });

  const TABLE_HEAD = [
    { id: 'checkbox', label: '', align: 'left' },
    { id: 'no', label: 'No.', align: 'left' },
    { id: 'profile', label: '', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'profile_Percentage', label: 'Profile Score', align: 'left' },
    { id: 'quizPercentage', label: 'Quiz Score', align: 'left' },
    { id: 'totalScore', label: 'Total Score', align: 'left' },
    { id: 'level', label: 'Level', align: 'left' },
    { id: 'workExperience', label: 'Work Experience', align: 'left' },
    { id: 'stream', label: 'Stream', align: 'left' },
    { id: 'userTeam', label: 'Team', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));
  const csvData = filteredUsers.map((user) => {
    return {
      name: user.name,
      email: user.email,
      profile_Percentage: user.profile_Percentage,
      workExperience: user.workExperience,
      quizPercentage: user.quizPercentage,
      totalScore: user.totalScore,
      level: user.level,
      stream: user.stream,
    };
  });
  return (
    <>
      <Stack
        mb={2}
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <CsvDownload data={csvData}>
          <Button variant='outlined'>Users Data</Button>
        </CsvDownload>
      </Stack>

      {selectedUser.length > 0 && (
        <Stack
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={2}
        >
          <Box>{selectedUser.length} Users Selected</Box>
          <Button onClick={() => handleOpen()}>Add to Team</Button>
        </Stack>
      )}

      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <AllUserBody
                  row={row}
                  key={index}
                  index={index}
                  setSelectedUser={setSelectedUser}
                  selectedUser={selectedUser}
                />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 1000]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddToTeam
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
            handleClose={handleClose}
            setRefetch={setRefetch}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AllUserHeader;

import React, { useState, useEffect } from 'react';
import { supabase } from 'links/supabaseConfig';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import UserData from './UserData';

const Index = () => {
  const [quizData, setQuizData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase
        .from('quiz')
        .select('user_id , percentage')
        .match({ type: 'AICPRETEST' });

      setQuizData(fetchedData || []);
      setIsLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {isLoading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {quizData.length > 0 && <UserData quizData={quizData} />}
    </>
  );
};

export default Index;

import React, { useState, useEffect } from 'react';
import { Box, Button, Modal, Stack } from '@mui/material';
import Snackbar from 'components/misc/Snackbar';
import AddAssignment from './AddAssignment';
import AssignmentHeader from './AssignmentHeader';
import { supabase } from 'links/supabaseConfig';
import CircularProgress from '@mui/material/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Index = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [reFetch, setReFetch] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      const { data: fetchedData } = await supabase.from('assignments').select();
      setData(fetchedData || []);
      setIsLoading(false);
    };
    fetchData();
  }, [reFetch]);

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}
      >
        <Button variant='outlined' onClick={() => handleOpen()}>
          Add New Assignment
        </Button>
      </Stack>
      {isLoading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {data.length > 0 && (
        <AssignmentHeader data={data} setReFetch={setReFetch} />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddAssignment
            setReFetch={setReFetch}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </div>
  );
};

export default Index;

import React, { useRef } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Stack } from '@mui/material';
import {
  SelectInput,
  TimePickerField,
} from '../../../../../layouts/FormElement';

const GetDetailForm = ({ getTemplate }) => {
  const formikRef = useRef();
  return (
    <div>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          journeyType: 'School/DT Journey',
          journeyDuration: '5 weeks',
          masterClassTime: new Date(),
          coachTime: new Date(),
          mentorTime: new Date(),
        }}
        validationSchema={Yup.object({
          masterClassTime: Yup.string().required(
            'Please enter MasterClass Time'
          ),
          coachTime: Yup.string().required('Please enter coach Time'),
          mentorTime: Yup.string().required('Please enter mentor Time'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          getTemplate(
            values.journeyType,
            values.journeyDuration,
            values.masterClassTime,
            values.coachTime,
            values.mentorTime,
            setSubmitting
          );
          // setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='journeyType'
                  label='Journey Type'
                >
                  <MenuItem value='School/DT Journey'>
                    School/DT Journey
                  </MenuItem>
                  <MenuItem value='Tech'>Tech</MenuItem>
                  <MenuItem value='Non Tech'>Non Tech</MenuItem>
                </SelectInput>
              </Grid>

              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='journeyDuration'
                  label='No of Week'
                >
                  <MenuItem value='4 weeks'>4</MenuItem>
                  <MenuItem value='5 weeks'>5</MenuItem>
                  <MenuItem value='6 weeks'>6</MenuItem>
                  <MenuItem value='7 weeks'>7</MenuItem>
                  <MenuItem value='8 weeks'>8</MenuItem>
                  <MenuItem value='12 weeks'>12</MenuItem>
                  <MenuItem value='15 weeks'>15</MenuItem>
                  <MenuItem value='22 weeks'>22</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePickerField
                  name='masterClassTime'
                  value={values.masterClassTime}
                  label='MasterClass Time'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePickerField
                  name='coachTime'
                  value={values.coachTime}
                  label='Coach connect Time'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TimePickerField
                  name='mentorTime'
                  value={values.mentorTime}
                  label='Mentor connect Time'
                  onChange={setFieldValue}
                />
              </Grid>
            </Grid>

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Get Events
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            ></Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GetDetailForm;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Grid, Box, Modal, Stepper, Step, StepButton } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import ShowFilesPopup from '../../halper/showFilesPopup';
import CaptoneField from './CaptoneField';
import QuestionField from './QuestionField';
import TaskField from './TaskField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchAreas = async () => {
  const res = await axios.get(API_URL + '/area', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};
const fetchCompanies = async () => {
  const res = await axios.get(API_URL + '/company', {
    params: { limit: 0, sort: 'name', order: 'asc' },
  });
  return res;
};
const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};

const fetchSkillsWithQuestions = async () => {
  const res = await axios.get(API_URL + '/skills/capstone-skills');
  return res;
};

const CapstoneForm = ({
  values,
  setFieldValue,
  capstoneToEdit,
  errors,
  activeStep,
  setActiveStep,
}) => {
  const domains = useQuery('fetchAreas', fetchAreas);
  const companies = useQuery('fetchCompanies', fetchCompanies);
  const skills = useQuery('fetchSkills', fetchSkills);
  const skillsWithQuestions = useQuery(
    'fetchSkillsWithQuestions',
    fetchSkillsWithQuestions
  );
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }

  const steps = ['Capstone', 'Tasks', 'Quiz'];

  const firstStepkeys = [
    'title',
    'domainId',
    'companyId',
    'problemStatement',
    'learningObjectives',
    'businessObjectives',
    'introductoryVideo',
    'taskDependency',
  ];

  const errorKeys = Object.keys(errors);

  const handleStep = (step) => () => {
    setActiveStep(step);
    if (
      errorKeys.some((err) => {
        return firstStepkeys.includes(err);
      })
    ) {
      alert(Object.values(errors)[0]);
      setActiveStep(0);
    } else if (errorKeys.includes('tasks')) {
      alert('Please fill all required field');
      setActiveStep(1);
    }
  };

  return (
    <div>
      <Box sx={{ width: '50%' }} mb={2}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton color='inherit' onClick={handleStep(index)}>
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <CaptoneField
          values={values}
          setFieldValue={setFieldValue}
          capstoneToEdit={capstoneToEdit}
          errors={errors}
          domains={domains}
          companies={companies}
          setOpen={setOpen}
          setSelectedField={setSelectedField}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      )}
      {activeStep === 1 && (
        <TaskField
          values={values}
          setFieldValue={setFieldValue}
          capstoneToEdit={capstoneToEdit}
          errors={errors}
          skills={skills}
        />
      )}
      {activeStep === 2 && (
        <QuestionField
          values={values}
          setOpen={setOpen}
          setSelectedField={setSelectedField}
          setRefresh={setRefresh}
          refresh={refresh}
          skillsWithQuestions={skillsWithQuestions}
        />
      )}

      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </Grid>
    </div>
  );
};

export default CapstoneForm;

import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { supabase } from 'links/supabaseConfig';
import React, { useState } from 'react';

const AddToTeam = ({
  handleClose,
  selectedUser,
  setSelectedUser,
  setRefetch,
}) => {
  const [teamName, setTeamName] = useState('Byte Squad ');
  const [isLoading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const addTeamFn = async () => {
    setMessage('');
    setLoading(true);

    const { data, status } = await supabase
      .from('team')
      .select()
      .match({ team_name: teamName.trim() });

    if (status === 200 && data.length) {
      setMessage('Team name already taken');
      setLoading(false);
    }
    if (status === 200 && !data.length) {
      const users = selectedUser.map((user) => user._id);
      const teamData = {
        team_name: teamName.trim(),
        users,
        bootcamp_id: 1,
      };
      const { error } = await supabase.from('team').insert(teamData);

      if (error) {
        setLoading(false);

        setMessage('Something went wrong! Please try again.');
      } else {
        setSelectedUser([]);
        setRefetch((pre) => !pre);
        setLoading(false);
        handleClose();
      }
    }
  };

  return (
    <Box>
      <TextField
        value={teamName}
        onChange={(e) => setTeamName(e.target.value)}
        id='outlined-basic'
        label='Team Name'
        variant='outlined'
      />
      <Box m={2}>
        Selected Users
        {selectedUser.map((user, index) => {
          return (
            <Typography>
              {' '}
              {index + 1} : {user.firstName} {user.lastName} - {user.email}{' '}
            </Typography>
          );
        })}
      </Box>
      <Stack mt={5} direction='row' justifyContent='center' spacing={2}>
        <Button disabled={isLoading} onClick={() => addTeamFn()}>
          Add Team
        </Button>
      </Stack>
      <Stack mt={5} direction='row' justifyContent='center' spacing={2}>
        <Typography> {message} </Typography>
      </Stack>
    </Box>
  );
};

export default AddToTeam;

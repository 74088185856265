import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';

const UserBody = ({ row, setShowProfile }) => {
  return (
    <>
      <TableRow key={row._id} sx={{ cursor: 'pointer' }}>
        <TableCell onClick={() => setShowProfile(row._id)} align='left'>
          {row.firstName} {row.lastName}{' '}
        </TableCell>
        <TableCell align='left'> {row.email} </TableCell>
        <TableCell align='left'>{row.percentage} </TableCell>
      </TableRow>
    </>
  );
};

export default UserBody;

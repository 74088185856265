import React, { useState } from 'react';
import {
  getAllCompany,
  getAllArea,
  getUniqueAreas,
  updateDateTime,
  updateCompanyDomainName,
} from '../../helper';
import { API_URL } from 'links/API_LINKS';
import axios from 'axios';
import { Button, Stack } from '@mui/material';
import ShowProjects from './ShowProjects';
import ProjectErrors from './ProjectErrors';

const Index = ({ currentBatch }) => {
  const [allProject, setAllProject] = useState([]);
  const [duplicateProject, setDuplicateProject] = useState([]);
  const [emptyProject, setEmptyProject] = useState([]);
  const batchUser = currentBatch.users;
  const allCompany = getAllCompany(batchUser);
  const allArea = getAllArea(batchUser);
  const companyDomainCombination = [{ journeyType: currentBatch.journeyType }];
  allCompany.forEach((company) => {
    const uniqueAreas = getUniqueAreas({ companyId: company._id, batchUser });
    uniqueAreas.forEach((area) => {
      companyDomainCombination.push({
        companyId: company._id,
        domainId: area._id,
      });
    });
  });

  const checkForError = (projects) => {
    const tempDuplect = [];
    const tempEmpty = [];
    companyDomainCombination.forEach((combo) => {
      if (combo.companyId) {
        const sameProject = projects.filter((project) => {
          return (
            combo.companyId === project.companyId?._id &&
            combo.domainId === project.domainId?._id
          );
        });
        if (sameProject.length > 1) {
          tempDuplect.push(combo);
        }
        if (sameProject.length < 1) {
          tempEmpty.push(combo);
        }
      }
    });
    setDuplicateProject(
      updateCompanyDomainName({ tempIds: tempDuplect, allCompany, allArea })
    );
    setEmptyProject(
      updateCompanyDomainName({ tempIds: tempEmpty, allCompany, allArea })
    );
  };

  const makeJourny = (data) => {
    const tempProjects = [];
    data.forEach((project) => {
      const timeUpdatedProject = updateDateTime({
        project,
        batchStartDate: currentBatch.startDate,
      });
      tempProjects.push({ ...timeUpdatedProject, batchId: currentBatch._id });
    });
    setAllProject(tempProjects);
    checkForError(tempProjects);
  };

  async function getTemplate() {
    const res = await axios.get(API_URL + '/project-template', {
      params: { companyDomainCombination },
    });
    if (res.status === 200) {
      makeJourny(res.data.data);
      //   setSubmitting(false);
    }
  }

  return (
    <div>
      <Stack
        direction='row'
        justifyContent='center'
        alignItems='center'
        spacing={3}
        mt={2}
      >
        <Button
          onClick={() => {
            getTemplate();
          }}
        >
          Get Projects
        </Button>
      </Stack>

      {allProject.length > 0 && (
        <>
          <ProjectErrors
            duplicateProject={duplicateProject}
            emptyProject={emptyProject}
          />
          <ShowProjects
            allProject={allProject}
            checkForError={checkForError}
            setAllProject={setAllProject}
            currentBatch={currentBatch}
          />
        </>
      )}
    </div>
  );
};

export default Index;

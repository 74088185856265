import React, { useState } from 'react';
import TableCell from '@mui/material/TableCell';
import { Box, Button, Modal, TableRow } from '@mui/material';
import { useQuery } from 'react-query';
import { API_URL } from 'links/API_LINKS';
import axios from 'axios';
import { format } from 'date-fns/esm';
import ScoreView from './ScoreView';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '80%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function UsersBody({ row, setShowProfile }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const education = row.education && row.education[0];
  const degree = education?.degree || 'NA';
  const course = education?.fieldOfStudy || '';
  const startDate = education?.startDate
    ? format(new Date(education?.startDate), 'LLL yy')
    : '';
  const endDate = education?.endDate
    ? format(new Date(education?.endDate), 'LLL yy')
    : '';

  const fetchUserBelongScore = async () => {
    const res = await axios.get(API_URL + '/user/onboard/new-belong-score', {
      params: { userId: row._id },
    });
    return res;
  };

  const { status, data } = useQuery(
    `fetchUserBelongScore${row._id}`,
    fetchUserBelongScore,
    { select: (data) => data?.data?.data }
  );

  return (
    <>
      <TableRow key={row._id}>
        {/* sx={{ cursor: 'pointer' }} */}
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowProfile(row._id)}
          align='left'
        >
          {row.firstName} {row.lastName}
        </TableCell>
        <TableCell
          sx={{ cursor: 'pointer' }}
          onClick={() => setShowProfile(row._id)}
          align='left'
        >
          {row.email}
        </TableCell>
        <TableCell align='left'>
          {row.createdAt && format(new Date(row.createdAt), 'do LLLL yyyy')}
        </TableCell>
        <TableCell align='left'>
          {degree + ' ' + course} <br />
          {startDate + ' - ' + endDate}
        </TableCell>
        {/* <TableCell align='left'>{row.lastLogin}</TableCell> */}
        <TableCell align='center'>
          {status === 'success' && (
            <>{data?.belongScore ? `${data?.belongScore?.total} %` : 'NA'}</>
          )}
        </TableCell>{' '}
        <TableCell align='center'>
          {status === 'success' && (
            <>
              {data?.belongScore && <Button onClick={handleOpen}>View</Button>}
            </>
          )}
        </TableCell>{' '}
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ScoreView handleClose={handleClose} data={data} email={row.email} />
        </Box>
      </Modal>
    </>
  );
}

export default UsersBody;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import ProjectTaskForm from './ProjectTaskForm';

const EditProjectTask = ({
  projectToEdit,
  allProject,
  index,
  setAllProject,
  handleClose,
}) => {
  return (
    <Box>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            projectTemplateId: projectToEdit._id,
            title: projectToEdit.title,
            type: projectToEdit.type,
            level:
              projectToEdit.level || projectToEdit.tasks[0]?.level || 'Basic',
            // domainId: projectToEdit.domainId?._id,
            // companyId: projectToEdit.companyId?._id,
            problemStatement: projectToEdit.problemStatement || '',
            businessObjectives: projectToEdit.businessObjectives || '',
            dataset: projectToEdit.dataset || {
              link: '',
              description: '',
            },
            tasks: projectToEdit.tasks || [],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            // domainId: Yup.string().required('Domain is required'),
            // companyId: Yup.string().required('Company is required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            tasks: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required('Title is required'),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                resources: Yup.string(),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
                day: Yup.number().required(),
                week: Yup.number().required(),
                duration: Yup.number().required(),
              })
            ),
          })}
          onSubmit={(values) => {
            values.checked = true;
            const tempAllProject = [...allProject];
            const tempCurrentProject = { ...projectToEdit, ...values };
            tempAllProject[index] = tempCurrentProject;
            setAllProject(tempAllProject);
            handleClose();
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <ProjectTaskForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                projectTaskToEdit={projectToEdit}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Update Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              ></Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditProjectTask;

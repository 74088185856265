import React from 'react';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import TextEditor from './TextEditor';
import { Grid, MenuItem, Button, Typography, Box } from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';

const TaskField = ({
  values,
  setFieldValue,
  capstoneToEdit,
  errors,
  skills,
}) => {
  return (
    <div>
      <Grid mt={6} item>
        <FieldArray
          name='tasks'
          render={(arrayHelpers) => (
            <>
              {values.tasks.map((title, index) => {
                return (
                  <Grid
                    sx={{ border: 1 }}
                    key={index}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 1, md: 3 }}
                    mb={3}
                    pb={5}
                  >
                    <Grid item xs={6}>
                      Task - {index + 1}
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      direction='row'
                      justifyContent='flex-end'
                    >
                      {values.tasks.length > 1 && (
                        <Button
                          type='button'
                          color='error'
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Delete task - {index + 1} <FaTrashAlt />
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='text'
                        name={`tasks[${index}].title`}
                        label='Title'
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      {skills.status === 'success' && (
                        <SelectInput
                          size='small'
                          name={`tasks[${index}].skillId`}
                          label='Skills'
                          multiple
                          fullWidth
                        >
                          {skills.data.data.data.map((skill) => {
                            return (
                              <MenuItem key={skill._id} value={skill._id}>
                                {skill.title}
                              </MenuItem>
                            );
                          })}
                        </SelectInput>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='number'
                        name={`tasks[${index}].time`}
                        label='Time'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Background Description
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          capstoneToEdit?.tasks[index]?.backgroundDescription ||
                          ''
                        }
                        valueToUpdate={`tasks[${index}].backgroundDescription`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks &&
                          errors.tasks[index]?.backgroundDescription}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Task Instructions
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          capstoneToEdit?.tasks[index]?.taskInstructions || ''
                        }
                        valueToUpdate={`tasks[${index}].taskInstructions`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.taskInstructions}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Resources
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          capstoneToEdit?.tasks[index]?.resources || ''
                        }
                        valueToUpdate={`tasks[${index}].resources`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.resources}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Ideal Solution
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          capstoneToEdit?.tasks[index]?.idealSolution || ''
                        }
                        valueToUpdate={`tasks[${index}].idealSolution`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.idealSolution}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Box mb={1}>
                Add more task
                <Button
                  type='button'
                  onClick={() =>
                    arrayHelpers.push({
                      title: '',
                      skillId: [],
                      backgroundDescription: '',
                      taskInstructions: '',
                      resources: '',
                      time: '',
                      idealSolution: '',
                    })
                  }
                >
                  <FaPlusCircle />
                </Button>
              </Box>
            </>
          )}
        />
      </Grid>
    </div>
  );
};

export default TaskField;

import { Button, Stack } from '@mui/material';
import React from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';

const Feedback = ({ userData }) => {
  const feedbackUser = userData.filter((el) => {
    return el.feedback;
  });

  const feedbackData = feedbackUser.map((el) => {
    const feedbackObj = el.feedback?.data || {};
    return {
      name: `${el.userDetails?.firstName} ${el.userDetails?.lastName}`,
      email: el.userDetails?.email,
      ...feedbackObj,
    };
  });

  const headers =
    feedbackData.length > 0
      ? Object.keys(feedbackData[0]).map((el) => {
          return { key: el, name: el };
        })
      : [];
  return (
    <div>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={1}
        mb={2}>
        {feedbackData.length > 0 && (
          <ExportJsonCsv headers={headers} items={feedbackData}>
            <Button variant='outlined'>Feedback</Button>
          </ExportJsonCsv>
        )}
      </Stack>
    </div>
  );
};

export default Feedback;

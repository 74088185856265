import { Box, Card, Button, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
// import * as Yup from 'yup';
import axios from 'axios';
import React, { useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';
import { API_URL } from 'links/API_LINKS';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const EditAttendance = ({
  setEdit,
  attendance,
  snackbarActions,
  handleClose,
  eventId,
}) => {
  const formikRef = useRef();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <Button onClick={() => setEdit(false)}>Back</Button>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={{
          attendance: attendance,
        }}
        // validationSchema={Yup.object({
        //   title: Yup.string().required('Title is required'),
        //   type: Yup.string().required(),
        // })}
        onSubmit={(values, { setSubmitting }) => {
          const finalData = { eventId, users: values.attendance };
          axios({
            method: 'POST',
            url: API_URL + '/event/upload-attendance',
            data: finalData,
          })
            .then(function (response) {
              setSnackbarText('Event attendance added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${eventId}`
              );

              handleClose();
            })
            .catch(function (response) {
              setSnackbarText(
                'Failed to add Events attendance, Please try after sometime'
              );
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <TableContainer component={Card}>
              <Table
                sx={{ minWidth: 450, borderRadius: 2, shadow: 2 }}
                aria-label='table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell> Name</TableCell>
                    <TableCell align='center'>Email</TableCell>
                    <TableCell align='center'>Attendance</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {attendance.map((user, index) => (
                    <TableRow hover key={index}>
                      <TableCell component='th' scope='row'>
                        {user.name}
                      </TableCell>
                      <TableCell align='center'>{user.email}</TableCell>

                      <TableCell align='right'>
                        <RadioGroup
                          sx={{ display: 'flex', flexDirection: 'row' }}
                          defaultValue={values.attendance[index].status}
                        >
                          <FormControlLabel
                            onChange={(e) => {
                              setFieldValue(
                                `attendance[${index}].status`,
                                parseInt(e.target.value)
                              );
                            }}
                            value={0}
                            control={<Radio />}
                            label='A'
                          />
                          <FormControlLabel
                            onChange={(e) => {
                              setFieldValue(
                                `attendance[${index}].status`,
                                parseInt(e.target.value)
                              );
                            }}
                            value={1}
                            control={<Radio />}
                            label='P'
                          />
                        </RadioGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                loading={isSubmitting}
                type='submit'
                variant='contained'
              >
                Submit
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            ></Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditAttendance;

import React from 'react';
import AppWebsiteVisits from '../../layouts/AppWebsiteVisits';
import {
  oldLabel,
  OldEnrolment,
  OldEvent,
  OldUser,
} from '../../../../constants/oldDashboardData';

const ShowMonthlyChart = ({ data }) => {
  let modifiedChartData = { ...data };

  oldLabel.forEach((month, index) => {
    const chartDataIndex = modifiedChartData.labels.indexOf(month);
    if (chartDataIndex > -1) {
      modifiedChartData.values[0].data[chartDataIndex] = OldUser[index];
      modifiedChartData.values[1].data[chartDataIndex] = OldEnrolment[index];
      modifiedChartData.values[2].data[chartDataIndex] = OldEvent[index];
    }
  });

  return (
    <div>
      <AppWebsiteVisits chart={modifiedChartData} title='' />
    </div>
  );
};

export default ShowMonthlyChart;

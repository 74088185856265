import React, { useState } from 'react';
import axios from 'axios';
import { API_URL } from '../../../../../../../links/API_LINKS';
import { Typography, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';

const AudienceAttendanceSummary = ({
  eventId,
  eventDuration,
  usersFromFile,
  eligibleUsers,
  snackbarActions,
  handleClose,
}) => {
  const queryClient = useQueryClient();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  //40% duration required to mark attendance
  const CRIETRIA = 0.4;
  const CRITERIA_DURATION = Math.ceil(CRIETRIA * parseInt(eventDuration));
  const [loading, setLoading] = useState(false);
  const [serverResponse, setServerResponse] = useState('');

  const eligibleUsersEmail = eligibleUsers.map((user) => {
    return user.email;
  });

  const foundInFile = usersFromFile.slice(2).filter((user) => {
    return eligibleUsersEmail.includes(user.email);
  });
  //These are not eligible as audience for this event
  const notEligibleInFile = usersFromFile.slice(2).filter((user) => {
    return !eligibleUsersEmail.includes(user.email);
  });
  const matchingCriteria = foundInFile.filter((user) => {
    return parseInt(user.duration) >= CRITERIA_DURATION;
  });
  const notMatchingCriteria =
    eligibleUsersEmail.length - matchingCriteria.length;
  //Users who are eligible but not found in file, to be marked absent
  let temp = [];
  foundInFile.forEach((user) => {
    temp.push(user.email);
  });
  const absentUsers = eligibleUsersEmail.filter((email) => {
    return !temp.includes(email);
  });

  let FINAL_FILE = [];

  foundInFile.forEach((fileUser, index) => {
    const userName = eligibleUsers.find((user) => {
      return user.email === fileUser.email;
    }).name;
    foundInFile[index]['name'] = userName;
    if (parseInt(fileUser.duration) >= CRITERIA_DURATION) {
      foundInFile[index]['status'] = 1;
    } else {
      foundInFile[index]['status'] = 0;
    }
    const durPercent = Math.ceil(
      (parseInt(fileUser.duration) / parseInt(eventDuration)) * 100
    );
    foundInFile[index]['percent'] = Math.min(durPercent, 100);
  });
  console.log(FINAL_FILE);
  FINAL_FILE = [...foundInFile];
  absentUsers.forEach((email, index) => {
    let temp = {};
    const userName = eligibleUsers.find((user) => {
      return user.email === email;
    }).name;
    temp['name'] = userName;
    temp['email'] = email.trim().toLowerCase();
    temp['status'] = 0;
    temp['duration'] = 0;
    temp['percent'] = 0;
    FINAL_FILE.push(temp);
  });
  const markAttendance = async () => {
    setLoading(true);
    setServerResponse('');

    try {
      const res = await axios.post(API_URL + '/event/upload-attendance', {
        eventId: eventId,
        users: FINAL_FILE,
      });
      setLoading(false);
      setServerResponse(res.message);
      setSnackbarText('Event attendance added successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
      handleClose();
      queryClient.invalidateQueries(`fetchCurrentBatchEvents${eventId}`);
    } catch (res) {
      setSnackbarText(
        'Failed to add Events attendance, Please try after sometime'
      );
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <p>
        Eligible emails present in file:
        <Typography display='inline' variant='subtitle1'>
          {foundInFile.length}
        </Typography>
      </p>
      <p>
        Emails not eligible and present in file:{' '}
        <Typography display='inline' variant='subtitle1'>
          {notEligibleInFile.length}
        </Typography>
      </p>
      <div>
        {notEligibleInFile.map((user) => {
          return <p key={user.email}>{user.email}</p>;
        })}
      </div>

      <Box
        sx={{
          backgroundColor: 'primary.light',
          padding: '15px',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '2px',
        }}
      >
        <p>Event Duration: {eventDuration}</p>
        <p>Attendance Criteria: {parseInt(eventDuration * 0.4)} Minutes</p>
        <p>
          Users matching the criteria of 40% duration (Present):{' '}
          {matchingCriteria.length}
        </p>
        <p>
          Users not matching the criteria (Absent):
          {notMatchingCriteria}
        </p>

        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1,
              m: 1,
            }}
          >
            <LoadingButton
              size='large'
              variant='contained'
              loading={loading}
              onClick={() => {
                markAttendance();
              }}
            >
              Update {matchingCriteria.length} as Present and{' '}
              {notMatchingCriteria} as absent
            </LoadingButton>
          </Box>

          <p>{serverResponse}</p>
        </>
      </Box>
    </div>
  );
};

export default AudienceAttendanceSummary;

import React, { useState } from 'react';
import {
  TableRow,
  TableCell,
  Box,
  Table,
  TableBody,
  Chip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import LinkIcon from '@mui/icons-material/Link';

const AllBody = ({ event }) => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  let color = 'primary';
  if (event.eventOrAssignment === 'Assignment') {
    color = 'secondary';
  }

  return (
    <>
      <TableRow>
        <TableCell
          //sx={{ backgroundColor: bgColor }}
          component='th'
          scope='row'
        >
          {event.subData && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => setCollapseOpen(!collapseOpen)}
            >
              {collapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          {event.title}
        </TableCell>
        <TableCell align='left'>
          <Chip color={color} size='small' label={event.type} />
        </TableCell>
        <TableCell align='left'>{event.week}</TableCell>
        <TableCell align='left'>{event.date}</TableCell>
        <TableCell align='left'>
          {event.link && (
            <a target='_blank' rel='noreferrer' href={event.link}>
              <LinkIcon />
            </a>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={collapseOpen} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size='small' aria-label=''>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Date</TableCell>
                    <TableCell>Link</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {event.subData &&
                    event.subData.map((subEvent, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell component='th' scope='row'>
                            {subEvent.title}
                          </TableCell>
                          <TableCell>{subEvent.date}</TableCell>
                          <TableCell>
                            {subEvent.link && (
                              <a
                                target='_blank'
                                rel='noreferrer'
                                href={subEvent.link}
                              >
                                <LinkIcon />
                              </a>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default AllBody;

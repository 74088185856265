import React, { useEffect, useState } from 'react';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';
import ShowMonthlyChart from './layouts/ShowMonthlyChart';

const MonthlyChart = () => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    (async () => {
      const result = await axios(
        API_URL + '/dashboard/graph-data/monthly-activities'
      );
      setChartData(result.data.data);
    })();
  }, []);
  return <div>{chartData.labels && <ShowMonthlyChart data={chartData} />}</div>;
};

export default MonthlyChart;

import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import AllProjectHeader from './AllProjectHeader';

const Index = ({ currentBatch }) => {
  const fetchCurrentBatchProject = async () => {
    const res = await axios.get(API_URL + '/vga-project', {
      params: { batchId: currentBatch._id, limit: 0 },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchCurrentBatchProject${currentBatch._id}`,
    fetchCurrentBatchProject
  );

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <AllProjectHeader data={data.data.data} currentBatch={currentBatch} />
      )}
    </div>
  );
};

export default Index;

import axios from 'axios';
import {
  Typography,
  Button,
  Box,
  Stack,
  TextField,
  Modal,
  Grid,
} from '@mui/material';
import React, { useState } from 'react';
import { API_URL } from 'links/API_LINKS';
import format from 'date-fns/format';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
};

const AptitudeTest = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [isDeletingTest, setIsDeletingTest] = useState(false);
  const [emailId, setEmailId] = useState('');
  const [fetchedData, setFetchedData] = useState(null);
  const [currDeleteId, setCurrDeleteId] = useState(-1);

  const getTest = () => {
    setSubmitting(true);
    axios({
      method: 'GET',
      url: API_URL + '/test/aptitude-test/email',
      params: {
        email: emailId.trim().toLowerCase(),
      },
    })
      .then(function (response) {
        setFetchedData(response.data.data);
        setServerResponse('');
      })
      .catch(function (response) {
        setServerResponse('Error! Could not process your request.');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleTestDelete = () => {
    setIsDeletingTest(true);
    axios({
      method: 'DELETE',
      url: API_URL + '/test/aptitude-test',
      data: {
        testId: currDeleteId,
      },
    })
      .then((res) => {
        setServerResponse('Delete successful.');
        getTest();
      })
      .catch((err) => {
        setServerResponse('Error! Could not process your deletion request.');
      })
      .finally(() => {
        handleClose();
        setIsDeletingTest(false);
        setCurrDeleteId(-1);
      });
  };

  const getTotalAnswered = (category, idx) => {
    let count = 0;
    const categoryResponse = fetchedData[idx].responses.find(
      (element) => element.category === category
    );
    for (const ques of categoryResponse.questions) {
      if (ques.status === 'answered') {
        count += 1;
      }
    }

    return count;
  };

  const getTotalVisited = (category, idx) => {
    let count = 0;
    const categoryResponse = fetchedData[idx]?.responses.find(
      (element) => element.category === category
    );
    for (const ques of categoryResponse.questions) {
      if (ques.status === 'visited') {
        count += 1;
      }
    }

    return count;
  };

  const getTotalNotVisited = (category, idx) => {
    let count = 0;
    const categoryResponse = fetchedData[idx]?.responses.find(
      (element) => element.category === category
    );
    for (const ques of categoryResponse.questions) {
      if (!ques.status) {
        count += 1;
      }
    }

    return count;
  };

  return (
    <div>
      <Stack direction='row' spacing={4} alignItems='flex-end'>
        <Box>
          <Typography variant='caption'>
            Enter a vaild Email Id to fetch Test
          </Typography>
          <TextField
            size='small'
            fullWidth
            placeholder='Email Id'
            value={emailId}
            onChange={(e) => setEmailId(e.target.value)}
          />
        </Box>
        <Button
          onClick={() => getTest()}
          variant='outlined'
          disabled={emailId.length === 0 || isSubmitting}>
          {isSubmitting ? 'Processing...' : 'Get Test'}
        </Button>
      </Stack>
      <Stack
        // direction='row'
        mt={4}
        justifyContent='Center'
        alignItems='left'
        spacing={2}>
        {emailId &&
          fetchedData != null &&
          (fetchedData.length === 0 ? (
            <Typography>Data unavailable.</Typography>
          ) : (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography>
                    Name:{' '}
                    {`${fetchedData[0].userId?.firstName} ${fetchedData[0].userId?.lastName}`}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography>Email: {fetchedData[0].userId?.email}</Typography>
                </Grid>
                {fetchedData.map((dataItem, idx) => (
                  <Box key={idx} border={1} p={2}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack spacing={2}>
                          {dataItem.finalScore.map((elm) => (
                            <Stack direction='row' spacing={2}>
                              <Typography fontWeight={600}>
                                {elm.category}:
                              </Typography>
                              <Box display='flex' gap={1} flexDirection='row'>
                                <Typography>Score:</Typography>
                                <Typography>
                                  {`${elm.obtained}/${elm.max}`},
                                </Typography>
                              </Box>
                              <Box display='flex' gap={1} flexDirection='row'>
                                <Typography>Total Answered:</Typography>
                                <Typography>
                                  {getTotalAnswered(elm.category, idx)},
                                </Typography>
                              </Box>
                              <Box display='flex' gap={1} flexDirection='row'>
                                <Typography>Total Visited:</Typography>
                                <Typography>
                                  {getTotalVisited(elm.category, idx)},
                                </Typography>
                              </Box>
                              <Box display='flex' gap={1} flexDirection='row'>
                                <Typography>Total Unvisited:</Typography>
                                <Typography>
                                  {getTotalNotVisited(elm.category, idx)}
                                </Typography>
                              </Box>
                            </Stack>
                          ))}
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {'Percentage: '}
                          {dataItem.percentage}
                          {'%'}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {'Start Time: '}
                          {format(
                            new Date(dataItem.startTime),
                            'HH:mm:ss, do LLLL yyyy'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {'End Time: '}
                          {format(
                            new Date(dataItem.finishTime),
                            'HH:mm:ss, do LLLL yyyy'
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {'Time Taken: '}
                          {Math.ceil(dataItem.timeTaken / 1000) > 60
                            ? `${Math.ceil(
                                dataItem.timeTaken / 1000 / 60
                              )} minute(s)`
                            : `${Math.ceil(
                                dataItem.timeTaken / 1000
                              )} second(s)`}
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <Typography>
                          {'Timeout: '}
                          {dataItem.timeOut ? 'Yes' : 'No'}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Button
                      onClick={() => {
                        setCurrDeleteId(dataItem._id);
                        handleOpen();
                      }}
                      variant='outlined'
                      color='error'
                      size='medium'
                      sx={{ maxWidth: 200 }}
                      //   disabled={}
                    >
                      Delete Test
                    </Button>
                  </Box>
                ))}
              </Grid>
            </React.Fragment>
          ))}
        <Typography variant='h6'>{serverResponse}</Typography>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Are you sure you want to delete this test ? You can't retrieve
              this information again. This is a permanent action !
            </Typography>
            <Stack direction='row' spacing={2} pt={2}>
              <Button onClick={handleClose} variant='contained' color='primary'>
                Cancel
              </Button>
              <Button
                variant='contained'
                color='error'
                disabled={isDeletingTest}
                onClick={handleTestDelete}>
                {isDeletingTest ? 'Deleting...' : 'Delete'}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Stack>
    </div>
  );
};

export default AptitudeTest;

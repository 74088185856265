import React, { useState, useRef, useCallback } from 'react';
import { createReactEditorJS } from 'react-editor-js';

import { EDITOR_JS_TOOLS } from './EditorConstants';

import { Button, Stack, Typography } from '@mui/material';

const ReactEditorJS = createReactEditorJS();

const AddJobDetails = ({ formikRef, setOpen }) => {
  const [data, setData] = useState(null);
  const editorJS = useRef(null);
  const handleInitialize = useCallback((instance) => {
    editorJS.current = instance;
  }, []);
  const defaultValue =
    formikRef.current &&
    formikRef.current.values &&
    formikRef.current.values.jobDescription;

  const handleSave = useCallback(async () => {
    const savedData = await editorJS.current.save();
    formikRef.current.setFieldValue('jobDescription', savedData);
    setData(savedData);
    setOpen(false);
  }, [formikRef, setOpen]);

  const handleClear = useCallback(async () => {
    await editorJS.current.clear();
  }, []);

  return (
    <div>
      <Typography textAlign='center' color='error'>
        Please fill Job Description and Job Highlights
      </Typography>
      {defaultValue && (
        <>
          <ReactEditorJS
            tools={EDITOR_JS_TOOLS}
            onInitialize={handleInitialize}
            value={data}
            defaultValue={defaultValue}
          />
          <Stack
            direction='row'
            alignItems='center'
            justifyContent='center'
            spacing={4}
          >
            <Button
              variant='contained'
              color='success'
              type='button'
              onClick={handleSave}
            >
              Save
            </Button>
            <Button
              variant='contained'
              color='error'
              type='button'
              onClick={handleClear}
            >
              Clear
            </Button>
          </Stack>
        </>
      )}
    </div>
  );
};

export default AddJobDetails;

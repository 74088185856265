import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import FacultyForm from './FacultyForm';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditFaculty = ({ facultyToEdit, setFacultyToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const {
    _id,
    name,
    designation,
    profile,
    linkedIn,
    brief,
    signature,
    featured,
  } = {
    ...facultyToEdit,
  };
  // console.log(facultyToEdit);
  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setFacultyToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          facultyId: _id,
          name: name,
          designation: designation,
          profile: profile,
          linkedIn: linkedIn,
          brief: brief,
          signature: signature,
          featured: featured ? featured.map((cat) => cat._id) : [],
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(3, 'Must be 3 characters or more')
            .max(30, 'Must be 30 characters or less')
            .required('Please enter a name'),
          designation: Yup.string().required('Please enter the designation'),
          profile: Yup.string().url().required(),
          linkedIn: Yup.string().url().required(),
          brief: Yup.string().required(' Brief is required'),
          signature: Yup.string().url().required(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/faculty',
            data: values,
          })
            .then(function (response) {
              console.log(response);
              queryClient.invalidateQueries('fetchFaculty');
              setServerResponse(response.data.msg);
              setSubmitting(false);
              setFacultyToEdit(null);
            })
            .catch(function (response) {
              console.log(response);
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <FacultyForm values={values} setFieldValue={setFieldValue} />

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditFaculty;

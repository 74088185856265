import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import SkillHeader from './SkillHeader';

const Index = () => {
  const fetchSkills = async () => {
    const res = await axios.get(API_URL + '/skills', {
      params: { limit: 0, includeDeleted: true },
    });
    return res;
  };
  const { status, data } = useQuery('fetchSkills', fetchSkills);

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          <SkillHeader data={data.data.data} />{' '}
        </Box>
      )}
    </div>
  );
};

export default Index;

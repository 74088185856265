import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../../../layouts/FormElement';
import { useQuery, useQueryClient } from 'react-query';
import ShowFilesPopup from '../../../halper/showFilesPopup';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Grid,
  Modal,
  MenuItem,
  Typography,
  Stack,
  Button,
  Alert,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { API_URL, API_URL_V1 } from '../../../../../links/API_LINKS';
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchCountry = async () => {
  const res = await axios.get(API_URL_V1 + '/countrytimezones/countries');
  return res;
};

const fetchIndustry = async () => {
  const res = await axios.get(API_URL + '/industries', {
    params: { limit: 0 },
  });
  return res;
};

const EditUnderReviewCompany = ({
  company,
  handleCloseEdit,
  snackbarActions,
}) => {
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  const [showFormik, setShowFormik] = useState(true);
  const [companyData, setCompanyData] = useState(company);
  const [serverResponse, setServerResponse] = useState();
  const [sections, setSections] = useState(1);
  const formikRef = useRef();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const countries = useQuery('fetchCountry', fetchCountry);
  const industries = useQuery('fetchIndustry', fetchIndustry);

  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }

  const fetchSimilarCompanies = async () => {
    const res = await axios.get(API_URL + '/company/find-similar', {
      params: { companyId: company._id },
    });
    return res;
  };
  const similarCompanies = useQuery(
    `fetchSimilarCompanies${company._id}`,
    fetchSimilarCompanies
  );

  const replaceCompany = (oldCompany) => {
    const newData = {
      _id: oldCompany._id,
      slogan: oldCompany.slogan,
      companyImageUrl: oldCompany.companyImageUrl,
      logoUrl: oldCompany.logoUrl,
      companyStage: 2,
      sectionTitle: oldCompany.sectionTitle,
      sectionContent: oldCompany.sectionContent || [],
      name: companyData.name,
      website: companyData.website,
      country: companyData.country,
      industryId: companyData.industryId || '',
      companyPhone: companyData.companyPhone,
      employeeName: companyData.employeeName,
      companyEmail: companyData.companyEmail,
      companyToBeDeleted: companyData._id,
    };
    setShowFormik(false);
    setCompanyData(newData);
    setTimeout(() => {
      setShowFormik(true);
    }, 100);
  };
  return (
    <>
      {similarCompanies.status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {similarCompanies.status === 'error' && <div>Error fetching data</div>}
      {similarCompanies.status === 'success' && (
        <div>
          {!companyData.companyToBeDeleted && (
            <>
              {' '}
              {similarCompanies.data.data.data.length === 0 ? (
                <Typography m={2} align='center'>
                  {/* No similar company found */}
                </Typography>
              ) : (
                <>
                  <Alert severity='warning'>
                    This company matches some fields with the following
                    companies. Please review and merge them if required
                  </Alert>
                  {similarCompanies.data.data.data.map((item) => {
                    return (
                      <Stack
                        key={item._id}
                        direction='row'
                        justifyContent='flex-start'
                        alignItems='center'
                        spacing={2}
                      >
                        <Typography>
                          {item.name} | {item.country} | {item.website}{' '}
                        </Typography>
                        <Button onClick={() => replaceCompany(item)}>
                          Merge
                        </Button>
                      </Stack>
                    );
                  })}
                </>
              )}
            </>
          )}
          {showFormik && (
            <Formik
              enableReinitialize={true}
              innerRef={formikRef}
              initialValues={{
                companyId: companyData._id,
                name: companyData.name,
                website: companyData.website,
                country: companyData.country,
                industryId: companyData.industryId,
                slogan: companyData.slogan,
                companyImageUrl: companyData.companyImageUrl,
                logoUrl: companyData.logoUrl,
                companyPhone: companyData.companyPhone,
                employeeName: companyData.employeeName,
                companyEmail: companyData.companyEmail,
                companyStage: 2,
                sectionTitle: companyData.sectionTitle,
                sectionContent: companyData.sectionContent || [
                  {
                    sectionNumber: 0,
                    contentToPublish: '',
                    contentType: 'Text',
                  },
                ],
              }}
              validationSchema={Yup.object({
                name: Yup.string()
                  .min(2, 'Must be 2 characters or more')
                  .max(100, 'Must be 100 characters or less')
                  .required('Please enter a name'),
                website: Yup.string().url().required(),
                companyImageUrl: Yup.string().url().required(),
                logoUrl: Yup.string().url().required(),
                slogan: Yup.string().required(),
                sectionContent: Yup.array().of(
                  Yup.object().shape({
                    contentToPublish: Yup.string().required(
                      'Company Brief content is required'
                    ),
                  })
                ),
              })}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                setServerResponse('');
                if (companyData.companyToBeDeleted) {
                  const myData = {
                    ...values,
                    companyToBeDeleted: companyData.companyToBeDeleted,
                  };
                  axios({
                    method: 'POST',
                    url: API_URL + '/company/merge-company',
                    data: myData,
                  })
                    .then(function (response) {
                      console.log(response);
                      queryClient.invalidateQueries('fetchUnderCompanies');
                      queryClient.invalidateQueries('fetchAllCompanies');
                      setSnackbarText('Company Updated successfully');
                      setSnackbarSeverity('success');
                      setSnackbarOpen(true);
                      handleCloseEdit();
                      setSubmitting(false);
                    })
                    .catch(function (response) {
                      console.log(response);
                      setServerResponse(
                        'Error! Could not process your request.'
                      );
                      setSubmitting(false);
                    });
                } else {
                  const myData = {
                    ...values,
                    sendAlert: true,
                  };
                  axios({
                    method: 'PUT',
                    url: API_URL + '/company',
                    data: myData,
                  })
                    .then(function (response) {
                      console.log(response);
                      queryClient.invalidateQueries('fetchUnderCompanies');
                      queryClient.invalidateQueries('fetchAllCompanies');
                      setSubmitting(false);
                      setSnackbarText('Company Updated successfully');
                      setSnackbarSeverity('success');
                      setSnackbarOpen(true);
                      handleCloseEdit();
                    })
                    .catch(function (response) {
                      console.log(response);
                      setServerResponse(
                        'Error! Could not process your request.'
                      );
                      setSubmitting(false);
                    });
                }
              }}
            >
              {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                  <Grid
                    mt={1}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                    mb={3}
                  >
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        size='small'
                        fullWidth
                        name='name'
                        type='text'
                        label='Company Name'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        size='small'
                        fullWidth
                        name='website'
                        type='text'
                        label='Company Website'
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      {countries.status === 'success' && (
                        <SelectInput
                          size='small'
                          fullWidth
                          name='country'
                          label='Country'
                        >
                          {Object.entries(countries.data.data).map(
                            ([countryCode, countryData]) => {
                              return (
                                <MenuItem
                                  key={countryCode}
                                  value={countryData.name}
                                >
                                  {countryData.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </SelectInput>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {industries.status === 'success' && (
                        <SelectInput
                          size='small'
                          fullWidth
                          name='industryId'
                          label='Industry'
                        >
                          {industries.data.data.data.map((indistry) => {
                            return (
                              <MenuItem key={indistry._id} value={indistry._id}>
                                {indistry.title}
                              </MenuItem>
                            );
                          })}
                        </SelectInput>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        size='small'
                        name='companyImageUrl'
                        type='text'
                        label='Company ImageUrl'
                      />
                      <Button
                        type='button'
                        onClick={() => {
                          setOpen(true);
                          setSelectedField('companyImageUrl');
                          setRefresh(!refresh);
                        }}
                      >
                        Select Image
                      </Button>
                      <div>
                        <img src={values.companyImageUrl} alt='' width='150' />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        size='small'
                        name='logoUrl'
                        type='text'
                        label='Company Logo'
                      />
                      <Button
                        type='button'
                        onClick={() => {
                          setOpen(true);
                          setSelectedField('logoUrl');
                          setRefresh(!refresh);
                        }}
                      >
                        Select Image
                      </Button>
                      <div>
                        <img src={values.logoUrl} alt='' width='150' />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextInput
                        fullWidth
                        size='small'
                        name='slogan'
                        type='text'
                        label='Company slogan'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        size='small'
                        name='employeeName'
                        type='text'
                        label='Contact Person'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        fullWidth
                        size='small'
                        name='companyPhone'
                        type='text'
                        label='Contact Person phone'
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      {values.companyEmail && (
                        <Typography>Email: {values.companyEmail} </Typography>
                      )}
                    </Grid>
                  </Grid>

                  <Typography>Company Brief</Typography>
                  <Button
                    variant='text'
                    color='primary'
                    onClick={() => {
                      setSections(sections + 1);
                    }}
                    type='button'
                    className='shadow bg-green-400 text-green p-1 text-xs rounded ml-4'
                  >
                    Add section +
                  </Button>
                  {sections > 1 && (
                    <Button
                      variant='text'
                      color='primary'
                      onClick={() => {
                        setSections(sections - 1);
                      }}
                      type='button'
                      className='shadow bg-red-400 text-green p-1 text-xs rounded ml-2'
                    >
                      Remove section -
                    </Button>
                  )}

                  <Box
                    sx={{
                      backgroundColor: 'primary.light',
                      padding: '20px',
                      border: '1px solid',
                      borderColor: 'primary.main',
                      borderRadius: '2px',
                      marginY: '10px',
                    }}
                  >
                    {[...Array(sections)].map((e, i) => (
                      <div key={i}>
                        <Stack direction='row' justifyContent='flex-start'>
                          <Grid container>
                            <Grid item xs={4} md={2}>
                              <Typography
                                variant='subtitle1'
                                m={2}
                                gutterBottom
                              >
                                Section {i + 1}
                              </Typography>
                            </Grid>
                            <Grid item xs={8} md={10}>
                              <TextInput
                                name={`sectionTitle[${i}]`}
                                type='text'
                                placeholder='Title'
                                fullWidth
                              ></TextInput>
                            </Grid>
                          </Grid>
                        </Stack>
                        <FieldArray
                          name='sectionContent'
                          render={(arrayHelpers) => (
                            <>
                              <div className='space-y-4 mt-6'>
                                <Button
                                  type='button'
                                  onClick={() =>
                                    arrayHelpers.push({
                                      sectionNumber: i,
                                      contentToPublish: '',
                                      contentType: 'Text',
                                    })
                                  }
                                >
                                  +ADD
                                </Button>

                                {values.sectionContent.map((content, index) => {
                                  return (
                                    content.sectionNumber === i && (
                                      <Grid
                                        container
                                        rowSpacing={3}
                                        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                        mb={3}
                                      >
                                        {' '}
                                        <Grid item xs={12} md={6}>
                                          <Grid container>
                                            <Grid item xs={4}>
                                              <Typography
                                                variant='subtitle1'
                                                gutterBottom
                                              >
                                                Content to publish
                                              </Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                              <TextInput
                                                primary
                                                multiline
                                                fullWidth
                                                label='Content'
                                                name={`sectionContent[${index}].contentToPublish`}
                                                className='w-1/3 p-2'
                                                placeholder='Content'
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                          <SelectInput
                                            name={`sectionContent.${index}.contentType`}
                                          >
                                            <MenuItem value='Text'>
                                              Text
                                            </MenuItem>
                                            <MenuItem value='List'>
                                              List
                                            </MenuItem>
                                            <MenuItem value='Tab'>Tab</MenuItem>
                                            <MenuItem value='Link'>
                                              Link
                                            </MenuItem>
                                            <MenuItem value='Image'>
                                              Image
                                            </MenuItem>
                                            <MenuItem value='File'>
                                              File
                                            </MenuItem>
                                          </SelectInput>
                                        </Grid>
                                        <Grid item xs={2} sm={2}>
                                          {index > 0 && (
                                            <Button
                                              type='button'
                                              onClick={() =>
                                                arrayHelpers.remove(index)
                                              }
                                            >
                                              -Remove
                                            </Button>
                                          )}
                                        </Grid>
                                      </Grid>
                                    )
                                  );
                                })}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    ))}
                  </Box>

                  <Stack
                    direction='row'
                    justifyContent='center'
                    alignItems='center'
                    spacing={3}
                  >
                    <LoadingButton
                      type='submit'
                      variant='contained'
                      loading={isSubmitting}
                    >
                      Update
                    </LoadingButton>
                  </Stack>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      m: 1,
                    }}
                  >
                    {serverResponse && (
                      <Typography>{serverResponse}</Typography>
                    )}
                  </Box>
                </Form>
              )}
            </Formik>
          )}
          <div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby='modal-modal-title'
              aria-describedby='modal-modal-description'
            >
              <Box sx={style}>
                <ShowFilesPopup
                  setOpen={setOpen}
                  resource_type={'image'}
                  setImage={setImage}
                  setSelectedField={setSelectedField}
                  setRefresh={setRefresh}
                  refresh={refresh}
                />
              </Box>
            </Modal>
          </div>
        </div>
      )}
    </>
  );
};

export default EditUnderReviewCompany;

import React, { useRef, useState } from 'react';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import ShowFilesPopup from '../../halper/showFilesPopup';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { grey } from '@mui/material/colors';
import { Grid, MenuItem, Typography, Box, Button, Modal } from '@mui/material';
import {
  TextInput,
  SelectInput,
  CheckBoxField,
} from '../../layouts/FormElement';
import { FieldArray } from 'formik';
import { API_URL } from 'links/API_LINKS';
import axios from 'axios';
import { useQuery } from 'react-query';
import Multiselect from 'multiselect-react-dropdown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchCountry = async (keys) => {
  const res = await axios.get(API_URL + '/countrytimezones/countries');
  return res;
};

const fetchCapstone = async (params) => {
  const res = await axios.get(API_URL + '/capstone/info', {
    params: { limit: 0 },
  });
  return res;
};

const fetchCapDomains = async (params) => {
  const res = await axios.get(API_URL + '/capstone/filter-data');
  return res;
};

const PartnerForm = ({ values, setFieldValue }) => {
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const multiselectRef = useRef();
  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }

  function clear() {
    setFieldValue('capstones', []);
    multiselectRef.current.resetSelectedValues();
  }

  function onDomainSelect(domainId) {
    setFieldValue('selectedDomain', domainId);
    const selectedCapstones = data?.data?.data.filter(
      (cap) => cap.domainId._id === domainId
    );
    setFieldValue(
      'capstones',
      selectedCapstones.map((selectedCapstone) => {
        return selectedCapstone._id;
      })
    );
    multiselectRef.current.resetSelectedValues();
  }

  const countries = useQuery('fetchCountries', fetchCountry);

  const { data, isSuccess } = useQuery(`fetchCapstone`, fetchCapstone);
  const { data: domainsData } = useQuery(`fetchCapDomains`, fetchCapDomains);

  let allCapstones = [];

  if (isSuccess && data?.data?.data?.length) {
    allCapstones = data?.data?.data.map((cap) => {
      return { id: cap._id, name: cap.title + '-' + cap.domainId.title };
    });
  }

  return (
    <>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 2 }}
        my={2}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='name'
            type='text'
            label='Name'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='code'
            type='text'
            label='Code'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='website'
            type='text'
            label='Website URL'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <TextInput
              size='small'
              fullWidth
              name='logoUrl'
              type='text'
              label='Logo URL'
            />
            <Button
              sx={{ mr: 1 }}
              size='small'
              variant='contained'
              onClick={() => {
                setOpen(true);
                setSelectedField('logoUrl');
                setRefresh(!refresh);
              }}
            >
              Select image
            </Button>
            <div>
              <img src={values.logoUrl} alt='' width='150' />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Box>
            <TextInput
              size='small'
              fullWidth
              name='displayLogo'
              type='text'
              label='Display Logo URL'
            />
            <Button
              sx={{ mr: 1 }}
              size='small'
              variant='contained'
              onClick={() => {
                setOpen(true);
                setSelectedField('displayLogo');
                setRefresh(!refresh);
              }}
            >
              Select image
            </Button>
            <div>
              <img src={values.displayLogo} alt='' width='150' />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <PhoneInput
            name='phone'
            value={values.phone}
            onChange={(phone) => setFieldValue('phone', phone)}
            preferredCountries={['in', 'za']}
            placeholder='Phone No.'
            country={'in'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='city'
            type='text'
            label='City'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' fullWidth name='country' label='Country'>
            {countries.status === 'success' &&
              Object.entries(countries.data.data.data).map(
                ([countryCode, countryData]) => {
                  return (
                    <MenuItem key={countryCode} value={countryData.name}>
                      {countryData.name}
                    </MenuItem>
                  );
                }
              )}
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            multiline
            minRows={2}
            name='note'
            type='text'
            label='Note (For our internal use)'
          />
        </Grid>
      </Grid>

      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 6 }}
        my={3}
      >
        <Grid item xs={12}>
          <CheckBoxField
            name='showCourses'
            label='Show Courses'
            defaultChecked={values.showCourses}
          />
        </Grid>
        {values.showCourses && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 1, md: 6 }}
            my={1}
            px={6}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ backgroundColor: grey[200], p: 2, borderRadius: 2 }}>
                <Typography variant='h6'>Courses</Typography>
                <Typography variant='p'>
                  Add courses which will show up in dropdown on the custom
                  partner page
                </Typography>
              </Box>
            </Grid>
            {[...Array(1)].map((e, i) => (
              <Grid item xs={12} sm={6} md={6} key={i}>
                <FieldArray
                  name='courses'
                  render={(arrayHelpers) => (
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <Typography>Add Course</Typography>
                        <Button
                          type='button'
                          onClick={() => arrayHelpers.push()}
                        >
                          <FaPlusCircle />
                        </Button>
                      </Box>
                      {values.courses.map((content, index) => {
                        return (
                          <Box sx={{ display: 'flex', mt: 1 }} key={index}>
                            <TextInput
                              size='small'
                              fullWidth
                              name={`courses[${index}]`}
                              type='text'
                              label='Course'
                            />
                            <Button
                              type='button'
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <FaTrashAlt />
                            </Button>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      {/* Capstone starts here */}
      <Grid container>
        <Grid item xs={12}>
          <CheckBoxField name='showCapstones' label='Show all Capstones' />
        </Grid>
        {values.showCapstones ? null : (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 1, md: 6 }}
            my={1}
          >
            <Grid item xs={12} md={8}>
              {isSuccess && (
                <Box sx={{ backgroundColor: grey[200], p: 2, borderRadius: 2 }}>
                  <Typography variant='h6'>Select Capstones to show</Typography>

                  <Multiselect
                    ref={multiselectRef}
                    options={allCapstones}
                    displayValue='name'
                    placeholder='Select Capstones'
                    selectedValues={allCapstones.filter((cap) => {
                      return values.capstones.includes(cap.id);
                    })}
                    // loading={isLoading}
                    // onSelect={onChange}
                    onSelect={(selectedList, selectedItem) => {
                      const myCaptone = values.capstones.concat([
                        selectedItem.id,
                      ]);
                      setFieldValue('capstones', myCaptone);
                    }}
                    onRemove={(selectedList, removedItem) => {
                      const myCapstone = values.capstones.filter((cap) => {
                        return cap !== removedItem.id;
                      });
                      setFieldValue('capstones', myCapstone);
                    }}
                    style={style}
                    customCloseIcon={
                      <div
                        style={{
                          marginLeft: '6px',
                          fontWeight: '600',
                          cursor: 'pointer',
                        }}
                      >
                        X
                      </div>
                    }
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <SelectInput
                size='small'
                fullWidth
                name='selectedDomain'
                onChange={(e) => {
                  onDomainSelect(e.target.value);
                }}
                label='Domain'
                style={{ padding: '6px' }}
              >
                {domainsData?.data?.allDomain?.map((domain) => {
                  return (
                    <MenuItem key={domain._id} value={domain._id}>
                      {domain.title}
                    </MenuItem>
                  );
                })}
              </SelectInput>

              <Box>
                {values.selectedDomain && (
                  <Typography>
                    {values.capstones.length} Capstones selected
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Button variant='contained' onClick={clear}>
                Clear Selected Capstones
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>

      {/* Capstone ends here */}

      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 6 }}
        my={3}
      >
        <Grid item xs={12}>
          <CheckBoxField name='isEvent' label='Event' />
        </Grid>
        {values.isEvent && (
          <Grid
            container
            rowSpacing={2}
            columnSpacing={{ xs: 2, sm: 1, md: 6 }}
            my={1}
            px={6}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box sx={{ backgroundColor: grey[200], p: 2, borderRadius: 2 }}>
                <Typography variant='h6'>University</Typography>
                <Typography variant='p'>
                  Add University which will show up in dropdown on the custom
                  partner page for events
                </Typography>
              </Box>
            </Grid>
            {[...Array(1)].map((e, i) => (
              <Grid item xs={12} sm={6} md={6} key={i}>
                <FieldArray
                  name='university'
                  render={(arrayHelpers) => (
                    <>
                      <Box sx={{ display: 'flex' }}>
                        <Typography>Add</Typography>
                        <Button
                          type='button'
                          onClick={() => arrayHelpers.push()}
                        >
                          <FaPlusCircle />
                        </Button>
                      </Box>
                      {values.university.map((content, index) => {
                        return (
                          <Box sx={{ display: 'flex', mt: 1 }} key={index}>
                            <TextInput
                              size='small'
                              fullWidth
                              name={`university[${index}]`}
                              type='text'
                              label='University'
                            />
                            {index > 0 && (
                              <Button
                                type='button'
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <FaTrashAlt />
                              </Button>
                            )}
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Box>
        <Typography mt={2}>Tab to show on B2B user Sidebar</Typography>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <CheckBoxField name='sidebarTab.capstone' label='Capstones' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CheckBoxField name='sidebarTab.vga' label='VGA' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <CheckBoxField name='sidebarTab.job' label='Jobs' />
          </Grid>
        </Grid>
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </div>
    </>
  );
};
export default PartnerForm;

import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
// import { useFormikContext } from 'formik';

import {
  Box,
  Modal,
  Grid,
  MenuItem,
  Button,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import ShowFilesPopup from '../../halper/showFilesPopup';

import TextEditor from 'components/_dashboard/pod/capstones/TextEditor';
// import Multiselect from 'multiselect-react-dropdown';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const skillsByCategory = {
  COMMUNICATION: [
    'Effective Communication',
    'Emotional Intelligence and Empathy',
    'Self-Awareness and Growth Mindset',
  ],
  MINDSET: ['Problem Solving & Reasoning', 'Creativity', 'Attention to Detail'],
  MANAGEMENT: ['Self-Motivation', 'Time Management', 'Adaptability'],
  COLLABORATION: [
    'Teamwork',
    'Networking & Relationship Building',
    'Initiative & Leadership',
  ],
};

const QuestionForm = ({
  values,
  setFieldValue,
  preSkills,
  questionPreValue,
  optionsPreValue,
}) => {
  //   const formikRef = useRef();
  //   const [serverResponse, setServerResponse] = useState();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  //   const [sections, setSections] = useState(1);
  const [selectedSkills, setSelectedSkills] = useState(preSkills || []);
  //   const { setFieldValue: formikSetFieldValue } = useFormikContext();

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }

  useEffect(() => {
    setSelectedSkills(skillsByCategory[values.aptitudeCategory]);
  }, [values.aptitudeCategory]);

  return (
    <Grid>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
        mb={3}>
        <Grid item xs={12} sm={5}>
          <SelectInput
            size='small'
            name='aptitudeCategory'
            type='text'
            label='Question Category'>
            <MenuItem value='COMMUNICATION'>
              Interpersonal Communication
            </MenuItem>
            <MenuItem value='MINDSET'> Analytical Mindset </MenuItem>
            <MenuItem value='MANAGEMENT'>Self Management </MenuItem>
            <MenuItem value='COLLABORATION'>Collaborative Engagement</MenuItem>
          </SelectInput>
        </Grid>

        <Grid item xs={4} sm={2}>
          <TextInput
            size='small'
            label='Time in seconds'
            name='time'
            type='number'
          />
        </Grid>

        <Grid item xs={9}>
          <Typography variant='h6'>Question</Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={questionPreValue || ''}
            valueToUpdate='question'
          />
        </Grid>
        <Grid item xs={12}>
          <Grid item>
            {[...Array(1)].map((e, i) => (
              <Grid item key={i}>
                <FieldArray
                  name='image'
                  render={(arrayHelpers) => (
                    <>
                      <Grid
                        item
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '5px',
                        }}>
                        Add question image
                        <Button
                          type='button'
                          onClick={() => arrayHelpers.push()}>
                          <FaPlusCircle />
                        </Button>
                        {values.image.map((content, index) => {
                          return (
                            <Grid item key={index}>
                              <Grid
                                item
                                sm={12}
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}>
                                <TextInput
                                  name={`image[${index}]`}
                                  type='text'
                                  placeholder='Image URL'
                                />
                                <Grid item>
                                  <Button
                                    type='button'
                                    onClick={() => {
                                      setOpen(true);
                                      setSelectedField(`image[${index}]`);
                                      setRefresh(!refresh);
                                    }}>
                                    Select File
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <img
                                    src={values.image[index]}
                                    alt=''
                                    width='150'
                                  />
                                  <Grid item>
                                    <Button
                                      type='button'
                                      onClick={() =>
                                        arrayHelpers.remove(index)
                                      }>
                                      <FaTrashAlt />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </>
                  )}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1} direction='column'>
        <FieldArray
          name='options'
          render={(arrayHelpers) => (
            <>
              {values.options.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    padding: '16px',
                    marginTop: '16px',
                    backgroundColor: '',
                  }}>
                  <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12}>
                      <Typography variant='h6'>
                        Option - {index + 1}{' '}
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          (optionsPreValue && optionsPreValue[index]?.option) ||
                          ''
                        }
                        valueToUpdate={`options[${index}].option`}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Grid container spacing={3}>
                        {option.ratings?.map((rating, ratingIndex) => (
                          <Grid item xs={6} sm={4} md={2.2} key={ratingIndex}>
                            <Box>
                              <Typography sx={{ mb: 1 }} variant='subtitle2'>
                                {rating.skill}
                              </Typography>
                              <SelectInput
                                size='small'
                                name={`options[${index}].ratings[${ratingIndex}].score`}
                                label='Score'>
                                {[0, 0.25, 0.5, 0.75, 1].map((value) => (
                                  <MenuItem key={value} value={value}>
                                    {value}
                                  </MenuItem>
                                ))}
                              </SelectInput>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                      <IconButton
                        color='error'
                        onClick={() => arrayHelpers.remove(index)}>
                        <FaTrashAlt />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Box>
              ))}

              <Stack direction='row' spacing={2} alignItems='center' mt={2}>
                <Button
                  variant='contained'
                  startIcon={<FaPlusCircle />}
                  onClick={() =>
                    arrayHelpers.push({
                      option: '',
                      ratings: selectedSkills.map((skill) => ({
                        skill: skill,
                        score: 0,
                      })),
                    })
                  }>
                  Add Option
                </Button>
              </Stack>
            </>
          )}
        />
      </Grid>

      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </Grid>
    </Grid>
  );
};

export default QuestionForm;

import React from 'react';
import { parse } from 'papaparse';
import { Typography } from '@mui/material';

const ReadCsv = ({ setCsvData }) => {
  async function csvdata(files) {
    const text = await files.text();
    const result = parse(text, {
      header: true,
      skipEmptyLines: 'greedy',
      dynamicTyping: true,
      comments: '//',
    });
    setCsvData(result.data);
  }
  return (
    <div>
      <Typography>Please upload a csv file</Typography>
      <input
        type='file'
        accept='.csv'
        onChange={(e) => {
          const files = e.target.files;
          if (files) {
            csvdata(files[0]);
          }
        }}
      />
    </div>
  );
};

export default ReadCsv;

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL_V1 } from '../../../links/API_LINKS';
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import { FaFilter, FaInfoCircle } from 'react-icons/fa';
import { AiOutlineZoomIn, AiOutlineZoomOut } from 'react-icons/ai';
import { GrPowerReset } from 'react-icons/gr';

import FoldersList from './FoldersList';
import ImageCard from './ImageCard';
import FileCard from './FileCard';
import FileUpload from './UploadFile';
import { Box, Divider, Grid, Stack, Typography, Button } from '@mui/material';

const fetchAllFiles = async (keys) => {
  //fetch files from cloudinary through server
  const res = await axios.get(API_URL_V1 + '/fileupload', {
    params: {
      resource_type: keys.queryKey[1],
      next: keys.queryKey[2],
      prefix: keys.queryKey[3],
    },
  });
  return res;
};

const fetchFolders = async () => {
  const res = await axios.get(API_URL_V1 + '/fileupload/sub_folders', {
    params: {
      root: 'uploaded_resources',
    },
  });
  return res;
};

const ShowFiles = (props) => {
  const [checked, setChecked] = useState({ image: true, raw: false });
  const [prev, setPrev] = useState([]);
  const [next, setNext] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [resourceType, setResourceType] = useState(
    props.resource_type || 'image'
  );
  const [trueSize, setTrueSize] = useState(false);
  const [imageWidth, setImageWidth] = useState('140px');
  const [popupVisible, setPopupVisibility] = useState(true);
  const folders = useQuery(['fetchAllFolders'], fetchFolders);

  const { status, data } = useQuery(
    ['fetchAllfiles', resourceType, next, selectedFolder],
    fetchAllFiles
  );

  const zoom = (zoom) => {
    const scale = 20;
    let width = imageWidth;
    width = +width.replace(/px$/i, '');
    const newWidth = width + zoom * scale;
    if (newWidth >= 80 && newWidth <= 240) setImageWidth(newWidth + 'px');
  };

  return (
    popupVisible && (
      <Box>
        <Stack
          direction='row'
          sx={{
            backgroundColor: 'primary.light',
          }}
          justifyContent='space-between'
        >
          <Typography variant='h6' sx={{ fontWeight: '8' }}>
            Choose a File
          </Typography>

          <Button
            variant='text'
            color='error'
            onClick={() => {
              setPopupVisibility(false);
              setNext(null);
              props.setSelectedField(!props.refresh);
              props.setRefresh(!props.refresh);
              props.setOpen(false);
            }}
          >
            Close
          </Button>
        </Stack>
        <Divider />
        <Grid container>
          <Grid item xs={3}>
            <Box
              sx={{
                backgroundColor: 'primary.light',
              }}
            >
              <Typography>Choose from cloud</Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: '#DFE3E8',
              }}
            >
              <Typography>Select Folder</Typography>
              {folders.status === 'success' && (
                <FoldersList
                  changeFolderParent={setSelectedFolder}
                  data={folders}
                  setPrev={setPrev}
                  setNext={setNext}
                ></FoldersList>
              )}
              <Divider />
              <FileUpload setImage={props.setImage} />
            </Box>
          </Grid>
          <Grid item xs={9}>
            <Stack direction='row' justifyContent='space-between'>
              <Box>
                <ButtonGroup toggle className='flex'>
                  <span>
                    <FaFilter />
                  </span>
                  <ToggleButton
                    type='checkbox'
                    checked={checked.image}
                    value='image'
                    onChange={(e) => {
                      setChecked({ raw: false, image: true });
                      if (resourceType !== 'image') {
                        // setSelectedFolder(null);
                        setResourceType('image');
                        setNext(null);
                        setPrev([]);
                      }
                    }}
                  >
                    <span>Image</span>
                  </ToggleButton>
                  <ToggleButton
                    type='checkbox'
                    checked={checked.raw}
                    value='raw'
                    onChange={(e) => {
                      setChecked({ raw: true, image: false });
                      if (resourceType !== 'raw') {
                        //setSelectedFolder(null);
                        setResourceType('raw');
                        // setSelectedFolder(null);
                        setNext(null);
                        setPrev([]);
                      }
                    }}
                  >
                    <span>File</span>
                  </ToggleButton>
                </ButtonGroup>
              </Box>
              <Box>
                <AiOutlineZoomOut
                  onClick={() => {
                    zoom(-1);
                  }}
                />
                <AiOutlineZoomIn
                  onClick={() => {
                    zoom(1);
                  }}
                />
                <GrPowerReset
                  onClick={() => {
                    setImageWidth('140px');
                  }}
                />
              </Box>
              <Box>
                <FaInfoCircle className='inline' />{' '}
                <Typography variant='caption'>
                  Click on the file to copy link
                </Typography>
                <ButtonGroup toggle>
                  <ToggleButton
                    type='checkbox'
                    checked={trueSize}
                    onChange={(e) => {
                      setTrueSize(!trueSize);
                    }}
                  >
                    <Typography variant='caption'>
                      View original resolution
                    </Typography>
                  </ToggleButton>
                </ButtonGroup>
              </Box>
            </Stack>
            <Box>
              <Grid
                container
                spacing={{ xs: 1, md: 1 }}
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                {status === 'loading' && <div>Loading...</div>}
                {status === 'error' && <div>Error fetching data</div>}
                {status === 'success' &&
                  data.data.data.map((file) => {
                    return resourceType === 'image' ? (
                      <Grid item xs={2} sm={2} md={3}>
                        <ImageCard
                          file={file}
                          trueSize={trueSize}
                          width={imageWidth}
                          setImage={props.setImage}
                        />
                      </Grid>
                    ) : (
                      <Grid item xs={2} sm={4} md={3}>
                        <FileCard file={file} setImage={props.setImage} />
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
            <Box>
              <Button
                type='button'
                className={`${
                  prev.length === 0 ? 'text-gray-400' : 'text-primary '
                }`}
                disabled={prev.length === 0}
                onClick={() => {
                  setNext(prev.pop() || null);
                }}
              >
                prev
              </Button>
              <Button
                type='button'
                className={`${
                  data && data.data.next === null
                    ? 'text-gray-400'
                    : 'text-primary '
                }`}
                disabled={data && data.data.next === null}
                onClick={() => {
                  prev.push(next);
                  setNext(data.data.next || null);
                }}
              >
                next
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    )
  );
};

export default ShowFiles;

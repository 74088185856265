import React from 'react';
import { DateTimePickerField, TextInput } from '../../layouts/FormElement';
import { Grid, Typography } from '@mui/material';
import TextEditor from '../../pod/capstones/TextEditor';

const AssignmentForm = ({
  values,
  setFieldValue,
  assignmentToEdit,
  errors,
}) => {
  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 3 }}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='title'
            type='text'
            label='Title*'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateTimePickerField
            name='deadline'
            value={values.deadline}
            label='Deadline'
            onChange={setFieldValue}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='skills'
            type='text'
            label='Skills*'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='task_no'
            type='number'
            label='Task No*'
          />
        </Grid>
        <Grid item xs={12}>
          <Typography mb={1} variant='subtitle2'>
            guideline
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={assignmentToEdit?.guideline || ''}
            valueToUpdate='guideline'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.guideline}
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography mb={1} variant='subtitle2'>
            Resources
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={assignmentToEdit?.resources || ''}
            valueToUpdate='resources'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.resources}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AssignmentForm;

import React from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import InvoiceHeader from './InvoiceHeader';
import exportFromJSON from 'export-from-json';

const PendingInvoice = ({ setValue }) => {
  const fetchPendingInvoice = async () => {
    const res = await axios.get(API_URL + '/invoice', {
      params: { status: 'Pending', limit: 0 },
    });
    return res;
  };
  const { status, data } = useQuery(`fetchPendingInvoice`, fetchPendingInvoice);

  const downloadFunc = () => {
    const dataToBeSaved = data.data.data.map((item) => {
      const rowObj = {
        'Coach Name': item.coachId.name,
        'Batch Name': item.batchId.name,
        'Total Amount': `${item.totalAmount} ${
          item.currency ? item.currency : ''
        }`,
        'Added On': new Date(item.createdAt).toDateString(),
      };

      return rowObj;
    });

    const fileName = `pending-invoices`;
    const exportType = 'xls';
    exportFromJSON({ data: dataToBeSaved, fileName, exportType });
  };

  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'>
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <Box>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
            mb={2}>
            <Button onClick={downloadFunc}>Download</Button>
          </Stack>
          <InvoiceHeader data={data.data.data} setValue={setValue} />
        </Box>
      )}
    </div>
  );
};

export default PendingInvoice;

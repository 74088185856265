import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { Box, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useQueryClient } from 'react-query';
import * as Yup from 'yup';
import axios from 'axios';

import PartnerForm from './PartnerForm';
import { API_URL } from 'links/API_LINKS';

const AddPartner = ({ setValue }) => {
  const queryClient = useQueryClient();
  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();
  return (
    <Box sx={{ my: 4, px: 4 }}>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          name: '',
          code: '',
          website: '',
          logoUrl: '',
          displayLogo: '',
          phone: '',
          city: '',
          country: 'India',
          timezone: 'Asia/Kolkata',
          // cpName: '',
          // cpPhone: '',
          // cpEmail: '',
          capstones: [],
          note: '',
          courses: [],
          whitelistField: [],
          isEvent: false,
          showCapstones: true,
          selectedDomain: '',
          showCourses: true,
          university: [''],
          sidebarTab: {
            capstone: false,
            vga: false,
            job: false,
          },
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be 3 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a Name'),
          code: Yup.string().required(),
          website: Yup.string().url().required(),
          logoUrl: Yup.string().url().required(),
          courses: Yup.array().of(Yup.string().required('Please enter course')),
          university: Yup.array().when('isEvent', {
            is: true,
            then: Yup.array().of(
              Yup.string().required('Please enter university')
            ),
          }),
        })}
        onSubmit={(values, { setSubmitting, setFieldValue, resetForm }) => {
          setServerResponse('');
          const myData = { ...values };
          delete myData.showCapstones;
          delete myData.selectedDomain;
          //Empty capstones if show all capstones is checked
          if (values.showCapstones) myData.capstones = [];
          axios({
            method: 'POST',
            url: API_URL + '/partner',
            data: myData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchAllPartners');
              setValue(0);
              setServerResponse(response.data.msg);
              setSubmitting(false);
              response.status === 201 && resetForm();
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <PartnerForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
              mt={2}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Partner
              </LoadingButton>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddPartner;

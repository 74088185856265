import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import CenturyLabForm from './CenturyLabForm';

const AddCenturyTask = ({
  allProject,
  checkForError,
  setAllProject,
  currentBatch,
  handleClose,
}) => {
  return (
    <Box>
      <Formik
        enableReinitialize={false}
        initialValues={{
          batchId: currentBatch._id,
          title: '',
          level: 'Basic',
          type: '21st Century Skills',
          introduction: '',
          template: {
            link: '',
            description: '',
          },
          tasks: [
            {
              skillId: [],
              learningOutcomes: '',
              backgroundDescription: '',
              taskInstructions: '',
              resources: '',
              // level: 'Basic',
              week: '',
              publishAt: new Date(),
              dueDateTime: new Date(),
            },
          ],
          checked: true,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          tasks: Yup.array().of(
            Yup.object().shape({
              learningOutcomes: Yup.string().required(
                'Learning Outcomes is required'
              ),
              taskInstructions: Yup.string().required(
                'Instructions is required'
              ),
              skillId: Yup.array().min(1, 'Minimum 1 skill required'),
              week: Yup.number().required(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const tempAllProject = [...allProject];
          tempAllProject.push(values);
          setAllProject(tempAllProject);
          checkForError(tempAllProject);
          handleClose();
        }}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <CenturyLabForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
              mt={2}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Add Project
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            ></Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddCenturyTask;

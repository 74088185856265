import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import QuestionFormB2C from './QuestionFormB2C';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditQuestionB2C = ({ questionToEdit, setQuestionToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const { _id, question, type, domain, options, maxSelect, skill, image } = {
    ...questionToEdit,
  };
  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setQuestionToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          questionId: _id,
          question: question,
          type: type,
          domain: domain ? domain._id : '',
          options: options,
          maxSelect: maxSelect,
          skill: skill?._id || '',
          image: image || [],
        }}
        validationSchema={Yup.object({
          question: Yup.string().required('Please enter a Question'),
          domain: Yup.string().when('type', {
            is: 'B2C DOMAIN',
            then: Yup.string().required('Please select a Domain.'),
          }),
          skill: Yup.string()
            .when('type', {
              is: 'B2C DOMAIN',
              then: Yup.string().required('Please select a skill.'),
            })
            .nullable(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const updatedData = { ...values };
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/question',
            data: updatedData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchQuestionB2c');
              setServerResponse('question updated');
              setSubmitting(false);
              setQuestionToEdit();
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <QuestionFormB2C values={values} setFieldValue={setFieldValue} />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditQuestionB2C;

import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import { Box, Button, Typography } from '@mui/material';
import { API_URL } from '../../../../../../links/API_LINKS';
import AddNewUser from './AddNewUser';

const Index = ({ batchData, setAdduserBatch }) => {
  const fetchCurrentBatch = async () => {
    const res = await axios.get(API_URL + '/batch', {
      params: { batchId: batchData._id },
    });
    return res;
  };
  const { status, data } = useQuery(
    `fetchCurrentBatch${batchData._id}`,
    fetchCurrentBatch
  );
  return (
    <div>
      <Button onClick={() => setAdduserBatch(null)}>Back</Button>
      <Typography mb={1} textAlign='center'>
        User Details of {batchData.name}{' '}
      </Typography>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && <AddNewUser batchData={data.data.data[0]} />}
    </div>
  );
};

export default Index;

import React from 'react';
import { Grid, useTheme, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {
  previousMonday,
  previousSunday,
  nextSunday,
  subWeeks,
  sub,
  format,
} from 'date-fns';

const Header = ({
  reportTime,
  setReportTime,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  reportSummary,
}) => {
  let today = new Date();
  const theme = useTheme();

  function handleChange(e) {
    const PREVIOUS_MONDAY = previousMonday(today);
    const PREVIOUS_SUNDAY = previousSunday(today);
    const NEXT_SUNDAY = nextSunday(today);

    setReportTime(e.target.value);

    switch (e.target.value) {
      case 'TODAY':
        setStartDate(today);
        setEndDate(today);
        break;
      case 'CURRENT_WEEK':
        setStartDate(PREVIOUS_MONDAY);
        setEndDate(NEXT_SUNDAY);
        break;
      case 'PREVIOUS_WEEK':
        setStartDate(subWeeks(PREVIOUS_MONDAY, 1));
        setEndDate(PREVIOUS_SUNDAY);
        break;
      case 'LAST_30_DAYS':
        setStartDate(sub(today, { days: 30 }));
        setEndDate(today);
        break;
      default:
        setStartDate(today);
        setEndDate(today);
    }
  }

  return (
    <>
      <Grid item xs={12} mt={4}>
        <Typography variant='h4' component='h4' align='center'>
          Report
        </Typography>
      </Grid>
      <Grid item xs={4} mx='auto'>
        <FormControl fullWidth>
          <InputLabel id='demo-simple-select-label'>Report Duration</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={reportTime}
            label='Report Duration'
            onChange={handleChange}
          >
            <MenuItem value='TODAY'>Today</MenuItem>
            <MenuItem value='CURRENT_WEEK'>Current Week</MenuItem>
            <MenuItem value='PREVIOUS_WEEK'>Previous Week</MenuItem>
            <MenuItem value='LAST_30_DAYS'>Last 30 Days</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid container item spacing={3} columns={{ xs: 12, md: 12 }}>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              Start Date:
            </Typography>{' '}
            {format(startDate, 'PPP')}
          </Box>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              End Date:
            </Typography>{' '}
            {format(endDate, 'PPP')}
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              Total Users:
            </Typography>{' '}
            {reportSummary.totalUsers}
          </Box>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              Organic Users:
            </Typography>{' '}
            {reportSummary.totalOrganic}
          </Box>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              Total Applications:
            </Typography>{' '}
            {reportSummary.totalApplications}
          </Box>
          <Box>
            <Typography variant='span' sx={{ ...theme.typography.subtitle1 }}>
              Organic Applications:
            </Typography>{' '}
            {reportSummary.organicApplications}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Header;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import CapstoneForm from './CapstoneForm';
import { useQueryClient } from 'react-query';

const CreateNewCapstone = ({ setValue }) => {
  const queryClient = useQueryClient();
  const [activeStep, setActiveStep] = useState(0);

  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  function addQuestions(questions, capstoneId) {
    return Promise.all(
      questions.map(async (question) => {
        const questionData = { ...question, capstoneId };
        return await axios({
          method: 'POST',
          url: API_URL + '/question',
          data: questionData,
        });
      })
    );
  }

  return (
    <div className='space-y-4'>
      <Box>
        <Typography variant='h6'>Add a new capstone</Typography> <br />
      </Box>
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            title: '',
            domainId: '',
            companyId: '',
            level: 'Basic',
            skillId: [],
            tasks: [
              {
                title: '',
                skillId: [],
                backgroundDescription: '',
                taskInstructions: '',
                resources: '',
                time: '',
                idealSolution: '',
              },
            ],
            tools: '',
            taskDependency: false,
            prerequisites: '',
            introductoryVideo: '',
            videoImage: '',
            capstoneImage: '',
            businessObjectives: '',
            learningObjectives: '',
            problemStatement: '',
            mentorDetail: {
              name: '',
              linkedin: '',
              designation: '',
              image: '',
            },
            dataset: {
              link: '',
              description: '',
            },
            questions: [],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            domainId: Yup.string().required('Domain is required'),
            companyId: Yup.string().required('Company is required'),
            level: Yup.string().required(),
            skillId: Yup.array().min(1, 'Minimum 1 skill required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            learningObjectives: Yup.string().required(
              'learning Objectives is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            introductoryVideo: Yup.string().url(),
            taskDependency: Yup.boolean().required('Task Dependency required'),
            prerequisites: Yup.string(),
            tools: Yup.string(),
            tasks: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required('Title is required'),
                backgroundDescription: Yup.string().required(
                  'Description is required'
                ),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                resources: Yup.string(),
                time: Yup.number(),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
              })
            ),
            questions: Yup.array().of(
              Yup.object().shape({
                question: Yup.string().required('question is required'),
              })
            ),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            const questions = values.questions;
            const capstoneData = { ...values, isActive: questions.length >= 5 };
            delete capstoneData.questions;
            // try {
            //   const res1 = await axios({
            //     method: 'POST',
            //     url: API_URL + '/capstone',
            //     data: capstoneData,
            //   });
            //   const res2 = await addQuestions(
            //     questions,
            //     res1?.data?.capstone?._id
            //   );
            //   console.log(res2);
            //   queryClient.invalidateQueries('fetchCapstone');
            //   queryClient.invalidateQueries(
            //     `fetchQuestion${res1?.data?.capstone?._id}`
            //   );
            //   setServerResponse(res1.data.msg);
            //   resetForm();
            //   setSubmitting(false);
            //   setValue(0);
            // } catch (e) {
            //   console.log(e);
            //   setServerResponse('Error! Could not process your request.');
            //   setSubmitting(false);
            // }
            axios({
              method: 'POST',
              url: API_URL + '/capstone',
              data: capstoneData,
            })
              .then(async function (response) {
                setServerResponse('Capstone Added, Question Adding..');
                const questionRes = await addQuestions(
                  questions,
                  response?.data?.capstone?._id
                );
                queryClient.invalidateQueries('fetchCapstone');
                queryClient.invalidateQueries(
                  `fetchQuestion${response?.data?.capstone?._id}`
                );
                console.log(questionRes);
                setServerResponse('question added');
                resetForm();
                setSubmitting(false);
                setValue(0);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <CapstoneForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                {activeStep === 2 && (
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    Add Capstone
                  </LoadingButton>
                )}
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewCapstone;

import React, { useState, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import {
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import CompanyBody from './CompanyBody';

import TableListHead from '../../layouts/TableListHead';
import EditCompany from './EditCompany';

import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { useQuery } from 'react-query';

const fetchCompanies = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword } = params;
  const res = await axios.get(API_URL + '/company', {
    params: { limit, page, sort, order, searchKeyword: searchRequestKeyword },
  });
  return res;
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function AllCompanies() {
  const [companyToEdit, setCompanyToEdit] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'name',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchCompanies`, paginationData, searchRequestKeyword],
    () => fetchCompanies({ ...paginationData, searchRequestKeyword }),
    { keepPreviousData: true }
  );

  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'name', label: 'Company', align: 'left' },
    { id: 'country', label: 'Country', align: 'left' },
    { id: 'website', label: 'Website', align: 'center' },
    { id: 'companyStage', label: 'status', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {companyToEdit ? (
        <EditCompany
          companyToEdit={companyToEdit}
          setCompanyToEdit={setCompanyToEdit}
        />
      ) : (
        <>
          <Paper elevation={5} sx={{ maxWidth: 345, margin: 1 }}>
            <TextField
              fullWidth
              label='Search Companies by name,webiste and country'
              value={searchKeyword}
              onChange={(e) => {
                setSearchKeyword(e.target.value);
                debouncedHandler(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      onClick={() => {
                        setSearchKeyword('');
                        debouncedHandler('', 0);
                      }}
                      edge='end'
                    >
                      {searchKeyword ? <CloseIcon /> : <SearchIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Paper>
          {status === 'loading' && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='50vh'
              minWidth='100vw'
            >
              <CircularProgress />
            </Box>
          )}
          {status === 'error' && <div>Error fetching data</div>}
          {status === 'success' && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data.data.data.map((row) => (
                    <CompanyBody
                      row={row}
                      key={row._id}
                      setCompanyToEdit={setCompanyToEdit}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data.data.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching || data.data.page === data.data.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default AllCompanies;

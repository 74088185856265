import React, { useRef } from 'react';
import {
  TextInput,
  DateTimePickerField,
  SelectInput,
} from '../../../../../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Button, Typography } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';

const ViewEvent = ({
  event,
  index,
  allEvent,
  setAllEvent,
  handleClose,
  snackbarActions,
}) => {
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          title: event.title,
          type: event.type,
          description: event.description,
          image: event.image,
          journies: event.journies,
          eventDate: event.eventDate,
          link: event.link,
          onlineOrOffline: event.onlineOrOffline,
          status: event.status,
          checked: false,
          resources: event.resources,
        }}
        validationSchema={Yup.object({
          link:
            event.type === 'Masterclass' &&
            Yup.string().url().required('link is required'),
          description: Yup.string().required('description is required'),
          title: Yup.string().required('title is required'),
          image: Yup.string().url().required('image is required'),
          resources: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
              link: Yup.string().url().required('Link is required'),
            })
          ),
          journies: Yup.object().shape({
            week: Yup.number().required('Week is required'),
          }),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          if (event.type === 'Masterclass') {
            values.checked = true;
            setSnackbarText('Event confirmed');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
          }
          const tempAllEvent = [...allEvent];
          const tempCurrentEvent = { ...tempAllEvent[index], ...values };
          tempAllEvent[index] = tempCurrentEvent;
          setAllEvent(tempAllEvent);
          setSubmitting(false);
          handleClose();
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='title'
                  type='text'
                  label='Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  name='journies.week'
                  type='number'
                  label='Week'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  multiline
                  rows={3}
                  name='description'
                  type='text'
                  label='Description'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='image'
                  type='text'
                  label='Image'
                />
                <div>
                  <img src={values.image} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePickerField
                  name='eventDate'
                  value={values.eventDate}
                  label='Event Date'
                  onChange={setFieldValue}
                />
              </Grid>
              {event.type === 'Masterclass' && (
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='link'
                    type='text'
                    label='Link'
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Status'
                >
                  <MenuItem value='publish'>Publish</MenuItem>
                  <MenuItem value='draft'>Draft</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name='resources'
                  render={(arrayHelpers) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography variant='h6'>Resources</Typography>

                        <Button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              link: '',
                            })
                          }
                        >
                          +ADD
                        </Button>
                      </div>
                      {values.resources.map((content, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                              mb={3}
                            >
                              {' '}
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].link`}
                                  type='text'
                                  label='Link'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].title`}
                                  type='text'
                                  label='Title'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update Event
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ViewEvent;

import React, { useRef } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectInput } from '../../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { Box, Grid, MenuItem, Typography, Stack, Button } from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const EditAssignment = ({ template, handleClose, snackbarActions }) => {
  const formikRef = useRef();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();

  return (
    <div>
      <div>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            assignmentTemplateId: template._id,
            title: template.title,
            type: template.type, //dropdown
            link: template.link,
            description: template.description, //multiline
            duration: template.duration / 1440, //number
            journies: template.journies,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            description: Yup.string().required('Description is required'),
            duration: Yup.number().required().positive().integer(),
            link: Yup.string().url(),
            journies: Yup.array().of(
              Yup.object().shape({
                journeyType: Yup.string().required(),
                journeyDuration: Yup.string().required(),
                week: Yup.number().required().positive().integer(),
                day: Yup.number().required().positive().integer(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            axios({
              method: 'PUT',
              url: API_URL + '/assignment-template',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Assignment template Updated successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchAssignmentTemplate');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to Update Assignment template, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                mb={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInput size='small' fullWidth name='type' label='Type'>
                    <MenuItem value='Company Tasks'>Company Tasks</MenuItem>
                    <MenuItem value='21st Century Skills'>
                      21st Century Skills{' '}
                    </MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='description'
                    type='text'
                    multiline
                    label='Description'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='link'
                    type='text'
                    label='Assignment Link'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='duration'
                    type='number'
                    label='Duration (in Days)'
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <FieldArray
                    name='journies'
                    render={(arrayHelpers) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Typography variant='h6'>Journies</Typography>
                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                journeyType: 'School/DT Journey',
                                journeyDuration: '4 weeks',
                                week: '',
                                day: '',
                              })
                            }
                          >
                            +ADD
                          </Button>
                        </div>

                        {values.journies.map((content, index) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor: 'primary.light',
                                padding: '20px',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '2px',
                                marginY: '10px',
                              }}
                            >
                              <Grid
                                key={index}
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                mb={3}
                              >
                                {' '}
                                <Grid item xs={12} sm={5}>
                                  <SelectInput
                                    size='small'
                                    fullWidth
                                    name={`journies[${index}].journeyType`}
                                    label='Journey Type'
                                  >
                                    <MenuItem value='School/DT Journey'>
                                      School/DT Journey
                                    </MenuItem>
                                    <MenuItem value='Tech'>Tech</MenuItem>
                                    <MenuItem value='Non Tech'>
                                      Non Tech
                                    </MenuItem>
                                  </SelectInput>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <SelectInput
                                    size='small'
                                    fullWidth
                                    name={`journies[${index}].journeyDuration`}
                                    label='Journey Duration'
                                  >
                                    <MenuItem value='4 weeks'>4</MenuItem>
                                    <MenuItem value='5 weeks'>5</MenuItem>
                                    <MenuItem value='6 weeks'>6</MenuItem>
                                    <MenuItem value='7 weeks'>7</MenuItem>
                                    <MenuItem value='8 weeks'>8</MenuItem>
                                    <MenuItem value='12 weeks'>12</MenuItem>
                                    <MenuItem value='15 weeks'>15</MenuItem>
                                    <MenuItem value='22 weeks'>22</MenuItem>
                                  </SelectInput>
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`journies[${index}].day`}
                                    type='number'
                                    label='Day'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`journies[${index}].week`}
                                    type='number'
                                    label='Week'
                                  />
                                </Grid>
                                <Grid item xs={2} sm={2}>
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </Grid>

              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Update Assignment Template
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography></Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditAssignment;

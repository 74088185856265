import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import QuestionForm from './QuestionForm';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditQuestion = ({ questionToEdit, setQuestionToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const {
    _id,
    question,
    type,
    aptitudeCategory,
    image,
    time,
    options,
    maxSelect,
  } = {
    ...questionToEdit,
  };

  const preSkills = options[0]?.ratings?.map((el) => {
    return { id: el.skill, name: el.skill };
  });

  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setQuestionToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          questionId: _id,
          question: question || '',
          type: type,
          aptitudeCategory: aptitudeCategory || '',
          image: image || [],
          time: time || '',
          options: options,
          maxSelect: maxSelect,
        }}
        validationSchema={Yup.object({
          question: Yup.string().required('Please enter a Question'),

          time: Yup.string().required('Please enter Time.'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const updatedData = { ...values }; //new added
          // console.log('updatedData', updatedData);

          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/question',
            data: updatedData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchQuestion');
              setServerResponse(response.data.msg);
              setSubmitting(false);
              setQuestionToEdit();
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <QuestionForm
              values={values}
              setFieldValue={setFieldValue}
              preSkills={preSkills}
              questionPreValue={question}
              optionsPreValue={options}
            />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}>
              <LoadingButton
                sx={{ mt: 2 }}
                type='submit'
                variant='contained'
                loading={isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}>
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditQuestion;

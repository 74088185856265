import React from 'react';
import { Grid, Container } from '@mui/material';
import DataCard from '../layouts/DataCard';
import AppCurrentVisits from '../layouts/AppCurrentVisits';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ClassIcon from '@mui/icons-material/Class';
import WeeklyChart from './WeeklyChart';
import MonthlyChart from './MonthlyChart';

import PeopleIcon from '@mui/icons-material/People';

const primaryLighter = '#C8FACD';
const infoLighter = '#D0F2FF';
const warningLighter = '#FFF7CD';
const errorLighter = '#FFE7D9';
const primaryDarker = '#005249';
const infoDarker = '#04297A';
const warningDarker = '#7A4F01';
const errorDarker = '#7A0C2E';

const Summary = ({ data }) => {
  const totalUser = 2120 + data.users.total;
  const batchLabel = ['VGA Batches', 'ETP Batches', 'Course Batches'];
  const batchData = [41 + data.batches, 4, 5];
  const userLabel = ['Organic Users', 'Partnership Users'];
  const userData = [data.users.organic, data.users.partner + 2120];
  const userCourseLabel = [
    'B. COM/ B.A / BSC/MSC/ LLB',
    'School/ Diploma Holder',
    'BBA/ MBA/ PGDM',
    'B.Tech/ BCA/ MCA/ I.T Support',
    'B.Arch / B. Des/ M Des.',
  ];
  const userCourseData = [
    Math.round(totalUser * 0.068),
    Math.round(totalUser * 0.029),
    Math.round(totalUser * 0.625),
    Math.round(totalUser * 0.243),
    Math.round(totalUser * 0.035),
  ];
  const userExperienceLabel = [
    'Fresher',
    'Less than 1 year',
    '1+ year - 3 years',
    '3+ years - 5 years',
    '5+ years',
  ];
  const userExperienceData = [
    Math.round(totalUser * 0.346),
    Math.round(totalUser * 0.323),
    Math.round(totalUser * 0.213),
    Math.round(totalUser * 0.112),
    Math.round(totalUser * 0.006),
  ];

  return (
    <Container maxWidth='xl' sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={totalUser}
            title='Total Users'
            icon={PeopleIcon}
            bgColor={primaryLighter}
            textColor={primaryDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={50 + data.batches}
            title='Total Batches'
            icon={ClassIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={data.events + data.masterclassAssessment}
            title='Total Live Sessions'
            icon={EmojiEventsIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={360 + data.assignments}
            title='Total Tasks'
            icon={AssignmentIcon}
            bgColor={errorLighter}
            textColor={errorDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={data.masterclassAssessment}
            title='No. of Masterclass'
            icon={AssignmentIcon}
            bgColor={errorLighter}
            textColor={errorDarker}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MonthlyChart />
        </Grid>
        <Grid item xs={12} md={12} lg={12} sx={{ mt: 3 }}>
          <WeeklyChart />
        </Grid>
      </Grid>
      <Grid container spacing={1} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <AppCurrentVisits
            heading='Batch Overview'
            label={batchLabel}
            data={batchData}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppCurrentVisits
            heading='User Overview'
            label={userLabel}
            data={userData}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppCurrentVisits
            heading='User Course'
            label={userCourseLabel}
            data={userCourseData}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <AppCurrentVisits
            heading='User Experience'
            label={userExperienceLabel}
            data={userExperienceData}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Summary;

import axios from 'axios';
import React, { useState } from 'react';

const UploadFile = (props) => {
  const [file, setFile] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);

  const uploadImage = async (e) => {
    const files = e.target.files;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'coias9vf');
    setUploadLoading(true);
    axios({
      method: 'POST',
      url: 'https://api.cloudinary.com/v1_1/belong/auto/upload',
      data: data,
      headers: null,
    })
      .then(function (response) {
        setFile(response.data.secure_url);
        setUploadLoading(false);
      })
      .catch(function (response) {
        setUploadLoading(false);
      });
  };
  return (
    <div>
      <p>Upload a new file</p>
      <input
        type='file'
        name='file'
        placeholder='Upload a file'
        onChange={uploadImage}
      />
      <div>
        {uploadLoading ? (
          <p>Loading...</p>
        ) : (
          <img
            src={file}
            width='200'
            alt=''
            onClick={() => {
              props.setImage(file);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default UploadFile;

import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { Auth } from 'aws-amplify';
import ResetForm from '../resetPassword/ResetForm';
import ConfirmationForm from '../resetPassword/ConfirmationForm';
import axios from 'axios';

import { connect } from 'react-redux';
import { signIn, signOut, fetchTeamDetails } from '../../../redux';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Box,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadingButton } from '@mui/lab';

// ----------------------------------------------------------------------

const LoginForm = ({ fetchTeamDetails, authStatus, team, signIn, signOut }) => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [resetPage, setResetPage] = useState(false);
  const [codeSent, setCodeSent] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState();
  const [processing, setProcessing] = useState(true);
  const [enteredEmail, setEnteredEmail] = useState('');
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (!team.loading) {
      if (authStatus && !team.team) {
        alert('Unauthorized to access this dashboard');
        Auth.signOut();
        signOut();
        localStorage.removeItem('authorization');
        navigate('/', { replace: true });
      } else if (authStatus) {
        navigate('/admin', { replace: true });
      } else {
        Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
          .then((user) => {
            axios.defaults.headers.common['Authorization'] =
              user.signInUserSession.accessToken.jwtToken;
            fetchTeamDetails();
            signIn();
          })
          .catch(() => {
            console.log('Not signed in');
            setProcessing(false);
          });
      }
    }
  }, [authStatus, signIn, signOut, fetchTeamDetails, navigate, team]);

  async function awsSignIn(username, password) {
    try {
      const user = await Auth.signIn(username, password);
      const token = user.signInUserSession.accessToken.jwtToken;
      localStorage.setItem('authorization', token);
      axios.defaults.headers.common['Authorization'] = token;
      fetchTeamDetails();
      if (!authStatus) {
        signIn();
      }
    } catch (error) {
      setFeedbackMessage(error.message);
      setSubmitting(false);
    }
  }

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email must be a valid email address')
      .required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: () => {
      awsSignIn(values.email, values.password);
    },
  });

  const {
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    values,
    handleSubmit,
    getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      {processing ? (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {resetPage ? (
            !codeSent ? (
              <ResetForm
                setCodeSent={setCodeSent}
                setEnteredEmail={setEnteredEmail}
                setResetPage={setResetPage}
              />
            ) : !confirmed ? (
              <ConfirmationForm
                enteredEmail={enteredEmail}
                setConfirmed={setConfirmed}
              />
            ) : (
              <>
                <Stack sx={{ mb: 5 }}>
                  <Typography variant='h4' gutterBottom>
                    Password Successfully Changed!
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    gutterBottom
                    style={{
                      marginTop: '10px',
                      display: 'flex',
                      justifyContent: 'right',
                      cursor: 'pointer',
                    }}
                    onClick={() => setResetPage(false)}
                  >
                    Back
                  </Typography>
                </Stack>
              </>
            )
          ) : (
            <>
              <Stack sx={{ mb: 5 }}>
                <Typography variant='h4' gutterBottom>
                  Sign in to Belong
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>
                  Enter your details below.
                </Typography>
              </Stack>
              <FormikProvider value={formik}>
                <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                  <Stack spacing={3} sx={{ my: 2 }}>
                    <TextField
                      fullWidth
                      autoComplete='username'
                      type='email'
                      label='Email address'
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />

                    <TextField
                      fullWidth
                      autoComplete='current-password'
                      type={showPassword ? 'text' : 'password'}
                      label='Password'
                      {...getFieldProps('password')}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton onClick={handleShowPassword} edge='end'>
                              <Icon
                                icon={showPassword ? eyeFill : eyeOffFill}
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(touched.password && errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Stack>

                  <LoadingButton
                    fullWidth
                    size='large'
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    Login
                  </LoadingButton>
                </Form>
                <Stack>{feedbackMessage}</Stack>
              </FormikProvider>
              <Typography
                variant='subtitle2'
                gutterBottom
                style={{
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'right',
                  cursor: 'pointer',
                }}
                onClick={() => setResetPage(true)}
              >
                Forgot Password?
              </Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authStatus: state.auth.authStatus,
    team: state.team,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: () => dispatch(signIn()),
    signOut: () => dispatch(signOut()),
    fetchTeamDetails: () => dispatch(fetchTeamDetails()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

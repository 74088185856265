import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import FacultyForm from './FacultyForm';
import { useQueryClient } from 'react-query';

const CreateNewFaculty = ({ setValue }) => {
  const queryClient = useQueryClient();

  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  return (
    <div className='space-y-4'>
      <Box>
        <Typography variant='h6'>Add a new faculty</Typography> <br />
      </Box>
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            name: '',
            designation: '',
            profile: '',
            linkedIn: '',
            brief: '',
            signature: '',
            featured: [],
          }}
          validationSchema={Yup.object({
            name: Yup.string()
              .min(3, 'Must be 3 characters or more')
              .max(30, 'Must be 30 characters or less')
              .required('Please enter a name'),
            designation: Yup.string().required('Please enter the designation'),
            profile: Yup.string().url().required(),
            linkedIn: Yup.string().url().required(),
            brief: Yup.string().required('Please enter the brief'),
            signature: Yup.string().url().required(),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            axios({
              method: 'POST',
              url: API_URL + '/faculty',
              data: values,
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchFaculty');
                setServerResponse(response.data.msg);
                resetForm();
                setSubmitting(false);
                setValue(0);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <FacultyForm values={values} setFieldValue={setFieldValue} />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Add Faculty
                </LoadingButton>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreateNewFaculty;

import React, { useState } from 'react';
import { TableRow, TableCell, Box, Chip } from '@mui/material';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from '../../../../misc/Snackbar';
import EditAssignmentTemplate from './EditAssignmentTemplate';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import Prompt from '../../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AssignmentTemplateBody = ({ template }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const queryClient = useQueryClient();

  const deleteTemplate = (id) => {
    setSnackbarText('Deleting Assignment template...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/assignment-template', {
        data: { assignmentTemplateId: id },
      })
      .then((res) => {
        setSnackbarText('Assignment template has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchAssignmentTemplate');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {template.title}
        </TableCell>
        <TableCell align='left'>
          {' '}
          {template.type === '21st Century Skills' ? (
            <Chip color='primary' size='small' label={template.type} />
          ) : (
            <Chip color='secondary' size='small' label={template.type} />
          )}
        </TableCell>

        <TableCell align='left'>
          {template.journies.map((item, index) => {
            const chipLabel = `${item.journeyType}-(${item.journeyDuration})- Week-${item.week}`;
            return <Chip size='small' key={index} label={chipLabel} />;
          })}
        </TableCell>

        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={handleOpen}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Assignment template?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteTemplate(template._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditAssignmentTemplate
            template={template}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default AssignmentTemplateBody;

import React, { useState } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack, Button } from '@mui/material';
import { API_URL } from 'links/API_LINKS';
import ProjectTaskForm from './ProjectTaskForm';
import { useQueryClient } from 'react-query';

const EditTaskTemplate = ({ setProjectToEdit, projectToEdit }) => {
  const queryClient = useQueryClient();
  const [serverResponse, setServerResponse] = useState();

  const tasks =
    projectToEdit.tasks && projectToEdit.tasks.length
      ? projectToEdit.tasks
      : [
          {
            title: '',
            skillId: [],
            backgroundDescription: '',
            taskInstructions: '',
            resources: '',
            // level: 'Basic',
            day: '',
            week: '',
            duration: '',
          },
        ];

  return (
    <Box>
      <Button onClick={() => setProjectToEdit(null)}>Back</Button>
      <Box>
        <Formik
          enableReinitialize={true}
          initialValues={{
            projectTemplateId: projectToEdit._id,
            title: projectToEdit.title,
            level: projectToEdit.level || '',
            type: projectToEdit.type,
            domainId: projectToEdit.domainId?._id,
            companyId: projectToEdit.companyId?._id,
            problemStatement: projectToEdit.problemStatement || '',
            businessObjectives: projectToEdit.businessObjectives || '',
            dataset: projectToEdit.dataset || {
              link: '',
              description: '',
            },
            tasks: tasks,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            level: Yup.string().required('Difficulty Level is required'),
            domainId: Yup.string().required('Domain is required'),
            companyId: Yup.string().required('Company is required'),
            problemStatement: Yup.string().required(
              'Problem Statement is required'
            ),
            businessObjectives: Yup.string().required(
              'business Objectives is required'
            ),
            tasks: Yup.array().of(
              Yup.object().shape({
                title: Yup.string().required('Title is required'),
                taskInstructions: Yup.string().required(
                  'Instructions is required'
                ),
                resources: Yup.string(),
                skillId: Yup.array().min(1, 'Minimum 1 skill required'),
                day: Yup.number().required(),
                week: Yup.number().required(),
                duration: Yup.number().required(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            axios({
              method: 'PUT',
              url: API_URL + '/project-template',
              data: values,
            })
              .then(function (response) {
                setServerResponse('Project updated');
                queryClient.invalidateQueries('fetchProjectTemplate');
                resetForm();
                setSubmitting(false);
                setProjectToEdit(null);
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <ProjectTaskForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                projectTaskToEdit={projectToEdit}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                  disabled={isSubmitting}
                >
                  Update Project
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default EditTaskTemplate;

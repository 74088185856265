import React from 'react';
import { useField } from 'formik';

import {
  TextField,
  Select,
  InputLabel,
  FormControl,
  Typography,
} from '@mui/material';
import { LocalizationProvider as DateTimeLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// import TimePicker from '@mui/lab/TimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
// import DatePicker from '@mui/lab/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <TextField
        label={label}
        {...field}
        {...props}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
      />
    </>
  );
};

export const CheckBoxField = ({ label, ...props }) => {
  const [field] = useField(props);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox defaultChecked={field.value} {...field} {...props} />
          }
          label={label}
        />
      </FormGroup>
    </>
  );
};

export const SelectInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <FormControl fullWidth>
      <InputLabel id='demo-simple-select-label'>{label}</InputLabel>
      <Select
        label={label}
        labelId='demo-simple-select-label'
        id='demo-simple-select'
        {...field}
        {...props}
        error={Boolean(meta.touched && meta.error)}
      />
      {meta.touched && meta.error && (
        <Typography
          variant='caption'
          display='block'
          ml={2}
          sx={{ color: 'error.main' }}
        >
          {meta.touched && meta.error}
        </Typography>
      )}
    </FormControl>
  );
};

export const DateTimePickerField = ({
  name,
  value,
  onChange,
  label,
  ...props
}) => {
  return (
    <>
      <DateTimeLocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          renderInput={(props) => (
            <TextField fullWidth size='small' {...props} />
          )}
          label={label}
          value={value}
          onChange={(newValue) => {
            onChange(name, newValue);
          }}
        />
      </DateTimeLocalizationProvider>
    </>
  );
};

export const TimePickerField = ({ name, value, onChange, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        renderInput={(props) => <TextField fullWidth size='small' {...props} />}
        label={label}
        value={value}
        onChange={(newValue) => {
          onChange(name, newValue);
        }}
      />
    </LocalizationProvider>
  );
};

export const DatePickerField = ({ name, value, onChange, label }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        renderInput={(props) => <TextField fullWidth size='small' {...props} />}
        label={label}
        value={value}
        onChange={(newValue) => {
          onChange(name, newValue);
        }}
      />
    </LocalizationProvider>
  );
};

import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Box, Button, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import { useQueryClient } from 'react-query';

import PartnerForm from './PartnerForm';

const EditPartner = ({ setPartnerToEdit, partnerToEdit }) => {
  const queryClient = useQueryClient();
  const [serverResponse, setServerResponse] = useState();
  return (
    <div>
      <Typography my={1}>
        <Button onClick={() => setPartnerToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={true}
        // innerRef={formikRef}
        initialValues={{
          partnerId: partnerToEdit._id,
          name: partnerToEdit.name,
          code: partnerToEdit.code,
          website: partnerToEdit.website,
          logoUrl: partnerToEdit.logoUrl,
          displayLogo: partnerToEdit.displayLogo,
          phone: partnerToEdit.phone,
          city: partnerToEdit.city,
          country: partnerToEdit.country,
          timezone: partnerToEdit.timezone,
          // cpName: partnerToEdit.cpName,
          // cpPhone: partnerToEdit.cpPhone,
          // cpEmail: partnerToEdit.cpEmail,
          note: partnerToEdit.note,
          courses: partnerToEdit.courses || [],
          whitelistField: partnerToEdit.whitelistField || [],
          isEvent: partnerToEdit.isEvent,
          showCourses: partnerToEdit.showCourses,
          university: partnerToEdit.university || [],
          sidebarTab: partnerToEdit.sidebarTab || {
            capstone: false,
            vga: false,
            job: false,
          },
          capstones: partnerToEdit.capstones || [],
          showCapstones: !partnerToEdit.capstones?.length,
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .min(2, 'Must be 3 characters or more')
            .max(100, 'Must be 100 characters or less')
            .required('Please enter a Name'),
          code: Yup.string().required(),
          website: Yup.string().url().required(),
          logoUrl: Yup.string().url().required(),
          courses: Yup.array().of(Yup.string().required('Please enter course')),
          university: Yup.array().when('isEvent', {
            is: true,
            then: Yup.array().of(
              Yup.string().required('Please enter university')
            ),
          }),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setServerResponse('');
          const myData = { ...values };
          delete myData.showCapstones;
          delete myData.selectedDomain;
          //Empty capstones if show all capstones is checked
          if (values.showCapstones) myData.capstones = [];
          axios({
            method: 'PUT',
            url: API_URL + '/partner',
            data: myData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchAllPartners');
              setPartnerToEdit(null);
              setServerResponse(response.data.msg);
              setSubmitting(false);
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <PartnerForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
            />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
              mt={2}
            >
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Update Partner
              </LoadingButton>
            </Stack>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditPartner;

import React, { useState } from 'react';

import { Box } from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL_V1 } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';
import MasterclassHeader from './MasterclassHeader';

import Report from './report';
import MasterClassForm from './MasterClassForm';

const AllMasterclass = ({ masterClassToEdit, setMasterClassToEdit }) => {
  const [showReport, setShowReport] = useState(null);
  const fetchAssessment = async () => {
    const res = await axios.get(API_URL_V1 + '/assessment');
    return res;
  };
  const { status, data } = useQuery(`fetchAllAssessment`, fetchAssessment);
  return (
    <div>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' &&
        (showReport ? (
          <Report setShowReport={setShowReport} masterclass={showReport} />
        ) : masterClassToEdit ? (
          <MasterClassForm
            setMasterClassToEdit={setMasterClassToEdit}
            data={masterClassToEdit}
          />
        ) : (
          <MasterclassHeader
            setMasterClassToEdit={setMasterClassToEdit}
            data={data.data}
            setShowReport={setShowReport}
          />
        ))}
    </div>
  );
};

export default AllMasterclass;

import React, { useState, useRef } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { TextInput, SelectInput } from '../../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import ShowFilesPopup from '../../../halper/showFilesPopup';
import {
  Box,
  Modal,
  Grid,
  MenuItem,
  Typography,
  Stack,
  Button,
} from '@mui/material';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EditTemplate = ({ template, snackbarActions, handleClose }) => {
  const [open, setOpen] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  const formikRef = useRef();
  const handleCloseImage = () => setOpen(false);

  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <div>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            eventTemplateId: template._id,
            title: template.title,
            type: template.type,
            description: template.description,
            duration: template.duration,
            image: template.image,
            resources: template.resources,
            journies: template.journies,
            decks: template.decks || [],
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .min(2, 'Must be 2 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required('Title is required'),
            type: Yup.string().required(),
            description: Yup.string().required('Description is required'),
            duration: Yup.number().required().positive().integer(),
            image: Yup.string().url().required(),
            resources: Yup.array().of(
              Yup.object().shape({
                title: Yup.string()
                  .min(2, 'Must be 2 characters or more')
                  .max(100, 'Must be 100 characters or less')
                  .required('Title is required'),
                link: Yup.string().url(),
              })
            ),
            journies: Yup.array().of(
              Yup.object().shape({
                journeyType: Yup.string().required(),
                journeyDuration: Yup.string().required(),
                week: Yup.number().required().positive().integer(),
                day: Yup.number().required().positive().integer(),
              })
            ),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            console.log(values);
            axios({
              method: 'PUT',
              url: API_URL + '/event-template',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Event template Updated successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchEventTemplate');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to Update Event template, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                mb={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInput size='small' fullWidth name='type' label='Type'>
                    <MenuItem value='Masterclass'>Masterclass</MenuItem>
                    <MenuItem value='Coach Connect'>Coach Connect</MenuItem>
                    <MenuItem value='Mentor Connect'>Mentor Connect</MenuItem>
                  </SelectInput>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='description'
                    type='text'
                    multiline
                    label='Description'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    fullWidth
                    size='small'
                    name='image'
                    type='text'
                    label='Image'
                  />
                  <div>
                    <img src={values.image} alt='' width='150' />
                  </div>
                  <Button
                    type='button'
                    onClick={() => {
                      setOpen(true);
                      setSelectedField('Image');
                      setRefresh(!refresh);
                    }}
                  >
                    Select Image
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='duration'
                    type='number'
                    label='Duration'
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div>
                  <FieldArray
                    name='resources'
                    render={(arrayHelpers) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Typography variant='h6'>Resources</Typography>

                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                title: '',
                                link: '',
                              })
                            }
                          >
                            +ADD
                          </Button>
                        </div>
                        {values.resources.map((content, index) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor: 'primary.light',
                                padding: '20px',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '2px',
                                marginY: '10px',
                              }}
                            >
                              <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                mb={3}
                              >
                                {' '}
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`resources[${index}].link`}
                                    type='text'
                                    label='Link'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`resources[${index}].title`}
                                    type='text'
                                    label='Title'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FieldArray
                  name='journies'
                  render={(arrayHelpers) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography variant='h6'>Journies</Typography>{' '}
                        <Button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              journeyType: 'School/DT Journey',
                              journeyDuration: '4 weeks',
                              week: '',
                              day: '',
                            })
                          }
                        >
                          +ADD
                        </Button>
                      </div>
                      {values.journies.map((content, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid
                              key={index}
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                              mb={3}
                            >
                              {' '}
                              <Grid item xs={12} sm={5}>
                                <SelectInput
                                  size='small'
                                  fullWidth
                                  name={`journies[${index}].journeyType`}
                                  label='Journey Type'
                                >
                                  <MenuItem value='School/DT Journey'>
                                    School/DT Journey
                                  </MenuItem>
                                  <MenuItem value='Tech'>Tech</MenuItem>
                                  <MenuItem value='Non Tech'>Non Tech</MenuItem>
                                </SelectInput>
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <SelectInput
                                  size='small'
                                  fullWidth
                                  name={`journies[${index}].journeyDuration`}
                                  label='Journey Duration'
                                >
                                  <MenuItem value='4 weeks'>4</MenuItem>
                                  <MenuItem value='5 weeks'>5</MenuItem>
                                  <MenuItem value='6 weeks'>6</MenuItem>
                                  <MenuItem value='7 weeks'>7</MenuItem>
                                  <MenuItem value='8 weeks'>8</MenuItem>
                                  <MenuItem value='12 weeks'>12</MenuItem>
                                  <MenuItem value='15 weeks'>15</MenuItem>
                                  <MenuItem value='22 weeks'>22</MenuItem>
                                </SelectInput>
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`journies[${index}].day`}
                                  type='number'
                                  label='Day'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`journies[${index}].week`}
                                  type='number'
                                  label='Week'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <div>
                  <FieldArray
                    name='decks'
                    render={(arrayHelpers) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Typography variant='h6'>Event decks</Typography>

                          <Button
                            type='button'
                            onClick={() => arrayHelpers.push('')}
                          >
                            +ADD
                          </Button>
                        </div>
                        {values.decks.map((content, index) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor: 'primary.light',
                                padding: '20px',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '2px',
                                marginY: '10px',
                              }}
                            >
                              <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                mb={3}
                              >
                                {' '}
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`decks[${index}]`}
                                    type='text'
                                    label='Link'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </Grid>

              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Update Event Template
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <Typography></Typography>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
      <div>
        <Modal
          open={open}
          onClose={handleCloseImage}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default EditTemplate;

import React, { useState } from 'react';
import { TableRow, TableCell, Chip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import Prompt from '../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import Snackbar from '../../../misc/Snackbar';

const Templatebody = ({ template, setProjectToEdit, skills }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  // new

  const queryClient = useQueryClient();

  // to match skills and get the skill title
  const getSkillName = (skillId) => {
    const skill = skills?.find((skill) => skill?._id === skillId);
    return skill ? skill.title : 'Unknown Skill';
  };

  const deleteTemplate = (id) => {
    setSnackbarText('Deleting Project template...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/project-template', {
        data: { projectTemplateId: id },
      })
      .then((res) => {
        setSnackbarText('Project template has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchProjectTemplate');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };
  // console.log('template', template);

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {template.title}
        </TableCell>
        <TableCell align='left'>
          {' '}
          {template.type === '21st Century Skills' ? (
            <Chip color='primary' size='small' label={template.type} />
          ) : (
            <Chip color='secondary' size='small' label={template.type} />
          )}
        </TableCell>
        <TableCell align='left'>
          {skills?.length === 0 ? (
            <span>Loading....</span>
          ) : (
            <>
              {template.tasks.map((task, taskIndex) => (
                <div
                  key={taskIndex}
                  style={{ display: 'flex', flexWrap: 'wrap' }}
                >
                  {task.skillId?.map((skillId, skillIndex) => (
                    <span key={skillIndex} style={{ marginRight: '10px' }}>
                      {getSkillName(skillId)}
                    </span>
                  ))}
                </div>
              ))}
            </>
          )}
        </TableCell>
        <TableCell component='th' scope='row'>
          {template.level}
        </TableCell>
        <TableCell align='left'>
          {template.tasks && template.tasks.length}
        </TableCell>
        <TableCell align='left'>{template.companyId?.name}</TableCell>
        <TableCell align='left'>{template.domainId?.title}</TableCell>
        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={() => setProjectToEdit(template)}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Project template?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteTemplate(template._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default Templatebody;

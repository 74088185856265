import axios from 'axios';
import Loading from 'components/layouts/Loading';
import { API_URL } from 'links/API_LINKS';
import React from 'react';
import { useQuery } from 'react-query';
import NpsHeader from './NpsHeader';

const Index = () => {
  const fetchAllBatchNps = async () => {
    const res = await axios.get(API_URL + '/batch/nps');
    return res;
  };
  const { status, data } = useQuery('fetchAllNps', fetchAllBatchNps);
  return (
    <div>
      {status === 'loading' && <Loading />}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && <NpsHeader data={data?.data?.data || []} />}
    </div>
  );
};

export default Index;

import React, { useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { LoadingButton } from '@mui/lab';
import QuestionForm from './QuestionForm';
import { Box, Typography, Stack, Button } from '@mui/material';

import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

const EditQuestion = ({ questionToEdit, setQuestionToEdit }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const {
    _id,
    question,
    type,
    domain,
    category,
    image,
    time,
    options,
    maxSelect,
    skill,
  } = {
    ...questionToEdit,
  };
  console.log(skill);
  return (
    <div>
      <Typography my={4}>
        <Button onClick={() => setQuestionToEdit(null)}>Back</Button>
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          questionId: _id,
          question: question,
          type: type,
          domain: domain ? domain._id : '',
          category: category || '',
          image: image || [],
          time: time || '',
          options: options,
          maxSelect: maxSelect,
          skill: skill?._id,
        }}
        validationSchema={Yup.object({
          question: Yup.string().required('Please enter a Question'),
          domain: Yup.string().when('type', {
            is: 'DOMAIN',
            then: Yup.string().required('Please select a Domain.'),
          }),
          category: Yup.string()
            .when('type', {
              is: 'MASTERCLASS',
              then: Yup.string().required('Please select a category.'),
            })
            .nullable(),
          time: Yup.string()
            .when('type', {
              is: 'MASTERCLASS',
              then: Yup.string().required('Please enter Time.'),
            })
            .nullable(),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const updatedData = { ...values }; //new added
          if (values.type === 'GENERAL') {
            delete updatedData.domain;
            delete updatedData.image;
            delete updatedData.skill;
            delete updatedData.category;
            delete updatedData.time;
          }
          if (values.type === 'DOMAIN') {
            delete updatedData.image;
            delete updatedData.category;
            delete updatedData.time;
          }
          if (values.type === 'MASTERCLASS') {
            delete updatedData.domain;
            delete updatedData.skill;
          }
          setServerResponse('');
          axios({
            method: 'PUT',
            url: API_URL + '/question',
            data: updatedData,
          })
            .then(function (response) {
              queryClient.invalidateQueries('fetchQuestion');
              setServerResponse(response.data.msg);
              setSubmitting(false);
              setQuestionToEdit();
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}>
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <QuestionForm values={values} setFieldValue={setFieldValue} />

            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}>
                Update
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}>
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditQuestion;

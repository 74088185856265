import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import CircularProgress from '@mui/material/CircularProgress';
import {
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  TextField,
  InputAdornment,
  IconButton,
  Stack,
  Box,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

import { API_URL } from 'links/API_LINKS';
import UsersBody from './UsersBody';

import TableListHead from '../../layouts/TableListHead';
import NewProfile from '../../layouts/NewProfile';

const fetchUsers = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword, partnerId } = params;
  const matchObj = {
    limit,
    page,
    sort,
    order,
    searchKeyword: searchRequestKeyword,
  };
  if (partnerId) matchObj.partnerId = partnerId;
  const res = await axios.get(API_URL + '/dashboard/partner/users', {
    params: matchObj,
  });
  return res;
};

const fetchPartner = async () => {
  const res = await axios.get(API_URL + '/partner', {
    params: { limit: 0, sort: 'name', order: 'asc' },
  });
  return res;
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function UserHeader() {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');
  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'createdAt',
    order: 'desc',
  });
  const [partnerId, setPartnerId] = useState(null);
  const [showProfile, setShowProfile] = useState(null);
  const { status, data, isFetching } = useQuery(
    [`fetchUsers`, paginationData, searchRequestKeyword, partnerId],
    () => fetchUsers({ ...paginationData, searchRequestKeyword, partnerId }),
    { keepPreviousData: true }
  );
  const allPartner = useQuery('fetchPartner', fetchPartner);

  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'firstName', label: 'Name' },
    { id: 'email', label: 'Email' },
    { id: 'createdAt', label: 'Start Date' },
    { id: 'education', label: 'Course' },
    { id: 'belongscore', label: 'Belong Score', disabled: true },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {showProfile ? (
        <>
          {/* <Profile setShowProfile={setShowProfile} userId={showProfile} /> */}
          <NewProfile setShowProfile={setShowProfile} userId={showProfile} />
        </>
      ) : (
        <>
          <Paper elevation={3} sx={{ margin: 1, padding: 2 }}>
            <Stack
              direction='row'
              justifyContent='space-between'
              alignItems='center'
              spacing={4}
            >
              <TextField
                sx={{ maxWidth: 350 }}
                fullWidth
                label='Search user by name or email'
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                  debouncedHandler(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          setSearchKeyword('');
                          debouncedHandler('', 0);
                        }}
                        edge='end'
                      >
                        {searchKeyword ? <CloseIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <FormControl>
                <InputLabel id='demo-simple-select-label'>
                  Select Partner
                </InputLabel>
                <Select
                  sx={{ maxWidth: 350, minWidth: 300 }}
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={partnerId}
                  label='Select Partner'
                  onChange={(e) => setPartnerId(e.target.value)}
                >
                  <MenuItem value={null}>All Users</MenuItem>
                  {allPartner.status === 'success' &&
                    allPartner?.data?.data?.data?.map((partner) => {
                      return (
                        <MenuItem key={partner._id} value={partner._id}>
                          {partner.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Stack>
          </Paper>
          {status === 'loading' && (
            <Box
              display='flex'
              justifyContent='center'
              alignItems='center'
              minHeight='50vh'
              minWidth='100vw'
            >
              <CircularProgress />
            </Box>
          )}
          {status === 'error' && <div>Error fetching data</div>}
          {status === 'success' && (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 850, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />

                <TableBody>
                  {data?.data?.data?.map((row) => (
                    <UsersBody
                      row={row}
                      key={row._id}
                      setShowProfile={setShowProfile}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data?.data?.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching ||
                    data?.data?.page === data?.data?.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default UserHeader;

import React, { useState, useRef } from 'react';
import axios from 'axios';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, Stack } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';
import ProgramForm from './ProgramForm';

const AddProgram = ({ setValue }) => {
  const queryClient = useQueryClient();
  const formikRef = useRef();
  const [serverResponse, setServerResponse] = useState();

  return (
    <Box sx={{ my: 4, px: 4 }}>
      <Typography variant='h6'>
        Add a new program: Program can be a course, internship or experience
        project
      </Typography>
      <Box sx={{ py: 2 }}>
        <Formik
          enableReinitialize={true}
          innerRef={formikRef}
          initialValues={{
            title: '',
            company: '',
            jobLocation: { country: '', city: '', mode: '' },
            price: [],
            area: '',
            skills: [],
            overviewNote: '',
            slug: '',
            externallink: '',
            points: ['', '', '', '', ''],
            publishStatus: true,
            featured: false,
            showOnHomePage: false,
            sectionTitle: ['', '', '', '', '', '', '', '', '', '', '', ''],
            sectionContent: [],
            category: '606b0f03b689e8771091c667',
            highlightPoints: [],
          }}
          validationSchema={Yup.object({
            title: Yup.string()
              .min(3, 'Must be 3 characters or more')
              .max(100, 'Must be 100 characters or less')
              .required(),
            slug: Yup.string()
              .required()
              .matches(
                '^[A-Za-z0-9-]*$',
                'URL can have alphanumberic letters or hyphen'
              ),
            price: Yup.array().of(
              Yup.object().shape({
                amount: Yup.number(),
              })
            ),
            area: Yup.string().required(),
            company: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setServerResponse('');
            axios({
              method: 'POST',
              url: API_URL + '/programs',
              data: values,
              config: { headers: { 'Content-Type': 'application/json' } },
            })
              .then(function (response) {
                queryClient.invalidateQueries('fetchProgram');
                setServerResponse(response.data.msg);
                setSubmitting(false);
                setValue(0);
                response.data.status === 'success' && resetForm();
              })
              .catch(function (response) {
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <ProgramForm
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
              />
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}
              >
                <LoadingButton
                  type='submit'
                  variant='contained'
                  loading={isSubmitting}
                >
                  Add Program
                </LoadingButton>
              </Stack>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                {serverResponse && <Typography>{serverResponse}</Typography>}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default AddProgram;

import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

export default function CustomizedSnackbars(props) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={props.open}
        autoHideDuration={props.duration ?? 5000}
        anchorOrigin={props.anchorOrigin ?? {}}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={props.severity ?? 'info'}
          sx={{ width: '100%' }}
        >
          {props.title}
        </Alert>
      </Snackbar>
      {/* <Alert severity='error'>This is an error message!</Alert>
        <Alert severity='warning'>This is a warning message!</Alert>
        <Alert severity='info'>This is an information message!</Alert>
        <Alert severity='success'>This is a success message!</Alert> */}
    </>
  );
}

import React from 'react';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import TeamBody from './TeamBody';

const TeamHeader = ({ data }) => {
  return (
    <TableContainer component={Card}>
      <Table
        size='small'
        sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
        aria-label='table'
      >
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align='left'> Team Name</TableCell>
            <TableCell align='left'> Total User</TableCell>
            <TableCell align='left'> Users</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((team, index) => {
            return <TeamBody row={team} key={team.id} index={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TeamHeader;

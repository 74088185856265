import React, { useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';

import { Grid, Box, Modal } from '@mui/material';
import { API_URL } from '../../../../links/API_LINKS';
import ShowFilesPopup from '../../halper/showFilesPopup';
import SuperCaptoneField from './SuperCaptoneField';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const fetchAreas = async () => {
  const res = await axios.get(API_URL + '/area', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};
const fetchCompanies = async () => {
  const res = await axios.get(API_URL + '/company', {
    params: { limit: 0, sort: 'name', order: 'asc' },
  });
  return res;
};
// const fetchSkills = async () => {
//   const res = await axios.get(API_URL + '/skills', {
//     params: { limit: 0, sort: 'title', order: 'asc' },
//   });
//   return res;
// };

const SuperCapstoneForm = ({ values, setFieldValue, capstoneData, errors }) => {
  const domains = useQuery('fetchAreas', fetchAreas);
  const companies = useQuery('fetchCompanies', fetchCompanies);
  // const skills = useQuery('fetchSkills', fetchSkills);

  // const skillsWithQuestions = useQuery(
  //   'fetchSkillsWithQuestions',
  //   fetchSkillsWithQuestions
  // );
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }

  return (
    <div>
      <SuperCaptoneField
        values={values}
        setFieldValue={setFieldValue}
        capstoneData={capstoneData}
        errors={errors}
        domains={domains}
        companies={companies}
        setOpen={setOpen}
        setSelectedField={setSelectedField}
        setRefresh={setRefresh}
        refresh={refresh}
      />

      <Grid>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </Grid>
    </div>
  );
};

export default SuperCapstoneForm;

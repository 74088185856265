import React, { useState, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import TableListHead from '../../layouts/TableListHead';
import CoachBody from './CoachBody';
import EditCoach from './EditCoach';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchCoach = async (params) => {
  const { limit, page, sort, order, searchRequestKeyword } = params;
  const matchObj = {
    limit,
    page,
    sort,
    order,
    searchKeyword: searchRequestKeyword,
  };

  const res = await axios.get(API_URL + '/coach', {
    params: matchObj,
  });

  return res;
};

const debounce = (fn, delay) => {
  let timerId;
  return (...args) => {
    clearTimeout(timerId);
    timerId = setTimeout(() => fn(...args), delay);
  };
};

function CoachHeader() {
  const [coachToEdit, setCoachToEdit] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchRequestKeyword, setSearchRequestKeyword] = useState('');

  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'name',
    order: 'asc',
  });

  const { status, data, isFetching } = useQuery(
    [`fetchCoach`, paginationData, searchRequestKeyword],
    () => fetchCoach({ ...paginationData, searchRequestKeyword }),
    { keepPreviousData: true }
  );

  //new
  const debouncedHandler = useCallback((value, duration = 1200) => {
    debounce(() => setSearchRequestKeyword(value), duration)();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'coachImageUrl', label: '', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'designation', label: 'Designation', align: 'left' },
    { id: 'country', label: 'Country', align: 'left' },
    { id: 'areas', label: 'Area', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {coachToEdit ? (
            <EditCoach
              coachToEdit={coachToEdit}
              setCoachToEdit={setCoachToEdit}
            />
          ) : (
            <TableContainer component={Paper}>
              <TextField
                sx={{ marginY: 2 }}
                fullWidth
                label='Search Area or Designation'
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                  debouncedHandler(e.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        onClick={() => {
                          setSearchKeyword('');
                          debouncedHandler('', 0);
                        }}
                        edge='end'
                      >
                        {searchKeyword ? <CloseIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Table
                sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.data.data.map((row) => (
                    <CoachBody
                      key={row._id}
                      row={row}
                      setCoachToEdit={setCoachToEdit}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data.data.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching || data.data.page === data.data.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default CoachHeader;

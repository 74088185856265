import React from 'react';
import TableCell from '@mui/material/TableCell';
import { Button, TableRow, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { format } from 'date-fns';

function InvoiceBody({ row, setEditInvoice, invoiceType }) {
  //   console.log(row);
  // Function to find the time when status is "Paid"
  //   const findPaidTime = () => {
  //     const activity = row.invoice.activity || []; // Ensure activity exists
  //     for (const activityItem of activity) {
  //       if (activityItem.status === 'Paid') {
  //         return activityItem.time; // Return time if status is "Paid"
  //       }
  //     }
  //     return null; // Return null if status is not found or not "Paid"
  //   };

  const getApprovedDate = () => {
    for (const activity of row.invoice.activity) {
      if (activity.status === 'Approved') {
        return activity.time;
      }
    }
    return null;
  };

  const getPaidDate = () => {
    for (const activity of row.invoice.activity) {
      if (activity.status === 'Paid') {
        return activity.time;
      }
    }

    return null;
  };

  // Get the time when status is "Paid"
  //   const paidTime = findPaidTime();
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell align='left'>{row.coachName} </TableCell>
        <TableCell align='left'> {row.batchName} </TableCell>
        <TableCell align='left'>
          {' '}
          {row.totalAmount} {row.currency}{' '}
        </TableCell>
        {/* <TableCell align='left'>
          {format(new Date(row.addedOn), 'do LLLL yyyy')}
        </TableCell> */}
        <TableCell align='left'>
          <Typography>
            {format(new Date(row.addedOn), 'do LLLL yyyy')}
          </Typography>
          {/* <Typography variant='subtitle1'>
            {paidTime &&
              ` Paid on: ${format(new Date(paidTime), 'do LLLL yyyy')}`}{' '}
          </Typography> */}
          {/* Display time if status is Paid */}
        </TableCell>

        {(invoiceType === 'approved' || invoiceType === 'paid') && (
          <React.Fragment>
            <TableCell>
              {format(new Date(getApprovedDate()), 'do LLLL yyyy')}
            </TableCell>
            {invoiceType === 'paid' && (
              <TableCell>
                {format(new Date(getPaidDate()), 'do LLLL yyyy')}
              </TableCell>
            )}
          </React.Fragment>
        )}

        <TableCell align='left'>
          <Button
            onClick={() => {
              setEditInvoice(row.invoice);
            }}>
            <EditIcon />
          </Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default InvoiceBody;

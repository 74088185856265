import React, { useState } from 'react';

import { Box, Modal, Grid, Typography, Button, MenuItem } from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import ShowFilesPopup from '../../halper/showFilesPopup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const FacultyForm = ({ values, setFieldValue }) => {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);

  function setImage(valueToSetTo) {
    if (selectedField) {
      setFieldValue(selectedField, valueToSetTo);
    }
  }
  return (
    <div>
      <Grid
        container
        rowSpacing={3}
        columnSpacing={{ xs: 2, sm: 1, md: 5 }}
        mb={3}
      >
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='name'
            type='text'
            label='Faculty Name'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            size='small'
            fullWidth
            name='designation'
            type='text'
            label='Designation'
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='profile'
            type='text'
            label='image URL'
          />
          <Button
            type='button'
            onClick={() => {
              setOpen(true);
              setSelectedField('profile');
              setRefresh(!refresh);
            }}
          >
            Select Image
          </Button>
          <div>
            <img src={values.profile} alt='' width='150' />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='signature'
            type='text'
            label='Signature Image URL'
          />
          <Button
            type='button'
            onClick={() => {
              setOpen(true);
              setSelectedField('signature');
              setRefresh(!refresh);
            }}
          >
            Select Image
          </Button>
          <div>
            <img src={values.signature} alt='' width='150' />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInput
            fullWidth
            size='small'
            name='linkedIn'
            type='text'
            label='linkedIn'
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SelectInput name='featured' label='featured category' multiple>
            <MenuItem value='606704203a3dbf3fc82cc74f'>Startup Labs</MenuItem>
            <MenuItem value='60f7b81e4e145c6894f8ff11'>Career Lounge</MenuItem>
          </SelectInput>
        </Grid>

        <Grid item xs={12}>
          <Typography variant='h6'>Faculty Brief</Typography>
        </Grid>
        <Grid item xs={8}>
          <TextInput
            rows={2}
            name='brief'
            label='Faculty Brief'
            type='text'
            multiline
            fullWidth
          />
        </Grid>
      </Grid>

      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={style}>
            <ShowFilesPopup
              setOpen={setOpen}
              resource_type={'image'}
              setImage={setImage}
              setSelectedField={setSelectedField}
              setRefresh={setRefresh}
              refresh={refresh}
            />
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default FacultyForm;

import React from 'react';
import {
  Container,
  // Button,
  Typography,
  Grid,
  Link,
  Stack,
  // Box,
  Card,
} from '@mui/material';
import Reply from './Reply';
import htmlparser from 'html-react-parser';
// import EditIcon from '@mui/icons-material/Edit';
import { UPLOAD_OR_DOWNLOAD_URL } from 'links/API_LINKS';

const TicketDetails = ({ ticketDetails, setTicketDetails, batchId }) => {
  const comment = ticketDetails.activity.filter((act) => {
    return act.comment;
  });
  // console.log('yolo', ticketDetails);
  // const [editIndex, setEditIndex] = useState(-1);
  return (
    <div>
      <Container>
        <Typography mt={4} variant='h6'>
          {ticketDetails.subject}
        </Typography>
        <Typography mt={1} variant='body2'>
          Asked by {ticketDetails.user.name || ticketDetails.user.email}
          {ticketDetails.batch.name && ` | Batch : ${ticketDetails.batch.name}`}
        </Typography>
        <Typography sx={{ p: 2 }}>{ticketDetails.description}</Typography>
        <Grid sx={{ p: 2 }} container spacing={4}>
          {ticketDetails.attachments.map((image, index) => {
            return (
              <Grid key={index} item xs={6} md={4}>
                <Link
                  target='_blank'
                  href={UPLOAD_OR_DOWNLOAD_URL + '/' + image}
                >
                  <img
                    src={UPLOAD_OR_DOWNLOAD_URL + '/' + image}
                    alt='attachment'
                    loading='lazy'
                  />
                </Link>
              </Grid>
            );
          })}
        </Grid>
        {comment.map((myComment, index) => {
          return (
            <Card key={index} variant='outlined' sx={{ my: 2 }}>
              <Stack
                direction='row'
                alignItems='center'
                spacing={1}
                my={1}
                p={1}
              >
                {myComment.owner ? (
                  <Typography variant='subtitle2'>{myComment.owner}</Typography>
                ) : (
                  <Typography variant='subtitle2'>
                    {ticketDetails.user.name}
                  </Typography>
                )}

                <Typography>{htmlparser(myComment.comment)}</Typography>

                {/* {myComment.owner === 'coach' &&
                  (index === editIndex ? (
                    <Button onClick={() => setEditIndex(-1)}>Cancel</Button>
                  ) : (
                    <Button onClick={() => setEditIndex(index)}>
                      <EditIcon />
                    </Button>
                  ))} */}
              </Stack>
            </Card>
          );
        })}
      </Container>
      {ticketDetails.status === 'open' && (
        <Reply
          ticketId={ticketDetails._id}
          status={ticketDetails.status}
          setTicketDetails={setTicketDetails}
          batchId={batchId}
        />
      )}
    </div>
  );
};

export default TicketDetails;

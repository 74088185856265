import React, { useRef, useState } from 'react';
import {
  TextInput,
  DateTimePickerField,
  SelectInput,
} from '../../../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Typography, Modal, Button } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';
import axios from 'axios';
import ShowFilesPopup from '../../../../halper/showFilesPopup';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddMasterClass = ({ currentBatch, snackbarActions, handleClose }) => {
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const formikRef = useRef();
  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const handleCloseImage = () => setOpen(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);

  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }

  return (
    <div>
      <Typography align='center' mb={2}>
        {' '}
        Add new masterclass{' '}
      </Typography>
      <Formik
        enableReinitialize={false}
        innerRef={formikRef}
        initialValues={{
          batch: currentBatch._id,
          title: '',
          type: 'Masterclass',
          description: '',
          image: '',
          journies: {
            day: 1,
            journeyDuration: '5 weeks',
            journeyType: 'School/DT Journey',
            week: 1,
          },
          eventDate: new Date(),
          link: '',
          onlineOrOffline: 'Online',
          status: 'publish',
          resources: [],
        }}
        validationSchema={Yup.object({
          link: Yup.string().url().required('link is required'),
          title: Yup.string().required('Title is required'),
          image: Yup.string().url().required('image is required'),
          description: Yup.string().required('Description is required'),
          journies: Yup.object().shape({
            week: Yup.number().required('Week is required'),
          }),
          resources: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
              link: Yup.string().url().required('Link is required'),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          axios({
            method: 'POST',
            url: API_URL + '/event/add-multiple',
            data: [values],
          })
            .then(function (response) {
              setSnackbarText('Event added successfully');
              setSnackbarSeverity('success');
              setSnackbarOpen(true);
              setSubmitting(false);
              queryClient.invalidateQueries(
                `fetchCurrentBatchEvents${currentBatch._id}`
              );
              queryClient.invalidateQueries(
                `fetchCurrentBatch${currentBatch._id}`
              );
              handleClose();
            })
            .catch(function (response) {
              setSnackbarText(
                'Failed to add Events, Please try after sometime'
              );
              setSnackbarSeverity('error');
              setSnackbarOpen(true);
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='title'
                  type='text'
                  label='Title'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  name='journies.week'
                  type='number'
                  label='Week'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  fullWidth
                  multiline
                  rows={3}
                  name='description'
                  type='text'
                  label='Description'
                  size='small'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='image'
                  type='text'
                  label='Image'
                />
                <Button
                  type='button'
                  onClick={() => {
                    setOpen(true);
                    setSelectedField('image');
                    setRefresh(!refresh);
                  }}
                >
                  Select Image
                </Button>
                <div>
                  <img src={values.image} alt='' width='150' />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <DateTimePickerField
                  name='eventDate'
                  value={values.eventDate}
                  label='Event Date'
                  onChange={setFieldValue}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput
                  size='small'
                  fullWidth
                  name='link'
                  type='text'
                  label='Link'
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='status'
                  label='Status'
                >
                  <MenuItem value='publish'>Publish</MenuItem>
                  <MenuItem value='draft'>Draft</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12}>
                <FieldArray
                  name='resources'
                  render={(arrayHelpers) => (
                    <>
                      <div style={{ display: 'flex' }}>
                        <Typography variant='h6'>Resources</Typography>

                        <Button
                          type='button'
                          onClick={() =>
                            arrayHelpers.push({
                              title: '',
                              link: '',
                            })
                          }
                        >
                          +ADD
                        </Button>
                      </div>
                      {values.resources.map((content, index) => {
                        return (
                          <Box
                            sx={{
                              backgroundColor: 'primary.light',
                              padding: '20px',
                              border: '1px solid',
                              borderColor: 'primary.main',
                              borderRadius: '2px',
                              marginY: '10px',
                            }}
                          >
                            <Grid
                              container
                              rowSpacing={3}
                              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                              mb={3}
                            >
                              {' '}
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].link`}
                                  type='text'
                                  label='Link'
                                />
                              </Grid>
                              <Grid item xs={12} sm={5}>
                                <TextInput
                                  size='small'
                                  fullWidth
                                  name={`resources[${index}].title`}
                                  type='text'
                                  label='Title'
                                />
                              </Grid>
                              <Grid item xs={12} sm={2}>
                                <Button
                                  type='button'
                                  onClick={() => arrayHelpers.remove(index)}
                                >
                                  -Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Box>
                        );
                      })}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Add Event
              </LoadingButton>
            </Box>
          </Form>
        )}
      </Formik>
      <Modal
        open={open}
        onClose={handleCloseImage}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ShowFilesPopup
            setOpen={setOpen}
            resource_type={'image'}
            setImage={setImage}
            setSelectedField={setSelectedField}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddMasterClass;

import React, { useState } from 'react';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Table,
  Button,
  Box,
  Stack,
  Modal,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import BatchBody from './BatchBody';
import TableListHead from '../../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../../halper/TableSort';
import { parseInt } from 'lodash';
import AddBatch from './AddBatch';
import Snackbar from '../../../../misc/Snackbar';
import AddUser from './addUser';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '95%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function BatchHeader({ data, setCurrentBatch }) {
  const [adduserBatch, setAdduserBatch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('startDate');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((batch) => {
    return {
      _id: batch._id,
      batchData: batch,
      name: batch.name,
      startDate: batch.startDate,
      user: batch.usersCount,
      status: batch.status,
    };
  });

  const TABLE_HEAD = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Batch Name',
      align: 'left',
    },
    {
      id: 'startDate',
      numeric: false,
      disablePadding: true,
      label: 'Start Date',
      align: 'left',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'status',
      align: 'left',
    },
    {
      id: 'user',
      numeric: false,
      disablePadding: true,
      label: 'User',
      align: 'left',
    },
    {
      id: 'Action',
      numeric: false,
      disablePadding: true,
      label: 'Action',
      align: 'left',
    },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  return (
    <>
      {adduserBatch ? (
        <AddUser batchData={adduserBatch} setAdduserBatch={setAdduserBatch} />
      ) : (
        <>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
            mb={2}
          >
            <Button variant='outlined' onClick={() => handleOpen()}>
              Add New Batch
            </Button>
          </Stack>

          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
              aria-label='table'
              size='small'
            >
              <TableListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={rows.length}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {filteredUsers
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <BatchBody
                      key={row._id}
                      row={row}
                      setCurrentBatch={setCurrentBatch}
                      setAdduserBatch={setAdduserBatch}
                    />
                  ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 50]}
              component='div'
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <AddBatch
                handleCloseBatch={handleClose}
                snackbarActions={{
                  setSnackbarText,
                  setSnackbarSeverity,
                  setSnackbarOpen,
                }}
              />
            </Box>
          </Modal>

          <Snackbar
            open={snackbarOpen}
            setOpen={setSnackbarOpen}
            duration={3000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            title={snackbarText}
            severity={snackbarSeverity}
          ></Snackbar>
        </>
      )}
    </>
  );
}

export default BatchHeader;

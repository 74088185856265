import React, { useState } from 'react';
// useCallback
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import {
  TablePagination,
  Box,
  Select,
  InputLabel,
  FormControl,
  Stack,
  MenuItem,
  //   TextField,
  //   InputAdornment,
  //   IconButton,
} from '@mui/material';
// import SearchIcon from '@mui/icons-material/Search';
// import CloseIcon from '@mui/icons-material/Close';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import ProgramBody from './ProgramBody';
import EditProgram from './EditProgram';
import { useQuery } from 'react-query';
import axios from 'axios';
import { API_URL } from '../../../../links/API_LINKS';
import CircularProgress from '@mui/material/CircularProgress';

const fetchProgram = async (params) => {
  const { limit, page, sort, order, categoryId } = params;
  // searchRequestKeyword
  const matchObj = {
    limit,
    page,
    sort,
    order,
    // searchKeyword: searchRequestKeyword,
  };
  if (categoryId) matchObj.categoryId = categoryId;
  const res = await axios.get(API_URL + '/programs', {
    params: matchObj,
    //    searchKeyword: searchRequestKeyword
  });
  return res;
};

const fetchCategory = async () => {
  const res = await axios.get(API_URL + '/categories', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};
// const debounce = (fn, delay) => {
//   let timerId;
//   return (...args) => {
//     clearTimeout(timerId);
//     timerId = setTimeout(() => fn(...args), delay);
//   };
// };
function ProgramHeader() {
  //   const [searchKeyword, setSearchKeyword] = useState('');
  //   const [searchRequestKeyword, setSearchRequestKeyword] = useState('');
  const [programToEdit, setProgramToEdit] = useState(null);

  const [paginationData, setPaginationData] = useState({
    page: 0,
    limit: 10,
    sort: 'title',
    order: 'asc',
  });
  const [categoryId, setCategoryId] = useState(null);

  const { status, data, isFetching } = useQuery(
    [`fetchProgram`, paginationData, categoryId],
    //   searchRequestKeyword
    () => fetchProgram({ ...paginationData, categoryId }),
    // searchRequestKeyword
    { keepPreviousData: true }
  );
  //   const debouncedHandler = useCallback((value, duration = 1200) => {
  //     debounce(() => setSearchRequestKeyword(value), duration)();
  //   }, []);
  const allCategory = useQuery('fetchCategory', fetchCategory);

  const handleRequestSort = (event, property) => {
    const isAsc =
      paginationData.sort === property && paginationData.order === 'asc';
    setPaginationData({
      ...paginationData,
      sort: property,
      order: isAsc ? 'desc' : 'asc',
    });
  };

  const TABLE_HEAD = [
    { id: 'title', label: 'Title', align: 'left' },
    { id: 'area', label: 'Area', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPaginationData({
      ...paginationData,
      page: newPage,
    });
  };
  const handleChangeRowsPerPage = (event) => {
    setPaginationData({
      ...paginationData,
      page: 0,
      limit: event.target.value,
    });
  };

  return (
    <>
      <Paper elevation={3} sx={{ margin: 1, padding: 2 }}>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          spacing={4}
        >
          {/* <TextField
        fullWidth
        label='Search by Title'
        value={searchKeyword}
        onChange={(e) => {
          setSearchKeyword(e.target.value);
          debouncedHandler(e.target.value);
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              <IconButton
                onClick={() => {
                  setSearchKeyword('');
                  debouncedHandler('', 0);
                }}
                edge='end'
              >
                {searchKeyword ? <CloseIcon /> : <SearchIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      /> */}
          <FormControl>
            <InputLabel id='demo-simple-select-label'>
              Select Category
            </InputLabel>
            <Select
              sx={{ maxWidth: 350, minWidth: 300 }}
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={categoryId}
              label='Select Partner'
              onChange={(e) => setCategoryId(e.target.value)}
            >
              {allCategory.status === 'success' &&
                allCategory?.data?.data?.data.map((cat) => {
                  return (
                    <MenuItem key={cat._id} value={cat._id}>
                      {cat.title}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Stack>
      </Paper>

      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
          minWidth='100vw'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <div>Error fetching data</div>}
      {status === 'success' && (
        <>
          {programToEdit ? (
            <EditProgram
              row={programToEdit}
              setProgramToEdit={setProgramToEdit}
            />
          ) : (
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
                aria-label='table'
                size='small'
              >
                <TableListHead
                  order={paginationData.order}
                  orderBy={paginationData.sort}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {data.data.data.map((row) => (
                    <ProgramBody
                      key={row._id}
                      row={row}
                      setProgramToEdit={setProgramToEdit}
                    />
                  ))}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[
                  ...new Set([paginationData.limit, 5, 10, 50]),
                ]}
                component='div'
                count={data.data.totalCount}
                rowsPerPage={paginationData.limit}
                page={paginationData.page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{
                  disabled:
                    isFetching || data.data.page === data.data.totalPages - 1,
                }}
              />
            </TableContainer>
          )}
        </>
      )}
    </>
  );
}

export default ProgramHeader;

import React, { useRef, useState } from 'react';
import { TextInput, SelectInput } from '../layouts/FormElement';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Typography, Button, MenuItem } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';

const EditTeam = ({ setShowAllAdmin, adminData }) => {
  const [serverResponse, setServerResponse] = useState();
  const formikRef = useRef();

  return (
    <div>
      <Box
        sx={{
          p: 1,
          m: 1,
        }}
      >
        <Button
          onClick={() => setShowAllAdmin('ALL')}
          variant='outlined'
          startIcon={<ArrowBackIosIcon />}
        >
          Back
        </Button>
      </Box>
      <Formik
        enableReinitialize={true}
        innerRef={formikRef}
        initialValues={{
          adminId: adminData._id,
          name: adminData.name,
          roles: adminData.roles[0] || 'Team',
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Name is required'),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const myData = { ...values, roles: [values.roles] };
          axios({
            method: 'PUT',
            url: API_URL + '/admin/edit-admin',
            data: myData,
          })
            .then(function (response) {
              setServerResponse(response.data.message);
              setSubmitting(false);
              response.status === 200 && resetForm();
            })
            .catch(function (response) {
              setServerResponse('Error! Could not process your request.');
              setSubmitting(false);
            });
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              {' '}
              <Grid item xs={12} sm={6}>
                Email - {adminData.email}
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInput fullWidth name='name' type='text' label='Name' />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInput fullWidth name='roles' label='Role'>
                  <MenuItem value='Team'>Team</MenuItem>
                  <MenuItem value='Admin'>Admin</MenuItem>
                </SelectInput>
              </Grid>
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              <LoadingButton
                size='large'
                type='submit'
                variant='contained'
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}
            >
              {serverResponse && <Typography>{serverResponse}</Typography>}
            </Box>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default EditTeam;

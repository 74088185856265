import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useQuery } from 'react-query';
import axios from 'axios';
import { format } from 'date-fns';
import htmlparser from 'html-react-parser';

import { API_URL } from '../../../../links/API_LINKS';
import { useState } from 'react';
import CsvDownload from 'react-json-to-csv';

const Index = () => {
  const fetchBootcamp = async () => {
    const res = await axios.get(API_URL + '/user/profile/bootcamp-leaderboard');
    return res;
  };

  const { status, data } = useQuery('fetchbootcampData', fetchBootcamp);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [selectedCapstone, setSelectedCapstone] = useState('');
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 700,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '80vh',
  };

  const feedbackData = [];
  if (status === 'success' && data?.data?.data) {
    data?.data?.data?.forEach((el) => {
      const capstones = el.capstones || [];
      capstones.forEach((cap) => {
        if (cap.feedback && cap.feedback.data) {
          const obj = {
            name: ` ${el.firstName} ${el.lastName}`,
            email: el.email,
            capstone: cap?.capstone?.title,
            feedbackTime: format(
              new Date(cap.feedback.submitTime),
              'do LLLL yyyy'
            ),
          };
          const feedbackObj = cap.feedback.data || {};
          feedbackData.push({ ...obj, ...feedbackObj });
        }
      });
    });
  }

  return (
    <>
      {status === 'loading' && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {status === 'error' && <Typography>Error fetching data</Typography>}
      {status === 'success' && (
        <>
          <Stack
            direction='row'
            justifyContent='flex-end'
            alignItems='center'
            spacing={1}
            mb={2}
          >
            <CsvDownload data={feedbackData}>
              <Button variant='outlined'>Feedback</Button>
            </CsvDownload>
          </Stack>
          <TableContainer component={Card}>
            <Table
              sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
              aria-label='table'
            >
              <TableHead>
                <TableRow>
                  <TableCell>Rank</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell align='left'>Email</TableCell>
                  <TableCell align='left'>Domain</TableCell>
                  <TableCell align='left'>Belong Score</TableCell>
                  <TableCell align='left'>Domain Score</TableCell>
                  <TableCell align='left'>Aptitude Score</TableCell>
                  <TableCell align='left'>Profile Strength</TableCell>
                  {/* <TableCell align='left'>Registration Date</TableCell> */}
                  <TableCell align='left'>Capstone</TableCell>
                  <TableCell align='left'>Tasks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.data.data.map((user, index) => {
                  return (
                    <TableRow
                      key={user._id}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align='left'>{index + 1}</TableCell>
                      <TableCell component='th' scope='row'>
                        {user.firstName} {user.lastName}
                      </TableCell>
                      <TableCell
                        align='left'
                        // sx={{ maxWidth: '250px', wordBreak: 'break-word' }}
                      >
                        {user.email}
                      </TableCell>
                      <TableCell align='left'>{user.areas[0]?.title}</TableCell>
                      <TableCell align='left'>
                        {Math.ceil(user.belongScore)}
                      </TableCell>
                      <TableCell align='left'>
                        {(user.domainScore * 0.5).toFixed(2)}/50
                      </TableCell>
                      <TableCell align='left'>
                        {(user.aptitudeScore * 0.4).toFixed(2)}/40
                      </TableCell>
                      <TableCell align='left'>
                        {user.profileStrength}/10
                      </TableCell>
                      {/* <TableCell align='left'>
                        {user.createdAt &&
                          format(
                            new Date(user.createdAt),
                            'do LLLL yyyy h:mm a'
                          )}
                      </TableCell> */}
                      <TableCell align='left' sx={{ minWidth: '300px' }}>
                        {user.capstones.map((capstone) => {
                          const isAssessmentGiven = Boolean(
                            capstone.capstoneAssessments?.percentage
                          );
                          return (
                            <Box key={capstone._id} marginBottom='5px'>
                              <Box>{capstone?.capstone?.title}</Box>
                              <Box fontSize='12px'>
                                {' '}
                                Enrolled -{' '}
                                {capstone.createdAt &&
                                  format(
                                    new Date(capstone.createdAt),
                                    'do LLLL yyyy'
                                  )}
                              </Box>
                              {isAssessmentGiven && (
                                <Box>
                                  Quiz Score:{' '}
                                  {capstone.capstoneAssessments?.percentage}%
                                </Box>
                              )}
                            </Box>
                          );
                        })}
                      </TableCell>
                      <TableCell sx={{ minWidth: '400px' }}>
                        {}
                        {user.capstones[0]?.capstoneSubmissions.length ? (
                          <>
                            <strong>
                              Submitted{' '}
                              {user.capstones[0]?.capstoneSubmissions.length}/
                              {user.capstones[0]?.capstone.tasks.length}
                            </strong>

                            <Box>
                              {user.capstones[0]?.capstone.tasks.map((task) => {
                                const isSubmission =
                                  user.capstones[0]?.capstoneSubmissions.find(
                                    (submission) =>
                                      submission.taskId === task._id
                                  )?.lastUpdated;

                                return (
                                  <Box key={task._id}>
                                    <Box
                                      onClick={() => {
                                        handleOpen();
                                        setSelectedCapstone(
                                          user.capstones[0]?.capstoneSubmissions.find(
                                            (submission) =>
                                              submission.taskId === task._id
                                          )?.solution
                                        );
                                      }}
                                      display='inline'
                                      sx={{
                                        cursor: 'pointer',
                                        color: isSubmission ? 'blue' : 'black',
                                      }}
                                    >
                                      {task.title} -{' '}
                                    </Box>

                                    {isSubmission ? (
                                      <Typography
                                        variant='span'
                                        fontSize={13}
                                        color='gray'
                                      >
                                        {format(
                                          new Date(
                                            user.capstones[0]?.capstoneSubmissions.find(
                                              (submission) =>
                                                submission.taskId === task._id
                                            )?.lastUpdated
                                          ),
                                          'do LLLL yyyy'
                                        )}
                                      </Typography>
                                    ) : (
                                      'Not Submitted'
                                    )}
                                  </Box>
                                );
                              })}
                            </Box>
                          </>
                        ) : (
                          'No submissions'
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
          >
            <Box sx={style}>
              <Typography id='modal-modal-title' variant='h6' component='h2'>
                Submission
              </Typography>
              <Box id='modal-modal-description' sx={{ mt: 2 }}>
                {htmlparser(selectedCapstone || 'No submission')}
              </Box>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
};

export default Index;

import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';
import UserBody from './UserBody';

import TableListHead from '../../layouts/TableListHead';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function UserHeader({ data }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('createdAt');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((user) => {
    return {
      _id: user._id,
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      createdAt: user.createdAt,
      aptitudeTest: user.aptitudeTest?.percentage,
      domainTests: user.domainTests,
    };
  });

  const TABLE_HEAD = [
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'email', label: 'Email', align: 'left' },
    { id: 'createdAt', label: 'Account Created', align: 'left' },
    { id: 'aptitudeTest', label: 'Aptitude Test', align: 'left' },
    { id: 'domainTests', label: 'Domain Tests', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={rows.length}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {filteredUsers
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => (
                <UserBody key={row._id} row={row} />
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 50]}
          component='div'
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default UserHeader;

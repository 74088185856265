import React, { useEffect, useState } from 'react';
import { Grid, Container } from '@mui/material';
import DataCard from '../layouts/DataCard';
import { API_URL } from '../../../links/API_LINKS';
import axios from 'axios';

import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import PublicIcon from '@mui/icons-material/Public';
import SchoolIcon from '@mui/icons-material/School';
import DomainIcon from '@mui/icons-material/Domain';

import CompanyUser from '../layouts/CompanyUser';

const primaryLighter = '#C8FACD';
const infoLighter = '#D0F2FF';
const successLighter = '#E9FCD4';
const warningLighter = '#FFF7CD';
const errorLighter = '#FFE7D9';
const primaryDarker = '#005249';
const infoDarker = '#04297A';
const successDarker = '#08660D';
const warningDarker = '#7A4F01';
const errorDarker = '#7A0C2E';

const Stakeholders = ({ data }) => {
  const [companyData, setCompanyData] = useState();

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/dashboard/company-users');
      setCompanyData(result.data);
    })();
  }, []);
  return (
    <Container maxWidth='xl' sx={{ mt: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={22}
            title='Total Universities'
            icon={SchoolIcon}
            bgColor={primaryLighter}
            textColor={primaryDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={72}
            title='Organic Colleges'
            icon={SchoolIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={2}
            title='Total Schools'
            icon={SchoolIcon}
            bgColor={warningLighter}
            textColor={warningDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={161 + data.companies}
            title='Total Companies'
            icon={BusinessIcon}
            bgColor={errorLighter}
            textColor={errorDarker}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={24 + data.coaches}
            title='Total Coaches'
            icon={PeopleIcon}
            bgColor={successLighter}
            textColor={successDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={22}
            title='Total Countries'
            icon={PublicIcon}
            bgColor={errorLighter}
            textColor={errorDarker}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <DataCard
            total={22 + data.areas}
            title='Total Domains'
            icon={DomainIcon}
            bgColor={infoLighter}
            textColor={infoDarker}
          />
        </Grid>
      </Grid>
      {companyData && <CompanyUser companyData={companyData.data} />}
    </Container>
  );
};

export default Stakeholders;

import React from 'react';
import { Box, Typography } from '@mui/material';

const ProjectErrors = ({ duplicateProject, emptyProject }) => {
  return (
    <Box>
      {duplicateProject.map((project, index) => {
        return (
          <Typography mt={1} mb={1} key={index} color='error'>
            {' '}
            {project.companyId.name} - {project.domainId.title} has duplicate
            Projects{' '}
          </Typography>
        );
      })}
      {emptyProject.map((project, index) => {
        return (
          <Typography mt={1} mb={1} key={index} color='error'>
            {' '}
            {project.companyId?.name} - {project.domainId?.title} Need a Project
          </Typography>
        );
      })}
    </Box>
  );
};

export default ProjectErrors;

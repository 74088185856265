import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

import { Container, Box } from '@mui/material';
import Page from '../components/Page';
import Admin from '../components/_dashboard/admin';
import { API_URL } from '../links/API_LINKS';

const DashboardApp = () => {
  const [adminData, setAdminData] = useState();

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/dashboard/all');
      setAdminData(result.data);
    })();
  }, []);

  return (
    <Page title='Admin'>
      {!adminData && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {adminData && (
        <Container maxWidth='xl'>
          <Admin adminData={adminData} />
        </Container>
      )}
    </Page>
  );
};

export default DashboardApp;

import React from 'react';
import TableCell from '@mui/material/TableCell';
import { TableRow } from '@mui/material';
import { format } from 'date-fns';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useQuery } from 'react-query';
import { API_URL } from '../../../../links/API_LINKS';
import axios from 'axios';

function ApplicantBody({ row, setShowProfile }) {
  let paramsObj = { userId: row.userId };
  if (row.area) {
    paramsObj['areaId'] = row.area;
  }
  const fetchUserBelongScore = async () => {
    const res = await axios.get(API_URL + '/user/profile/score-summary', {
      params: paramsObj,
    });
    return res;
  };

  const { status, data } = useQuery(
    `fetchUserBelongScore${row.userId}`,
    fetchUserBelongScore,
    { select: (data) => data?.data?.data }
  );

  function calculatebelongScore(data) {
    const profileStrength = data?.profileStrength || 0;
    const domainScore = data?.domain?.bestAttempt?.percentage || 0;
    const aptitudeScore = data?.aptitude?.bestAttempt?.percentage || 0;
    const belongScore = Math.ceil(
      profileStrength + domainScore * 0.5 + aptitudeScore * 0.4
    );
    return belongScore;
  }

  let belongScoreAvailable;
  if (data) {
    belongScoreAvailable = data.aptitude.totalAttempts > 0;
  }
  return (
    <>
      <TableRow
        key={row._id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell
          align='left'
          onClick={() => {
            setShowProfile(row);
          }}
          sx={{ cursor: 'pointer' }}
        >
          {row.applicantName}
        </TableCell>
        <TableCell align='left'>{row.roleName}</TableCell>
        <TableCell align='left'>{row.company}</TableCell>
        <TableCell align='left'>{row.domain}</TableCell>
        <TableCell align='left'>
          {format(new Date(row.appliedOn), 'do LLLL yyyy')}{' '}
        </TableCell>
        <TableCell align='left'>{row.emailAddress}</TableCell>
        <TableCell align='left'>
          {' '}
          {row.status === 'Applied' ? (
            <>
              New{' '}
              <FiberManualRecordIcon sx={{ fontSize: 12 }} color='warning' />
            </>
          ) : (
            row.status
          )}
        </TableCell>
        <TableCell align='center'>
          {' '}
          {status === 'success' && (
            <>
              {belongScoreAvailable
                ? `${calculatebelongScore(data)} %`
                : row.empScore
                ? `${row.empScore}%`
                : 'NA'}
            </>
          )}{' '}
        </TableCell>
        <TableCell align='left'>{row.source}</TableCell>
      </TableRow>
    </>
  );
}

export default ApplicantBody;

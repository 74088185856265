import React from 'react';
import TextEditor from './TextEditor';
import { Grid, MenuItem, Typography } from '@mui/material';
import { TextInput, SelectInput } from '../../layouts/FormElement';

const SuperCaptoneField = ({
  values,
  setFieldValue,
  errors,
  domains,
  companies,
  // setOpen,
  // setSelectedField,
  // setRefresh,
  // refresh,
  capstoneData,
}) => {
  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 3 }}
        mb={3}>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='title'
            type='text'
            label='Title*'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' fullWidth name='domainId' label='Domain'>
            {domains.status === 'success' &&
              domains.data.data.data.map((domain) => {
                return (
                  <MenuItem key={domain._id} value={domain._id}>
                    {domain.title}
                  </MenuItem>
                );
              })}
          </SelectInput>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <SelectInput
            size='small'
            name='companyId'
            label='Select the Company*'>
            <MenuItem value=''>Select the Domain</MenuItem>
            {companies.status === 'success' &&
              companies.data.data.data.map((company) => {
                return (
                  <MenuItem key={company._id} value={company._id}>
                    {company.name}
                  </MenuItem>
                );
              })}
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {/* <TextInput
            size='small'
            fullWidth
            name='problemStatement'
            type='text'
            label='Problem statement*'
          /> */}
          <Typography mb={1} variant='subtitle2'>
            Problem Statement
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={capstoneData?.problemStatement || ''}
            valueToUpdate='problemStatement'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' name='level' label='Level*'>
            <MenuItem value='Basic'>Basic</MenuItem>
            <MenuItem value='Intermediate'>Intermediate</MenuItem>
            <MenuItem value='Advanced'>Advanced</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='tools'
            type='text'
            label='Tools'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Learning objectives
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={capstoneData?.learningObjectives || ''}
            valueToUpdate='learningObjectives'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.learningObjectives}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Business objectives
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={capstoneData?.businessObjectives || ''}
            valueToUpdate='businessObjectives'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.businessObjectives}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Pre-requisites
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={capstoneData?.prerequisites || ''}
            valueToUpdate='prerequisites'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.prerequisites}
          </Typography>
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='introductoryVideo'
            type='text'
            label='Introductory Video'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='videoImage'
            type='text'
            label='Video Background Image'
          />
          <Box>
            <Button
              type='button'
              onClick={() => {
                setOpen(true);
                setSelectedField('videoImage');
                setRefresh(!refresh);
              }}>
              Select File
            </Button>
            <img src={values.videoImage} alt='' width='150' />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='mentorDetail.name'
            type='text'
            label='Mentor Name'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='mentorDetail.designation'
            type='text'
            label='Mentor Designation'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='mentorDetail.linkedin'
            type='text'
            label='Mentor linkedin Url'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='mentorDetail.image'
            type='text'
            label='Mentor Image'
          />
          <Box>
            <Button
              type='button'
              onClick={() => {
                setOpen(true);
                setSelectedField('mentorDetail.image');
                setRefresh(!refresh);
              }}>
              Select File
            </Button>
            <img src={values.mentorDetail?.image} alt='' width='150' />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='capstoneImage'
            type='text'
            label='Capstone Image Url'
          />
          <Box>
            <Button
              type='button'
              onClick={() => {
                setOpen(true);
                setSelectedField('capstoneImage');
                setRefresh(!refresh);
              }}>
              Select File
            </Button>
            <img src={values.capstoneImage} alt='' width='150' />
          </Box>
        </Grid> */}
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='dataset.link'
            type='text'
            label='Dataset Link'
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Dataset Description
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={capstoneData?.dataset?.description || ''}
            valueToUpdate='dataset.description'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors?.dataset?.description}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default SuperCaptoneField;

import React from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import {
  TextInput,
  SelectInput,
  DateTimePickerField,
} from '../../../../layouts/FormElement';

import { Grid, Typography, MenuItem, Button, Box } from '@mui/material';
import { API_URL } from 'links/API_LINKS';
import TextEditor from '../../../../pod/capstones/TextEditor';
import { FieldArray } from 'formik';
import { FaPlusCircle, FaTrashAlt } from 'react-icons/fa';
import { getAllCompany, getAllArea } from '../helper';

const fetchSkills = async () => {
  const res = await axios.get(API_URL + '/skills', {
    params: { limit: 0, sort: 'title', order: 'asc' },
  });
  return res;
};

const CompanyProjectForm = ({
  values,
  setFieldValue,
  projectTaskToEdit,
  errors,
  currentBatch,
  addNew,
}) => {
  const skills = useQuery('fetchSkills', fetchSkills);
  const batchUser = currentBatch?.users || [];
  const allCompany = getAllCompany(batchUser);
  const allArea = getAllArea(batchUser);

  return (
    <div>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{ xs: 2, sm: 1, md: 3 }}
        mb={3}
      >
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='title'
            type='text'
            label='Title*'
          />
        </Grid>
        {addNew && (
          <>
            <Grid item xs={12} sm={6} md={4}>
              <SelectInput
                size='small'
                fullWidth
                name='domainId'
                label='Domain'
              >
                {allArea.map((domain) => {
                  return (
                    <MenuItem key={domain._id} value={domain._id}>
                      {domain.title}
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SelectInput
                size='small'
                name='companyId'
                label='Select the Company*'
              >
                <MenuItem value=''>Select the Domain</MenuItem>
                {allCompany.map((company) => {
                  return (
                    <MenuItem key={company._id} value={company._id}>
                      {company.name}
                    </MenuItem>
                  );
                })}
              </SelectInput>
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={6} md={4}>
          <SelectInput size='small' name={`level`} label='Level*'>
            <MenuItem value='Basic'>Basic</MenuItem>
            <MenuItem value='Intermediate'>Intermediate</MenuItem>
            <MenuItem value='Advanced'>Advanced</MenuItem>
          </SelectInput>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Problem statement
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.problemStatement || ''}
            valueToUpdate='problemStatement'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.problemStatement}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Business objectives
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.businessObjectives || ''}
            valueToUpdate='businessObjectives'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors.businessObjectives}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography mb={1} variant='subtitle2'>
            Dataset Description
          </Typography>
          <TextEditor
            setFieldValue={setFieldValue}
            initialtext={projectTaskToEdit?.dataset?.description || ''}
            valueToUpdate='dataset.description'
          />
          <Typography mb={1} variant='string' color='error'>
            {errors?.dataset?.description}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInput
            size='small'
            fullWidth
            name='dataset.link'
            type='text'
            label='Dataset Link'
          />
        </Grid>
      </Grid>
      <Grid mt={6} item>
        <FieldArray
          name='tasks'
          render={(arrayHelpers) => (
            <>
              {values.tasks.map((title, index) => {
                return (
                  <Grid
                    sx={{ border: 1 }}
                    key={index}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2, sm: 1, md: 3 }}
                    mb={3}
                    pb={5}
                  >
                    <Grid item xs={6}>
                      Task - {index + 1}
                    </Grid>
                    <Grid
                      container
                      xs={6}
                      direction='row'
                      justifyContent='flex-end'
                    >
                      {values.tasks.length > 1 && (
                        <Button
                          type='button'
                          color='error'
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Delete task - {index + 1} <FaTrashAlt />
                        </Button>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='text'
                        name={`tasks[${index}].title`}
                        label='Title'
                        fullWidth
                      />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      {skills.status === 'success' && (
                        <SelectInput
                          size='small'
                          name={`tasks[${index}].skillId`}
                          label='Skills'
                          multiple
                          fullWidth
                        >
                          {skills.data.data.data.map((skill) => {
                            return (
                              <MenuItem key={skill._id} value={skill._id}>
                                {skill.title}
                              </MenuItem>
                            );
                          })}
                        </SelectInput>
                      )}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <TextInput
                        size='small'
                        type='number'
                        name={`tasks[${index}].week`}
                        label='Week'
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DateTimePickerField
                        name={`tasks[${index}].publishAt`}
                        value={values.tasks[index].publishAt}
                        label='Publish At'
                        onChange={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <DateTimePickerField
                        name={`tasks[${index}].dueDateTime`}
                        value={values.tasks[index].dueDateTime}
                        label='Due Date'
                        onChange={setFieldValue}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Background Description
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]
                            ?.backgroundDescription || ''
                        }
                        valueToUpdate={`tasks[${index}].backgroundDescription`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks &&
                          errors.tasks[index]?.backgroundDescription}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Task Instructions
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]?.taskInstructions ||
                          ''
                        }
                        valueToUpdate={`tasks[${index}].taskInstructions`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.taskInstructions}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4}>
                      <Typography mb={1} variant='subtitle2'>
                        Resources
                      </Typography>
                      <TextEditor
                        setFieldValue={setFieldValue}
                        initialtext={
                          projectTaskToEdit?.tasks[index]?.resources || ''
                        }
                        valueToUpdate={`tasks[${index}].resources`}
                      />
                      <Typography mb={1} variant='string' color='error'>
                        {errors.tasks && errors.tasks[index]?.resources}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
              <Box mb={1}>
                Add more task
                <Button
                  type='button'
                  onClick={() =>
                    arrayHelpers.push({
                      title: '',
                      skillId: [],
                      backgroundDescription: '',
                      taskInstructions: '',
                      resources: '',
                      level: 'Basic',
                      week: '',
                      publishAt: new Date(),
                      dueDateTime: new Date(),
                    })
                  }
                >
                  <FaPlusCircle />
                </Button>
              </Box>
            </>
          )}
        />
      </Grid>
    </div>
  );
};

export default CompanyProjectForm;

import { Typography, Stack, Button, Box } from '@mui/material';
import React, { useState } from 'react';
// import CsvDownload from 'react-json-to-csv';
import axios from 'axios';
import * as XLSX from 'xlsx';
import { API_URL } from 'links/API_LINKS';
import { useQueryClient } from 'react-query';
import _ from 'lodash';
import { ExportJsonCsv } from 'react-export-json-csv';

const UploadCsv = ({ mydata, batchData, findEmailInBatch }) => {
  const [serverResponse, setServerResponse] = useState();
  const [isSubmitting, setSubmitting] = useState();
  const [items, setItems] = useState([]);
  const [uploadData, setUploadData] = useState([]);
  const queryClient = useQueryClient();
  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: 'buffer' });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      setItems(data);
    });
  };

  async function datasubmit(arrayData) {
    let tempArray = [];
    let failItems = [];
    arrayData.forEach((data) => {
      data['email'] = data.email.trim().toLowerCase();
    });
    ///Check for duplicate emails within file
    const uniqueData = _.uniqBy(arrayData, (e) => e.email);

    uniqueData.forEach((user) => {
      if (findEmailInBatch(batchData.users, user.email)) {
        failItems.push({
          title: user.email,
          error:
            'This email already exists in the batch. Please edit the existing account.',
        });
      } else {
        tempArray.push(user);
      }
    });

    const finalData = { batchId: batchData._id, users: tempArray };
    setSubmitting(true);

    axios({
      method: 'POST',
      url: API_URL + '/batch/batch-users-array',
      data: finalData,
    })
      .then(function (response) {
        setServerResponse('Users uploaded successfully');
        setSubmitting(false);
        queryClient.invalidateQueries(`fetchCurrentBatch${batchData._id}`);
      })
      .catch(function (response) {
        setServerResponse('Error! Could not process your request.');
        setSubmitting(false);
      });

    setUploadData(failItems);
  }

  const headers = [
    {
      key: 'area',
      name: 'area',
    },
    {
      key: 'area_id',
      name: 'area_id',
    },
    {
      key: 'coach',
      name: 'coach',
    },
    {
      key: 'coach_id',
      name: 'coach_id',
    },
    {
      key: 'company',
      name: 'company',
    },
    {
      key: 'company_id',
      name: 'company_id',
    },
    {
      key: 'mentor',
      name: 'mentor',
    },
    {
      key: 'mentor_id',
      name: 'mentor_id',
    },
  ];

  return (
    <Box sx={{ border: 1 }} p={1}>
      <Typography mb={2} variant='subtitle2' textAlign='center'>
        Add User in Batch
      </Typography>
      <Stack
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={2}
      >
        <ExportJsonCsv headers={headers} items={mydata}>
          <Button variant='outlined'>Alocation Data</Button>
        </ExportJsonCsv>
        {/* <CsvDownload data={mydata}>
          <Button variant='outlined'>Alocation Data</Button>
        </CsvDownload> */}
        <Button variant='outlined'>
          <a
            href='https://res.cloudinary.com/belong/raw/upload/v1718729459/uploaded_resources/Sample-Add-User_siz2ly_yigj0h.xlsx'
            download
          >
            Sample File for add user
          </a>
        </Button>
      </Stack>
      <Typography p={1}>Please upload XSLX file</Typography>
      <input
        type='file'
        onChange={(e) => {
          const file = e.target.files[0];
          readExcel(file);
        }}
      />
      {items.length > 0 && (
        <Box pt={1}>
          <b>{items.length}</b> Users found
          <Button
            variant='outlined'
            disabled={isSubmitting}
            onClick={() => datasubmit(items)}
          >
            {isSubmitting ? 'Processing...' : 'Submit'}
          </Button>
        </Box>
      )}
      <Typography>{serverResponse}</Typography>
      {uploadData.map((item, index) => {
        return (
          <div key={index}>
            <Typography>
              {item.title} : {item.error}
            </Typography>
          </div>
        );
      })}
    </Box>
  );
};

export default UploadCsv;

import React, { useState } from 'react';
import BatchInfo from './info';
import VgaAutomation from './vgaAutomation';

const Index = () => {
  const [currentBatch, setCurrentBatch] = useState(null);
  return (
    <div>
      {currentBatch ? (
        <VgaAutomation
          setCurrentBatch={setCurrentBatch}
          currentBatch={currentBatch}
        />
      ) : (
        <BatchInfo setCurrentBatch={setCurrentBatch} />
      )}
    </div>
  );
};

export default Index;

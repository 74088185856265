import React, { useState, useRef } from 'react';
import { Box, Typography, Grid, MenuItem, Stack } from '@mui/material';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';
import { LoadingButton } from '@mui/lab';
import TextEditor from './TextEditor';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import QuestionField from './QuestionField';

const AddEditTask = ({
  taskNo,
  skills,
  superCapstoneId,
  taskData,
  setCapStoneEditData,
  setValue,
  setActiveStep,
}) => {
  const [serverResponse, setServerResponse] = useState('');
  const formikRef = useRef();

  return (
    <div className='space-y-4'>
      <Box>
        <Typography variant='h6'>Add Task - {taskNo}</Typography>
        <br />
      </Box>
      <div>
        <Formik
          enableReinitialize
          innerRef={formikRef}
          initialValues={taskData}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            skillId: Yup.array().min(1, 'Minimum 1 skill required'),
            backgroundDescription: Yup.string().required(
              'Background description is required'
            ),
            taskInstructions: Yup.string().required(
              'Task instructions are required'
            ),
            resources: Yup.string().required('Resources are required'),
            time: Yup.number().required('Time is required'),
            idealSolution: Yup.string()
              .required('Ideal solution is required')
              .url('Must be a valid URL'),
            // submissionFormat: Yup.array().min(1, 'Submission is required'),
            submissionFormat: Yup.string().required(
              'One Submission format is required'
            ),
            hints: Yup.string().required('Hints are required'),
            questions: Yup.array().min(5, 'Minimum 5 questions required'),
          })}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            // setTaskData(values);
            const taskData = { ...values };
            taskData.superCapstoneId = superCapstoneId;

            axios
              .put(`${API_URL}/super-capstone/add-task`, taskData)
              .then((resp) => {
                setServerResponse(
                  resp?.data?.message || 'Task added successfully'
                );
                setCapStoneEditData(resp.data.supercapstone);
                if (taskNo === 4) {
                  setValue(0);
                } else {
                  setActiveStep(taskNo + 1);
                }
                setSubmitting(false);
              })
              .catch((err) => {
                console.log(err);
                setServerResponse('Error! Could not process your request.');
                setSubmitting(false);
              });
          }}>
          {({ values, setFieldValue, isSubmitting, errors }) => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title*'
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  {skills.status === 'success' && (
                    <SelectInput
                      name='skillId'
                      label='Skills'
                      multiple
                      fullWidth>
                      {skills.data.data.data.map((skill) => (
                        <MenuItem key={skill._id} value={skill._id}>
                          {skill.title}
                        </MenuItem>
                      ))}
                    </SelectInput>
                  )}
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    name='time'
                    label='Time (hours)'
                    type='number'
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} mt={4}>
                  <SelectInput
                    name='userInputType'
                    label=' Select user input type'
                    fullWidth>
                    {['TEXT EDITOR', 'CODE IDE'].map((fmt, idx) => (
                      <MenuItem key={idx} value={fmt}>
                        {fmt}
                      </MenuItem>
                    ))}
                  </SelectInput>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography mb={1} variant='subtitle2'>
                    Background Description
                  </Typography>
                  <TextEditor
                    valueToUpdate='backgroundDescription'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.backgroundDescription || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.backgroundDescription}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography mb={1} variant='subtitle2'>
                    Task Instructions
                  </Typography>
                  <TextEditor
                    valueToUpdate='taskInstructions'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.taskInstructions || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.taskInstructions}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography mb={1} variant='subtitle2'>
                    Resources
                  </Typography>
                  <TextEditor
                    valueToUpdate='resources'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.resources || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.resources}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography mb={1} variant='subtitle2'>
                    Submission Format
                  </Typography>
                  <TextEditor
                    valueToUpdate='submissionFormat'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.submissionFormat || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.submissionFormat}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <Typography mb={1} variant='subtitle2'>
                    Hints
                  </Typography>
                  <TextEditor
                    valueToUpdate='hints'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.hints || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.hints}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <TextInput
                    name='idealSolution'
                    label='Ideal Solution (Drive Link) '
                    type='text'
                    fullWidth
                  />
                  {/* <Typography mb={1} variant='subtitle2'>
                    Ideal Solution
                  </Typography>
                  <TextEditor
                    valueToUpdate='idealSolution'
                    setFieldValue={setFieldValue}
                    initialtext={taskData?.idealSolution || ''}
                  />
                  <Typography mb={1} color='error'>
                    {errors.idealSolution}
                  </Typography> */}
                </Grid>

                <Grid item xs={12}>
                  <QuestionField
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Grid>
              </Grid>

              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
                mt={2}>
                <LoadingButton
                  type='submit'
                  variant='contained'
                  disabled={values.questions.length < 5}
                  loading={isSubmitting}>
                  {taskData.title
                    ? `Update Task ${taskNo}`
                    : `Add Task ${taskNo}`}
                </LoadingButton>
              </Stack>
              {serverResponse && (
                <Box mt={2}>
                  <Typography>{serverResponse}</Typography>
                </Box>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default AddEditTask;

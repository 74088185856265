import { Box, Typography, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import React, { useRef } from 'react';
import { TextInput } from '../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../../links/API_LINKS';
import { useQuery, useQueryClient } from 'react-query';
import Multiselect from 'multiselect-react-dropdown';

const fetchAreas = async () => {
  const res = await axios.get(API_URL + '/area', { params: { limit: 0 } });
  return res;
};

const EditSkill = ({ skill, snackbarActions, handleClose }) => {
  const areas = useQuery('fetchAreas', fetchAreas);
  const formikRef = useRef();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <Typography align='center' mb={4} variant='h6'>
        Edit Skill
      </Typography>
      <div>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            skillId: skill._id,
            title: skill.title,
            areas: skill.areas ? skill.areas.map((area) => area._id) : [],
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            axios({
              method: 'PUT',
              url: API_URL + '/skills',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Skill added successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchSkills');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to update Skill, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <TextInput
                size='small'
                fullWidth
                name='title'
                type='text'
                label='Title'
              />
              <Stack direction='row' spacing={2} mt={4}>
                <Typography> Select Areas </Typography>
                {areas.status === 'success' && (
                  <Multiselect
                    isObject={true}
                    displayValue='name'
                    options={areas.data.data.data.map((area) => {
                      return { name: area.title, id: area._id };
                    })}
                    selectedValues={areas.data.data.data
                      .filter((area) => {
                        return values.areas.includes(area._id);
                      })
                      .map((area) => {
                        return { name: area.title, id: area._id };
                      })}
                    onSelect={(selectedList, selectedItem) => {
                      let myAreas = values.areas;
                      myAreas.push(selectedItem.id);
                      setFieldValue('areas', myAreas);
                    }}
                    onRemove={(selectedList, removedItem) => {
                      const myAreas = values.areas.filter((area) => {
                        return removedItem.id !== area;
                      });
                      setFieldValue('areas', myAreas);
                    }}
                  />
                )}
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={3}
                >
                  <LoadingButton
                    loading={isSubmitting}
                    type='submit'
                    variant='contained'
                  >
                    Update Skill
                  </LoadingButton>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditSkill;

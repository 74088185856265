import React, { useEffect, useState } from 'react';
import { TableRow, TableCell, Typography } from '@mui/material';
import axios from 'axios';
import { API_URL } from 'links/API_LINKS';

const TeamBody = ({ row, index }) => {
  const [allUser, setAllUser] = useState([]);
  const usersArray = row.users;

  useEffect(() => {
    const fetchData = async () => {
      const data = await Promise.all(
        usersArray.map(async (userId) => {
          const user = await axios(API_URL + '/user', {
            params: { userId },
          });
          return user?.data?.data;
        })
      );
      setAllUser(data);
    };

    fetchData();
  }, [usersArray]);
  console.log(allUser);
  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {index + 1}
        </TableCell>
        <TableCell align='left'>{row.team_name}</TableCell>
        <TableCell align='left'>{usersArray.length}</TableCell>
        <TableCell align='left'>
          {allUser.map((user) => {
            return (
              <Typography>
                {' '}
                {user.firstName} {user.lastName} - {user.email}{' '}
              </Typography>
            );
          })}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TeamBody;

import React from 'react';
import { Table, Card } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TeamBody from './TeamBody';
import {
  applySortFilter,
  getComparator,
} from 'components/_dashboard/halper/TableSort';

const AllTeams = ({ data, teamData, setRefetch }) => {
  const shortedTeamData = applySortFilter(
    teamData,
    getComparator('asc', 'team_name')
  );
  return (
    <>
      <TableContainer component={Card}>
        <Table
          size='small'
          sx={{ minWidth: 650, borderRadius: 2, shadow: 2 }}
          aria-label='table'
        >
          <TableHead>
            <TableRow>
              <TableCell>Team Name</TableCell>
              <TableCell align='left'> Total User</TableCell>
              {/* <TableCell align='left'>User</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {shortedTeamData.map((team) => {
              return (
                <TeamBody
                  key={team.id}
                  team={team}
                  userData={data}
                  setRefetch={setRefetch}
                  allTeamData={shortedTeamData}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AllTeams;

import {
  FETCH_TEAM_REQUEST,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
} from './teamTypes';

const initialState = {
  loading: false,
  team: null,
  error: '',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TEAM_SUCCESS:
      return {
        loading: false,
        team: action.payload,
        error: '',
      };
    case FETCH_TEAM_FAILURE:
      return {
        loading: false,
        team: null,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

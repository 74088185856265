import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import CapstoneTaskDetail from './CapstoneTaskDetail';

import TableContainer from '@mui/material/TableContainer';
import { TableRow } from '@mui/material';
import TableCell from '@mui/material/TableCell';

const CapstoneDetailTable = ({ currentCapstone }) => {
  const myCapstones = currentCapstone.capstones.filter((cap) => {
    return cap.capstone;
  });

  const currentCapstoneObj = { ...currentCapstone, capstones: myCapstones };
  return (
    <>
      <TableContainer sx={{ maxHeight: 500 }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
          aria-label='table'
          size='small'
        >
          <TableHead>
            <TableRow>
              <TableCell align='left'>Name</TableCell>
              <TableCell align='left'>Title</TableCell>
              <TableCell align='left'>Domain</TableCell>
              <TableCell align='left'>Total Tasks</TableCell>
              <TableCell align='left'>Completed Tasks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow
              key={currentCapstoneObj._id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
              }}
            >
              <TableCell component='th' scope='row' padding='none'>
                {currentCapstoneObj.firstName} {currentCapstoneObj.lastName}
              </TableCell>
              <TableCell>
                {currentCapstoneObj.capstones.map((cap) => (
                  <div style={{ marginBottom: '6px' }} cap={cap} key={cap._id}>
                    {cap.capstone.title}
                  </div>
                ))}
              </TableCell>

              <TableCell>
                {currentCapstoneObj.capstones.map((cap) => (
                  <div style={{ marginBottom: '6px' }} cap={cap} key={cap._id}>
                    {cap.capstone.domainId.title}
                  </div>
                ))}
              </TableCell>

              <TableCell>
                {currentCapstoneObj.capstones.map((cap) => (
                  <div style={{ marginBottom: '6px' }} cap={cap} key={cap._id}>
                    {cap.capstone.tasks.length}
                  </div>
                ))}
              </TableCell>

              <TableCell>
                {currentCapstoneObj.capstones.map((cap) => (
                  <div style={{ marginBottom: '6px' }} cap={cap} key={cap._id}>
                    {cap.capstoneSubmissions.length}
                  </div>
                ))}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <CapstoneTaskDetail currentCapstone={currentCapstoneObj} />
    </>
  );
};

export default CapstoneDetailTable;

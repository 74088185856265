import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { TableRow, TablePagination } from '@mui/material';
import { Card, Box, CircularProgress } from '@mui/material';

import PartnerBody from './PartnerBody';

import { API_URL } from '../../../links/API_LINKS';

function Partners() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [partnerData, setPartneData] = useState([]);

  useEffect(() => {
    (async () => {
      const result = await axios(API_URL + '/dashboard/partner-users');
      setPartneData(result.data.data);
    })();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  //  const Average = assessment.map((elem) => elem.averagePercentage).reduce((a, b) => a + b) / assessment.length;
  return partnerData.length === 0 ? (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      minHeight='50vh'
    >
      <CircularProgress />
    </Box>
  ) : (
    <TableContainer component={Card} sx={{ marginTop: '30px' }}>
      <Table
        sx={{ minWidth: 550, shadow: 2, padding: '6px' }}
        aria-label='table'
        size='small'
      >
        <TableHead>
          <TableRow>
            <TableCell align='left'></TableCell>
            <TableCell align='left'>Partner</TableCell>
            <TableCell align='center'>Country</TableCell>
            <TableCell align='center'>Students Engaged</TableCell>
            <TableCell align='center'>
              Employibility Assessments Conducted
            </TableCell>
            <TableCell align='center'>Average Employibility Score</TableCell>
            <TableCell align='center'> Website</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {partnerData
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row) => {
              return <PartnerBody key={row._id} row={row} />;
            })}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 50]}
        component='div'
        count={partnerData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>
  );
}

export default Partners;

import { Typography, Stack, Button, Box } from '@mui/material';
import React, { useState } from 'react';
import EditAudience from './EditAudience';
import { getAllCompany, getAllCoach, getAllArea } from '../helper';

const checkForUser = (criteriaId, usersIdForThatCriteria) => {
  if (!criteriaId.length) {
    return true;
  }
  if (criteriaId.length && criteriaId.includes(usersIdForThatCriteria._id)) {
    return true;
  }
  return false;
};

const AudienceDetails = ({
  currentBatch,
  eventId,
  eventWeek,
  snackbarActions,
  handleClose,
  eventData,
}) => {
  const [edit, setEdit] = useState(false);
  const batchUser = currentBatch.users;
  const currentEvent = currentBatch.events.filter((event) => {
    return event.eventId === eventId;
  })[0];
  let eligibleUsers = [];

  if (currentEvent) {
    currentBatch.users.forEach((user) => {
      const userCompany = user.company;
      const userMentor = user.mentor;
      const userCoach = user.coach;
      const userArea = user.area;
      const userCompany2 = user.company2;
      const userMentor2 = user.mentor2;
      const userCoach2 = user.coach2;

      if (
        currentBatch.timeSheet &&
        currentBatch.timeSheet.startsAt <= eventWeek
      ) {
        checkForUser(currentEvent.companyId, userCompany2 || userCompany) &&
          checkForUser(currentEvent.mentorId, userMentor2 || userMentor) &&
          checkForUser(currentEvent.coachId, userCoach2 || userCoach) &&
          checkForUser(currentEvent.areas, userArea) &&
          eligibleUsers.push(user);
      } else {
        checkForUser(currentEvent.companyId, userCompany) &&
          checkForUser(currentEvent.mentorId, userMentor) &&
          checkForUser(currentEvent.coachId, userCoach) &&
          checkForUser(currentEvent.areas, userArea) &&
          eligibleUsers.push(user);
      }
    });
  }
  let allCoach = getAllCoach(batchUser);
  let allCompany = getAllCompany(batchUser);
  let allArea = getAllArea(batchUser);

  let areaName = 'All Areas';
  if (currentEvent && currentEvent.areas.length) {
    const eventArea = allArea.filter((area) => {
      return area._id === currentEvent.areas[0];
    });
    if (eventArea[0]) {
      areaName = eventArea[0].title;
    }
  }
  let coachName = 'All Coach';
  if (currentEvent && currentEvent.coachId.length) {
    const eventCoach = allCoach.filter((coach) => {
      return coach._id === currentEvent.coachId[0];
    });
    if (eventCoach[0]) {
      coachName = eventCoach[0].name;
    }
  }
  let companyName = 'All Company';
  if (currentEvent && currentEvent.companyId.length) {
    const eventCompany = allCompany.filter((company) => {
      return company._id === currentEvent.companyId[0];
    });
    if (eventCompany[0]) {
      companyName = eventCompany[0].name;
    }
  }
  console.log(eventData);
  return (
    <div>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography variant='subtitle2'> Audience Details</Typography>
        <Button onClick={() => setEdit(!edit)}>
          {edit ? 'Cancel' : 'Add/Edit Audience'}
        </Button>
      </Stack>

      {edit ? (
        <EditAudience
          allCoach={allCoach}
          allCompany={allCompany}
          allArea={allArea}
          eventId={eventId}
          batchId={currentBatch._id}
          snackbarActions={snackbarActions}
          handleClose={handleClose}
        />
      ) : currentEvent ? (
        <>
          <Stack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing={2}
          >
            <Typography>Areas : {areaName} </Typography>
            <Typography>Coach : {coachName} </Typography>
            <Typography>Company : {companyName} </Typography>
          </Stack>
          <Typography>
            {eligibleUsers.length} Audience eligible for this event
          </Typography>
        </>
      ) : (
        <Typography>No Audience added for this Event </Typography>
      )}
      {eventData.pollingEvent && (
        <Box mt={4}>
          <Typography>
            {' '}
            Polling Users Details : {
              eventData.pollingUser.length
            } Registrations{' '}
          </Typography>
          {eventData.pollingUser.map((user, index) => {
            return (
              <Typography mt={1}>
                Name / Email : {user.userId?.name} - {user.userId?.email}{' '}
              </Typography>
            );
          })}
        </Box>
      )}
    </div>
  );
};

export default AudienceDetails;

import { Box, Grid, MenuItem, Typography, Stack } from '@mui/material';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import React, { useRef } from 'react';
import { TextInput, SelectInput } from '../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const EditArea = ({ area, snackbarActions, handleClose }) => {
  const formikRef = useRef();
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <Typography align='center' mb={4} variant='h6'>
        Edit Area
      </Typography>
      <div>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            areaId: area._id,
            title: area.title,
            type: area.type,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
            type: Yup.string().required(),
          })}
          onSubmit={(values, { setSubmitting }) => {
            axios({
              method: 'PUT',
              url: API_URL + '/area',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Area added successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchAreas');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to add Area, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                mb={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SelectInput size='small' fullWidth name='type' label='Type'>
                    <MenuItem value='Technical'>Technical</MenuItem>
                    <MenuItem value='Business'>Business </MenuItem>
                  </SelectInput>
                </Grid>
              </Grid>
              <Stack
                direction='row'
                justifyContent='center'
                alignItems='center'
                spacing={3}
              >
                <LoadingButton
                  loading={isSubmitting}
                  type='submit'
                  variant='contained'
                >
                  Submit
                </LoadingButton>
              </Stack>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              ></Box>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditArea;

import { Box } from '@mui/material';
import { supabase } from 'links/supabaseConfig';
import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import TeamHeader from './TeamHeader';

const Index = () => {
  const [teamLoading, setTeamLoading] = useState(true);
  const [teamData, setTeamData] = useState([]);

  useEffect(() => {
    const setDataFn = async () => {
      const { data: fetchedData } = await supabase
        .from('team')
        .select()
        .match({ bootcamp_id: 2 });
      setTeamData(fetchedData);

      setTeamLoading(false);
    };
    setDataFn();
  }, []);
  return (
    <div>
      {teamLoading && (
        <Box
          display='flex'
          justifyContent='center'
          alignItems='center'
          minHeight='50vh'
        >
          <CircularProgress />
        </Box>
      )}
      {!teamLoading && <TeamHeader data={teamData} />}
    </div>
  );
};

export default Index;

import React, { useState } from 'react';
import { TableRow, TableCell, Box, Chip } from '@mui/material';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Snackbar from '../../../../misc/Snackbar';
import EditTemplate from './EditTemplate';
import axios from 'axios';
import { API_URL } from '../../../../../links/API_LINKS';
import Prompt from '../../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '100%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const EventTemplateBody = ({ template }) => {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const queryClient = useQueryClient();

  const deleteTemplate = (id) => {
    setSnackbarText('Deleting event template...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/event-template', {
        data: { eventTemplateId: id },
      })
      .then((res) => {
        setSnackbarText('Event template has been deleted');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        queryClient.invalidateQueries('fetchEventTemplate');
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <TableRow>
        <TableCell component='th' scope='row'>
          {template.title}
        </TableCell>
        <TableCell align='left'>
          {template.type === 'Masterclass' ? (
            <Chip color='primary' size='small' label={template.type} />
          ) : template.type === 'Mentor Connect' ? (
            <Chip color='secondary' size='small' label='Mentor Connect' />
          ) : (
            <Chip color='warning' size='small' label='Coach Connect' />
          )}
        </TableCell>

        <TableCell align='left'>
          {template.journies.map((item, index) => {
            const chipLabel = `${item.journeyType}-(${item.journeyDuration})- Week-${item.week}`;
            return <Chip size='small' key={index} label={chipLabel} />;
          })}
        </TableCell>

        <TableCell align='left'>
          <IconButton
            sx={{ cursor: 'pointer' }}
            size='small'
            onClick={handleOpen}
          >
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='right'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this event template?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteTemplate(template._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
      </TableRow>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditTemplate
            template={template}
            handleClose={handleClose}
            snackbarActions={{
              setSnackbarText,
              setSnackbarSeverity,
              setSnackbarOpen,
            }}
          />
        </Box>
      </Modal>

      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </>
  );
};

export default EventTemplateBody;

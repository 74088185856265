import { Grid, IconButton } from '@mui/material';
import React, { useState } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { API_URL } from '../../../../../../links/API_LINKS';
import Prompt from '../../../../../misc/Prompt';
import { useQueryClient } from 'react-query';
import Snackbar from '../../../../../misc/Snackbar';

const ShowUser = ({ batchUser, updateUserId, batchId }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const queryClient = useQueryClient();
  const deleteUser = (email) => {
    setSnackbarText('Deleting User...');
    setSnackbarSeverity('warning');
    setSnackbarOpen(true);

    axios
      .delete(API_URL + '/batch/batch-users', {
        data: { email: email, batchId: batchId },
      })
      .then((res) => {
        setSnackbarOpen(true);
        setSnackbarText('User has been deleted');
        setSnackbarSeverity('success');

        queryClient.invalidateQueries(`fetchCurrentBatch${batchId}`);
      })
      .catch((res) => {
        setSnackbarText('Something went wrong');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      });
  };
  return (
    <div>
      {batchUser.map((user, index) => {
        return (
          <Grid
            sx={{ border: 1 }}
            key={user._id}
            p={1}
            container
            spacing={1}
            mb={3}
          >
            <Grid item xs={1}>
              {index + 1}
            </Grid>
            <Grid item xs={4}>
              {user.name}
            </Grid>
            <Grid item xs={5}>
              {user.email}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                onClick={() => {
                  updateUserId(user);
                  document.body.scrollTop =
                    document.documentElement.scrollTop = 0;
                }}
                color='primary'
                aria-label='Edit'
              >
                <EditIcon />
              </IconButton>
            </Grid>
            <Grid item xs={1}>
              <Prompt
                triggerComponent={
                  <IconButton size='small' color='error' aria-label='delete'>
                    <DeleteIcon />
                  </IconButton>
                }
                title={'Are you sure to delete this User?'}
                description={'The action cannot be undone'}
                cancelText={'cancel'}
                confirmText={'DELETE'}
                handlConfirm={() => {
                  deleteUser(user.email);
                }}
                handleCancel={() => {}}
              ></Prompt>
            </Grid>
            <Grid item xs={4}>
              Company: {user.company && user.company.name}
            </Grid>
            <Grid item xs={4}>
              Mentor: {user.mentor && user.mentor.name}
            </Grid>
            <Grid item xs={4}>
              Coach: {user.coach && user.coach.name}
            </Grid>
            <Grid item xs={4}>
              Area: {user.area && user.area.title}
            </Grid>
            <Grid item xs={4}>
              {user.status}
            </Grid>
            <Grid item xs={4}>
              {user.completionStatus}
            </Grid>
            {user.company2 && (
              <Grid item xs={4}>
                Company 2 : {user.company2.name}
              </Grid>
            )}
            {user.mentor2 && (
              <Grid item xs={4}>
                Mentor 2 : {user.mentor2.name}
              </Grid>
            )}
            {user.coach2 && (
              <Grid item xs={4}>
                Coach 2 : {user.coach2.name}
              </Grid>
            )}
            {user.coach2 && (
              <Grid item xs={4}>
                Coach 2 : {user.coach2.name}
              </Grid>
            )}
            {user.certificateUrl && (
              <Grid item xs={4}>
                <img
                  src={user.certificateUrl}
                  component='img'
                  height='20'
                  alt='logo'
                />
              </Grid>
            )}
          </Grid>
        );
      })}
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        duration={3000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        title={snackbarText}
        severity={snackbarSeverity}
      ></Snackbar>
    </div>
  );
};

export default ShowUser;

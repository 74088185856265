import React, { useState } from 'react';
import { TableRow, TableCell, Box, Button } from '@mui/material';
import { format } from 'date-fns';
import Modal from '@mui/material/Modal';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import EditEvent from './EditEvent';
import LinkIcon from '@mui/icons-material/Link';
import AddAttendance from './attendance';
import AudienceDetails from './AudienceDetails';
import Prompt from '../../../../../misc/Prompt';
import DeleteIcon from '@mui/icons-material/Delete';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  height: '90%',
  overflow: 'scroll',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SubEventBody = ({
  subEvent,
  currentBatch,
  snackbarActions,
  deleteEvent,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openAttendance, setOpenAttendance] = useState(false);
  const handleOpenAttendance = () => setOpenAttendance(true);
  const handleCloseAttendance = () => setOpenAttendance(false);

  const [openAudience, setOpenAudience] = useState(false);
  const handleOpenAudience = () => setOpenAudience(true);
  const handleCloseAudience = () => setOpenAudience(false);

  return (
    <>
      <TableRow key={subEvent._id}>
        <TableCell>{subEvent.title}</TableCell>
        <TableCell>
          {subEvent.dateTime &&
            format(new Date(subEvent.dateTime), 'do LLLL yyyy h:mm a iiii')}
        </TableCell>
        <TableCell>
          <a target='_blank' rel='noreferrer' href={subEvent.link}>
            <LinkIcon />
          </a>
        </TableCell>
        <TableCell>
          <IconButton size='small' onClick={handleOpen}>
            <EditIcon color='primary' />
          </IconButton>
        </TableCell>
        <TableCell align='left'>
          <Button onClick={handleOpenAudience}>Audience</Button>
        </TableCell>
        <TableCell align='left'>
          <Button onClick={handleOpenAttendance}>Attendance</Button>
        </TableCell>
        <TableCell align='left'>
          <Prompt
            triggerComponent={
              <IconButton size='small' color='error' aria-label='delete'>
                <DeleteIcon />
              </IconButton>
            }
            title={'Are you sure to delete this Event?'}
            description={'The action cannot be undone'}
            cancelText={'cancel'}
            confirmText={'DELETE'}
            handlConfirm={() => {
              deleteEvent(subEvent._id);
            }}
            handleCancel={() => {}}
          ></Prompt>
        </TableCell>
        {subEvent.pollingUser?.length > 0 && (
          <TableCell align='left'>
            Polling User: {subEvent.pollingUser?.length}
          </TableCell>
        )}
      </TableRow>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <EditEvent
            handleClose={handleClose}
            event={subEvent}
            snackbarActions={snackbarActions}
            currentBatch={currentBatch}
          />
        </Box>
      </Modal>
      <Modal
        open={openAttendance}
        onClose={handleCloseAttendance}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AddAttendance
            currentBatch={currentBatch}
            event={subEvent}
            handleClose={handleCloseAttendance}
            snackbarActions={snackbarActions}
          />
        </Box>
      </Modal>
      <Modal
        open={openAudience}
        onClose={handleCloseAudience}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <AudienceDetails
            currentBatch={currentBatch}
            eventId={subEvent._id}
            eventData={subEvent}
            eventWeek={subEvent.week}
            snackbarActions={snackbarActions}
            handleClose={handleCloseAudience}
          />
        </Box>
      </Modal>
    </>
  );
};

export default SubEventBody;

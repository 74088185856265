import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';

import { TablePagination } from '@mui/material';
import Paper from '@mui/material/Paper';

import TableListHead from '../../layouts/TableListHead';
import RolesBody from './RolesBody';
import EditRole from './EditRole';

import { applySortFilter, getComparator } from '../../halper/TableSort';

function RolesHeader({ data, setValue }) {
  const [editRole, setEditRole] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('roleTitle');

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  let rows = data.map((role) => {
    return {
      role: role,
      roleTitle: role.roleDetails.roleTitle,
      company: role.companyId.name,
      roleType: role.roleDetails.roleType,
      domain: role.domainId.title,
      status: role.status,
      slug: role.slug,
      cutoffDate: role.cutoffDate,
      addedOn: role.createdAt,
    };
  });

  const TABLE_HEAD = [
    { id: 'roleTitle', label: 'Roles', align: 'left' },
    { id: 'roleType', label: 'Role Type', align: 'left' },
    { id: 'company', label: 'Company', align: 'left' },
    { id: 'domain', label: 'Domain', align: 'left' },
    { id: 'addedOn', label: 'Added On', align: 'left' },
    { id: 'cutoffDate', label: 'Cut-off Date', align: 'left' },
    { id: 'status', label: 'Status', align: 'left' },
    { id: 'edit', label: 'View Job', align: 'left' },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredUsers = applySortFilter(rows, getComparator(order, orderBy));

  return (
    <>
      {editRole ? (
        <EditRole
          setValue={setValue}
          setEditRole={setEditRole}
          editRole={editRole}
        />
      ) : (
        <TableContainer component={Paper}>
          <Table
            sx={{ minWidth: 650, shadow: 2, padding: '6px' }}
            aria-label='table'
            size='small'
          >
            <TableListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={rows.length}
              onRequestSort={handleRequestSort}
            />
            <TableBody>
              {filteredUsers
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => (
                  <RolesBody
                    key={row.role._id}
                    row={row}
                    setEditRole={setEditRole}
                  />
                ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 50]}
            component='div'
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}
    </>
  );
}

export default RolesHeader;

import { Typography, Stack, Box } from '@mui/material';
import React from 'react';
import { format } from 'date-fns';

const EventSummary = ({
  batchName,
  eventName,
  eventDateTime,
  totalUsers,
  totalEligibleUsers,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: 'primary.light',
        padding: '15px',
        border: '1px solid',
        borderColor: 'primary.main',
        borderRadius: '2px',
      }}
    >
      <p>
        You are adding attendance for the event{' '}
        <Typography display='inline' variant='subtitle1'>
          {eventName}
        </Typography>{' '}
        scheduled for{' '}
        <Typography display='inline' variant='subtitle1'>
          {eventDateTime &&
            format(new Date(eventDateTime), 'do LLLL yyyy h:mm a iiii')}
        </Typography>{' '}
        added in batch{' '}
        <Typography display='inline' variant='subtitle1'>
          {batchName}
        </Typography>
      </p>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        spacing={2}
      >
        <Typography display='inline' variant='subtitle1'>
          Total Users in batch: {totalUsers}
        </Typography>
        <Typography display='inline' variant='subtitle1'>
          Audience for this event: {totalEligibleUsers}
        </Typography>
      </Stack>
    </Box>
  );
};

export default EventSummary;

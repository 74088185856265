import { Box, Grid, Modal, Typography, Stack, Button } from '@mui/material';
import { Formik, Form, FieldArray } from 'formik';
import ShowFilesPopup from '../../halper/showFilesPopup';
import * as Yup from 'yup';
import axios from 'axios';
import React, { useState, useRef } from 'react';
import { TextInput } from '../../layouts/FormElement';
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../../links/API_LINKS';
import { useQueryClient } from 'react-query';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  height: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AddCategory = ({ snackbarActions, handleClose }) => {
  const [open, setOpen] = useState(false);
  const [selectedField, setSelectedField] = useState();
  const [refresh, setRefresh] = useState(0);
  // const [faq, setFaq] = useState(0);
  // const [obj, setObj] = useState(1);
  // const [sections, setSections] = useState(1);
  const formikRef = useRef();
  const handleCloseImage = () => setOpen(false);
  function setImage(valueToSetTo) {
    if (formikRef.current) {
      if (selectedField) {
        formikRef.current.setFieldValue(selectedField, valueToSetTo);
      }
    }
  }
  const { setSnackbarText, setSnackbarSeverity, setSnackbarOpen } =
    snackbarActions;
  const queryClient = useQueryClient();
  return (
    <div>
      <Typography align='center' mb={4} variant='h6'>
        Add New Category
      </Typography>
      <div>
        <Formik
          innerRef={formikRef}
          enableReinitialize={true}
          initialValues={{
            title: '',
            shortDescription: '',
            longDescription: '',
            imageUrl: '',
            // videoUrl: '',
            slug: '',
            startingPrice: '',
            // objectives: [],
            highlightPoints: [],
            // faq: {
            //   sectionTitle: [],
            //   sectionContent: [],
            // },
          }}
          validationSchema={Yup.object({
            title: Yup.string().required('Title is required'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            axios({
              method: 'POST',
              url: API_URL + '/categories',
              data: values,
            })
              .then(function (response) {
                setSnackbarText('Category added successfully');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                setSubmitting(false);
                queryClient.invalidateQueries('fetchCategory');

                handleClose();
              })
              .catch(function (response) {
                setSnackbarText(
                  'Failed to add Category, Please try after sometime'
                );
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
                setSubmitting(false);
              });
          }}
        >
          {({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Grid
                container
                rowSpacing={3}
                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                mb={3}
              >
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='title'
                    type='text'
                    label='Title'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='slug'
                    type='text'
                    label='Slug'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    name='startingPrice'
                    type='text'
                    label='Starting Price'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    multiline
                    name='shortDescription'
                    type='text'
                    label='Short Description'
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    multiline
                    name='longDescription'
                    type='text'
                    label='Long Description'
                  />
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <TextInput
                    size='small'
                    fullWidth
                    multiline
                    name='videoUrl'
                    type='text'
                    label='Intro Video'
                  />
                </Grid> */}
                <Grid item xs={12} sm={6}>
                  <TextInput
                    fullWidth
                    size='small'
                    name='imageUrl'
                    type='text'
                    label='Image'
                  />
                  <div>
                    <img src={values.imageUrl} alt='' width='150' />
                  </div>
                  <Button
                    type='button'
                    onClick={() => {
                      setOpen(true);
                      setSelectedField('imageUrl');
                      setRefresh(!refresh);
                    }}
                  >
                    Select Image
                  </Button>
                </Grid>
              </Grid>
              {/* <Grid>
                <div>
                  <FieldArray
                    name='objectives'
                    render={(arrayHelpers) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Typography variant='h6'>Objectives</Typography>

                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                point: '',
                              })
                            }
                          >
                            +ADD
                          </Button>
                        </div>
                        {values.objectives.map((content, index) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor: 'primary.light',
                                padding: '20px',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '2px',
                                marginY: '10px',
                              }}
                            >
                              <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                mb={3}
                              >
                                {' '}
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`objectives[${index}].point`}
                                    type='text'
                                    label='Point'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </Grid> */}
              <Grid>
                <div>
                  <FieldArray
                    name='highlightPoints'
                    render={(arrayHelpers) => (
                      <>
                        <div style={{ display: 'flex' }}>
                          <Typography variant='h6'>Highlight Points</Typography>

                          <Button
                            type='button'
                            onClick={() =>
                              arrayHelpers.push({
                                point: '',
                              })
                            }
                          >
                            +ADD
                          </Button>
                        </div>
                        {values.highlightPoints.map((content, index) => {
                          return (
                            <Box
                              sx={{
                                backgroundColor: 'primary.light',
                                padding: '20px',
                                border: '1px solid',
                                borderColor: 'primary.main',
                                borderRadius: '2px',
                                marginY: '10px',
                              }}
                            >
                              <Grid
                                container
                                rowSpacing={3}
                                columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                                mb={3}
                              >
                                {' '}
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`highlightPoints[${index}].title`}
                                    type='text'
                                    label='Title'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5}>
                                  <TextInput
                                    size='small'
                                    fullWidth
                                    name={`highlightPoints[${index}].text`}
                                    type='text'
                                    label='Text'
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                  <Button
                                    type='button'
                                    onClick={() => arrayHelpers.remove(index)}
                                  >
                                    -Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          );
                        })}
                      </>
                    )}
                  />
                </div>
              </Grid>
              {/* <Grid>
                <Button
                  onClick={() => {
                    setFaq(faq + 1);
                  }}
                  type='button'
                >
                  {' '}
                  Add FAQ Question +
                </Button>
                {sections > 0 && (
                  <button
                    onClick={() => {
                      setFaq(faq - 1);
                    }}
                    type='button'
                  >
                    Remove FAQ Question -
                  </button>
                )}
              </Grid> */}
              {/* <Grid>
                {[...Array(faq)].map((e, i) => (
                  <Box
                    sx={{
                      backgroundColor: 'primary.light',
                      padding: '20px',
                      border: '1px solid',
                      borderColor: 'primary.main',
                      borderRadius: '2px',
                      marginY: '10px',
                    }}
                  >
                    <div key={i}>
                      <Grid item>
                        <p>FAQ {i + 1}</p>
                        <TextInput
                          name={`faq.sectionTitle[${i}]`}
                          type='text'
                          placeholder='FAQ Question'
                        />
                      </Grid>
                      <FieldArray
                        name='faq.sectionContent'
                        render={(arrayHelpers) => (
                          <>
                            <div>
                              <Button
                                type='button'
                                onClick={() =>
                                  arrayHelpers.push({
                                    sectionNumber: i,
                                    contentToPublish: '',
                                    contentType: 'Text',
                                  })
                                }
                              >
                                Add Points
                              </Button>

                              {values.faq.sectionContent.map((faq, index) => {
                                return (
                                  faq.sectionNumber === i && (
                                    <div key={index}>
                                      <Input
                                        name={`faq.sectionContent[${index}].contentToPublish`}
                                        placeholder='FAQ content'
                                      />
                                      <SelectInput
                                        name={`faq.sectionContent.${index}.contentType`}
                                      >
                                        <option value='Text'>Text</option>
                                        <option value='List'>List</option>
                                        <option value='Tab'>Tab</option>
                                      </SelectInput>
                                      <Button
                                        type='button'
                                        onClick={() =>
                                          arrayHelpers.remove(index)
                                        }
                                      >
                                        Remove
                                      </Button>
                                    </div>
                                  )
                                );
                              })}
                            </div>
                          </>
                        )}
                      />
                    </div>
                  </Box>
                ))}
              </Grid> */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  p: 1,
                  m: 1,
                }}
              >
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={3}
                >
                  <LoadingButton type='submit' variant='contained'>
                    Add Category
                  </LoadingButton>
                </Stack>
              </Box>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        open={open}
        onClose={handleCloseImage}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <ShowFilesPopup
            setOpen={setOpen}
            resource_type={'image'}
            setImage={setImage}
            setSelectedField={setSelectedField}
            setRefresh={setRefresh}
            refresh={refresh}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default AddCategory;

import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Stack } from '@mui/material';
import ProjectTaskForm from './ProjectTaskForm';

const AddCompanyProject = ({
  allProject,
  checkForError,
  setAllProject,
  currentBatch,
  handleClose,
}) => {
  return (
    <Box>
      <Formik
        enableReinitialize={false}
        initialValues={{
          batchId: currentBatch._id,
          title: '',
          type: 'Company Tasks',
          level: 'Basic',
          domainId: '',
          companyId: '',
          problemStatement: '',
          businessObjectives: '',
          dataset: {
            link: '',
            description: '',
          },
          tasks: [
            {
              title: '',
              skillId: [],
              backgroundDescription: '',
              taskInstructions: '',
              resources: '',
              week: '',
              publishAt: new Date(),
              dueDateTime: new Date(),
            },
          ],
          checked: true,
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Title is required'),
          domainId: Yup.object().required('Domain is required'),
          companyId: Yup.object().required('Company is required'),
          problemStatement: Yup.string().required(
            'Problem Statement is required'
          ),
          businessObjectives: Yup.string().required(
            'business Objectives is required'
          ),
          tasks: Yup.array().of(
            Yup.object().shape({
              title: Yup.string().required('Title is required'),
              taskInstructions: Yup.string().required(
                'Instructions is required'
              ),
              resources: Yup.string(),
              skillId: Yup.array().min(1, 'Minimum 1 skill required'),
              week: Yup.number().required(),
            })
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const tempAllProject = [...allProject];
          tempAllProject.push(values);
          setAllProject(tempAllProject);
          checkForError(tempAllProject);
          handleClose();
        }}>
        {({ values, setFieldValue, isSubmitting, errors }) => (
          <Form>
            <ProjectTaskForm
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              currentBatch={currentBatch}
              addNew={true}
            />
            <Stack
              direction='row'
              justifyContent='center'
              alignItems='center'
              spacing={3}
              mt={2}>
              <LoadingButton
                type='submit'
                variant='contained'
                loading={isSubmitting}
                disabled={isSubmitting}>
                Add Project
              </LoadingButton>
            </Stack>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
              }}></Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddCompanyProject;

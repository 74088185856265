import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

const BasicInfo = ({ allCoach, allCompany, batchUser, eventAdded, tasks }) => {
  return (
    <Box mb={6}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          {' '}
          <Typography>
            You are creating a journey for {batchUser && batchUser.length} users
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {' '}
          <Typography>
            You are creating a journey for {allCompany.length} companies
          </Typography>
        </Grid>
        {allCoach && (
          <Grid item xs={6}>
            {' '}
            <Typography>
              You are creating a journey for {allCoach.length} Coaches
            </Typography>
          </Grid>
        )}
        {eventAdded && (
          <Grid item xs={6}>
            <Typography>
              Event Added : {eventAdded && eventAdded.length}
            </Typography>
          </Grid>
        )}
        {tasks && (
          <Grid item xs={6}>
            <Typography>Task Added : {tasks.length}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default BasicInfo;

import React, { useRef, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, MenuItem, Stack } from '@mui/material';
import { SelectInput } from '../../../../../layouts/FormElement';
import axios from 'axios';
import { API_URL } from '../../../../../../../links/API_LINKS';

const AddNewEvent = ({
  allEvent,
  setAllEvent,
  makeCurrentEvent,
  handleClose,
}) => {
  const formikRefType = useRef();
  const formikRefselectEvent = useRef();
  const [data, setData] = useState([]);

  async function getTemplate(type) {
    const res = await axios.get(API_URL + '/event-template', {
      params: { type: type },
    });
    if (res.status === 200) {
      setData(res.data.data);
    }
  }

  const AddToAllEventList = (selectedEvent) => {
    const journies = {
      journeyType: 'School/DT Journey',
      journeyDuration: '5 weeks',
      week: 1,
      day: 1,
    };
    let tempSelectedEvent = {
      ...selectedEvent,
      journies: journies,
    };
    const eventDate = new Date();
    const currentEventMade = {
      ...makeCurrentEvent(tempSelectedEvent, eventDate),
    };
    let tempAllEvent = [...allEvent];
    tempAllEvent.push(currentEventMade);
    setAllEvent(tempAllEvent);
    handleClose();
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        innerRef={formikRefType}
        initialValues={{
          type: 'Masterclass',
        }}
        validationSchema={Yup.object({})}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          getTemplate(values.type);
          setSubmitting(false);
        }}
      >
        {({ values, setFieldValue, isSubmitting }) => (
          <Form>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 2, sm: 1, md: 5 }}
              mb={3}
            >
              <Grid item xs={12} sm={6}>
                <SelectInput
                  size='small'
                  fullWidth
                  name='type'
                  label='Event Type'
                >
                  <MenuItem value='Masterclass'>Masterclass</MenuItem>
                  <MenuItem value='Coach Connect'>Coach Connect</MenuItem>
                  <MenuItem value='Mentor Connect'>Mentor Connect</MenuItem>
                </SelectInput>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                  spacing={3}
                >
                  <LoadingButton
                    type='submit'
                    variant='contained'
                    loading={isSubmitting}
                  >
                    Get Events
                  </LoadingButton>
                </Stack>
              </Grid>{' '}
            </Grid>
          </Form>
        )}
      </Formik>
      <Box>
        {data.length > 0 && (
          <Formik
            enableReinitialize={true}
            innerRef={formikRefselectEvent}
            initialValues={{
              selectedEvent: '',
            }}
            validationSchema={Yup.object({})}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              AddToAllEventList(values.selectedEvent);
              setSubmitting(false);
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={{ xs: 2, sm: 1, md: 5 }}
                  mb={3}
                >
                  <Grid item xs={12} sm={6}>
                    <SelectInput
                      size='small'
                      fullWidth
                      name='selectedEvent'
                      label='Event'
                    >
                      <MenuItem value=''>Select Event </MenuItem>
                      {data.map((item) => {
                        return (
                          <MenuItem value={item} key={item._id}>
                            {item.title}{' '}
                          </MenuItem>
                        );
                      })}
                    </SelectInput>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack
                      direction='row'
                      justifyContent='center'
                      alignItems='center'
                      spacing={3}
                    >
                      {values.selectedEvent && (
                        <LoadingButton
                          type='submit'
                          variant='contained'
                          loading={isSubmitting}
                        >
                          Add Events
                        </LoadingButton>
                      )}
                    </Stack>
                  </Grid>{' '}
                </Grid>
              </Form>
            )}
          </Formik>
        )}
      </Box>
    </div>
  );
};

export default AddNewEvent;
